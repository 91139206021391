<app-navbar [partnerLogo]="partnerLogo" [wishlistLength]="wishlistLength" [puid]="puid" [initialFilter]="initialFilter">
</app-navbar>

<div class="wishlist-container container">
  <div>
    <button (click)="prevStep()" class="btn btn-link text-secondary mb-4 mr-4 d-flex align-items-center">
      <img src="assets/icons/arrow-left.svg" alt="Back" class="me-2" width="24" height="24" />
    </button>
  </div>


  <div class="wishlist-header d-flex justify-content-between align-items-center">
    <h2 class="wishlist-title ">
      <div class="d-flex align-items-center">
        <div class="heart-icon me-2">
          <img src="assets/icons/red-heart.svg" alt="heart" class="red-heart" />
        </div>
        <div class="title-shortlisted">Your Shortlisted Universities</div>
      </div>
    </h2>




    <!-- Button and Dropdown Wrapper -->
    <div class="position-relative d-inline-block">
      <!-- Main Download Button -->
      <button (click)="toggleDownloadOptions()" class="btn btn-primary d-flex align-items-center">
        Download Shortlist
        <img src="assets/icons/download-arrow.svg" alt="Download Icon" class="ms-2" width="24" height="24" />
      </button>

      <!-- Dropdown for PDF and Excel Options -->
      <div *ngIf="isDownloadOptionsVisible" class="download-options-container position-relative">
        <div class="download-options position-absolute bg-white border rounded shadow p-2 end-0">
        <button (click)="downloadPDF(); toggleDownloadOptions()" class="dropdown-item" > 
          Download as PDF
        </button>
        <button (click)="downloadExcel(); toggleDownloadOptions()" class="dropdown-item">
          Download as Excel
        </button>
        </div>
      </div>
    </div>
  </div>

  <div class="scroll-bar mt-4">
    <div id="wishlistContent" *ngIf="wishlist.length > 0">



      <div *ngFor="let course of wishlist" class="course-card  position-relative p-3" (click)="viewDetails(course)">
        <div class="course-info rounded p-3 ">
          <h3 class="university-name fw-semibold text-dark">{{ course.universityName |
            removeAffiliations }}</h3>
          <p class="course-name text-primary">{{ course.courseName }}</p>
          <div class="course-details d-flex align-items-center bg-light border p-2 rounded">
            <div class="details-row d-flex flex-wrap gap-3 ">
              <div class="detail-item d-flex align-items-center">
                <img src="assets/icons/map.svg" alt="Country Icon" class="detail-icon me-2" />
                <span class="fw-semibold">Country:</span>
                <div class="text-primary ms-2"> {{ course.country }}
                </div>
              </div>

              <div class="detail-item d-flex align-items-center">
                <img src="assets/icons/money.svg" alt="Tuition Icon" class="detail-icon me-2" />
                <span class="fw-semibold">Tuition Fees:</span>
                <div class="text-primary ms-2">
                  {{
                  course.country === 'US' ? '$' :
                  course.country === 'Germany' ? '€' :
                  course.country === 'Australia' ? 'AUD' :
                  course.country === 'UK' ? '£' : ''
                  }} {{ course.tuitionFees }}/year
                </div>
              </div>


              <div class="detail-item d-flex align-items-center">
                <img src="assets/icons/calendar-icon.svg" alt="Duration Icon" class="detail-icon me-2" />
                <span class="fw-semibold">Duration:</span>
                <div class="text-primary ms-2">
                  {{ course.courseDuration }}
                </div>
              </div>

              <div class="detail-item d-flex align-items-center">
                <img src="assets/icons/receipt-text.svg" alt="Application Fees Icon" class="detail-icon me-2" />
                <span class="fw-semibold">Application Fees:</span>
                <div class="text-primary ms-2">
                  {{ formatApplicationFee(course) }}
                </div>
              </div>

            </div>
          </div>


        </div>

        <div class="actions top-0 end-0 mt-3 me-3">
          <button class="remove-button btn btn-outline-danger d-flex align-items-center"
            (click)="removeFromWishlist(course); $event.stopPropagation()">
            <img src="assets/icons/trash.svg" alt="Remove Icon" class="me-2" width="16" height="16" />
            Remove
          </button>
        </div>
      </div>


    </div>
  </div>

  <div *ngIf="wishlist.length === 0" class="d-flex flex-column align-items-center justify-content-center mt-5">
    <img
      src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1737440005436Frame%201000002048.png"
      alt="No Universities Shortlisted" class="w-25 h-25 mb-3" />
    <h2 class="fs-4 fw-semibold text-primary mb-2">No Universities Shortlisted Yet</h2>
    <p class="text-secondary">Go back and shortlist the universities that fits you the best.</p>
  </div>


</div>