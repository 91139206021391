<div class="mb-10">
    <div class="p-1 scrollable-container mb-6">
        <ng-container *ngIf="!isLoading && !saveChangesWorkExp else showWorkExpEdit">
            <!-- Work Experience Header -->
            <div class="d-flex justify-content-between align-items-center mb-4">
                <h4 class="text-primary-dark"></h4>
                <button (click)="openEditSection('saveChangesWorkExp')" 
                        class="btn btn-primary d-flex align-items-center gap-1 px-2 py-1 rounded-2">
                    <i class="bi bi-plus-lg fs-5"></i>
                    <span class="fs-5">Add Work Experience</span>
                </button>
            </div>
    
            <!-- Work Experience List -->
            <div class="work-experience-list">
                <div class="experience-item mb-4 p-3 border rounded" *ngFor="let exp of workExperiences; let i = index">
                    <div class="d-flex justify-content-between align-items-start">
                        <div>
                            <h5 class="mb-1">{{exp.position}}</h5>
                            <h6 class="mb-2">{{exp.organizationName}}</h6>
                            <p class="mb-1">{{exp.jobProfile}}</p>
                            <small class="text-muted">
                                From: {{exp.workingFrom | date}} 
                                To: {{exp.currentlyWorking ? 'Currently working here' : (exp.workingEnd | date)}}
                            </small>
                        </div>
                        <div class="action-buttons">
                            <button class="btn btn-link text-primary" 
                                    (click)="editExperience(i)"
                                    [disabled]="isLoading">
                                <i class="bi bi-pencil"></i>
                            </button>
                            <button class="btn btn-link text-danger" 
                                    (click)="deleteExperience(i)"
                                    [disabled]="isLoading">
                                <i class="bi bi-trash"></i>
                            </button>
                        </div>
                    </div>
                </div>
    
                <div *ngIf="isLoading" class="position-fixed top-50 start-50 translate-middle">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
    
                <!-- No Experience Message -->
                <div *ngIf="workExperiences.length === 0 && !isLoading" class="text-center text-muted">
                    <p>No work experience added yet</p>
                </div>
            </div>
        </ng-container>
    
        <ng-template #showWorkExpEdit>
            <aerp-base-schema-form 
                *ngIf="model" 
                (onSubmit)="onSubmit($event)"
                [dataProcessor]="dataProcessor"
                [dataModel]="dataModel"
                [model]="model"
                [layout]="layoutWorkExp">
            </aerp-base-schema-form>
    
            <!-- Cancel Button -->
            <div class="d-flex justify-content-start mt-3 px-3">
                <button class="btn btn-outline-primary" (click)="cancelEdit()">
                    Cancel
                </button>
            </div>
        </ng-template>
    </div>
    </div>