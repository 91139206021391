import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { MediaService } from 'projects/apna-lib/src/lib/services/media.service'; 
import { ApiHelperService } from 'projects/apna-lib/src/lib/services/api-helper.service'; 
import { PassDataToEditLoanAppService } from '../formly-multi-field-file/pass-data-to-edit-loan-app.service';

@Component({
  selector: 'apnst-formly-admission-documents-file',
  templateUrl: './formly-admission-documents-file.component.html',
  styleUrls: ['./formly-admission-documents-file.component.scss']
})
export class FormlyAdmissionDocumentsFileComponent extends FieldType {
  mimeTypes = {
    "files": {
      type: [".jpg", ".jpeg", ".png", ".pdf"],
      mime: ["image/jpg", "image/jpeg", "image/png", "application/pdf"],
    },
    "document": {
      types: [".pdf"],
      mime: ["application/pdf"]
    },
    "image": {
      types: [".jpg", ".png"],
      mime: ["image/jpg", "image/jpeg", "image/png"]
    },
  }
  progress: number = 0;
  selectedFiles: any = [];

  @ViewChild("fileInput") fileInputRef: ElementRef<HTMLInputElement>
  constructor(
    private mediaService: MediaService,
    private cd: ChangeDetectorRef,
    private apiHelperService: ApiHelperService,
    private passDataToEditLoanAppEvent: PassDataToEditLoanAppService) {
    super();
  }
  get type() {
    return this.field.type;
  }

  uploadMedia(fileList: FileList) {
    console.info(fileList, 43)
    if (!fileList || fileList.length === 0) return;

    let existingFiles = this.formControl.value || [];
    const maxAllowed = this.to.maxFiles ?? 1;
    console.info(existingFiles)
    
    if (existingFiles.length + fileList.length > maxAllowed) {
      this.apiHelperService.openSnackBar(`You can upload up to ${maxAllowed} files only.`);
      return;
    }

    let uploadedFiles = [...existingFiles];

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);

      if (!this.mimeTypes["files"].mime.includes(file.type)) {
        this.fileInputRef.nativeElement.value = "";
        this.apiHelperService.openSnackBar("Invalid file type");
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        this.apiHelperService.openSnackBar("File size must be less than 5MB");
        return;
      }
      console.info("check", 70)
      this.mediaService.uploadMedia(file).subscribe((data: any) => {
        console.info("check ehre",72)
        if (data && data.body) {
          console.info(data,73)
          let uploadedFile = {
            filePath: data.body[0]["Location"],
            fileName: file.name,
            fileSize: file.size,
            mimeType: file.type
          };

          uploadedFiles.push(uploadedFile);

          if (uploadedFiles.length > maxAllowed) {
            this.apiHelperService.openSnackBar(`You can upload only ${maxAllowed} files.`);
            return;
          }

          
          this.formControl.setValue(uploadedFiles);

          this.fileInputRef.nativeElement.value = "";
          this.progress = 0;
        }
        this.cd.detectChanges();
      },
      (error) => {
        console.error("Upload failed:", error);
      }
      );
    }
  }


  generateTooltip(file: any): string {
    if (this.isImage(file)) {
        return `<img src="${file?.filePath}" class="img-thumbnail" style="max-width: 150px; max-height: 150px;" alt="Preview" />`;
    }
    return `<span>${file?.fileName || 'No preview available'}</span>`;
}


  async downloadSampleDocument(url: string) {
    await this.apiHelperService.downloadDocumentByS3Uri(url)
  }



  removeImage() {
    this.selectedFiles = [];
    this.formControl.setValue([]);
    this.formControl.markAsTouched();
    this.formControl.updateValueAndValidity();
  }
  

  removeFile(index: number): void {
    const files = this.formControl.value || [];
    files.splice(index, 1);
    console.info(files)
    this.formControl.setValue(files);
    this.formControl.markAsTouched();
    this.formControl.updateValueAndValidity();
}


  isImage(file: any): boolean {
    if (!file) return false;

    let url = typeof file === 'string' ? file : file?.filePath;

    if (typeof url !== 'string') {
      console.error("Invalid URL format for image preview:", file);
      return false;
    }

    const validExtensions = ["jpeg", "jpg", "gif", "png"];
    const ext = url.substring(url.lastIndexOf(".") + 1).toLowerCase();

    return validExtensions.includes(ext);
  }


  downloadLoanDocumentsByType(documentType: string) {
    this.passDataToEditLoanAppEvent.passDataToEditLoanAppEvent.emit(documentType);
  }
}
