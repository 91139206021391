<div class="table-wrapper">
  <div class="d-flex justify-content-end align-items-center mb-2">
    <div>
      <p class="text-muted">Total Records: <span class="fw-bold"> {{ matDataSource?.data?.length || 0 }} </span></p>
    </div>

    <div class="mt-n3">
      <mat-paginator [length]="matDataSource?.data.length" [pageSize]=pageSize [pageSizeOptions]=pageSizeOptions
        aria-label="Select page"></mat-paginator>
    </div>

  </div>

  <div class="table-responsive" [ngClass]="tableCss">

    <table mat-table [dataSource]="matDataSource" matSort (matSortChange)="customSort($event)"
      class="mat-elevation-z8 ">
      <ng-container class="text-center" *ngFor="let column of columnDefinitions" [matColumnDef]="column.key">
        <th mat-header-cell *matHeaderCellDef [hidden]="column.hidden" [ngClass]="showBorders?'show-borders':''">
          <ng-container *ngIf="column.isSelect">
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()">
            </mat-checkbox>
          </ng-container>

          <ng-container *ngIf="!column.isSelect">
            <ng-container *ngIf="column?.sortable">
              <div [mat-sort-header]="column.key">
                {{ column.label }}
              </div>
            </ng-container>

            <ng-container *ngIf="!column?.sortable">
              <span>{{ column.label }}</span>
            </ng-container>
          </ng-container>
        </th>
        <td mat-cell *matCellDef="let element" [hidden]="column.hidden" [ngClass]="showBorders?'show-borders':''">
          <!-- If column has dropdown -->
          <!-- Dropdown cell -->
          <ng-container *ngIf="column.isDropdown">
            <select class="form-select" (change)="onDropdownChange($event, element, column)">
              <option *ngFor="let option of dropdownData[column.key]" [value]="option.id"
                [selected]="option.id === element[column.key]">
                {{ option.name }}
              </option>
            </select>
          </ng-container>

          <!-- Select checkbox cell -->
          <ng-container *ngIf="column.isSelect">
            <mat-checkbox (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(element[selectColumnName??'id']) : null"
              [checked]="selection.isSelected(element[selectColumnName??'id'])">
            </mat-checkbox>
          </ng-container>

          <!-- Action cell -->
          <ng-container *ngIf="column.key === 'action'">
            <button (click)="onActionClick(element)" class="btn btn-link">
              <i class="bi bi-arrow-up-right"></i>
            </button>
          </ng-container>

          <ng-container *ngIf="column.key === 'update'">
            <button (click)="updateApplication(element)" class="btn btn-link">
              <i class="bi bi-arrow-up-right"></i>
            </button>

          </ng-container>

          <!-- Default text cell -->
          <ng-container
            *ngIf="!column.isDropdown && !column.isSelect && column.key !== 'action' && column.key !== 'update'">
            <div>
              {{ column.formatter ? column.formatter(element[column.key], element) : element[column.key] }}
            </div>
            <div *ngIf="column.subText" class="text-primary-dark medium py-1">
              {{ column.subText(element) }}
            </div>
          </ng-container>
        </td>

      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>