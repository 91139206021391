<!-- chat-app.component.html -->
<div class="chat-container">
    <div class="chat-header">
      <div class="chat-title">
        <i class="bi bi-chat-dots-fill"></i>
        Chat with Counsellor
      </div>
    </div>
  
    <div class="chat-input-area">
      <div class="input-container d-block">
        <div>
          <textarea 
            [(ngModel)]="newMessage" 
            placeholder="Type your message here..."
            class="message-input w-100"
            (keydown.enter)="sendMessage($event)">
          </textarea>
        </div>
        <div class="d-flex justify-content-end">
          <div class="attachment-input">
            <label for="file-upload" class="attachment-label">
              <i class="bi bi-paperclip"></i>
            </label>
            <input id="file-upload" type="file" class="hidden-file-input" (change)="handleFileUpload($event)">
          </div>
          
          <button class="send-button" (click)="sendMessage()" 
            [disabled]="isUploading || (!newMessage.trim() && uploadedFiles.length === 0)">
            Send
          </button>
        </div>
    </div>
      
      <!-- Show uploaded files preview -->
      <div *ngIf="uploadedFiles.length > 0" class="uploaded-files">
        <div *ngFor="let file of uploadedFiles; let i = index" class="file-item">
          <i class="bi bi-file-earmark"></i>
          <span>{{ file.name }}</span>
          <span class="file-size">({{ formatFileSize(file.size) }})</span>
          <button class="remove-file" (click)="removeFile(i)">
            <i class="bi bi-x"></i>
          </button>
        </div>
      </div>
    </div>
  
    <!-- Messages container -->
    <div class="messages-container" #scrollContainer>
      <div *ngIf="!chatData || chatData.messages.length === 0" class="no-messages">
        No messages yet. Start a conversation!
      </div>
  
      <div *ngFor="let message of chatData?.messages; let i = index">
        <div [ngClass]="getMessageClass(message)" class="message-wrapper">
          <!-- Avatar with initials -->
          <div class="avatar" [ngClass]="{'fa-avatar': isAdmin(message.userId), 'student-avatar': !isAdmin(message.userId)}">
            {{ getInitials(message.name) }}
          </div>
          
          <div class="message-content">
            <div class="message-header">
              <span class="message-sender">{{ message.name }}</span>
            </div>
            <div class="message-body">
              {{ message.message }}
            </div>
            
            <div *ngIf="message.attachment && message.attachment.length > 0" class="attachments">
              <div *ngFor="let attUrl of message.attachment" class="attachment">
                <a (click)="downloadAttachment(attUrl)" class="attachment-link">
                  <div class="attachment-info">
                    <i class="bi bi-file-earmark-text"></i>
                    <span>{{ getFileName(attUrl) }}</span>
                  </div>
                </a>
              </div>
            </div>
            
            <div class="message-time">
              {{ message.createdAt | date:'shortTime' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  
    
  </div>