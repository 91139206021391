import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-student-admission-profile',
  templateUrl: './student-admission-profile.component.html',
  styleUrls: ['./student-admission-profile.component.scss']
})
export class StudentAdmissionProfileComponent implements OnInit {

  @Input() admissionData: any;
  @Output() updateProfileSectionData = new EventEmitter<boolean>();
  active: 1 | undefined;

  ngOnInit(): void {
  }

  getUpdatedProfileData(ev:boolean){
    console.info(ev)
    if(ev){
      this.updateProfileSectionData.emit(true);
    }
  }

}
