<div class="row">
  <div class="sidebar border-end col-2 mb-4">
    <!-- Partner Name (Instead of Logo) -->

    <div class="fw-bold text-primary partner-logo">
      <img [src]="partnerLogo" alt="Logo">
    </div>

    <!-- Mobile Layout -->
    <div class="nav-mobile-layout d-md-none">
      <div class="row gx-2 gy-2">
        <div class="col-6">
          <a [routerLink]="['/results', puid]" routerLinkActive="active"
            class="nav-item w-100 text-center">Dashboard</a>
        </div>
        <div class="col-6">
          <a [routerLink]="['/wishlist', puid]" routerLinkActive="active" class="nav-item w-100 text-center">University
            Shortlist</a>
        </div>
        <div class="col-6">
          <a [routerLink]="['/personal-details', puid]" routerLinkActive="active" class="nav-item w-100 text-center">My
            Profile</a>
        </div>
        <div class="col-6">
          <button (click)="logout()" class="btn btn-danger w-100">
            <i class="bi bi-power me-1"></i> Logout
          </button>
        </div>
      </div>
    </div>



    <!-- Navigation Links -->
    <div class="nav-links mt-4">
      <a [routerLink]="['/results', puid]" routerLinkActive="active" class="nav-item">Dashboard</a>
      <a [routerLink]="['/personal-details', puid]" routerLinkActive="active" class="nav-item">My Profile</a>
      <a [routerLink]="['/wishlist', puid]" routerLinkActive="active" class="nav-item">University Shortlist</a>
      <button (click)="logout()" class="btn btn-danger d-flex align-items-center ms-2">
        <i class="bi bi-power me-1"></i>Logout
      </button>
    </div>
  </div>

  <div class="mb-10 col-10 p-4 mobile-container">
    <div class="p-2 scrollable-container container">



      <ng-container *ngIf="!isEditing && !saveChangesPersonal; else showPersonalEdit">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center">
          <h1 class="fw-bold">My Profile</h1>
          <button *ngIf="!isEditing" class="btn btn-primary d-flex align-items-center gap-1 px-2 py-1 rounded-2"
            (click)="openEditSection()">
            <i class="bi bi-pencil-square"></i> Edit
          </button>
        </div>

        <!-- Profile Card (Blue Box) -->
        <div class="top-bar my-2 p-3 bg-primary text-white">
          <!-- First Row -->
          <div class="row">
            <div class="col-md-3 profile-info pe-3">
              <i class="bi bi-person"></i>
              <strong *ngIf="!isEditing">{{ personalDetails.firstName }} {{ personalDetails.lastName }}</strong>
            </div>
            <div class="col-md-3 profile-info">
              <i class="bi bi-telephone"></i> <span>{{ personalDetails.phoneNumber }}</span>
            </div>
            <div class="col-md-3 profile-info">
              <i class="bi bi-calendar2-week"></i> <span>{{ personalDetails.targetIntakeMonth }} {{
                personalDetails.targetIntakeYear }}</span>
            </div>
            <div class="col-md-3 profile-info">
              <i class="bi bi-envelope"></i>
              <strong *ngIf="!isEditing">{{ personalDetails.email }}</strong>
            </div>
          </div>
          <!-- Second Row -->
          <div class="row mt-2">
            <div class="col-md-3 profile-info pe-3">
              <i class="bi bi-geo-alt"></i>
              <strong *ngIf="!isEditing">{{ personalDetails.targetCountry }}</strong>
            </div>
            <div class="col-md-3 profile-info">
              <i class="bi bi-mortarboard"></i>
              <strong *ngIf="!isEditing">{{ personalDetails.targetDegree }}</strong>
            </div>
            <div class="col-md-3 profile-info">
              <i class="bi bi-book"></i>
              <strong *ngIf="!isEditing">{{ personalDetails.targetCourse }}</strong>
            </div>
            <div class="col-md-3 profile-info">
              <i class="bi bi-cash-stack"></i> <span>{{ personalDetails.currency }} {{ personalDetails.budgetRange
                }}</span>
            </div>
          </div>
        </div>

        <!-- Under Graduation Score Section -->
        <div class="section-container my-2 " *ngIf="personalDetails?.academicHistory?.undergraduateMarks?.percentage">
          <h4>Under Graduation Score</h4>
          <div class="data-grid row">
            <div class="data-item col-md-3">
              <p class="data-label">College Name</p>
              <p class="data-value">
                <strong *ngIf="!isEditing">{{ personalDetails?.academicHistory?.undergraduateMarks?.undergradCollegeName
                  || 'N/A'
                  }}</strong>
              </p>
            </div>
            <div class="data-item col-md-3">
              <p class="data-label">Graduation Score</p>
              <p class="data-value">
                <strong *ngIf="!isEditing">
                  {{ personalDetails.academicHistory.undergraduateMarks.percentage + '%' }}
                </strong>
              </p>
            </div>
            <div class="data-item col-md-3">
              <p class="data-label">No. of Backlogs</p>
              <p class="data-value">
                <strong *ngIf="!isEditing">{{ personalDetails?.academicHistory?.undergraduateMarks?.degreeBacklogs ||
                  '0'
                  }}</strong>
              </p>
            </div>
          </div>
        </div>

        <div class="section-container my-2 " *ngIf="!personalDetails?.academicHistory?.undergraduateMarks?.percentage">
          <h4>High School Score</h4>
          <div class="data-grid row">
            <div class="data-item col-md-3">
              <p class="data-label">High School Board</p>
              <p class="data-value">
                <strong *ngIf="!isEditing">{{ personalDetails?.academicHistory?.highSchoolMarks?.highSchoolName || 'N/A'
                  }}</strong>
              </p>
            </div>

            <div class="data-item col-md-3">
              <p class="data-label">High School Score</p>
              <p class="data-value">
                <strong *ngIf="!isEditing">
                  {{ personalDetails.academicHistory.highSchoolMarks.percentage + '%' }}
                </strong>
              </p>
            </div>
          </div>
        </div>

        <!-- Divider -->
        <div class="divider my-2"></div>

        <!-- Test Scores Section -->
        <div class="section-container my-2 ">
          <h4>Test Scores</h4>
          <div class="data-grid row">
            <div class="data-item col-md-6">
              <p class="data-label">Aptitude Test Scores</p>
              <p class="data-value">
                <strong *ngIf="!isEditing">
                  {{ personalDetails?.entryTests?.length > 0 && personalDetails.entryTests[0]?.testName ?
                  personalDetails.entryTests[0].testName + ': ' + (personalDetails.entryTests[0].testScore ??
                  'NotAvailable') : 'No Test Data' }}
                </strong>
              </p>
            </div>
            <div class="data-item col-md-6">
              <p class="data-label">Language Test Score</p>
              <p class="data-value">
                <strong *ngIf="!isEditing">
                  {{ personalDetails?.entryTests?.length > 1 && personalDetails.entryTests[1]?.testName ?
                  personalDetails.entryTests[1].testName + ': ' + (personalDetails.entryTests[1].testScore ?? 'Not
                  Available') : 'No Test Data' }}
                </strong>
                <input *ngIf="isEditing" type="text" [(ngModel)]="personalDetails.entryTests[1].testScore"
                  placeholder="Language Test Score" class="form-control">
              </p>
            </div>
          </div>
        </div>

        <!-- Divider -->
        <div class="divider my-2"></div>

        <!-- Work Experience Section -->
        <div class="section-container my-2 ">
          <h4>Work Experience</h4>
          <p class="data-label">Years of Experience</p>
          <p class="data-value">
            <strong *ngIf="!isEditing">
              {{ personalDetails?.months_of_experience !== null && personalDetails?.months_of_experience !== undefined ?
              (personalDetails.months_of_experience / 12).toFixed(1) + ' years' : 'No Experience Data' }}
            </strong>
            <input *ngIf="isEditing" type="text" [(ngModel)]="personalDetails.months_of_experience"
              placeholder="Years of Experience" class="form-control">
          </p>
        </div>

      </ng-container>

      <ng-template #showPersonalEdit>
        <aerp-base-schema-form *ngIf="model" (onSubmit)="onSubmit($event)" [dataProcessor]="dataProcessor.bind(this)"
          [dataModel]="dataModel" [model]="model" [layout]="layoutPersonalInfo">
        </aerp-base-schema-form>
      </ng-template>
    </div>
  </div>
</div>