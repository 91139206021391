import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/core'; 
import { FormControl } from '@angular/forms';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
  selector: 'formly-field-intl-tel-input',
  template: `
    <ngx-intl-tel-input
      [id]="id" 
      [cssClass]="to['cssClass'] || 'custom'"
      [preferredCountries]="to['preferredCountries'] || [countryISO.India, countryISO.UnitedStates]"
      [enableAutoCountrySelect]="to['enableAutoCountrySelect'] !== false"
      [enablePlaceholder]="to['enablePlaceholder'] !== false"
      [searchCountryFlag]="to['searchCountryFlag'] !== false"
      [searchCountryField]="to['searchCountryField'] || [searchCountryField.Iso2, searchCountryField.Name]"
      [selectFirstCountry]="to['selectFirstCountry'] !== false"
      [phoneValidation]="to['phoneValidation'] !== false"
      [separateDialCode]="to['separateDialCode'] !== false"
      [numberFormat]="to['numberFormat'] || phoneNumberFormat.National"
      [formControl]="formControlTyped"
      [formlyAttributes]="field">
    </ngx-intl-tel-input>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldIntlTelInputComponent extends FieldType {
  searchCountryField = SearchCountryField;
  countryISO = CountryISO;
  phoneNumberFormat = PhoneNumberFormat;

  get formControlTyped(): FormControl {
    return this.formControl as FormControl;
  }
}
