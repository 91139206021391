import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MediaService } from 'projects/apna-lib/src/lib/services/media.service';
import { ApiHelperService } from 'projects/apna-lib/src/lib/services/api-helper.service';


interface ChatMessage {
  _id?: string;
  name: string;
  userId: string;
  message: string;
  createdAt: string;
  attachment: string[];
}

interface ChatData {
  applicationId: string;
  applicantId: string;
  messages: ChatMessage[];
}

@Component({
  selector: 'fa-chat-app',
  templateUrl: './chat-app.component.html',
  styleUrls: ['./chat-app.component.scss']
})
export class ChatAppComponent {
  @Input() chatData: ChatData | null = null;
  @Input() currentUserId: string = '';
  @Input() currentUserName: string = '';
  @Output() sendMessageEvent = new EventEmitter<any>();
  @Output() uploadFileEvent = new EventEmitter<{file: File, formData: FormData}>();
  @Output() uploadStatusChange = new EventEmitter<{inProgress: boolean, file: string, url?: string}>();
  
  @ViewChild('scrollContainer') private scrollContainer!: ElementRef;
  
  newMessage: string = '';
  uploadedFiles: File[] = [];
  isUploading: boolean = false;
  fileUrls: {[fileName: string]: string} = {};
  
  constructor(
    private mediaService: MediaService,
    private apiHelper: ApiHelperService
  ) {}
  
  ngOnInit(): void {
    this.scrollToBottom();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chatData'] && !changes['chatData'].firstChange) {
      setTimeout(() => this.scrollToBottom(), 100);
    }
  }
  
  // ngAfterViewChecked() {
  //   this.scrollToBottom();
  // }
  
  // Determine message class based on user role
  getMessageClass(message: ChatMessage): string {
    if (message.userId === this.currentUserId) {
      return 'user-message-dark';
    } 
    return 'user-message-light';
  }
  
  
  // Check if message is from admin/FA team
  isAdmin(userId: string): boolean {
    return userId !== this.currentUserId && userId !== this.chatData?.applicantId;
  }

  getInitials(name: string): string {
    if (!name) return '';
    const names = name.split(' ');
    if (names.length === 1) return names[0].charAt(0).toUpperCase();
    return (names[0].charAt(0) + names[names.length - 1].charAt(0)).toUpperCase();
  }
  
  // Extract filename from attachment URL
  getFileName(url: string): string {
    if (!url) return '';

    const parts = url.split('/');
    let encodedFileName = parts[parts.length - 1];
    let decodedFileName = decodeURIComponent(encodedFileName);
    let cleanedFileName = decodedFileName.replace(/^[^a-zA-Z]+/, '');

    return cleanedFileName;
  }

  
  // Handle file upload
  handleFileUpload(event: any): void {
    const files = event.target.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!this.uploadedFiles.some(f => f.name === file.name)) {
          this.uploadedFiles.push(file);
        }
      }
      
      this.isUploading = true;
      
      // Process each file
      this.processUploadedFiles();
    }
  }
  
  processUploadedFiles(): void {
    const filesToUpload = this.uploadedFiles.filter(file => 
      !this.fileUrls[file.name] || this.fileUrls[file.name] === '');
      
    if (filesToUpload.length === 0) {
      this.isUploading = false;
      return;
    }
  
    let uploadedCount = 0;
    
    // Upload each file
    filesToUpload.forEach(file => {
      this.mediaService.uploadMedia(file).subscribe({
        next: (response: any) => {
          if (response && response.progress) {
          } else if (response && response.body) {
            try {
              if (Array.isArray(response.body) && response.body.length > 0) {
                for (const item of response.body) {
                  if (item && item.Location) {
                    this.fileUrls[file.name] = item.Location;
                    break;
                  }
                  
                  for (const key in item) {
                    if (item[key] && item[key].Location) {
                      this.fileUrls[file.name] = item[key].Location;
                      break;
                    }
                  }
                }
              }

              uploadedCount++;

              if (uploadedCount === filesToUpload.length) {
                this.isUploading = false;
              }
            } catch (error) {
              console.error('Error processing response:', error);
              uploadedCount++;

              if (uploadedCount === filesToUpload.length) {
                this.isUploading = false;
              }
            }
          }
        },
        error: (error) => {
          console.error('Error uploading file:', error);
          uploadedCount++;

          const index = this.uploadedFiles.findIndex(f => f.name === file.name);
          if (index !== -1) {
            this.uploadedFiles.splice(index, 1);
          }

          if (uploadedCount === filesToUpload.length) {
            this.isUploading = false;
          }
        }
      });
    });
  }

  
  // Remove uploaded file
  removeFile(index: number): void {
    this.uploadedFiles.splice(index, 1);
  }
  
  // Format file size
  formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
    
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }
  

  downloadAttachment(url: string): void {
    if (!url) return;
    this.apiHelper.downloadDocumentByS3Uri(url);
  }

  // Send message
  sendMessage(event?: KeyboardEvent): void {
    if (event) {
      event.preventDefault();
    }
    
    if (this.isUploading) {
      return;
    }
    
    if (!this.newMessage.trim() && this.uploadedFiles.length === 0) {
      return;
    }

    const attachments = this.uploadedFiles.map(file => ({
      fileName: file.name, 
      filePath: this.fileUrls[file.name] || ''
    }));
    
    // Create message data
    const messageData = {
      message: this.newMessage.trim(),
      attachments: attachments
    };

    this.sendMessageEvent.emit(messageData);
    
    // Clear local state
    this.newMessage = '';
    this.uploadedFiles = [];
    this.fileUrls = {};
    this.scrollToBottom();
  }
  
  private scrollToBottom(): void {
    try {
      this.scrollContainer.nativeElement.scrollTop = 
        this.scrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }


  handleUploadStatusChange(status: {inProgress: boolean, file: string, url?: string}): void {
    if (!status.inProgress && status.url) {
      this.fileUrls[status.file] = status.url;
    } else if (!status.inProgress && !status.url) {
      const index = this.uploadedFiles.findIndex(f => f.name === status.file);
      if (index !== -1) {
        this.uploadedFiles.splice(index, 1);
      }
    }
  }
}