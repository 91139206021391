
    <div class="container row mb-8">
        <div class="col-12 mb-6">
            <div class="d-flex align-items-center justify-content-between mb-4">
                <h4 class="text-dark">Unpaid Application Fees</h4>
                <button class="btn btn-outline-primary rounded rounded-1 button-outline-class"
                    (click)="scrollToPaymentHistory()">View
                    Payment
                    History</button>
            </div>
            <div *ngIf="!loading">
                <div *ngIf="dataColumns?.length>0">
                    <fa-global-data-table [dataSource]="dataColumns" [displayedColumns]="columnsToDisplay" class="mb-4"
                        [tableCss]="'rounded-1 border-end-1 mb-4'" [columnDefinitions]="studentTableColumns"
                        (selectionOutput)="selectedReceivers($event)" [showBorders]=true>
                    </fa-global-data-table>
                    <div class="d-flex justify-content-end align-items-center pt-3">
                        <p class="mb-0 fs-6 text-muted me-3">Total Fees: <span class="fw-bold fs-5 text-dark">INR
                                {{indianCurrencyFormat(payableAmount??0)}}</span></p>
                        <div class="d-flex gap-2">
                            <button *ngIf="payableAmount>0"
                                class="btn btn-outline-primary rounded-1 button-outline-class">Request
                                Payment</button>
                            <button *ngIf="payableAmount>0" class="btn btn-primary rounded-1  button-class"
                                (click)="proccedToPayment(payableAmount)">Pay Now</button>

                            <button *ngIf="payableAmount===0 && payable===true"
                                class="btn btn-primary rounded-1  button-class"
                                (click)="proccedToPayment(payableAmount)">Pay Directly Now</button>
                        </div>
                    </div>
                </div>
                <div class="text-center bg-light-gray border rounded rounded-1 p-6 no-fees-paid  mt-3"
                    *ngIf="dataColumns?.length<=0">
                    <h4>No Fees to be Paid</h4>
                </div>
            </div>
            <div *ngIf="loading">
                <p class="text-center fs-4 text-primary-dark">Loading...</p>
            </div>
        </div>
        <hr class="border-class mb-4" />

        <!-- <div class="col-12 mb-6">
            <h4 class="text-dark">App fee confirmation pending by FA</h4>
            <div *ngIf="!applicationFeesLoading">
                <div *ngIf="dataVerifiedColumns?.length>0">
                    <fa-global-data-table [dataSource]="dataVerifiedColumns"
                        [displayedColumns]="verifiedColumnsToDisplay" class="mb-4" [tableCss]="'rounded-1 mb-4'"
                        [columnDefinitions]="studentTableVerifyColumns" [showBorders]=true></fa-global-data-table>
                </div>

                <div class="text-center bg-light-gray border rounded rounded-1 p-6 no-fees-paid mt-3"
                    *ngIf="dataVerifiedColumns?.length<=0">
                    <h4>No Application Fee confirmation pending</h4>
                </div>
            </div>
            <div *ngIf="applicationFeesLoading">
                <p class="text-center fs-4 text-primary-dark">Loading...</p>
            </div>
        </div> -->

        <!-- <hr class="border-class mb-4" /> -->
        <div class="col-12 mt-6 " #paymentHistory id="payment-history">
            <div class="d-flex align-items-center justify-content-between mb-4">
                <h4 class="text-dark">Payment History</h4>
                <p class="mb-0 fs-6 text-muted">Total Fees Paid: <span class="fw-bold fs-5 text-dark">INR
                        {{indianCurrencyFormat(amountPaid??0)}}</span></p>
            </div>
            <div *ngIf="!paymentTableLoading">
                <div *ngIf="paymentColumnsData?.length>0">
                    <fa-global-data-table [dataSource]="paymentColumnsData" [displayedColumns]="paymentColumnsToDisplay"
                        class="mb-4" [tableCss]="'rounded-1 mb-4'" [columnDefinitions]="paymentTableColumns"
                        [showBorders]=true>
                    </fa-global-data-table>
                </div>
                <div *ngIf="paymentColumnsData?.length<=0"
                    class="text-center bg-light-gray border rounded rounded-1 p-6 no-fees-paid  mt-3">
                    <h4>No Payments Made Yet</h4>
                </div>
            </div>
            <div *ngIf="paymentTableLoading">
                <p class="text-center fs-4 text-primary-dark">Loading...</p>
            </div>
        </div>


        <hr class="border-class mt-4" />
    </div>
