<div class="mx-3">
    <ng-container *ngIf="isSingleUpload">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active"
            class="nav-tabs row row-cols-1 row-cols-md-3 custom-active rounded-2 text-dark bg-light px-3 py-2 flex-nowrap overflow">
            <li (click)="changeCategory('generalDocs')" [ngbNavItem]="1">
                <a class="text-dark text-center" ngbNavLink>General Docs</a>
                <ng-template ngbNavContent>
                    <div class="mb-4">
                        <fa-admission-document-upload (emitDocumentData)="updateDocumentUpload($event)"
                            *ngIf="uploadFormData" [data]="uploadFormData"></fa-admission-document-upload>
                    </div>
                </ng-template>
            </li>

            <li [disabled]="applicationDocLock ? true : false" [class.disabled]="applicationDocLock"
                (click)="changeCategory('uploadedByFA')" [ngbNavItem]="3">
                <a class="text-dark text-center" ngbNavLink>University Uploaded Documents<span *ngIf="applicationDocLock"><i
                            class="bi bi-lock"></i></span></a>
                <ng-template ngbNavContent>
                    <div class="mb-4">
                        <fa-admission-document-upload (documentUploaded)="updateDocumentUpload($event)"
                            *ngIf="uploadFormData" [data]="uploadFormData"></fa-admission-document-upload>
                    </div>

                </ng-template>
            </li>
            <li [disabled]="applicationDocLock ? true : false" [class.disabled]="applicationDocLock"
                (click)="changeCategory('additionalRequests')" [ngbNavItem]="4">
                <a class="text-dark text-center" ngbNavLink>Additional Requests <span *ngIf="applicationDocLock"><i
                            class="bi bi-lock"></i></span></a>
                <ng-template ngbNavContent>
                    <div class="mb-4">
                        <fa-admission-document-upload (documentUploaded)="updateDocumentUpload($event)"
                            *ngIf="uploadFormData" [data]="uploadFormData"></fa-admission-document-upload>
                    </div>
                </ng-template>
            </li>

        </ul>

        <div [ngbNavOutlet]="nav" class="nav-outlet"></div>
    </ng-container>
</div>