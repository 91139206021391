<div class="main-container">
    <div class="mb-3 d-flex w-100">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs left-nav-bar col-md-2 display-set">
        
        <li class="me-3 nav-item" [ngbNavItem]="5">
          <a class="link" ngbNavLink [routerLink]="['/results', puid]">Dashboard</a>
          <ng-template ngbNavContent></ng-template>
        </li>
        
        <li class="me-3 nav-item" [ngbNavItem]="6">
          <a class="link" ngbNavLink [routerLink]="['/wishlist', puid]">University Shortlist</a>
          <ng-template ngbNavContent></ng-template>
        </li>

        <li class="me-3" [ngbNavItem]="1">
          <a class="link" ngbNavLink>My Profile</a>
          <ng-template ngbNavContent>
            <app-student-admission-profile *ngIf="studentData" [admissionData]="studentData" 
            (updateProfileSectionData)="getUpdateProfileData($event)">
            </app-student-admission-profile>
          </ng-template>
        </li>
        <li class="me-3" [ngbNavItem]="2">
          <a ngbNavLink class="disabled-tab">My Documents <i class="bi bi-lock"></i></a>
          <ng-template ngbNavContent>
            <app-student-admission-documents *ngIf="studentId && applicantId" [applicantId]="applicantId" 
            [studentId]="studentId">
            </app-student-admission-documents>
          </ng-template>
        </li>
        
        <li class="me-3" [ngbNavItem]="4">
          <a class="link" ngbNavLink>Payments <i class="bi bi-lock"></i></a>
          <ng-template ngbNavContent>
            <fa-payments *ngIf="applicantData" [applicantData]="applicantData">
            </fa-payments>
          </ng-template>
        </li>


        <li class="me-3" [ngbNavItem]="3">
          <a ngbNavLink class="disabled-tab">My Applications <i class="bi bi-lock"></i></a>
          <ng-template ngbNavContent>
            <app-student-admission-applications *ngIf="studentId && applicantId" 
              [applicantId]="applicantId"
              [studentId]="studentId"
              [admissionData]="studentData"
              (updateProfileSectionData)="getUpdateProfileData($event)">
            </app-student-admission-applications>
          </ng-template>
        </li>

      </ul>
  
      <main>
        <div [ngbNavOutlet]="nav" class="mt-4 p-4"></div>
      </main>
    </div>
</div> 