<div class="row mb-10">
    <div class="col-5 card-container container-height mb-4" #scrollContainer>
        <!-- Loading Spinner -->
        <div *ngIf="loading" class="text-center">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <!-- Error Message -->
        <div *ngIf="error" class="alert alert-danger" role="alert">
            {{error}}
        </div>

        <!-- Applications List -->
        <div>
            <div class="mb-4" *ngFor="let application of applicationData">
                <div class="application-card h-100">
                    <div class="application-card h-100" 
                     [ngClass]="{'selected': application._id === selectedApplicationId}"
                     (click)="selectApplication(application._id)">

                    <div class="row">
                        <div class="col-12">
                            <h1 class="heading">{{application?.wishlist[0]?.courseName}}</h1>
                            <h2 class="university">{{application?.wishlist[0]?.universityName}}</h2>
                        </div>
                    </div>
                    
                    <div class="row align-items-center mb-3">
                        <div class="col-6">
                            <span class="reference-number">
                                <i class="bi bi-file-text"></i>
                                ID: {{ application?.applicationId}}
                            </span>
                        </div>
                        <div class="col-6 text-end">
                            <span class="timestamp">
                                <i class="bi bi-clock"></i>
                                {{application?.createdAt | date:'MM/dd/yyyy'}}
                            </span>
                        </div>
                    </div>

                    <!-- Status Box -->
                    <div class="status-box mt-auto">
                        <div class="row">
                            <div class="col-12">
                                <p class="status-label d-inline">Status: </p>
                                <p class="status-value d-inline">{{application?.applicationStatus}}</p>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!loading && !error && (!applicationData || applicationData.length === 0)" 
            class="alert alert-info text-center">
            No applications found.
        </div>
    </div>

    <div class="container col-7 container-height">
        <div *ngIf="!selectedApplicationId" class="select-application-prompt">
            Please select an application to start chatting
        </div>
        <fa-chat-app  
            *ngIf="selectedApplicationId"
            [chatData]="chatData"
            [currentUserId]="currentUserId"
            [currentUserName]="currentUserName"
            (sendMessageEvent)="handleSendMessage($event)"
            (uploadFileEvent)="handleFileUpload($event)">
        </fa-chat-app>
    </div>
</div>