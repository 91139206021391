import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ApiHelperService } from 'projects/apna-lib/src/lib/services/api-helper.service';

@Component({
  selector: 'fa-global-data-table-apps',
  templateUrl: './global-data-table-apps.component.html',
  styleUrls: ['./global-data-table-apps.component.scss'],
})
export class GlobalDataTableAppsComponent {
  constructor(
    private matDialogRef: MatDialogRef<GlobalDataTableAppsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiHelper: ApiHelperService
  ) {}
  @Output() private filterChanges = new EventEmitter<any>();
  updateFeesDataModel: string = 'university-students/update-course-fees';
  updateFeesModel: any = {};
  updateFeesLayout: FormlyFieldConfig[] = [];
  currencyOptions = [
    {
      label: 'USD',
      value: 'USD',
    },
    {
      label: 'CAD',
      value: 'CAD',
    },
    {
      label: 'AUD',
      value: 'AUD',
    },
    {
      label: 'EUR',
      value: 'EUR',
    },
    {
      label: 'GBP',
      value: 'GBP',
    },
  ];

  ngOnInit(): void {
    console.info(this.data);
    this.dataPreprocessor();
    this.setLayout();
  }

  setLayout() {
    this.updateFeesLayout = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12 col-md-6 rounded-1',
            key: 'universityName',
            type: 'input',
            templateOptions: {
              required: true,
              readonly: true,
              placeholder: 'Enter University Name',
              label: 'University Name',
            },
          },

          {
            className: 'col-12 col-md-6',
            key: 'courseName',
            type: 'input',
            templateOptions: {
              placeholder: 'Enter Course Name',
              label: 'Course Name',
              readonly: true,
            },
          },

          {
            fieldGroupClassName: 'row',
            key: 'applicationData',
            fieldGroup: [
              {
                className: 'col-12 col-md-6',
                key: 'currency',
                type: 'select',
                templateOptions: {
                  label: 'Select Currency',
                  placeholder: 'Select Currency',
                  options: this.currencyOptions,
                },
              },

              {
                className: 'col-12 col-md-6',
                key: 'applicationFees',
                type: 'input',
                templateOptions: {
                  placeholder: 'Enter Application Fees',
                  label: 'applicationFees',
                },
              },

              {
                className: 'col-12 col-md-6',
                key: 'tuitionFeesCurrency',
                type: 'select',
                templateOptions: {
                  label: 'Select Tuition Fees Currency',
                  placeholder: 'Select Tuition Fees Currency',
                  options: this.currencyOptions,
                },
              },

              {
                className: 'col-12 col-md-6',
                key: 'tuitionFees',
                type: 'input',
                templateOptions: {
                  placeholder: 'Enter Tuition Fees',
                  label: 'Tuition Fees',
                },
              },
            ],
          },
          {
            type: 'button',
            templateOptions: {
              type: 'submit',
              text: 'Submit',
              className: 'd-grid',
              btnType: 'primary rounded rounded-1 fw-bold fs-5 w-344',
            },
          },
        ],
      },
    ];
  }

  dataPreprocessor() {
    this.updateFeesModel = { ...this.data };
    this.updateFeesModel.applicationData =
      this.updateFeesModel.applicationData || {};
    this.updateFeesModel.applicationData.currency = this.data?.currency;
    this.updateFeesModel.applicationData.applicationFees =
      this.data?.applicationFees;
    this.updateFeesModel.applicationData.tuitionFees = this.data?.tuitionFees;
  }

  onSubmitFeesModel(event: any) {
    delete event?.data.isAppFeeCheckedByFA;
    const courseData = {
      courseId: event.data.courseId,
      courseName: event.data.courseName,
      universityName: event.data.universityName,
      courseDuration: event.data.courseDuration,
      addedThrough: event?.data?.addedThrough,
      degree: event.data.degree,
      isAppFeeCheckedByFA: true,
      currency: event?.data?.applicationData?.currency,
      applicationFees: event?.data?.applicationData?.applicationFees,
      tuitionFees: event?.data?.applicationData?.tuitionFees,
      test_scores: event?.data?.test_scores,
      tuitionFeesCurrency: event?.data?.applicationData?.tuitionFeesCurrency,
    };

    this.apiHelper
      .updateDatawithShortlistingUrl(
        this.updateFeesDataModel,
        this.data.applicationId,
        {
          courseData: courseData,
        }
      )
      .subscribe((res) => {
        this.matDialogRef.close();
        this.filterChanges.emit();
      });
  }
}
