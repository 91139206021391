import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerService } from '../../services/partner.service';

@Component({
  selector: 'app-student-profile-page',
  templateUrl: './student-profile-page.component.html',
  styleUrls: ['./student-profile-page.component.scss']
})
export class StudentProfilePageComponent implements OnInit {
  puid: string = ''; // Stores PUID from the URL
  partnerLogo: string = ''; // Stores Partner Logo URL

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private partnerService: PartnerService
  ) {

  }

  ngOnInit(): void {
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    this.puid = puid

    if (puid) {
      this.fetchPartnerLogo(this.puid);
    }

  }


  fetchPartnerLogo(puid?: string): void {
    this.partnerService.getPartnerLogo(puid).subscribe(
      (response) => {
        if (response?.data?.company?.logo) {
          this.partnerLogo = response.data.company.logo;
          console.info("Fetched Partner Logo URL:", this.partnerLogo);
        } else {
          console.warn("No partner logo found for PUID:", puid);
        }
      },
      (error) => {
        console.error('Error fetching partner logo:', error);
      }
    );
  }
}
