import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServicesModule } from 'projects/apna-lib/src/lib/services/services.module';
import { environment } from '../environments/environment';

// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { MatDialogModule } from '@angular/material/dialog';
import { ConfigService } from 'projects/apna-lib/src/lib/services/config.service';
import { ComponentsModule } from 'projects/apna-lib/src/lib/components/components.module';
import { DatePipe } from '@angular/common';
import { RecaptchaModule } from 'ng-recaptcha';
import { AdmissionModule } from './admission/admission.module';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    ServicesModule.forRoot(environment),
    NgxSpinnerModule,
    MatDialogModule,
    AdmissionModule,
    RecaptchaModule 
  ],
  providers: [
    // ConfigService,
    { provide: LOCALE_ID, useValue: 'en-IN' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'INR' },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }