<div class="row">
  <!-- Main Content -->

  <!-- Left Section -->
  <div class="col-md-6 bg-light left-half">
    <div class="d-flex-grow d-flex flex-column align-items-center justify-content-center">
      <img
        src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729246686447Illustrations%201.png"
        alt="Education Icon" class="w-64 h-64 mb-6">
      <h1 class="text-primary fw-semibold fs-2 mb-2">
        Start Your Next Learning
      </h1>
      <h1 class="text-primary fw-semibold fs-2">
        Adventure!
      </h1>

    </div>
  </div>

  <!-- Right Section -->
  <div class="col-md-6 right-half">
    <div class="max-w-md mx-auto">
      <h1 class="fs-2 fw-bold mb-3 ms-2">Login</h1>

      <!-- Mobile Number Form -->
      <div class="container" *ngIf="enterMobileStep">
        <div class="content">
          <aerp-base-schema-form *ngIf="model" [model]="model" [dataProcessor]="dataProcessor.bind(this)"
            [dataModel]="dataModel" (onSubmit)="onSubmit($event)" [layout]="layout">
          </aerp-base-schema-form>
        </div>

        <!-- Button Container -->
        <div class="button-container">
          <button type="button" class="btn btn-back" (click)="goBack()">
            Back
          </button>
          <button type="button" [disabled]="processingBtn" class="btn btn-submit" (click)="onSubmit(model)">
            {{ processingBtn ? 'Processing...' : 'Send OTP' }}
          </button>
        </div>
      </div>


      <!-- OTP Form -->
      <div *ngIf="enterOtpStep" class="mt-6">
        <aerp-base-schema-form [dataModel]="otpDataModel" [model]="otpModel" [layout]="otpFields">
        </aerp-base-schema-form>

        <!-- Timer/Resend Section -->
        <div class="mt-4">
          <ng-container *ngIf="timer >= 0 && timer !== 29; else resendBlock">
            <p class="text-gray-600">Didn't receive it? Resend OTP in {{timer}}s</p>
          </ng-container>
          <ng-template #resendBlock>
            <p *ngIf="!processingBtn" class="text-blue-600 underline cursor-pointer" (click)="sendOtp('resendOtp')">
              Resend OTP
            </p>
          </ng-template>
        </div>

        <!-- OTP Action Buttons -->
        <div class="d-flex gap-4 mt-6">
          <button type="button" class="flex-grow-1 px-3 py-2 border border-primary text-primary rounded-3 hover:bg-dark"
            (click)="goToMobileNumber()">
            Back
          </button>
          <button type="button" [disabled]="processingBtn"
            class="d-flex-1 px-4 py-2 bg-primary text-white border border-primary rounded-3 hover:bg-dark"
            (click)="submitOtp()">
            {{ processingBtn ? 'Processing...' : 'Verify & Continue' }}
          </button>
        </div>
      </div>
    </div>
  </div>

</div>