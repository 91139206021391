<div class="mb-32">
    <div class="p-2 scrollable-container container">
        <ng-container *ngIf="!isLoading && !saveChangesPersonal else showPersonalEdit">
            <div class="d-flex justify-content-between align-items-center">
                <h4 class="text-primary-dark"></h4>
                <button (click)="openEditSection('saveChangesPersonal')" 
                        class="btn btn-primary d-flex align-items-center gap-1 px-2 py-1 rounded-2">
                    <i class="bi bi-plus-lg fs-5"></i>
                    <span class="fs-5">Add Personal Info</span>
                </button>
            </div>
            
            <!-- Personal Details -->
            <h5 class="">Personal Information</h5>
            <div class="row">
                <div class="col-md-4">
                    <label class="mb-1">Date of Birth*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.dateOfBirth}">
                        {{userDetails?.personalInformation?.dateOfBirth ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="mb-1">Gender*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.gender}">
                        {{userDetails?.personalInformation?.gender ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="mb-1">Marital Status*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.maritalStatus}">
                        {{userDetails?.personalInformation?.maritalStatus ?? 'Enter Details Here'}}
                    </p>
                </div>
            </div>
    
            <hr class="my-4 border border-secondary" style="height: 1px;">
    
            <!-- Mailing Address -->
            <h5 class="mt-4">Mailing Address</h5>
            <div class="row">
                <div class="col-md-4">
                    <label class="mb-1">Address Line 1*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.mailingAddress?.line1}">
                        {{userDetails?.personalInformation?.mailingAddress?.line1 ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="mb-1">Address Line 2*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.mailingAddress?.line2}">
                        {{userDetails?.personalInformation?.mailingAddress?.line2 ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="mb-1">Country*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.mailingAddress?.country}">
                        {{userDetails?.personalInformation?.mailingAddress?.country ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="mb-1">City*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.mailingAddress?.city}">
                        {{userDetails?.personalInformation?.mailingAddress?.city ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="mb-1">State*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.mailingAddress?.state}">
                        {{userDetails?.personalInformation?.mailingAddress?.state ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="mb-1">Pincode*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.mailingAddress?.pincode}">
                        {{userDetails?.personalInformation?.mailingAddress?.pincode ?? 'Enter Details Here'}}
                    </p>
                </div>
            </div>
    
            <hr class="my-4 border border-secondary" style="height: 1px;">
    
    
            <!-- Permanent Address -->
            <h5 class="mt-4">Permanent Address</h5>
            <div class="row">
                <div class="col-md-4">
                    <label class="mb-1">Address Line 1*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.permanentAddress?.line1}">
                        {{userDetails?.personalInformation?.permanentAddress?.line1 ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="mb-1">Address Line 2*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.permanentAddress?.line2}">
                        {{userDetails?.personalInformation?.permanentAddress?.line2 ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="mb-1">Country*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.permanentAddress?.country}">
                        {{userDetails?.personalInformation?.permanentAddress?.country ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="mb-1">City*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.permanentAddress?.city}">
                        {{userDetails?.personalInformation?.permanentAddress?.city ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="mb-1">State*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.permanentAddress?.state}">
                        {{userDetails?.personalInformation?.permanentAddress?.state ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="mb-1">Pincode*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.permanentAddress?.pincode}">
                        {{userDetails?.personalInformation?.permanentAddress?.pincode ?? 'Enter Details Here'}}
                    </p>
                </div>
            </div>
    
            <hr class="my-4 border border-secondary" style="height: 1px;">
    
    
            <!-- Passport Information -->
            <h5 class="mt-4">Passport Information</h5>
            <div class="row">
                <div class="col-md-4">
                    <label class="mb-1">Passport Number*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.passport?.number}">
                        {{userDetails?.personalInformation?.passport?.number ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="mb-1">Issue Date*</label>
                    <p class="border  rounded-1 p-1"  [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.passport?.issueDate}">
                        {{userDetails?.personalInformation?.passport?.issueDate ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="mb-1">Expiry Date*</label>
                    <p class="border  rounded-1 p-1"  [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.passport?.expiryDate}">
                        {{userDetails?.personalInformation?.passport?.expiryDate ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="mb-1">Issue Country*</label>
                    <p class="border  rounded-1 p-1"  [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.passport?.isssueCountry}">
                        {{userDetails?.personalInformation?.passport?.isssueCountry ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="mb-1">City of Birth*</label>
                    <p class="border  rounded-1 p-1"  [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.passport?.cityOfBirth}">
                        {{userDetails?.personalInformation?.passport?.cityOfBirth ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="mb-1">Country of Birth*</label>
                    <p class="border  rounded-1 p-1"  [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.passport?.countryOfBirth}">
                        {{userDetails?.personalInformation?.passport?.countryOfBirth ?? 'Enter Details Here'}}
                    </p>
                </div>
            </div>
    
            <hr class="my-4 border border-secondary" style="height: 1px;">
    
    
            <!-- Background Information -->
            <h5 class="mt-4">Nationality</h5>
            <div class="row">
                <div class="col-md-6">
                    <label class="mb-1">Nationality*</label>
                    <p class="border  rounded-1 p-1"  [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.nationality?.country}">
                        {{userDetails?.personalInformation?.nationality?.country ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-6">
                    <label class="mb-1">Citizenship*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.nationality?.citizenship}">
                        {{userDetails?.personalInformation?.nationality?.citizenship ?? 'Enter Details Here'}}
                    </p>
                </div>
                        <!-- Multiple Citizenship -->
                <div class="row mt-3">
                    <div class="col-md-6">
                        <label class="mb-1">Does the applicant have multiple citizenship?*</label>
                        <div class="d-flex w-100 gap-2">
                            <!-- For nationality and background sections -->
                            <p class="border rounded-1 p-1 flex-fill" [ngClass]="{'placeholder-text': userDetails?.personalInformation?.nationality?.hasMultipleCitizenship === null}">
                                {{userDetails?.personalInformation?.nationality?.hasMultipleCitizenship === null ? 'Enter Details Here' : 
                                  userDetails?.personalInformation?.nationality?.hasMultipleCitizenship ? 'Yes' : 'No'}}
                            </p>
                            <p *ngIf="userDetails?.personalInformation?.nationality?.hasMultipleCitizenshipCountry" 
                               class="border rounded-1 p-1 flex-fill">
                                {{userDetails?.personalInformation?.nationality?.hasMultipleCitizenshipCountry}}
                            </p>
                        </div>
                    </div>
                    
                    <div class="col-md-6">
                        <label class="mb-1">Is the applicant living & studying in any other country?*</label>
                        <div class="d-flex w-100 gap-2">
                            <p class="border rounded-1 p-1 flex-fill" [ngClass]="{'placeholder-text': userDetails?.personalInformation?.nationality?.isStudyingAbroad !== true && userDetails?.personalInformation?.nationality?.isStudyingAbroad !== false}">
                                {{userDetails?.personalInformation?.nationality?.isStudyingAbroad === true ? 'Yes' : 
                                  userDetails?.personalInformation?.nationality?.isStudyingAbroad === false ? 'No' : 'Enter Details Here'}}
                            </p>
                            <p *ngIf="userDetails?.personalInformation?.nationality?.isStudyingAbroadCountry"
                                 class="border rounded-1 p-1 flex-fill">
                                {{userDetails?.personalInformation?.nationality?.isStudyingAbroadCountry}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
    
            <hr class="my-4 border border-secondary" style="height: 1px;">
    
            <!-- Background Information -->
            <h5 class="mt-4">Background Information</h5>
            <div class="row">
    
                <div class="col-md-6">
                    <label class="mb-1">Has the applicant applied for immigration to any country?*</label>
                    <div class="d-flex w-100 gap-2">
                        <p class="border rounded-1 p-1 flex-fill" [ngClass]="{'placeholder-text': userDetails?.personalInformation?.background?.immigrationApplied === null}">
                            {{userDetails?.personalInformation?.background?.immigrationApplied === null ? 'Enter Details Here' : 
                              userDetails?.personalInformation?.background?.immigrationApplied ? 'Yes' : 'No'}}
                        </p>
                        <p *ngIf="userDetails?.personalInformation?.background?.immigrationAppliedCountry" 
                            class="border rounded-1 p-1 flex-fill">
                            {{userDetails?.personalInformation?.background?.immigrationAppliedCountry}}
                        </p>
                    </div>
                </div>
    
                <div class="col-md-6">
                    <label class="mb-1">Does the applicant suffer from a serious medical condition?*</label>
                    <div class="d-flex w-100 gap-2">
                        <p class="border rounded-1 p-1 flex-fill" [ngClass]="{'placeholder-text': userDetails?.personalInformation?.background?.hasMedicalCondition === null}">
                            {{userDetails?.personalInformation?.background?.hasMedicalCondition === null ? 'Enter Details Here' : 
                              userDetails?.personalInformation?.background?.hasMedicalCondition ? 'Yes' : 'No'}}
                        </p>
                        <p *ngIf="userDetails?.personalInformation?.background?.medicalDetails" 
                            class="border rounded-1 p-1 flex-fill">
                            {{userDetails?.personalInformation?.background?.medicalDetails}}
                        </p>
                    </div>
                </div>
    
                <div class="col-md-6">
                    <label class="mb-1">Has the applicant ever had a VISA refusal for any country?*</label>
                    <div>
                        <div class="d-flex w-100 gap-2">
                            <p class="border rounded-1 p-1 flex-fill" [ngClass]="{'placeholder-text': userDetails?.personalInformation?.background?.visaRefused === null}">
                                {{userDetails?.personalInformation?.background?.visaRefused === null ? 'Enter Details Here' : 
                                  userDetails?.personalInformation?.background?.visaRefused ? 'Yes' : 'No'}}
                            </p>
                        
                            <div *ngIf="userDetails?.personalInformation?.background?.visaRefused" class="d-flex gap-2">
                                <p *ngIf="userDetails?.personalInformation?.background?.visaRefusalCountry" class="border rounded-1 p-1 flex-fill">
                                    Country: {{userDetails?.personalInformation?.background?.visaRefusalCountry}}
                                </p>
                                <p *ngIf="userDetails?.personalInformation?.background?.visaRefusalType" class="border rounded-1 p-1 flex-fill">
                                    Visa Type: {{userDetails?.personalInformation?.background?.visaRefusalType}}
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="mb-1">Has the applicant ever been convicted of a criminal offence?*</label>
                    <div class="d-flex w-100 gap-2">
                        <p class="border rounded-1 p-1 flex-fill" [ngClass]="{'placeholder-text': userDetails?.personalInformation?.background?.hasCriminalRecord === null}">
                            {{userDetails?.personalInformation?.background?.hasCriminalRecord === null ? 'Enter Details Here' : 
                              userDetails?.personalInformation?.background?.hasCriminalRecord ? 'Yes' : 'No'}}
                        </p>
                        <p *ngIf="userDetails?.personalInformation?.background?.criminalRecordDetails" 
                            class="border rounded-1 p-1 flex-fill">
                            {{userDetails?.personalInformation?.background?.criminalRecordDetails}}
                        </p>
                    </div>
                </div>
            </div>
    
            <hr class="my-4 border border-secondary" style="height: 1px;">
    
            <!-- Emergency Contact -->
            <h5 class="mt-4">Emergency Contact</h5>
            <div class="row">
                <div class="col-md-6">
                    <label class="mb-1">Name*</label>
                    <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.emergency?.name}">
                        {{userDetails?.personalInformation?.emergency?.name ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-6">
                    <label class="mb-1">Phone*</label>
                    <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.emergency?.phone}">
                        {{userDetails?.personalInformation?.emergency?.phone ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-6">
                    <label class="mb-1">Email*</label>
                    <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.emergency?.email}">
                        {{userDetails?.personalInformation?.emergency?.email ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-6">
                    <label class="mb-1">Relationship with Applicant*</label>
                    <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.personalInformation?.emergency?.relationshipWithApplicant}">
                        {{userDetails?.personalInformation?.emergency?.relationshipWithApplicant ?? 'Enter Details Here'}}
                    </p>
                </div>
            </div>
        </ng-container>
        
        <ng-template #showPersonalEdit>
            <aerp-base-schema-form 
                *ngIf="model" 
                (onSubmit)="onSubmit($event)"
                [dataProcessor]="dataProcessor.bind(this)" 
                [dataModel]="dataModel"
                [model]="model"
                [layout]="layoutPersonalInfo">
            </aerp-base-schema-form>
        </ng-template>
    
        <!-- Footer Buttons -->
        <!-- <div class="row mt-4" *ngIf="saveChangesPersonal">
            <div class="col-12 d-flex justify-content-end gap-3">
                <button class="btn btn-outline-primary" (click)="saveChangesPersonal = false">
                    Cancel
                </button>
                <button type="submit" class="btn btn-primary">
                    Save & Continue
                </button>
            </div>
        </div> -->
    </div>
    </div>