<div class="mb-10">
    <div class="p-1 scrollable-container mb-6">
        <ng-container *ngIf="!isLoading && !saveChangesTestScores else showTestScoresEdit">
            <!-- Aptitude Test Scores Header -->
            <div class="d-flex justify-content-between align-items-center">
                <h4 class="text-primary-dark"></h4>
                <button *ngIf="!userDetails?.testScores?.gre && !userDetails?.testScores?.gmat && 
                       !userDetails?.testScores?.sat && !userDetails?.testScores?.act && 
                       !userDetails?.testScores?.ielts && !userDetails?.testScores?.toefl && 
                       !userDetails?.testScores?.pte && !userDetails?.testScores?.det"
                        (click)="openEditSection('saveChangesTestScores')" 
                        class="btn btn-primary d-flex align-items-center gap-1 px-2 py-1 rounded-2">
                    <i class="bi bi-plus-lg fs-5"></i>
                    <span class="fs-5">Add Test</span>
                </button>
                <button *ngIf="userDetails?.testScores?.gre || userDetails?.testScores?.gmat || 
                       userDetails?.testScores?.sat || userDetails?.testScores?.act || 
                       userDetails?.testScores?.ielts || userDetails?.testScores?.toefl || 
                       userDetails?.testScores?.pte || userDetails?.testScores?.det"
                    (click)="openEditSection('saveChangesTestScores')" 
                    class="btn btn-primary d-flex align-items-center gap-1 px-2 py-1 rounded-2">
                    <i class="bi bi-plus-lg fs-5"></i>
                    <span class="fs-5">Edit Test</span>
                </button>
            </div>
    
            <div class="d-flex justify-content-between align-items-center mb-2">
                <h4 class="text-primary-dark">Aptitude Test Scores</h4>
            </div>
    
            <!-- Display message if no aptitude test scores -->
            <div *ngIf="!hasAptitudeScores()" class="mb-4 mt-4 text-center text-muted">
                No Aptitude test score added yet
            </div>
    
            <!-- Aptitude test Section -->
            <div class="row mb-4">
                <!-- GRE Card -->
                <div class="col-md-6 mb-3" *ngIf="userDetails?.testScores?.gre">
                    <div class="card rounded-3 shadow-sm">
                        <div class="card-body">
                            <div class="position-absolute top-0 end-0 p-2">
                                <button class="btn btn-sm text-danger" 
                                        (click)="deleteTestScore('gre')"
                                        [disabled]="isLoading">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                            <h6>GRE</h6>
                            <h3 class="mb-3">{{userDetails?.testScores?.gre?.overallScore ?? 'NA'}}</h3>
                            <div class="small text-muted mb-3">
                                <div>Date of Examination:<span class="text-custom-blue">{{userDetails?.testScores?.gre?.examDate ?? 'NA'}}</span></div>
                                <div>ETS Registration No.: <span class="text-custom-blue">{{userDetails?.testScores?.gre?.registrationNo ?? 'NA'}}</span></div>
                            </div>
                            <div class="p-2">
                                <div class="row justify-content-between scores-bg">
                                    <div class="scores-css mt-2">
                                        <label class="small text-muted">Quantitative</label>
                                        <p class="m-0">{{userDetails?.testScores?.gre?.quantitative ?? 'NA'}}</p>
                                    </div>
                                    <div class="scores-css mt-2">
                                        <label class="small text-muted">Verbal</label>
                                        <p class="m-0">{{userDetails?.testScores?.gre?.verbal ?? 'NA'}}</p>
                                    </div>
                                    <div class="scores-css mt-2">
                                        <label class="small text-muted">Analytical Writing</label>
                                        <p class="m-0">{{userDetails?.testScores?.gre?.analyticalWriting ?? 'NA'}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <!-- GMAT Card -->
                <div class="col-md-6 mb-3" *ngIf="userDetails?.testScores?.gmat">
                    <div class="card rounded-3 shadow-sm">
                        <div class="card-body">
                            <div class="position-absolute top-0 end-0 p-2">
                                <button class="btn btn-sm text-danger" 
                                        (click)="deleteTestScore('gmat')"
                                        [disabled]="isLoading">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                            <h6>GMAT 
                                <ng-container *ngIf="userDetails?.testScores?.gmat?.version">
                                    ({{userDetails?.testScores?.gmat?.version === 'traditional' ? 'Traditional' : 'Focus Edition'}})
                                </ng-container>
                            </h6>
                            <h3 class="mb-3">{{userDetails?.testScores?.gmat?.overallScore ?? 'NA'}}</h3>
                            <div class="small text-muted mb-3">
                                <div>Date of Examination: <span class="text-custom-blue">{{userDetails?.testScores?.gmat?.examDate ?? 'NA'}}</span></div>
                                <div>GMAT Appointment Number: <span class="text-custom-blue">{{userDetails?.testScores?.gmat?.appointmentNumber ?? 'NA'}}</span></div>
                            </div>
                            <div class="p-2">
                                <div class="row justify-content-between scores-bg">
                                    <div class="scores-css mt-2">
                                        <label class="small text-muted">Quantitative</label>
                                        <p class="m-0">{{userDetails?.testScores?.gmat?.quantitative ?? 'NA'}}</p>
                                    </div>
                                    <div class="scores-css mt-2">
                                        <label class="small text-muted">Verbal</label>
                                        <p class="m-0">{{userDetails?.testScores?.gmat?.verbal ?? 'NA'}}</p>
                                    </div>
                                    <!-- Traditional Version Fields -->
                                    <ng-container *ngIf="userDetails?.testScores?.gmat?.version === 'traditional'">
                                        <div class="scores-css mt-2">
                                            <label class="small text-muted">IR</label>
                                            <p class="m-0">{{userDetails?.testScores?.gmat?.integratedReasoning ?? 'NA'}}</p>
                                        </div>
                                        <div class="scores-css">
                                            <label class="small text-muted">Analytical Writing</label>
                                            <p class="m-0">{{userDetails?.testScores?.gmat?.analyticalWriting ?? 'NA'}}</p>
                                        </div>
                                    </ng-container>
                                    <!-- Focus Edition Fields -->
                                    <ng-container *ngIf="userDetails?.testScores?.gmat?.version === 'focus'">
                                        <div class="scores-css">
                                            <label class="small text-muted">Data Insights</label>
                                            <p class="m-0">{{userDetails?.testScores?.gmat?.dataInsightsScore ?? 'NA'}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <!-- SAT Card -->
                <div class="col-md-6 mb-3" *ngIf="userDetails?.testScores?.sat">
                    <div class="card rounded-3 shadow-sm">
                        <div class="card-body">
                            <div class="position-absolute top-0 end-0 p-2">
                                <button class="btn btn-sm text-danger" 
                                        (click)="deleteTestScore('sat')"
                                        [disabled]="isLoading">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                            <h6>SAT</h6>
                            <h3 class="mb-3">{{userDetails?.testScores?.sat?.totalScore ?? 'NA'}}</h3>
                            <div class="small text-muted mb-3">
                                <div>Date of Examination: <span class="text-custom-blue">{{userDetails?.testScores?.sat?.examDate ?? 'NA'}}</span></div>
                                <div>Registration Number: <span class="text-custom-blue">{{userDetails?.testScores?.sat?.registrationNumber ?? 'NA'}}</span></div>
                            </div>
                            <div class="p-2">
                                <div class="row  scores-bg">
                                    <div class="scores-css mt-2">
                                        <label class="small text-muted">R & W</label>
                                        <p class="m-0">{{userDetails?.testScores?.sat?.readingWriting ?? 'NA'}}</p>
                                    </div>
                                    <div class="scores-css mt-2">
                                        <label class="small text-muted">Math</label>
                                        <p class="m-0">{{userDetails?.testScores?.sat?.math ?? 'NA'}}</p>
                                    </div>
                                    <div class="scores-css mt-2">
                                        <label class="small text-muted">Essay</label>
                                        <p class="m-0">{{userDetails?.testScores?.sat?.essay ?? 'NA'}}</p>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- Additional SAT Subscores -->
                            <!-- <div class="mt-4">
                                <h6 class="small text-muted mb-3">Detailed Scores</h6>
                                <div class="row">
                                    <div class="col-6 mb-2">
                                        <label class="small text-muted">Command of Evidence</label>
                                        <p class="m-0">{{userDetails?.testScores?.sat?.commandOfEvidence ?? 'NA'}}</p>
                                    </div>
                                    <div class="col-6 mb-2">
                                        <label class="small text-muted">Words in Context</label>
                                        <p class="m-0">{{userDetails?.testScores?.sat?.wordsInContext ?? 'NA'}}</p>
                                    </div>
                                    <div class="col-6 mb-2">
                                        <label class="small text-muted">Expression of Ideas</label>
                                        <p class="m-0">{{userDetails?.testScores?.sat?.expressionOfIdeas ?? 'NA'}}</p>
                                    </div>
                                    <div class="col-6 mb-2">
                                        <label class="small text-muted">Standard English Conventions</label>
                                        <p class="m-0">{{userDetails?.testScores?.sat?.standardEnglishConventions ?? 'NA'}}</p>
                                    </div>
                                    <div class="col-6">
                                        <label class="small text-muted">Heart of Algebra</label>
                                        <p class="m-0">{{userDetails?.testScores?.sat?.heartOfAlgebra ?? 'NA'}}</p>
                                    </div>
                                    <div class="col-6">
                                        <label class="small text-muted">Problem Solving & Data Analysis</label>
                                        <p class="m-0">{{userDetails?.testScores?.sat?.problemSolvingDataAnalysis ?? 'NA'}}</p>
                                    </div>
                                    <div class="col-6">
                                        <label class="small text-muted">Passport to Advanced Math</label>
                                        <p class="m-0">{{userDetails?.testScores?.sat?.passportToAdvanceMath ?? 'NA'}}</p>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
    
                <!-- ACT Card -->
                <div class="col-md-6 mb-3" *ngIf="userDetails?.testScores?.act">
                    <div class="card rounded-3 shadow-sm">
                        <div class="card-body">
                            <div class="position-absolute top-0 end-0 p-2">
                                <button class="btn btn-sm text-danger" 
                                        (click)="deleteTestScore('act')"
                                        [disabled]="isLoading">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                            <h6>ACT</h6>
                            <h3 class="mb-3">{{userDetails?.testScores?.act?.overallScore ?? 'NA'}}</h3>
                            <div class="small text-muted mb-3">
                                <div>Date of Examination: <span class="text-custom-blue">{{userDetails?.testScores?.act?.examDate ?? 'NA'}}</span></div>
                                <div>ACT ID: <span class="text-custom-blue">{{userDetails?.testScores?.act?.actId ?? 'NA'}}</span></div>
                            </div>
                            <div class="p-2">
                                <div class="row  scores-bg">
                                    <div class="scores-css mt-2">
                                        <label class="small text-muted">English</label>
                                        <p class="m-0">{{userDetails?.testScores?.act?.english ?? 'NA'}}</p>
                                    </div>
                                    <div class="scores-css mt-2">
                                        <label class="small text-muted">Math</label>
                                        <p class="m-0">{{userDetails?.testScores?.act?.math ?? 'NA'}}</p>
                                    </div>
                                    <div class="scores-css mt-2">
                                        <label class="small text-muted">Reading</label>
                                        <p class="m-0">{{userDetails?.testScores?.act?.reading ?? 'NA'}}</p>
                                    </div>
                                    <div class="scores-css">
                                        <label class="small text-muted">Science</label>
                                        <p class="m-0">{{userDetails?.testScores?.act?.science ?? 'NA'}}</p>
                                    </div>
                                    <div class="scores-css">
                                        <label class="small text-muted">Essay</label>
                                        <p class="m-0">{{userDetails?.testScores?.act?.essay ?? 'NA'}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <hr class="my-6 border border-secondary" style="height: 1px;">
    
            <!-- English Test Scores Section -->
            <div class="d-flex justify-content-between align-items-center mb-4">
                <h4 class="text-primary-dark">English Test Scores</h4>
            </div>
    
            <!-- Display message if no English test scores -->
            <div *ngIf="!hasEnglishScores()" class="mb-4 mt-4 text-center text-muted">
                No English test score added yet
            </div>
    
            <div class="row mb-4">
                <!-- IELTS Card -->
                <div class="col-md-6 mb-3" *ngIf="userDetails?.testScores?.ielts">
                    <div class="card rounded-3 shadow-sm">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-start mb-2">
                                <h6>IELTS</h6>
                                <div class="d-flex align-items-center">
                                    <!-- Badge first, then trash with proper spacing -->
                                    <div class="badge me-2" *ngIf="userDetails?.testScores?.ielts?.waiver || userDetails?.testScores?.ielts?.yetToReceive"
                                        [ngClass]="userDetails?.testScores?.ielts?.waiver ? 'bg-warning' : 'bg-info'">
                                        {{userDetails?.testScores?.ielts?.waiver ? 'Waiver' : 'Pending'}}
                                    </div>
                                    <button class="btn btn-sm text-danger" 
                                            (click)="deleteTestScore('ielts')"
                                            [disabled]="isLoading">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                </div>
                            </div>
                            
                            <!-- Show these sections only if no waiver -->
                            <ng-container *ngIf="!userDetails?.testScores?.ielts?.waiver">
                                <h3 class="mb-3">{{userDetails?.testScores?.ielts?.overallScore ?? 'NA'}}</h3>
                                <div class="small text-muted mb-3">
                                    <div>Date of Examination: <span class="text-custom-blue">{{userDetails?.testScores?.ielts?.examDate ?? 'NA'}}</span></div>
                                    <div>TRF No.: <span class="text-custom-blue">{{userDetails?.testScores?.ielts?.trfNo ?? 'NA'}}</span></div>
                                    <div *ngIf="userDetails?.testScores?.ielts?.yetToReceive">
                                        Expected Result Date: {{userDetails?.testScores?.ielts?.testResultDate ?? 'NA'}}
                                    </div>
                                </div>
                                
                                <!-- Show scores only if not yet to receive -->
                                <div class="p-2">
                                    <div class="row scores-bg" *ngIf="!userDetails?.testScores?.ielts?.yetToReceive">
                                        <div class="scores-css mt-2">
                                            <label class="small text-muted">Reading</label>
                                            <p class="m-0">{{userDetails?.testScores?.ielts?.reading ?? 'NA'}}</p>
                                        </div>
                                        <div class="scores-css mt-2">
                                            <label class="small text-muted">Writing</label>
                                            <p class="m-0">{{userDetails?.testScores?.ielts?.writing ?? 'NA'}}</p>
                                        </div>
                                        <div class="scores-css mt-2">
                                            <label class="small text-muted">Speaking</label>
                                            <p class="m-0">{{userDetails?.testScores?.ielts?.speaking ?? 'NA'}}</p>
                                        </div>
                                        <div class="scores-css">
                                            <label class="small text-muted">Listening</label>
                                            <p class="m-0">{{userDetails?.testScores?.ielts?.listening ?? 'NA'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                
                            <!-- Show these sections only if waiver -->
                            <ng-container *ngIf="userDetails?.testScores?.ielts?.waiver">
                                <div class="mt-3">
                                    <div class="row">
                                        <div class="col-12 mb-2" *ngIf="userDetails?.testScores?.ielts?.englishMarks">
                                            <label class="small text-muted">12th English Marks</label>
                                            <p class="m-0">{{userDetails?.testScores?.ielts?.englishMarksValue ?? 'NA'}}/100</p>
                                        </div>
                                        <div class="col-12">
                                            <label class="small text-muted">Medium of Instruction (MOI)</label>
                                            <p class="m-0">{{userDetails?.testScores?.ielts?.mediumOfInstruction ? 'Yes' : 'No'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                
                <!-- TOEFL Card -->
                <div class="col-md-6 mb-3" *ngIf="userDetails?.testScores?.toefl">
                    <div class="card rounded-3 shadow-sm">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-start mb-2">
                                <h6>TOEFL</h6>
                                <div class="d-flex align-items-center">
                                    <div class="badge me-2" *ngIf="userDetails?.testScores?.toefl?.waiver || userDetails?.testScores?.toefl?.yetToReceive"
                                        [ngClass]="userDetails?.testScores?.toefl?.waiver ? 'bg-warning' : 'bg-info'">
                                        {{userDetails?.testScores?.toefl?.waiver ? 'Waiver' : 'Pending'}}
                                    </div>
                                    <button class="btn btn-sm text-danger" 
                                            (click)="deleteTestScore('toefl')"
                                            [disabled]="isLoading">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                </div>
                            </div>
                            
                            <!-- Show these sections only if no waiver -->
                            <ng-container *ngIf="!userDetails?.testScores?.toefl?.waiver">
                                <h3 class="mb-3">{{userDetails?.testScores?.toefl?.overallScore ?? 'NA'}}</h3>
                                <div class="small text-muted mb-3">
                                    <div>Date of Examination: <span class="text-custom-blue">{{userDetails?.testScores?.toefl?.examDate ?? 'NA'}}</span></div>
                                    <div>Registration Number: <span class="text-custom-blue">{{userDetails?.testScores?.toefl?.registrationNumber ?? 'NA'}}</span></div>
                                    <div *ngIf="userDetails?.testScores?.toefl?.yetToReceive">
                                        Expected Result Date: {{userDetails?.testScores?.toefl?.testResultDate ?? 'NA'}}
                                    </div>
                                </div>
                                
                                <!-- Show scores only if not yet to receive -->
                                 <div class="p-2">
                                    <div class="row score scores-bg" *ngIf="!userDetails?.testScores?.toefl?.yetToReceive">
                                        <div class="scores-css mt-2">
                                            <label class="small text-muted">Reading</label>
                                            <p class="m-0">{{userDetails?.testScores?.toefl?.reading ?? 'NA'}}</p>
                                        </div>
                                        <div class="scores-css mt-2">
                                            <label class="small text-muted">Writing</label>
                                            <p class="m-0">{{userDetails?.testScores?.toefl?.writing ?? 'NA'}}</p>
                                        </div>
                                        <div class="scores-css mt-2">
                                            <label class="small text-muted">Speaking</label>
                                            <p class="m-0">{{userDetails?.testScores?.toefl?.speaking ?? 'NA'}}</p>
                                        </div>
                                        <div class="scores-css">
                                            <label class="small text-muted">Listening</label>
                                            <p class="m-0">{{userDetails?.testScores?.toefl?.listening ?? 'NA'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                
                            <!-- Show these sections only if waiver -->
                            <ng-container *ngIf="userDetails?.testScores?.toefl?.waiver">
                                <div class="mt-3">
                                    <div class="row">
                                        <div class="col-12 mb-2" *ngIf="userDetails?.testScores?.toefl?.englishMarks">
                                            <label class="small text-muted">12th English Marks</label>
                                            <p class="m-0">{{userDetails?.testScores?.toefl?.englishMarksValue ?? 'NA'}}/100</p>
                                        </div>
                                        <div class="col-12">
                                            <label class="small text-muted">Medium of Instruction (MOI)</label>
                                            <p class="m-0">{{userDetails?.testScores?.toefl?.mediumOfInstruction ? 'Yes' : 'No'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                
                <!-- PTE Card -->
                <div class="col-md-6 mb-3" *ngIf="userDetails?.testScores?.pte">
                    <div class="card rounded-3 shadow-sm">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-start mb-2">
                                <h6>PTE</h6>
                                <div class="d-flex align-items-center">
                                    <div class="badge me-2" *ngIf="userDetails?.testScores?.pte?.waiver || userDetails?.testScores?.pte?.yetToReceive"
                                        [ngClass]="userDetails?.testScores?.pte?.waiver ? 'bg-warning' : 'bg-info'">
                                        {{userDetails?.testScores?.pte?.waiver ? 'Waiver' : 'Pending'}}
                                    </div>
                                    <button class="btn btn-sm text-danger" 
                                            (click)="deleteTestScore('pte')"
                                            [disabled]="isLoading">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                </div>
                            </div>
                            <!-- Show these sections only if no waiver -->
                            <ng-container *ngIf="!userDetails?.testScores?.pte?.waiver">
                                <h3 class="mb-3">{{userDetails?.testScores?.pte?.overallScore ?? 'NA'}}</h3>
                                <div class="small text-muted mb-3">
                                    <div>Date of Examination: <span class="text-custom-blue">{{userDetails?.testScores?.pte?.examDate ?? 'NA'}}</span></div>
                                    <div>Score Report Code: <span class="text-custom-blue">{{userDetails?.testScores?.pte?.scoreReportCode ?? 'NA'}}</span></div>
                                    <div *ngIf="userDetails?.testScores?.pte?.yetToReceive">
                                        Expected Result Date: {{userDetails?.testScores?.pte?.testResultDate ?? 'NA'}}
                                    </div>
                                </div>
                                
                                <!-- Show scores only if not yet to receive -->
                                <div class="p-2">
                                    <div class="row scores-bg" *ngIf="!userDetails?.testScores?.pte?.yetToReceive">
                                        <div class="scores-css mt-2">
                                            <label class="small text-muted">Reading</label>
                                            <p class="m-0">{{userDetails?.testScores?.pte?.reading ?? 'NA'}}</p>
                                        </div>
                                        <div class="scores-css mt-2">
                                            <label class="small text-muted">Writing</label>
                                            <p class="m-0">{{userDetails?.testScores?.pte?.writing ?? 'NA'}}</p>
                                        </div>
                                        <div class="scores-css mt-2">
                                            <label class="small text-muted">Speaking</label>
                                            <p class="m-0">{{userDetails?.testScores?.pte?.speaking ?? 'NA'}}</p>
                                        </div>
                                        <div class="scores-css">
                                            <label class="small text-muted">Listening</label>
                                            <p class="m-0">{{userDetails?.testScores?.pte?.listening ?? 'NA'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                
                            <!-- Show these sections only if waiver -->
                            <ng-container *ngIf="userDetails?.testScores?.pte?.waiver">
                                <div class="mt-3">
                                    <div class="row">
                                        <div class="col-12 mb-2" *ngIf="userDetails?.testScores?.pte?.englishMarks">
                                            <label class="small text-muted">12th English Marks</label>
                                            <p class="m-0">{{userDetails?.testScores?.pte?.englishMarksValue ?? 'NA'}}/100</p>
                                        </div>
                                        <div class="col-12">
                                            <label class="small text-muted">Medium of Instruction (MOI)</label>
                                            <p class="m-0">{{userDetails?.testScores?.pte?.mediumOfInstruction ? 'Yes' : 'No'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
    
                <!-- DET Card -->
                <div class="col-md-6 mb-3" *ngIf="userDetails?.testScores?.det">
                    <div class="card rounded-3 shadow-sm">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-start mb-2">
                                <h6>DET</h6>
                                <div class="d-flex align-items-center">
                                    <div class="badge me-2" *ngIf="userDetails?.testScores?.det?.waiver || userDetails?.testScores?.det?.yetToReceive"
                                        [ngClass]="userDetails?.testScores?.det?.waiver ? 'bg-warning' : 'bg-info'">
                                        {{userDetails?.testScores?.det?.waiver ? 'Waiver' : 'Pending'}}
                                    </div>
                                    <button class="btn btn-sm text-danger" 
                                            (click)="deleteTestScore('det')"
                                            [disabled]="isLoading">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                </div>
                            </div>
                            
                            <!-- Show these sections only if no waiver -->
                            <ng-container *ngIf="!userDetails?.testScores?.det?.waiver">
                                <h3 class="mb-3">{{userDetails?.testScores?.det?.totalScore ?? 'NA'}}</h3>
                                <div class="small text-muted mb-3">
                                    <div>Date of Examination: <span class="text-custom-blue">{{userDetails?.testScores?.det?.examDate ?? 'NA'}}</span></div>
                                    <div>Score Report Code: <span class="text-custom-blue">{{userDetails?.testScores?.det?.scoreReportCode ?? 'NA'}}</span></div>
                                    <div *ngIf="userDetails?.testScores?.det?.yetToReceive">
                                        Expected Result Date: {{userDetails?.testScores?.det?.testResultDate ?? 'NA'}}
                                    </div>
                                </div>
                                
                                <!-- Show scores only if not yet to receive -->
                                <div class="p-2">
                                    <div class="row scores-bg" *ngIf="!userDetails?.testScores?.det?.yetToReceive">
                                        <div class="scores-css mt-2">
                                            <label class="small text-muted">Literacy</label>
                                            <p class="m-0">{{userDetails?.testScores?.det?.literacy ?? 'NA'}}</p>
                                        </div>
                                        <div class="scores-css mt-2">
                                            <label class="small text-muted">Comprehension</label>
                                            <p class="m-0">{{userDetails?.testScores?.det?.comprehension ?? 'NA'}}</p>
                                        </div>
                                        <div class="scores-css mt-2">
                                            <label class="small text-muted">Conversation</label>
                                            <p class="m-0">{{userDetails?.testScores?.det?.conversation ?? 'NA'}}</p>
                                        </div>
                                        <div class="scores-css">
                                            <label class="small text-muted">Production</label>
                                            <p class="m-0">{{userDetails?.testScores?.det?.production ?? 'NA'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
    
                            <!-- Show these sections only if waiver -->
                            <ng-container *ngIf="userDetails?.testScores?.det?.waiver">
                                <div class="mt-3">
                                    <div class="row">
                                        <div class="col-12 mb-2" *ngIf="userDetails?.testScores?.det?.englishMarks">
                                            <label class="small text-muted">12th English Marks</label>
                                            <p class="m-0">{{userDetails?.testScores?.det?.englishMarksValue ?? 'NA'}}/100</p>
                                        </div>
                                        <div class="col-12">
                                            <label class="small text-muted">Medium of Instruction (MOI)</label>
                                            <p class="m-0">{{userDetails?.testScores?.det?.mediumOfInstruction ? 'Yes' : 'No'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                            
            </div>   
        </ng-container>
    
    
        <ng-template #showTestScoresEdit>
            <!-- Form edit mode -->
    
            <!-- Test Scores List View -->
            <div class="test-scores-container">
                <!-- Aptitude Test Scores Section -->
                <h5 class="mb-3">Aptitude Test Scores</h5>
                <div class="test-scores-list mb-5">
                    <div class="test-score-item" >
                        <div class="d-flex justify-content-between align-items-center" (click)="toggleSection('gre')">
                            <span>GRE</span>
                            <i class="bi" [ngClass]="expandedSections.gre ? 'bi-dash-lg' : 'bi-plus-lg'"></i>
                        </div>
                        <!-- Form fields will render here when expanded -->
                        <div class="test-score-form mt-3" *ngIf="expandedSections.gre">
                            <aerp-base-schema-form 
                                *ngIf="model" 
                                (onSubmit)="onSubmit($event)"
                                [dataProcessor]="dataProcessor.bind(this)" 
                                [dataModel]="dataModel"
                                [model]="model"
                                [layout]="layoutTestScores">
                            </aerp-base-schema-form>
                        </div>
                    </div>
    
                    <div class="test-score-item" >
                        <div class="d-flex justify-content-between align-items-center" (click)="toggleSection('gmat')">
                            <span>GMAT</span>
                            <i class="bi" [ngClass]="expandedSections.gmat ? 'bi-dash-lg' : 'bi-plus-lg'"></i>
                        </div>
                        <div class="test-score-form mt-3" *ngIf="expandedSections.gmat">
                            <aerp-base-schema-form 
                                *ngIf="model" 
                                (onSubmit)="onSubmit($event)"
                                [dataProcessor]="dataProcessor.bind(this)" 
                                [dataModel]="dataModel"
                                [model]="model"
                                [layout]="layoutTestScores">
                            </aerp-base-schema-form>
                        </div>
                    </div>
    
                    <div class="test-score-item" >
                        <div class="d-flex justify-content-between align-items-center" (click)="toggleSection('sat')">
                            <span>SAT</span>
                            <i class="bi" [ngClass]="expandedSections.sat ? 'bi-dash-lg' : 'bi-plus-lg'"></i>
                        </div>
                        <div class="test-score-form mt-3" *ngIf="expandedSections.sat">
                            <aerp-base-schema-form 
                                *ngIf="model" 
                                (onSubmit)="onSubmit($event)"
                                [dataProcessor]="dataProcessor.bind(this)" 
                                [dataModel]="dataModel"
                                [model]="model"
                                [layout]="layoutTestScores">
                            </aerp-base-schema-form>
                        </div>
                    </div>
    
                    <div class="test-score-item">
                        <div class="d-flex justify-content-between align-items-center" (click)="toggleSection('act')">
                            <span>ACT</span>
                            <i class="bi" [ngClass]="expandedSections.act ? 'bi-dash-lg' : 'bi-plus-lg'"></i>
                        </div>
                        <div class="test-score-form mt-3" *ngIf="expandedSections.act">
                            <aerp-base-schema-form 
                                *ngIf="model" 
                                (onSubmit)="onSubmit($event)"
                                [dataProcessor]="dataProcessor.bind(this)" 
                                [dataModel]="dataModel"
                                [model]="model"
                                [layout]="layoutTestScores">
                            </aerp-base-schema-form>
                        </div>
                    </div>
                </div>
    
                <hr class="my-4 border border-secondary" style="height: 1px;">
    
                <!-- English Test Scores Section -->
                <h5 class="mb-2">English Test Scores</h5>
                <div class="test-scores-list">
                    <div class="test-score-item" >
                        <div class="d-flex justify-content-between align-items-center" (click)="toggleSection('ielts')">
                            <span>IELTS</span>
                            <i class="bi" [ngClass]="expandedSections.ielts ? 'bi-dash-lg' : 'bi-plus-lg'"></i>
                        </div>
                        <div class="test-score-form mt-3" *ngIf="expandedSections.ielts">
                            <aerp-base-schema-form 
                                *ngIf="model" 
                                (onSubmit)="onSubmit($event)"
                                [dataProcessor]="dataProcessor.bind(this)" 
                                [dataModel]="dataModel"
                                [model]="model"
                                [layout]="layoutTestScores">
                            </aerp-base-schema-form>
                        </div>
                    </div>
    
                    <div class="test-score-item" >
                        <div class="d-flex justify-content-between align-items-center" (click)="toggleSection('toefl')">
                            <span>TOEFL</span>
                            <i class="bi" [ngClass]="expandedSections.toefl ? 'bi-dash-lg' : 'bi-plus-lg'"></i>
                        </div>
                        <div class="test-score-form mt-3" *ngIf="expandedSections.toefl">
                            <aerp-base-schema-form 
                                *ngIf="model" 
                                (onSubmit)="onSubmit($event)"
                                [dataProcessor]="dataProcessor.bind(this)" 
                                [dataModel]="dataModel"
                                [model]="model"
                                [layout]="layoutTestScores">
                            </aerp-base-schema-form>
                        </div>
                    </div>
    
                    <div class="test-score-item" >
                        <div class="d-flex justify-content-between align-items-center" (click)="toggleSection('pte')">
                            <span>PTE</span>
                            <i class="bi" [ngClass]="expandedSections.pte ? 'bi-dash-lg' : 'bi-plus-lg'"></i>
                        </div>
                        <div class="test-score-form mt-3" *ngIf="expandedSections.pte">       
                            <aerp-base-schema-form 
                                *ngIf="model" 
                                (onSubmit)="onSubmit($event)"
                                [dataProcessor]="dataProcessor.bind(this)" 
                                [dataModel]="dataModel"
                                [model]="model"
                                [layout]="layoutTestScores">
                            </aerp-base-schema-form>
                        </div>
                    </div>
    
                    <div class="test-score-item" >
                        <div class="d-flex justify-content-between align-items-center" (click)="toggleSection('det')">
                            <span>DET</span>
                            <i class="bi" [ngClass]="expandedSections.det ? 'bi-dash-lg' : 'bi-plus-lg'"></i>
                        </div>
                        <div class="test-score-form mt-3" *ngIf="expandedSections.det">
                            <aerp-base-schema-form 
                                *ngIf="model" 
                                (onSubmit)="onSubmit($event)"
                                [dataProcessor]="dataProcessor.bind(this)" 
                                [dataModel]="dataModel"
                                [model]="model"
                                [layout]="layoutTestScores">
                            </aerp-base-schema-form>
                        </div>
                    </div>
                </div>
            </div>
    
    
            <hr class="my-4 border border-secondary" style="height: 1px;">
    
            <!-- Footer Buttons -->
            <!-- <div class="d-flex justify-content-between mt-4">
                <button class="btn btn-outline-primary px-5" type="button" (click)="cancelEdit()">
                    Cancel
                </button>
                <button class="btn btn-primary px-5" type="button" type="submit">
                    Save Changes
                </button>
            </div> -->
        </ng-template>
    </div>
    </div>