import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { environment } from 'projects/foreign-admits/src/environments/environment';
import { ApiHelperService } from 'projects/apna-lib/src/lib/services/api-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerService } from '../services/partner.service';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit {
  personalDetails: any = {};
  selectedSection: string = 'personal';
  partnerLogo: string = '';
  puid: string = "";
  isEditing: boolean = false;
  isLoading: boolean = false;
  private baseUrl: string = environment.apiBaseUrl;
  layoutPersonalInfo: FormlyFieldConfig[];
  userDetails: any;
  model: any = {};
  dataModel: string = 'users';
  saveChangesPersonal: boolean = false;


  constructor(
    private apiHelper: ApiHelperService,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private partnerService: PartnerService,
    private router: Router
  ) { }


  ngOnInit(): void {
    console.info("hello", 32)
    this.getStudentDetails();

    this.puid = this.activatedRoute.snapshot.paramMap.get('puid') || '';
    if (this.puid) {
      this.fetchPartnerLogo(this.puid);
    }

    this.dataPreProcessor();
    // this.userDetails = this.studentDetails;
  }


  toggleEdit(): void {
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      this.dataPreProcessor();
    }
  }

  dataProcessor(data: any): any {
    return data;
  }


  populateFormWithDetails(): void {
    this.model = { ...this.personalDetails };
    this.setLayout();
    this.cd.detectChanges();
  }


  onSubmit(event: any): void {
    console.log(" Form submitted with data:", event);

    if (event?.error) {
      console.error(' Form submission error:', event.error);
      return;
    }

    const uid = localStorage.getItem('uid');
    if (!uid) {
      console.error(' UID not found.');
      return;
    }

    this.isLoading = true;

    const endpoint = `student-choices/update-student-details-uid/${uid}`;
    console.log("📡 Sending PATCH request to:", endpoint); // Debugging Log

    this.apiHelper.patchDataWithShortlistingUrl(endpoint, event)
      .then((response: any) => {
        console.log("API Response:", response);
        this.isLoading = false;

        if (response.success) {

          this.isEditing = false;
          console.log('Student details updated successfully');

          this.personalDetails = { ...response.data };

          this.getStudentDetails();
          this.cd.detectChanges();
          this.cd.markForCheck();
        } else {
          // this.isLoading = false;
          console.error(' Error updating student details:', response.message);
        }
      })
      .catch((error: any) => {
        this.isLoading = false;
        console.error(' API Error:', error);
      });
  }

  setLayout(): void {
    this.layoutPersonalInfo = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            template: '<h4>My Profile</h4>',
            className: 'col-12',
          },
          // Profile Card Fields
          {
            className: 'col-md-3',
            key: 'firstName',
            type: 'input',
            templateOptions: {
              label: 'First Name',
              placeholder: 'First Name',

            },
          },
          {
            className: 'col-md-3',
            key: 'lastName',
            type: 'input',
            templateOptions: {
              label: 'Last Name',
              placeholder: 'Last Name',
            },
          },
          {
            className: 'col-md-3',
            key: 'email',
            type: 'input',
            templateOptions: {
              label: 'Email',
              placeholder: 'Email',
              type: 'email',
            },
          },
          {
            className: 'col-md-3',
            key: 'targetCountry',
            type: 'input',
            templateOptions: {
              label: 'Target Country',
              placeholder: 'Target Country',
            },
          },
          {
            className: 'col-md-3',
            key: 'targetDegree',
            type: 'input',
            templateOptions: {
              label: 'Target Degree',
              placeholder: 'Target Degree',
            },
          },
          {
            className: 'col-md-3',
            key: 'targetCourse',
            type: 'input',
            templateOptions: {
              label: 'Target Course',
              placeholder: 'Target Course',
            },
          },
        ],
      },
      // Under Graduation Score Section
      {
        fieldGroupClassName: 'row mt-4',
        hideExpression: "model.personalDetails?.academicHistory?.undergraduateMarks?.percentage === null",
        fieldGroup: [
          {
            template: '<h4>Under Graduation Score</h4>',
            className: 'col-12',
          },
          {
            className: 'col-md-3',
            key: 'academicHistory.undergraduateMarks.undergradCollegeName',
            type: 'input',
            templateOptions: {
              label: 'College Name',
              placeholder: 'College Name',
            },
          },
          {
            className: 'col-md-3',
            key: 'academicHistory.undergraduateMarks.scoreSystem',
            type: 'select',
            templateOptions: {
              label: 'Grading System',
              placeholder: 'Select Grading System',
              options: [
                { value: '4', label: 'Out of 4 CGPA' },
                { value: '5', label: 'Out of 5 CGPA' },
                { value: '7', label: 'Out of 7 CGPA' },
                { value: '10', label: 'Out of 10 CGPA' },
                { value: '100', label: 'Out of 100 Percentage' },
              ],

            },
          },
          // Aggregate Marks Input
          {
            className: 'col-md-3',
            key: 'academicHistory.undergraduateMarks.score',
            type: 'input',
            templateOptions: {
              label: 'Aggregate Marks',
              placeholder: 'Enter aggregate marks from all semesters',
              type: 'number',
            },
          },
          {
            className: 'col-md-3',
            key: 'academicHistory.undergraduateMarks.degreeBacklogs',
            type: 'input',
            templateOptions: {
              label: 'No. of Backlogs',
              placeholder: 'Backlogs',
            },
          },
        ],
      },

      //high school score
      {
        fieldGroupClassName: 'row mt-4',
        hideExpression: "model.personalDetails?.academicHistory?.undergraduateMarks?.percentage != null",
        fieldGroup: [
          {
            template: '<h4>High School Score</h4>',
            className: 'col-12',
          },
          {
            className: 'col-md-3',
            key: 'academicHistory.highSchoolMarks.highSchoolName',
            type: 'input',
            templateOptions: {
              label: 'College Name',
              placeholder: 'College Name',
            },
          },
          {
            className: 'col-md-3',
            key: 'academicHistory.highSchoolMarks.highSchoolScoreSystem',
            type: 'select',
            templateOptions: {
              label: 'Grading System',
              placeholder: 'Select Grading System',
              options: [
                { value: '4', label: 'Out of 4 CGPA' },
                { value: '5', label: 'Out of 5 CGPA' },
                { value: '7', label: 'Out of 7 CGPA' },
                { value: '10', label: 'Out of 10 CGPA' },
                { value: '100', label: 'Out of 100 Percentage' },
              ],

            },
          },
          // Aggregate Marks Input
          {
            className: 'col-md-3',
            key: 'academicHistory.highSchoolMarks.highSchoolScore',
            type: 'input',
            templateOptions: {
              label: 'Aggregate Marks',
              placeholder: 'Enter aggregate marks from all semesters',
              type: 'number',
            },
          },
        ],
      },
      // Test Scores Section
      {
        fieldGroupClassName: 'row mt-4',
        fieldGroup: [
          {
            template: '<h4>Test Scores</h4>',
            className: 'col-12',
          },
          {
            className: 'col-md-6',
            key: 'entryTests[0].testName',
            type: 'select',
            templateOptions: {
              label: 'Aptitude Test Name',
              options: [
                { label: 'GRE', value: 'gre' },
                { label: 'GMAT', value: 'gmat' },
                { label: 'SAT', value: 'sat' },
                { label: 'ACT', value: 'act' }
              ]
            },
          },
          {
            className: 'col-md-6',
            key: 'entryTests[0].testScore',
            type: 'input',
            templateOptions: {
              label: 'Aptitude Test Score',
              placeholder: 'Aptitude Test Score',
            },
          },
          {
            className: 'col-md-6',
            key: 'entryTests[1].testName',
            type: 'select',
            templateOptions: {
              label: 'Language Test Name',
              options: [
                { label: 'IELTS', value: 'ielts' },
                { label: 'TOEFL', value: 'toefl' },
                { label: 'PTE', value: 'pte' },
                { label: 'DET', value: 'det' }
              ]
            },
          },
          {
            className: 'col-md-6',
            key: 'entryTests[1].testScore',
            type: 'input',
            templateOptions: {
              label: 'Language Test Score',
              placeholder: 'Language Test Score',

            },
          },
        ],
      },
      // Work Experience Section
      {
        fieldGroupClassName: 'row mt-4',
        fieldGroup: [
          {
            template: '<h4>Work Experience</h4>',
            className: 'col-12',
          },
          {
            className: 'col-md-12',
            key: 'months_of_experience',
            type: 'input',
            templateOptions: {
              label: 'Months of Experience',
              placeholder: 'Enter experience in months',
            },
          },
        ],
      },
      // Footer Buttons
      {
        fieldGroupClassName: 'fixed-bottom-button-container d-flex justify-content-center align-items-center gap-3 mb-5',
        fieldGroup: [
          {
            fieldGroupClassName: 'd-flex gap-3 mb-4', // Ensure buttons are in the same row
            fieldGroup: [
              {
                type: 'button',
                templateOptions: {
                  btnType: 'primary px-2',
                  type: 'submit',
                  text: 'Save Changes',
                },
              },
              {
                type: 'button',
                templateOptions: {
                  btnType: 'secondary  px-3 py-1.5', // Use "secondary" for cancel
                  type: 'button', // Corrected from 'camcel' to 'button'
                  text: 'Cancel',
                  onClick: () => this.onCancel(),
                },
              },
            ],
          },
        ],
      }
    ];
  }


  fetchPartnerLogo(puid?: string): void {
    this.partnerService.getPartnerLogo(puid).subscribe(
      (response) => {
        if (response?.data?.company?.logo) {
          this.partnerLogo = response.data.company.logo;
          console.info("Fetched Partner Logo URL:", this.partnerLogo);
        } else {
          console.warn("No partner logo found for PUID:", puid);
        }
      },
      (error) => {
        console.error('Error fetching partner logo:', error);
      }
    );
  }

  openEditSection(key?: string): void {
    if (key === 'saveChangesPersonal') {
      this.saveChangesPersonal = true;
    } else {
      this.isEditing = true;
    }

    this.dataPreProcessor();
    this.setLayout();
  }

  getStudentDetails(): void {
    const uid = localStorage.getItem('uid');
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');

    if (!uid || !puid) {
      console.error('UID or PUID not found.');
      return;
    }

    let endpoint = `student-choices/get-student-details/${uid}`;
    if (puid) {
      endpoint += `/${puid}`;
    } else {
      endpoint += '/null';
    }

    console.log("🔵 Fetching latest student details from:", endpoint);


    this.apiHelper.getDataWithShortlistingUrl(endpoint)
      .then((response: any) => {
        if (response.success) {
          console.log("✅ Fetched updated student details:", response.data);

          this.personalDetails = { ...response.data };
          this.cd.detectChanges();
          console.log('Student Details fetched:', this.personalDetails);


          this.populateFormWithDetails();
        } else {
          console.error('Error fetching student details:', response.message);
        }
      })
      .catch((error: any) => {
        console.error('Error fetching student details:', error);
      });
  }


  dataPreProcessor(): void {
    const defaultModel = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      targetCountry: '',
      targetDegree: '',
      targetCourse: '',
      academicHistory: {
        undergraduateMarks: { percentage: 'Not Available', degreeBacklogs: '0' },
        highSchoolMarks: { highSchoolName: 'N/A' }
      },
      entryTests: [],
      months_of_experience: null
    };

    // Merge API data into the default structure
    this.personalDetails = { ...defaultModel, ...this.personalDetails };

    // Handle missing nested properties
    this.personalDetails.academicHistory = { ...defaultModel.academicHistory, ...this.personalDetails.academicHistory };

    this.personalDetails.entryTests = this.personalDetails.entryTests || [];

    this.cd.detectChanges(); // Trigger UI update
  }


  viewResults() {
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    if (puid) {
      this.router.navigate(['/results/' + puid]);
    }
    else {
      this.router.navigate(['/results']);
    }
  }


  onCancel(): void {
    console.log("Cancel button clicked");
    this.isEditing = false;
    this.getStudentDetails();
  }

  logout(): void {
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    localStorage.removeItem('access_token');
    localStorage.removeItem('uid');
    localStorage.removeItem('results');
    localStorage.removeItem('appliedFilters');
    localStorage.removeItem('currentFilter');
    localStorage.clear();

    if (puid) {
      this.router.navigate(['/student-login/' + puid]);
    }
    else {
      this.router.navigate(['/student-login']);
    }
  }


}
