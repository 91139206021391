import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-student-nav-header',
  templateUrl: './student-nav-header.component.html',
  styleUrls: ['./student-nav-header.component.scss']
})
export class StudentNavHeaderComponent implements OnInit {
  @Input() partnerLogo: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    console.info('Received Partner Logo in Navbar:', this.partnerLogo);
  }

  logout(): void {
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');

    // Clearing Local Storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('uid');
    localStorage.removeItem('results');
    localStorage.removeItem('appliedFilters');
    localStorage.removeItem('currentFilter');
    localStorage.clear();

    // Redirect to login page with or without PUID
    if (puid) {
      this.router.navigate(['/student-login/' + puid]);
    } else {
      this.router.navigate(['/student-login']);
    }
  }
}
