<app-connect-counsellor #connectCounsellor [puid]="puid" (requestSubmitted)="onRequestSubmitted()">
</app-connect-counsellor>

<div class=" navbar bg-light border-bottom border-secondary-subtle custom-navbar-padding">
  <div class="container-fluid">
    <div class="d-flex align-items-center justify-content-between w-100">
      <!-- Left Section: Logo -->
      <div class="d-flex align-items-center partner-logo">
        <div>
          <img *ngIf="partnerLogo" [src]="partnerLogo" alt="Partner Logo" />
        </div>
      </div>

      <!-- Right Section: Buttons (Counsellor, Wishlist, Profile) -->
      <div class="d-flex align-items-center custom-buttons-right ms-auto">
        <!-- Connect to Counsellor -->
        <button (click)="openConnectModal()"
          class="connect-counsellor btn bg-warning text-white d-flex align-items-center">
          <img src="assets/icons/phone-white.svg" alt="Phone Icon">
          Connect to Counsellor
        </button>

        <!-- Wishlist -->
        <button (click)="viewWishlist()" class="wishlist-button d-flex align-items-center">
          <!-- Heart Icon for Desktop -->
          <img src="assets/icons/red-heart.svg" alt="Heart Icon" class="desktop-heart me-2">

          <!-- Heart Icon for Mobile -->
          <img src="assets/icons/heart.svg" alt="Heart Icon" class="mobile-heart">

          <span>My Shortlist <span class="shortlist-count">({{ wishlistLength !== null && wishlistLength !== undefined ?
              wishlistLength : 0 }})</span></span>

          <!-- Wishlist Count Badge -->
          <div *ngIf="wishlistLength !== null && wishlistLength !== undefined && wishlistLength > 0"
            class="wishlist-badge">
            {{ wishlistLength }}
          </div>
        </button>

        <!-- Profile Button -->
        <button (click)="viewProfile()"
          class="profile-button d-flex align-items-center text-white border border-none fw-medium">

          <!-- Profile Icon -->
          <div class="rounded-circle d-flex align-items-center justify-content-center overflow-hidden"
            style="height: 2rem; width: 2rem;">
            <img src="assets/icons/profile.svg" alt="Profile Icon" style="width: 2rem; height: 1.75rem;">
          </div>

          <p class="font-poppins fw-medium text-dark d-none d-md-block">
            {{ initialFilter.firstName }}
          </p>
        </button>
      </div>
    </div>
  </div>
</div>