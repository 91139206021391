<div *ngIf="formControl?.value" class="card rounded-2 mb-4 bg-transparent border border-0"
    title="Hold Ctrl(PC) or Command(Mac) key to select multiple files">

    <div class="border border-1 rounded-1 px-2 py-2">
        <div class="d-flex justify-content-between align-items-start">
            <p class="">{{ to.label }} <span class="text-muted text-smallest"> (upto {{to?.maxFiles}} documents
                    only)</span> <span *ngIf="to.required && to.hideRequiredMarker !== true" class="text-danger"
                    aria-hidden="true">*</span></p>
            <ng-container *ngIf="!to?.hideDeleteDownloadButton">

                <div *ngIf="formControl?.value?.length > 0" class="d-flex justify-content-between align-items-center">
                    <p *ngIf="to?.placeholder" (click)="fileInput.click()"
                        class="btn btn-outline-primary rounded-1 me-2">
                        {{to?.placeholder}} <span><i class="bi bi-upload fs-5"></i></span> </p>

                    <button type="button" class="btn btn-outline-primary rounded-1 py-2 mt-n3" (click)="removeImage()">
                        <i class="bi bi-arrow-counterclockwise me-1"></i> Replace All </button>
                </div>

                <div *ngIf="!formControl?.value?.length > 0"
                    class="d-flex justify-content-between align-items-center mt-3">
                    <p *ngIf="to?.sampleDocumentLink" type="button" class="btn btn-outline-primary rounded-1 me-2"
                        (click)="downloadSampleDocument(to?.sampleDocumentLink)">
                        <i class="bi bi-download"></i> Download Sample
                    </p>

                    <p *ngIf="to?.placeholder" (click)="fileInput.click()"
                        class="btn btn-outline-primary py-1 rounded-1 me-2">
                        {{to?.placeholder}} <span><i class="bi bi-upload fs-5"></i></span> </p>
                </div>
            </ng-container>
        </div>

        <div class="img-wrapper d-flex justify-content-start align-items-center bg-light p-2">
            <ng-container *ngIf="type === 'admission-files'">
                <ng-container *ngFor="let file of formControl.value; let i = index">
                    <div class="d-flex justify-content-start align-items-center me-4 bg-white border-1 rounded-1 p-2 w-40">
                        <div>
                            <ng-container *ngIf="isImage(file); else fileTemplate">
                                <div class="img-box-height">
                                    <ng-container *ngIf="file?.filePath?.includes('public'); else privateImage">
                                        <img *ngIf="file?.filePath" [src]="file?.filePath" class="uploaded-image"
                                            alt="image" />
                                    </ng-container>
                                    <ng-template #privateImage>
                                        <div class="async-image-box">
                                            <fa-async-image [src]="file?.filePath" [width]="'40'"
                                                [styleClass]="'img img-fluid me-2 set-async-img-height'"></fa-async-image>

                                        </div>

                                    </ng-template>
                                </div>

                            </ng-container>
                            <ng-template #fileTemplate>
                                <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1682432634780pdf-1.png"
                                    class="uploaded-image me-1" alt="image" />
                            </ng-template>
                        </div>


                        <div class="row align-items-center mt-3">
                            <p [matTooltip]="file?.fileName">{{file?.fileName | slice:0:7}}...</p>
                        </div>

                        <div>
                            <i (click)="removeFile(i)" class="bi bi-trash ms-3 text-danger"></i>
                        </div>

                    </div>
                </ng-container>
            </ng-container>
        </div>


    </div>
</div>

<!-- NEW CARD IF DATA NOT PRESENT -->

<div class="card px-2 py-3" [class.border-danger]="showError" *ngIf="!formControl.value">
    <div class="d-flex justify-content-between align-items-center">
        <p *ngIf="to?.label">{{to?.label}} <span class="text-muted text-smallest"> (upto {{to?.maxFiles}} documents
                only)</span> <span *ngIf="to.required && to.hideRequiredMarker !== true" class="text-danger"
                aria-hidden="true">*</span> </p>

        <div class="d-flex justify-content-between align-items-center mt-3">
            <p *ngIf="to?.sampleDocumentLink" type="button" class="btn btn-outline-primary rounded-1 me-2"
                (click)="downloadSampleDocument(to?.sampleDocumentLink)">
                <i class="bi bi-download"></i> Download Sample
            </p>

            <p *ngIf="to?.placeholder" (click)="fileInput.click()" class="btn btn-outline-primary py-1 rounded-1 me-2">
                {{to?.placeholder}} <span><i class="bi bi-upload fs-5"></i></span> </p>
        </div>
    </div>

    <!-- Show error only if document is required -->
    <p *ngIf="to?.required && showError" class="text-danger mt-2">
        This document is required.
    </p>
</div>


<input type="file" class="form-control" [formlyAttributes]="field" [class.is-invalid]="showError" #fileInput
    (change)="uploadMedia(fileInput.files)" [hidden]="true" />

<div class="progress" *ngIf="progress">
    <div class="progress-bar progress-bar-striped" role="progressbar"
        [ngStyle]="{width: progress ? progress + '%': '0%'}" [attr.aria-valuenow]="progress" aria-valuemin="0"
        aria-valuemax="100"></div>
</div>