import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'projects/apna-lib/src/lib/components/components.module';
import { ChatAppComponent } from './chat-app.component';
import { FormsModule } from '@angular/forms';


@NgModule({
    declarations: [
      ChatAppComponent,
    ],
    imports: [
      CommonModule,
      ComponentsModule,
      ReactiveFormsModule,
      ComponentsModule,
      FormsModule
    ],
    exports:[
      ChatAppComponent
    ]
  })
  export class ChatAppModule { }