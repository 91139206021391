<app-navbar [partnerLogo]="partnerLogo" [wishlistLength]="wishlistLength" [puid]="puid" [initialFilter]="initialFilter"
  class="p-0 navbar-css">
</app-navbar>

<div class="page-container">
  <div class="header d-flex sub-continer">
    <div class="uni-rec">
      <div class="d-flex">
        <img src="assets/icons/home.svg" alt="Uni Icon" class="icon-md me-2" />
        <h2 class="fw-semibold font-poppins text-dark">
          {{ filteredConfig.length }} Courses in {{ uniqueUniversitiesCount }} Universities
        </h2>
      </div>
      <p class="text-secondary font-poppins">Recommendation for you</p>
    </div>
    <div class="target-container">
      <div class="target-item">
        <span class="target-label">Target Destination</span>
        <span class="target-value">
          <img *ngIf="getCountryFlag(initialFilter.country)" [src]="getCountryFlag(initialFilter.country)"
            alt="{{ initialFilter.country }} Flag" class="flag-icon me-2">
          {{ initialFilter.country }}
        </span>
      </div>
      <div class="target-item">
        <span class="target-label">Target Degree</span>
        <span class="target-value">
          {{ initialFilter.studyLevel === 'Postgraduate' ? "Master's" : initialFilter.studyLevel === 'Undergraduate' ?
          "Bachelor's" : initialFilter.studyLevel }}
        </span>
      </div>

      <div class="target-item">
        <span class="target-label">Course Specialization</span>
        <span class="target-value">{{ initialFilter.course }}</span>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-between filter-wrapper-desktop font-poppins">
    <div class="d-flex justify-content-between font-poppins">
      <div class="d-flex">
        <div class="d-flex align-items-center filter-button-container bg-white">
          <button
            class="filter-button d-flex align-items-center font-poppins border border-none small bg-primary filter-button-blue"
            (click)="toggleFilterDrawer()">
            <img src="assets/icons/filter-white.svg" alt="Filter Icon" class="filter-icon me-2">
            <span class="text-white">Filter</span>
          </button>
        </div>

        <div class="frontend-filters">
          <div class="d-flex">
            <label class="d-flex align-items-center gap-2 ms-4 mb-1">
              <input type="checkbox" [(ngModel)]="isScholarshipFilterEnabled" (change)="applyLocalFilters()"
                class="form-check-input" />
              <span class="small fw-medium text-secondary">Is Scholarship Available?</span>
            </label>

            <!-- Application Fee Waiver Checkbox -->
            <label class="d-flex align-items-center gap-2 ms-4 mb-1">
              <input type="checkbox" [(ngModel)]="isApplicationFeeWaiverEnabled" (change)="applyLocalFilters()"
                class="form-check-input" />
              <span class="small fw-medium text-secondary">Application Fee Waiver?</span>
            </label>

            <label class="d-flex align-items-center gap-2 ms-4 mb-1">
              <input type="checkbox" [(ngModel)]="isLoanOfferEnabled" (change)="applyLocalFilters()"
                class="form-check-input" />
              <span class="small fw-medium text-secondary">Faster Loan Sanctions</span>
            </label>
          </div>

          <div class="sort-by-container position-relative" clickOutside (clickOutside)="isSortDropdownOpen = false">
            <button
              class="sort-button d-flex align-items-center text-secondary border border-none fw-medium font-poppins"
              (click)="toggleSortDropdown($event)">
              <img src="assets/icons/sort-by.svg" alt="Sort Icon" class="me-2 icon-sm" />
              Sort by: {{ sortOptionLabel }}
            </button>
            <div *ngIf="isSortDropdownOpen"
              class="position-absolute bg-white border mt-8 rounded shadow-lg z-3 sort-dropdown">
              <button class="sort-option p-2 w-100 text-start" (click)="sortCourses('high-to-low')">Tuition Fees: High
                to
                Low</button>
              <button class="sort-option p-2 w-100 text-start" (click)="sortCourses('low-to-high')">Tuition Fees: Low to
                High</button>
              <button class="sort-option p-2 w-100 text-start" (click)="sortCourses('duration-low-to-high')">Duration:
                Low to
                High</button>
              <button class="sort-option p-2 w-100 text-start" (click)="sortCourses('duration-high-to-low')">Duration:
                High to
                Low</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


  <div class="justify-content-between filter-wrapper-mobile font-poppins">
    <div class="justify-content-between font-poppins">
      <div class="d-flex">
        <!-- filter button -->
        <div class="d-flex align-items-center filter-button-container bg-white">
          <button class="filter-button d-flex align-items-center font-poppins small bg-primary filter-button-blue"
            (click)="toggleFilterDrawer()">
            <img src="assets/icons/filter-white.svg" alt="Filter Icon" class="filter-icon me-2" />
            <span class="text-white">Filter</span>
          </button>
        </div>

        <!-- checkboxes -->
        <div class="frontend-filters">
          <label class="d-flex align-items-center gap-2 ms-4 mb-1">
            <input type="checkbox" [(ngModel)]="isScholarshipFilterEnabled" (change)="applyLocalFilters()"
              class="form-check-input" />
            <span class="small fw-medium text-secondary">Is Scholarship Available?</span>
          </label>
          <label class="d-flex align-items-center gap-2 ms-4 mb-1">
            <input type="checkbox" [(ngModel)]="isApplicationFeeWaiverEnabled" (change)="applyLocalFilters()"
              class="form-check-input" />
            <span class="small fw-medium text-secondary">Application Fee Waiver?</span>
          </label>
          <label class="d-flex align-items-center gap-2 ms-4 mb-1">
            <input type="checkbox" [(ngModel)]="isLoanOfferEnabled" (change)="applyLocalFilters()"
              class="form-check-input" />
            <span class="small fw-medium text-secondary">Faster Loan Sanctions</span>
          </label>
        </div>
      </div>

      <!-- sort button -->
      <div class="sort-by-container">
        <div class="position-relative" clickOutside (clickOutside)="isSortDropdownOpen = false">
          <button class="sort-button d-flex align-items-center border border-none  fw-medium font-poppins"
            (click)="toggleSortDropdown($event)">
            <img src="assets/icons/sort-by.svg" alt="Sort Icon" class="me-2 icon-sm" />
            Sort by: {{ sortOptionLabel }}
          </button>
          <div *ngIf="isSortDropdownOpen"
            class="position-absolute bg-white border mt-1 rounded shadow-lg z-3 sort-dropdown">
            <button class="sort-option p-2 w-100 text-start" (click)="sortCourses('high-to-low')">Tuition Fees: High
              to
              Low</button>
            <button class="sort-option p-2 w-100 text-start" (click)="sortCourses('low-to-high')">Tuition Fees: Low to
              High</button>
            <button class="sort-option p-2 w-100 text-start" (click)="sortCourses('duration-low-to-high')">Duration:
              Low to
              High</button>
            <button class="sort-option p-2 w-100 text-start" (click)="sortCourses('duration-high-to-low')">Duration:
              High to
              Low</button>
          </div>
        </div>
      </div>




    </div>
  </div>





  <div class="visible-only-mobile-uni d-md-none">
    <div class="d-flex">
      <img src="assets/icons/home.svg" alt="Uni Icon" class="icon-md me-2 ms-4" />
      <h2 class="fw-medium font-poppins text-dark fs-5"><b>{{ filteredConfig.length }} Universities</b>
        Recommendation for you</h2>
    </div>
  </div>

  <div *ngIf="isLoading" class="d-flex flex-column justify-content-center align-items-center">
    <!-- <dotlottie-player src="https://lottie.host/b909175e-70e7-44a3-90d3-3e653d302fdc/TQ1LaZcdbq.json"
      background="transparent" speed="1" style="width: 300px; height: 300px;" loop autoplay>
    </dotlottie-player> -->
    <div class="d-flex loader-statement">
      <div class="spinner-grow text-muted"></div>
      <div class="spinner-grow text-primary"></div>
      <div class="spinner-grow text-success"></div>
      <div class="spinner-grow text-info"></div>
      <div class="spinner-grow text-warning"></div>
      <div class="spinner-grow text-danger"></div>
      <div class="spinner-grow text-secondary"></div>
      <div class="spinner-grow text-dark"></div>
      <div class="spinner-grow text-light"></div>
    </div>
    <h1 class="font-poppins fw-semibold loading-text ms-4 mt-4">Finding you the best universities...</h1>
  </div>

  <div class="overlay" [ngClass]="{ 'active': isFilterDrawerOpen }"></div>

  <div class="filter-drawer" [ngClass]="{ 'open': isFilterDrawerOpen }">
    <div class="drawer-header">
      <div class="fs-4 fw-medium font-poppins">
        <button class="filter-button d-flex align-items-center border border-none fw-medium font-poppins filter-title"
          (click)="toggleFilterDrawer()">
          <img src="assets/icons/filter.svg" alt="Filter Icon" class="filter-icon me-2" />
          Filter
        </button>
      </div>
      <button class="close-button" (click)="toggleFilterDrawer()">
        <img src="assets/icons/cross.svg" alt="Close Icon" class="icon" />
      </button>
    </div>

    <!-- Scrollable Content Wrapper -->
    <div class="filter-content-wrapper">
      <div class="filter-content">

        <!-- Country -->
        <div>
          <label class="font-poppins form-label text-secondary-light">
            Country<span class="text-danger">*</span>
          </label>
          <select [(ngModel)]="currentFilter.country" class="form-select font-poppins text-dark">
            <option value="">Select a Country</option>
            <option value="US">US</option>
            <option value="UK">UK</option>
            <option value="Australia">Australia</option>
            <option value="Germany">Germany</option>
          </select>
        </div>

        <!-- Degree -->
        <div>
          <label class="font-poppins form-label text-secondary-light">
            Degree<span class="text-danger">*</span>
          </label>
          <select [(ngModel)]="currentFilter.studyLevel" class="form-select font-poppins text-dark">
            <option value="">Select a Degree</option>
            <option value="Bachelors">Bachelors</option>
            <option value="Masters">Masters</option>
          </select>
        </div>

        <!-- Specialization -->
        <div>
          <label class="font-poppins form-label text-secondary-light">
            Specialization<span class="text-danger">*</span>
          </label>


          <div class="position-relative" clickOutside (clickOutside)="isSpecializationDropdownOpen = false">
            <div (click)="toggleSpecializationDropdown()" class="specialization-dropdown">
              {{ selectedSpecialization || 'Select a Specialization' }}
            </div>
            <div *ngIf="isSpecializationDropdownOpen"
              class="position-absolute bg-white border border-secondary-subtle mt-2 w-100 dropdown-menu-custom overflow-auto z-3">
              <input type="text" [(ngModel)]="specializationSearchTerm" placeholder="Search Specialization..."
                class="w-100 p-2 border-bottom border-secondary-subtle form-control-plaintext font-poppins" />
              <div *ngFor="let specialization of filteredSpecializations()">
                <div (click)="selectSpecialization(specialization)" class="p-2 cursor-pointer specialization-option">
                  {{ specialization }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <label class="font-poppins form-label text-secondary-light">Course Duration</label>
          <select [(ngModel)]="currentFilter.courseDuration" class="form-select font-poppins text-dark"
            style="max-height: 40px; overflow-y: auto;">
            <option value="">Select Duration</option>
            <option *ngFor="let duration of courseDuration" [value]="duration">{{ duration }}</option>
          </select>
        </div>

        <div>
          <label class="font-poppins form-label text-secondary-light">Tuition Fees</label>

          <div class="slider-container mt-4">
            <div class="position-relative">
              <input [(ngModel)]="currentFilter.tuitionFees" type="range" min="0" max="100000" step="10000"
                class="custom-range form-range" (input)="updateSliderBackground($event)">

              <div class="step-markers">
                <div class="step-mark" *ngFor="let val of marks">
                  <div class="mark"></div>
                  <span class="mark-value">{{val}}K</span>
                </div>
              </div>
            </div>

            <div class="mt-5">
              <span class="text-dark fw-medium">
                {{
                currentFilter.country === 'US' ? '$' :
                currentFilter.country === 'Germany' ? '€' :
                currentFilter.country === 'Australia' ? 'AUD' :
                currentFilter.country === 'UK' ? '£' : '$'
                }}0 -
                {{
                currentFilter.country === 'US' ? '$' :
                currentFilter.country === 'Germany' ? '€' :
                currentFilter.country === 'Australia' ? 'AUD' :
                currentFilter.country === 'UK' ? '£' : '$'
                }}{{ currentFilter.tuitionFees | number:'1.0-0' }}
              </span>
            </div>
          </div>
        </div>

        <div>
          <label class="font-poppins form-label text-secondary-light">Backlogs</label>
          <input [(ngModel)]="currentFilter.backlogs" type="number" placeholder="e.g., 0"
            class="form-control font-poppins text-dark">
        </div>

        <button class="apply-button font-poppins mt-5 bg-primary-accent" (click)="applyFilters()">Apply Filters</button>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoading && filteredConfig.length === 0" class="align-items-center">
    <div
      class="no-match mt-5 ms-3 d-flex flex-column justify-content-center align-items-center bg-light rounded-3 shadow-lg p-5">
      <a>
        <img
          src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729754303996smiley.png"
          alt="Sad Face Emoji" class="empty-state-icon mb-4" />
      </a>
      <p class="fs-4 font-poppins fw-semibold text-warning-custom">No University Matched</p>
      <div class="text-center">
        <p class="small font-poppins text-secondary mt-2">We couldn't find a perfect university</p>
        <p class="small font-poppins text-secondary mt-2">match for your profile.</p>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoading">
    <div *ngIf="filteredConfig.length > 0" class="card-grid">
      <div *ngFor="let course of filteredConfig" class="card" (click)="viewDetails(course)">
        <div>
          <h3 class="fw-semibold font-poppins">{{ course.university | removeAffiliations }}</h3>
          <p class="fw-normal font-poppins small">{{ course.country === 'US' ? 'USA' : course.country }}</p>
          <div class="d-flex">
            <div class="col-10">
              <p class="fw-normal font-poppins small text-primary course-name">
                {{ course.course_name }}
              </p>
            </div>

            <div class="position-relative col-1 ms-4" *ngIf="course.scholarship_available === 'Yes'">
              <img class="scholarship-icon" src="assets/icons/scholarship.svg" alt="Scholarship Icon" />
              <span class="tooltip-text position-absolute start-0 mt-1 small bg-light rounded p-1 shadow-lg">
                Scholarship Available
              </span>
            </div>
          </div>
        </div>

        <div class="card-info mb-2">
          <div class="tution-fee fw-normal font-poppins small">
            <img src="assets/icons/money.svg" alt="Receipt Icon" [customTooltip]="'Yearly Tuition Fee'" />
            <span>
              {{
              course.country === 'US' ? '$' :
              course.country === 'Germany' ? '€' :
              course.country === 'Australia' ? 'AUD' :
              course.country === 'UK' ? '£' : ''
              }} {{ course.yearly_tuition_fees }}/year
            </span>
          </div>

          <div class="info-item fw-normal font-poppins small">
            <img src="assets/icons/calendar-icon.svg" alt="Duration Icon" [customTooltip]="'Course Duration'" />
            <span>{{ course.duration }}</span>
          </div>

          <div class="application-fee fw-normal font-poppins small" *ngIf="course.application_fee">
            <img src="assets/icons/receipt-text.svg" alt="Receipt Icon" [customTooltip]="'Application Fee'" />
            <span>
              {{
              (course.application_fee === 'Waiver'
              ? 'Waiver'
              : (course.country === 'US' ? '$' :
              course.country === 'Germany' ? '€' :
              course.country === 'Australia' ? 'AUD' :
              course.country === 'UK' ? '£' : '') + ' ' + course.application_fee
              ).length <= 7 ? (course.application_fee==='Waiver' ? 'Waiver' : (course.country==='US' ? '$' :
                course.country==='Germany' ? '€' : course.country==='Australia' ? 'AUD' : course.country==='UK' ? '£'
                : '' ) + ' ' + course.application_fee) : '' }} </span>
          </div>
        </div>

        <div class="d-flex">
          <!-- Faster Offer Letter Icon -->
          <div *ngIf="course.university.includes('(Shorelight)') ||  
                course.university.includes('(Study Group)') " class="offer-tag">
            <img src="assets/icons/faster-offer-letter-tag.svg" alt="Faster Offer Letter Icon" class="offer-icon" />
          </div>

          <!-- Loan Offer Icon -->
          <div *ngIf="course.loan_offers === 'Yes'" class="offer-tag">
            <img src="assets/icons/faster-loan-tag.svg" alt="Loan Offer Icon" class="button-icon" />
          </div>
        </div>

        <div class="action-buttons fw-normal font-poppins small">
          <a href="{{ course.website }}" target="_blank" class="website-button" (click)="$event.stopPropagation()">
            <img src="assets/icons/global.svg" alt="Earth Icon" class="button-icon" />
            Visit Website
          </a>

          <button class="wishlist-button fw-normal font-poppins small" [ngClass]="{ 'active': course.isInWishlist }"
            (click)="addToWishlist(course); $event.stopPropagation()">
            <img [src]="course.isInWishlist ? 'assets/icons/active-heart.svg' : 'assets/icons/heart.svg'"
              alt="Heart Icon" class="button-icon" />
            {{ course.isInWishlist !== undefined ? (course.isInWishlist ? 'Shortlisted' : 'Shortlist') : 'Shortlist' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>