import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { map, concatMap } from 'rxjs/operators';
import { AccessToken, FreshToken } from '../interfaces/user-details';
import { ApiHelperService } from './api-helper.service';
import { TimerService } from './timer-service';
import { differenceInSeconds } from "date-fns";
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
@Injectable({
  providedIn: 'root'
})
export class UserIdentityService implements OnDestroy {
  public isLoggedIn = new BehaviorSubject(false);
  public _userDetails: any = null;
  public _userDetailsSubject = new BehaviorSubject(null);
  private jwtTimer = new TimerService(1000);
  private jwtSubscription: Subscription;
  private renderer: Renderer2;
  private lastInteraction: Date;

  constructor(
    private apiHelper: ApiHelperService,
    private rendererFactory2: RendererFactory2,
    private router: Router,
    private matDialog: MatDialog) {
    this.renderer = this.rendererFactory2.createRenderer(null, null);
    this.getUserDetails();
  }

  ngOnDestroy(): void {
    if (this.jwtSubscription) {
      this.jwtSubscription.unsubscribe();
    }

  }

  getUserSubscription() {
    return this._userDetailsSubject;
  }

  getUserDetails() {
    if (this.isValidToken()) {
      this.isLoggedIn.next(true);
      this._userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.listenToUserActivity();
      this.startJwtTimer();
    } else {
      this._userDetails = null;
      this.clearUserDetails();
    }
    this._userDetailsSubject.next(this._userDetails);
    return this._userDetails;
  }

  getBucketAcccesToken() {
    this.login({
        emailOrMobile: "testi@foreignadmits.app",
        password: "Admin@2024"
      })
      .then((res: any) => {
        console.info(res, 62)
        localStorage.setItem('bucket_id', res.userId);
        console.log("Access token stored in localStorage");
      })
      .catch((error: any) => {
        console.error("Failed to retrieve access token:", error);
      });
  }
  
  setUserDetails(user) {
    let userDetails = this.getUserDetails();
    Object.assign(userDetails, user);
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
  }

  get roleName() {
    return localStorage.getItem('roleName');
  }

  set userDetails(userDetails) {
    this._userDetails = userDetails;
  }

  get userDetails() {
    return this.getUserDetails();
  }

  isOthersDetailsSubmitted() {
    return this._userDetails && this._userDetails.isOtherDetailsSubmitted;
  }


  storeUserDetails(accessToken) {
    localStorage.setItem('token', accessToken.id.toString());
    localStorage.setItem('userId', accessToken.userId.toString());
    localStorage.setItem('ttl', accessToken.ttl.toString());
    localStorage.setItem('created', accessToken.created.toString());
    localStorage.setItem('refreshToken', accessToken.refreshToken.toString());
    if (accessToken.userDetails) {
      localStorage.setItem('userDetails', JSON.stringify(accessToken.userDetails));
    }
    this.isLoggedIn.next(true);
  }

  login(credentails: any) {
    return new Promise((resolve, reject) => {
      this.apiHelper.postData("users/login", credentails).subscribe((accessToken: AccessToken) => {
        this.storeUserDetails(accessToken);
        resolve(accessToken.userDetails);
        //this.apiHelper.openSnackBar('Login Successfully!');
        this.startJwtTimer();
      }, err => {
        reject(err);
        let errMsg = err.error.error ? err.error.error.message : err.message;
        this.apiHelper.openSnackBar(errMsg, { duration: 6000 });
      });
    });
  }


  logout() {
    this.clearUserDetails();
    localStorage.removeItem("sound");
    localStorage.removeItem("signUpComplete");
    localStorage.removeItem("LoginComplete");
  }

  clearUserDetails() {
    this.userDetails = null;
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('ttl');
    localStorage.removeItem('created');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userDetails');
    localStorage.removeItem("sound");
    localStorage.removeItem("signUpComplete");
    localStorage.removeItem("LoginComplete");
    this.isLoggedIn.next(false);
  }


  register(userDetails: any) {
    return new Promise((resolve, reject) => {
      this.apiHelper.postData("users/verifyOtp", userDetails).subscribe((accessToken: AccessToken) => {
        this.storeUserDetails(accessToken);
        resolve(accessToken.userDetails);
        //this.apiHelper.openSnackBar('Login Successfully!');
      }, err => {
        reject(err);
        let errMsg = err.error.error ? err.error.error.message : err.message;
        this.apiHelper.openSnackBar(errMsg, { duration: 6000 });
      });
    });
  }

  quickRegister(userDetails: any) {
    return this.apiHelper.postData("users", userDetails);
  }

  isValidToken() {
    let token = localStorage.getItem('token')
    if (token) {
      const expiry = JSON.parse(atob(token.split('.')[1])).exp;
      return (Math.floor((new Date).getTime() / 1000)) <= expiry;
    } else {
      return false;
    }
  }

  isValidRefreshToken() {
    let refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      const expiry = JSON.parse(atob(refreshToken.split('.')[1])).exp;
      return (Math.floor((new Date).getTime() / 1000)) <= expiry;
    } else {
      return false;
    }
  }

  sendOtp(mobile: string) {
    return this.apiHelper.getData(`users/otp/${mobile}`);
  }

  verifyOtp(mobileOtp: any) {
    return this.apiHelper.postData(`/users/login-with-mobile-otp`, mobileOtp);
  }

  isJwtTokenAboutToExpiry() {
    // return true;
    let token = localStorage.getItem('token');
    if (token) {
      let expiryTimeSec = JSON.parse(atob(token.split('.')[1])).exp; // Expiry time is in sec
      let thresholdTimeSec = expiryTimeSec - 60; // Thresold is 1 min less than expiry
      let currentTimeSec = Math.floor(new Date().getTime() / 1000); // Current Time from milliseconds to seconds
      return currentTimeSec > thresholdTimeSec;
    } else {
      return false;
    }
  }

  startJwtTimer() {
    this.jwtSubscription = this.jwtTimer.observable$.pipe(
      map(count => {
        let isTokenValid = this.isValidToken();
        if (isTokenValid) {
          return count;
        } else {
          throw new Error("jwtExpired");
        }
      }),
      concatMap((count: any) => {
        let isTokenAboutToExpiry = this.isJwtTokenAboutToExpiry();
        if (isTokenAboutToExpiry) {
          return this.refreshToken();
        } else {
          return of(count);
        }
      })).subscribe(tokenRefreshed => {
        if (tokenRefreshed === true) {
          this.jwtTimer.stop();
          this.jwtTimer.start();
        }
      }, error => {
        this.jwtSubscription.unsubscribe();
        this.logout();
      });
  }

  refreshToken(): Observable<boolean> {
    return this.apiHelper.postData('users/refresh', {
      refreshToken: localStorage.getItem('refreshToken')
    }).pipe(map((fresh: FreshToken) => {
      localStorage.setItem("token", fresh.id);
      localStorage.setItem("refreshToken", fresh.refreshToken);
      return true;
    }));
  }

  private listenToUserActivity() {
    this.renderer.listen('document', 'mousemove', (evt) => {
      this.lastInteraction = new Date();
    });
    this.renderer.listen('document', 'click', (evt) => {
      this.lastInteraction = new Date();
    });
    this.renderer.listen('document', 'keydown', (evt) => {
      this.lastInteraction = new Date();
    });
  }

  private isUserInActive() {
    return false;
    let idleTimeInSeconds = differenceInSeconds(new Date(), this.lastInteraction ?? new Date());
    let maximumIdleTimeAllowedInSeconds = 14 * 60;
    if (idleTimeInSeconds > maximumIdleTimeAllowedInSeconds) {
      return true;
    } else {
      return false;
    }
  }

}
