import { Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ApiHelperService } from 'projects/apna-lib/src/lib/services/api-helper.service';

@Component({
  selector: 'app-student-competitive-test-scores',
  templateUrl: './student-competitive-test-scores.component.html',
  styleUrls: ['./student-competitive-test-scores.component.scss']
})

export class StudentCompetitiveTestScoresComponent implements OnInit {
  @Input() studentDetails: any;
  @Output() updatedProfileData = new EventEmitter<boolean>();
  public isLoading: boolean = false;
  public dataModel: string = "users";
  public model: any = {};
  layoutTestScores: FormlyFieldConfig[] = [];
  userDetails: any;
  saveChangesTestScores: boolean = false;
  expandedSections: { [key: string]: boolean } = {
    gre: false,
    gmat: false,
    sat: false,
    act: false,
    ielts: false,
    toefl: false,
    pte: false,
    det: false
  };

  constructor(
    private apiHelper: ApiHelperService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.dataPreProcessor();
    this.userDetails = this.studentDetails;
    console.log('Test Score Data:', this.studentDetails);
  }

  openEditSection(key: string) {
    if (key === 'saveChangesTestScores') {
      this.saveChangesTestScores = true;
      this.dataPreProcessor();
    }
  }

  toggleSection(section: string) {
    Object.keys(this.expandedSections).forEach(key => {
      if (key !== section) {
        this.expandedSections[key] = false;
      }
    });
    this.expandedSections[section] = !this.expandedSections[section];
    
    if (this.expandedSections[section]) {
      const existingData = this.userDetails?.testScores?.[section] || {};
      this.model = { ...existingData };  
      this.setLayout(section);
      this.saveChangesTestScores = true;  
    }
}


  setLayout(section: string) {
    
    
    switch(section) {

      case 'gre':
        this.layoutTestScores = [
          {
            fieldGroupClassName: 'row',
            fieldGroup: [

              {
                fieldGroupClassName: 'd-flex justify-content-end align-items-center',
                fieldGroup: [
                  {
                    fieldGroup: [
                      {
                        type: 'button',
                        templateOptions: {
                          btnType: 'primary px-5 ',
                          type: 'submit',
                          text: 'Save Changes'
                        },
                        hideExpression: () => !this.saveChangesTestScores
                      }
                    ]
                  }
                ]
              },
              // Your existing GRE fields
              {
                className: 'col-md-6 mb-3',
                key: 'overallScore',
                type: 'input',
                templateOptions: {
                  label: 'Overall Score',
                  placeholder: 'Enter overall score (260-340)',
                  required: true,
                  type: 'number',
                },
                expressionProperties: {
                  'templateOptions.min': '260',
                  'templateOptions.max': '340'
                },
                validation: {
                  messages: {
                    min: 'Overall score must be at least 260',
                    max: 'Overall Writing score cannot exceed 340'
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'examDate',
                type: 'date',
                templateOptions: {
                  label: 'Date of Examination',
                  required: true,
                  placeholder: 'Enter position'
                }
              },
              {
                className: 'col-md-4 mb-3',
                key: 'quantitative',
                type: 'input',
                templateOptions: {
                  label: 'Quantitative',
                  required: true,
                  type: 'number',
                  placeholder: 'Q'
                },
                expressionProperties: {
                  'templateOptions.min': '130',
                  'templateOptions.max': '170'
                },
                validation: {
                  messages: {
                    min: 'Quantitative score must be at least 130',
                    max: 'Quantitative score cannot exceed 170'
                  }
                }
              },
              {
                className: 'col-md-4 mb-3',
                key: 'analyticalWriting',
                type: 'input',
                templateOptions: {
                  label: 'Analytical Writing',
                  placeholder: 'AW',
                  required: true,
                  type: 'number',
                  step: 0.5
                },
                expressionProperties: {
                  'templateOptions.min': '0',
                  'templateOptions.max': '6'
                },
                validation: {
                  messages: {
                    min: 'Analytical Writing score must be at least 0',
                    max: 'Analytical Writing score cannot exceed 6'
                  }
                }
              },
              {
                className: 'col-md-4 mb-3',
                key: 'verbal',
                type: 'input',
                templateOptions: {
                  label: 'Verbal',
                  required: true,
                  type: 'number',
                  placeholder: 'V'
                },
                expressionProperties: {
                  'templateOptions.min': '130',
                  'templateOptions.max': '170'
                },
                validation: {
                  messages: {
                    min: 'Verbal score must be at least 130',
                    max: 'Verbal score cannot exceed 170'
                  }
                }
              },
              {
                className: 'col-12 mb-3',
                key: 'registrationNo',
                type: 'input',
                templateOptions: {
                  label: 'ETS Registration Number',
                  placeholder: 'Enter the 7-digit number',
                  required: true,
                  type: 'number',
                  pattern: '^\\d{7}$'
                },
                expressionProperties: {
                  'templateOptions.min': '1000000',
                  'templateOptions.max': '9999999'  
                },
                validation: {
                  messages: {
                    pattern: 'Registration number must be exactly 7 digits.',
                    min: 'Registration number must be a 7-digit number.',
                    max: 'Registration number must be a 7-digit number.'
                  }
                }
              }                  
              
            ]
          }
        ];
        break;

      case 'gmat':
          this.layoutTestScores = [
            {
              fieldGroupClassName: 'row',
              fieldGroup: [
                //save button
                {
                  fieldGroupClassName: 'd-flex justify-content-end align-items-center',
                  fieldGroup: [
                    {
                      fieldGroup: [
                        {
                          type: 'button',
                          templateOptions: {
                            btnType: 'primary px-5 ',
                            type: 'submit',
                            text: 'Save Changes'
                          },
                          hideExpression: () => !this.saveChangesTestScores
                        }
                      ]
                    }
                  ]
                },
                {
                  className: 'col-md-6 mb-3',
                  key: 'version',
                  type: 'select',
                  templateOptions: {
                    label: 'GMAT Version',
                    required: true,
                    placeholder: 'Select version',
                    options: [
                      { label: 'Traditional GMAT', value: 'traditional' },
                      { label: 'GMAT Focus Edition', value: 'focus' }
                    ]
                  }  
                },
                {
                  className: 'col-md-6 mb-3',
                  key: 'overallScore',
                  type: 'input',
                  templateOptions: {
                    label: 'Overall Score',
                    required: true,
                    placeholder: 'Enter overall score'
                  },
                  expressionProperties: {
                    'templateOptions.min': 'model.version === "traditional" ? "200" : "205"',
                    'templateOptions.max': 'model.version === "traditional" ? "800" : "805"'
                  },
                  validation: {
                    messages: {
                      min: (error, field) => field.model.version === 'traditional' 
                        ? 'Score must be at least 200' 
                        : 'Score must be at least 205',
                      max: (error, field) => field.model.version === 'traditional' 
                        ? 'Score cannot exceed 800' 
                        : 'Score cannot exceed 805'
                    }
                  }
                },
                {
                  className: 'col-md-6 mb-3',
                  key: 'examDate',
                  type: 'date',
                  templateOptions: {
                    label: 'Date of Examination',
                    required: true,
                    placeholder: 'Choose date'
                  }
                },
                {
                  className: 'col-md-6 mb-3',
                  key: 'quantitative',
                  type: 'input',
                  templateOptions: {
                    label: 'Quantitative',
                    required: true,
                    placeholder: 'Enter quantitative score',
                    type:'number'
                  },
                  expressionProperties: {
                    'templateOptions.min': 'model.version === "traditional" ? "6" : "60"',
                    'templateOptions.max': 'model.version === "traditional" ? "51" : "90"'
                  },
                  validation: {
                    messages: {
                      min: (error, field) => field.model.version === 'traditional' 
                        ? 'Score must be at least 6' 
                        : 'Score must be at least 60',
                      max: (error, field) => field.model.version === 'traditional' 
                        ? 'Score cannot exceed 51' 
                        : 'Score cannot exceed 90'
                    }
                  }
                },
                {
                  className: 'col-md-6 mb-3',
                  key: 'verbal',
                  type: 'input',
                  templateOptions: {
                    label: 'Verbal',
                    required: true,
                    placeholder: 'Enter verbal score',
                    type:'number'
                  },
                  expressionProperties: {
                    'templateOptions.min': 'model.version === "traditional" ? "6" : "60"',
                    'templateOptions.max': 'model.version === "traditional" ? "51" : "90"'
                  },
                  validation: {
                    messages: {
                      min: (error, field) => field.model.version === 'traditional' 
                        ? 'Score must be at least 6' 
                        : 'Score must be at least 60',
                      max: (error, field) => field.model.version === 'traditional' 
                        ? 'Score cannot exceed 51' 
                        : 'Score cannot exceed 90'
                    }
                  }
                },
                {
                  className: 'col-md-6 mb-3',
                  key: 'appointmentNumber',
                  type: 'input',
                  templateOptions: {
                    label: 'GMAT Appointment Number',
                    required: true,
                    placeholder: 'Enter the 12-character number'
                  }
                },
        
                // Fields for Focus Edition
                {
                  className: 'col-md-6 mb-3',
                  key: 'dataInsightsScore',
                  type: 'input',
                  templateOptions: {
                    label: 'Data Insights Score',
                    required: true,
                    placeholder: 'Enter data insights score'
                  },
                  hideExpression: 'model.version !== "focus"',
                  expressionProperties: {
                    'templateOptions.min': '60',
                    'templateOptions.max': '90'
                  },
                  validation: {
                    messages: {
                      min: 'Overall score must be at least 60',
                      max: 'Overall score cannot exceed 90'
                    }
                  }
                },
        
                // Fields for Traditional GMAT
                {
                  className: 'col-md-6 mb-3',
                  key: 'analyticalWriting',
                  type: 'input',
                  templateOptions: {
                    label: 'Analytical Writing',
                    required: true,
                    placeholder: 'Enter AW score',
                    type:'number'
                  },
                  hideExpression: 'model.version !== "traditional"',
                  expressionProperties: {
                    'templateOptions.min': '0',
                    'templateOptions.max': '6'
                  },
                  validation: {
                    messages: {
                      min: 'Overall score must be at least 0',
                      max: 'Overall score cannot exceed 6'
                    }
                  }
                },
                {
                  className: 'col-md-6 mb-3',
                  key: 'integratedReasoning',
                  type: 'input',
                  templateOptions: {
                    label: 'Integrated Reasoning',
                    required: true,
                    placeholder: 'Enter IR score',
                    type:'number'
                  },
                  hideExpression: 'model.version !== "traditional"',
                  expressionProperties: {
                    'templateOptions.min': '1',
                    'templateOptions.max': '8'
                  },
                  validation: {
                    messages: {
                      min: 'Overall score must be at least 1',
                      max: 'Overall score cannot exceed 8'
                    }
                  }
                },
                
              ]
            }
          ];
          break;
  
      case 'sat':
        this.layoutTestScores = [
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                fieldGroupClassName: 'd-flex justify-content-end align-items-center',
                fieldGroup: [
                  {
                    fieldGroup: [
                      {
                        type: 'button',
                        templateOptions: {
                          btnType: 'primary px-5 ',
                          type: 'submit',
                          text: 'Save Changes'
                        },
                        hideExpression: () => !this.saveChangesTestScores
                      }
                    ]
                  }
                ]
              },
              {
                className: 'col-md-6 mb-3',
                key: 'totalScore',
                type: 'input',
                templateOptions: {
                  label: 'Total Score',
                  required: true,
                  placeholder: 'Enter overall scores',
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.min': '400',
                  'templateOptions.max': '1600'
                },
                validation: {
                  messages: {
                    min: 'Total score must be at least 400',
                    max: 'Total score cannot exceed 1600'
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'registrationNumber',
                type: 'input',
                templateOptions: {
                  label: 'Registration Number',
                  required: true,
                  placeholder: 'Enter the 8-digit number',
                  type: 'number',
                  
                },
                // expressionProperties: {
                //   'templateOptions.min': '10000000',
                //   'templateOptions.max': '99999999'  
                // },
                // validation: {
                //   messages: {
                //     min: 'Registration number must be a 8-digit number.',
                //     max: 'Registration number must be a 8-digit number.'
                //   }
                // }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'examDate',
                type: 'date',
                templateOptions: {
                  label: 'Date of Examination',
                  required: true,
                  placeholder: 'Enter position'
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'readingWriting',
                type: 'input',
                templateOptions: {
                  label: 'Reading & Writing',
                  required: true,
                  type: 'number',
                  placeholder: 'EBRW'
                },
                expressionProperties: {
                  'templateOptions.min': '200',
                  'templateOptions.max': '800'
                },
                validation: {
                  messages: {
                    min: 'EBRW score must be at least 200',
                    max: 'EBRW score cannot exceed 800'
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'math',
                type: 'input',
                templateOptions: {
                  label: 'Math',
                  required: true,
                  type: 'number',
                  placeholder: 'M'
                },
                expressionProperties: {
                  'templateOptions.min': '200',
                  'templateOptions.max': '800'
                },
                validation: {
                  messages: {
                    min: 'Math score must be at least 200',
                    max: 'Math score cannot exceed 800'
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'essay',
                type: 'input',
                templateOptions: {
                  label: 'Essay',
                  type: 'number',
                  placeholder: 'AW'
                },
                expressionProperties: {
                  'templateOptions.min': '2',
                  'templateOptions.max': '8'
                },
                validation: {
                  messages: {
                    min: 'Essay score must be at least 2',
                    max: 'Essay score cannot exceed 8'
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'commandOfEvidence',
                type: 'input',
                templateOptions: {
                  label: 'Command of Evidence',
                  type: 'number',
                  placeholder: 'RW'
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'wordsInContext',
                type: 'input',
                templateOptions: {
                  label: 'Words in Context',
                  type: 'number',
                  placeholder: 'RW'
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'expressionOfIdeas',
                type: 'input',
                templateOptions: {
                  label: 'Expression of Ideas',
                  type: 'number',
                  placeholder: 'W'
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'standardEnglishConventions',
                type: 'input',
                templateOptions: {
                  label: 'Standard English Conventions',
                  type: 'number',
                  placeholder: 'W'
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'heartOfAlgebra',
                type: 'input',
                templateOptions: {
                  label: 'Heart of Algebra',
                  type: 'number',
                  placeholder: 'M'
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'problemSolvingDataAnalysis',
                type: 'input',
                templateOptions: {
                  label: 'Problem Solving & Data Analysis',
                  type: 'number',
                  placeholder: 'M'
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'passportToAdvanceMath',
                type: 'input',
                templateOptions: {
                  label: 'Passport to Advance Math',
                  type: 'number',
                  placeholder: 'M'
                }
              }
            ]
          }
      ];
      break;
          
      case 'act':
        this.layoutTestScores = [
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                fieldGroupClassName: 'd-flex justify-content-end align-items-center',
                fieldGroup: [
                  {
                    fieldGroup: [
                      {
                        type: 'button',
                        templateOptions: {
                          btnType: 'primary px-5 ',
                          type: 'submit',
                          text: 'Save Changes'
                        },
                        hideExpression: () => !this.saveChangesTestScores
                      }
                    ]
                  }
                ]
              },
              {
                className: 'col-md-6 mb-3',
                key: 'overallScore',
                type: 'input',
                templateOptions: {
                  label: 'Overall Score',
                  placeholder: 'Enter overall scores',
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.min': '1',
                  'templateOptions.max': '36'
                },
                validation: {
                  messages: {
                    min: 'Overall score must be at least 1',
                    max: 'Overall score cannot exceed 36'
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'actId',
                type: 'input',
                templateOptions: {
                  label: 'ACT ID',
                  placeholder: 'Enter the 8-digit number'
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'examDate',
                type: 'date',
                templateOptions: {
                  label: 'Date of Examination',
                  placeholder: 'Enter position'
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'english',
                type: 'input',
                templateOptions: {
                  label: 'English',
                  placeholder: 'E',
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.min': '1',
                  'templateOptions.max': '36'
                },
                validation: {
                  messages: {
                    min: 'English score must be at least 1',
                    max: 'English score cannot exceed 36'
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'math',
                type: 'input',
                templateOptions: {
                  label: 'Math',
                  placeholder: 'M',
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.min': '1',
                  'templateOptions.max': '36'
                },
                validation: {
                  messages: {
                    min: 'Math score must be at least 1',
                    max: 'Math score cannot exceed 36'
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'reading',
                type: 'input',
                templateOptions: {
                  label: 'Reading',
                  placeholder: 'R',
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.min': '1',
                  'templateOptions.max': '36'
                },
                validation: {
                  messages: {
                    min: 'Reading score must be at least 1',
                    max: 'Reading score cannot exceed 36'
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'science',
                type: 'input',
                templateOptions: {
                  label: 'Science',
                  placeholder: 'S',
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.min': '1',
                  'templateOptions.max': '36'
                },
                validation: {
                  messages: {
                    min: 'Essay score must be at least 1',
                    max: 'Essay score cannot exceed 36'
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'essay',
                type: 'input',
                templateOptions: {
                  label: 'Essay',
                  placeholder: 'E',
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.min': '2',
                  'templateOptions.max': '12'
                },
                validation: {
                  messages: {
                    min: 'Essay score must be at least 2',
                    max: 'Essay score cannot exceed 12'
                  }
                }
              }
            ]
          },                    
        ];
        break;  

      case 'ielts':
        this.layoutTestScores = [
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                fieldGroupClassName: 'd-flex justify-content-end align-items-center',
                fieldGroup: [
                  {
                    fieldGroup: [
                      {
                        type: 'button',
                        templateOptions: {
                          btnType: 'primary px-5 ',
                          type: 'submit',
                          text: 'Save Changes'
                        },
                        hideExpression: () => !this.saveChangesTestScores
                      }
                    ]
                  }
                ]
              },
              {
                className: 'col-md-6 mb-3',
                key: 'overallScore',
                type: 'input',
                templateOptions: {
                  label: 'Overall Score',
                  placeholder: 'Enter overall scores',
                  required: true,
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '0',
                  'templateOptions.max': '9'
                },
                validation: {
                  messages: {
                    min: 'Overall score must be at least 0',
                    max: 'Overall score cannot exceed 9'
                  }
                }

              },
              {
                className: 'col-md-6 mb-3',
                key: 'trfNo',
                type: 'input',
                templateOptions: {
                  label: 'TRF no.',
                  placeholder: 'T',
                  required: true
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true'
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'examDate',
                type: 'date',
                templateOptions: {
                  label: 'Date of Examination',
                  placeholder: 'Choose date',
                  required: true
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true'
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'listening',
                type: 'input',
                templateOptions: {
                  label: 'Listening',
                  placeholder: 'L',
                  required: true,
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '0',
                  'templateOptions.max': '9'
                },
                validation: {
                  messages: {
                    min: 'Listening score must be at least 0',
                    max: 'Listening score cannot exceed 9'
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'reading',
                type: 'input',
                templateOptions: {
                  label: 'Reading',
                  placeholder: 'R',
                  required: true,
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '0',
                  'templateOptions.max': '9'
                },
                validation: {
                  messages: {
                    min: 'Reading score must be at least 0',
                    max: 'Reading score cannot exceed 9'
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'writing',
                type: 'input',
                templateOptions: {
                  label: 'Writing',
                  placeholder: 'W',
                  required: true,
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '0',
                  'templateOptions.max': '9'
                },
                validation: {
                  messages: {
                    min: 'Writing score must be at least 0',
                    max: 'Writing score cannot exceed 9'
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'speaking',
                type: 'input',
                templateOptions: {
                  label: 'Speaking',
                  placeholder: 'S',
                  required: true,
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '0',
                  'templateOptions.max': '9'
                },
                validation: {
                  messages: {
                    min: 'Speaking score must be at least 0',
                    max: 'Speaking score cannot exceed 9'
                  }
                }
              },
              {
                className: 'col-md-3 mb-3',
                key: 'yetToReceive',
                type: 'select',
                defaultValue: false,
                templateOptions: {
                  label: 'Yet to Receive?',
                  required: true,
                  options: [
                    { label: 'No', value: false },
                    { label: 'Yes', value: true }
                  ]
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true'
                }
              },
              {
                className: 'col-md-3 mb-3',
                key: 'testResultDate',
                type: 'date',
                templateOptions: {
                  label: 'Test Result Date',
                  placeholder: 'Select date'
                },
                expressionProperties: {
                  'templateOptions.disabled': '!model.yetToReceive || model.waiver === true'
                }
              },
              {
                className: 'col-md-3 mb-3',
                key: 'waiver',
                type: 'select',
                defaultValue: false,
                templateOptions: {
                  label: 'IELTS Waiver?',
                  required: true,
                  options: [
                    { label: 'No', value: false },
                    { label: 'Yes', value: true }
                  ]
                }
              },
                  {
                    className: 'col-md-2 mb-3 mt-4',
                    key: 'englishMarks',
                    type: 'checkbox',
                    templateOptions: {
                      label: '12th English Marks'
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'model.waiver === false'
                    }
                  },
                  {
                    className: 'col-md-3 mb-3 mt-4',
                    key: 'englishMarksValue',
                    type: 'input',
                    templateOptions: {
                      placeholder: 'Out of 100'
                    },
                    expressionProperties: {
                      'templateOptions.disabled': '!model.englishMarks || model.waiver === false'
                    }
                  },
                  {
                    className: 'col-md-3 mb-3 mt-4',
                    key: 'mediumOfInstruction',
                    type: 'checkbox',
                    templateOptions: {
                      label: 'Medium of Instruction (MOI)'
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'model.waiver === false'
                    }
                  }
               
            ]
          }
        ];
        break;
      
      case 'toefl':
        this.layoutTestScores = [
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              // Save Changes Button
              {
                fieldGroupClassName: 'd-flex justify-content-end align-items-center',
                fieldGroup: [
                  {
                    fieldGroup: [
                      {
                        type: 'button',
                        templateOptions: {
                          btnType: 'primary px-5',
                          type: 'submit',
                          text: 'Save Changes'
                        },
                        hideExpression: () => !this.saveChangesTestScores
                      }
                    ]
                  }
                ]
              },
              // TOEFL Fields
              {
                className: 'col-md-6 mb-3',
                key: 'overallScore',
                type: 'input',
                templateOptions: {
                  label: 'Overall Score',
                  placeholder: 'Enter overall scores',
                  required: true,
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '0',
                  'templateOptions.max': '120'
                },
                validation: {
                  messages: {
                    min: 'Reading score must be at least 0',
                    max: 'Reading score cannot exceed 120',
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'registrationNumber',
                type: 'input',
                templateOptions: {
                  label: 'Registration Number',
                  placeholder: 'Enter 16-digit number',
                  required: true
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true'
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'examDate',
                type: 'date',
                templateOptions: {
                  label: 'Date of Examination',
                  placeholder: 'Choose date',
                  required: true
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true'
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'reading',
                type: 'input',
                templateOptions: {
                  label: 'Reading',
                  placeholder: 'R',
                  required: true,
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '0',
                  'templateOptions.max': '30'
                },
                validation: {
                  messages: {
                    min: 'Reading score must be at least 0',
                    max: 'Reading score cannot exceed 30',
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'listening',
                type: 'input',
                templateOptions: {
                  label: 'Listening',
                  placeholder: 'L',
                  required: true
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '0',
                  'templateOptions.max': '30'
                },
                validation: {
                  messages: {
                    min: 'Listening score must be at least 0',
                    max: 'Listening score cannot exceed 30',
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'speaking',
                type: 'input',
                templateOptions: {
                  label: 'Speaking',
                  placeholder: 'S',
                  required: true
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '0',
                  'templateOptions.max': '30'
                },
                validation: {
                  messages: {
                    min: 'Speaking score must be at least 0',
                    max: 'Speaking score cannot exceed 30',
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'writing',
                type: 'input',
                templateOptions: {
                  label: 'Writing',
                  placeholder: 'W',
                  required: true
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '0',
                  'templateOptions.max': '30'
                },
                validation: {
                  messages: {
                    min: 'Reading score must be at least 0',
                    max: 'Reading score cannot exceed 30',
                  }
                }
              },
              {
                className: 'col-md-3 mb-3',
                key: 'yetToReceive',
                type: 'select',
                defaultValue: false,
                templateOptions: {
                  label: 'Yet to Receive?',
                  required: true,
                  options: [
                    { label: 'No', value: false },
                    { label: 'Yes', value: true }
                  ]
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true'
                }
              },
              {
                className: 'col-md-3 mb-3',
                key: 'testResultDate',
                type: 'date',
                templateOptions: {
                  label: 'Test Result Date',
                  placeholder: 'Select date'
                },
                expressionProperties: {
                  'templateOptions.disabled': '!model.yetToReceive || model.waiver === true'
                }
              },
              {
                className: 'col-md-3 mb-3',
                key: 'waiver',
                type: 'select',
                defaultValue: false,
                templateOptions: {
                  label: 'TOEFL Waiver?',
                  required: true,
                  options: [
                    { label: 'No', value: false },
                    { label: 'Yes', value: true }
                  ]
                }
              },
                  {
                    className: 'col-md-2 mb-3 mt-4',
                    key: 'englishMarks',
                    type: 'checkbox',
                    templateOptions: {
                      label: '12th English Marks'
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'model.waiver === false'
                    }
                  },
                  {
                    className: 'col-md-3 mb-3 mt-4',
                    key: 'englishMarksValue',
                    type: 'input',
                    templateOptions: {
                      placeholder: 'Out of 100'
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'model.waiver === false'
                    }
                  },
                  {
                    className: 'col-md-3 mb-3 mt-4',
                    key: 'mediumOfInstruction',
                    type: 'checkbox',
                    templateOptions: {
                      label: 'Medium of Instruction (MOI)'
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'model.waiver === false'
                    }
                  }
               
            ]
          }
        ];
        break;

      case 'pte':
        this.layoutTestScores = [
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              // Save Changes Button
              {
                fieldGroupClassName: 'd-flex justify-content-end align-items-center',
                fieldGroup: [
                  {
                    fieldGroup: [
                      {
                        type: 'button',
                        templateOptions: {
                          btnType: 'primary px-5',
                          type: 'submit',
                          text: 'Save Changes'
                        },
                        hideExpression: () => !this.saveChangesTestScores
                      }
                    ]
                  }
                ]
              },
              // PTE Fields
              {
                className: 'col-md-6 mb-3',
                key: 'overallScore',
                type: 'input',
                templateOptions: {
                  label: 'Overall Score',
                  placeholder: 'Enter overall scores',
                  required: true,
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '10',
                  'templateOptions.max': '90'
                },
                validation: {
                  messages: {
                    min: 'Total score must be at least 10',
                    max: 'Total score cannot exceed 90',
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'scoreReportCode',
                type: 'input',
                templateOptions: {
                  label: 'Score Report Code',
                  placeholder: 'Enter code',
                  required: true
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)'
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'examDate',
                type: 'date',
                templateOptions: {
                  label: 'Date of Examination',
                  placeholder: 'Choose date',
                  required: true
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true'
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'reading',
                type: 'input',
                templateOptions: {
                  label: 'Reading',
                  placeholder: 'R',
                  required: true,
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '10',
                  'templateOptions.max': '90'
                },
                validation: {
                  messages: {
                    min: 'Reading score must be at least 10',
                    max: 'Reading score cannot exceed 90',
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'listening',
                type: 'input',
                templateOptions: {
                  label: 'Listening',
                  placeholder: 'L',
                  required: true
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '10',
                  'templateOptions.max': '90'
                },
                validation: {
                  messages: {
                    min: 'Listening score must be at least 10',
                    max: 'Listening score cannot exceed 90',
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'speaking',
                type: 'input',
                templateOptions: {
                  label: 'Speaking',
                  placeholder: 'S',
                  required: true,
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '10',
                  'templateOptions.max': '90'
                },
                validation: {
                  messages: {
                    min: 'Reading score must be at least 10',
                    max: 'Reading score cannot exceed 90',
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'writing',
                type: 'input',
                templateOptions: {
                  label: 'Writing',
                  placeholder: 'W',
                  required: true
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '10',
                  'templateOptions.max': '90'
                },
                validation: {
                  messages: {
                    min: 'Writing score must be at least 10',
                    max: 'Writing score cannot exceed 90',
                  }
                }
              },
              {
                className: 'col-md-3 mb-3',
                key: 'yetToReceive',
                type: 'select',
                defaultValue: false,
                templateOptions: {
                  label: 'Yet to Receive?',
                  required: true,
                  options: [
                    { label: 'No', value: false },
                    { label: 'Yes', value: true }
                  ]
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true'
                }
              },
              {
                className: 'col-md-3 mb-3',
                key: 'testResultDate',
                type: 'date',
                templateOptions: {
                  label: 'Test Result Date',
                  placeholder: 'Select date'
                },
                expressionProperties: {
                  'templateOptions.disabled': '!model.yetToReceive || model.waiver === true'
                }
              },
              {
                className: 'col-md-3 mb-3',
                key: 'waiver',
                type: 'select',
                defaultValue: false,
                templateOptions: {
                  label: 'PTE Waiver?',
                  required: true,
                  options: [
                    { label: 'No', value: false },
                    { label: 'Yes', value: true }
                  ]
                }
              },
 
                  {
                    className: 'col-md-2 mb-3 mt-4',
                    key: 'englishMarks',
                    type: 'checkbox',
                    templateOptions: {
                      label: '12th English Marks'
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'model.waiver === false'
                    }
                  },
                  {
                    className: 'col-md-3 mb-3 mt-4',
                    key: 'englishMarksValue',
                    type: 'input',
                    templateOptions: {
                      placeholder: 'Out of 100'
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'model.waiver === false'
                    }
                  },
                  {
                    className: 'col-md-3 mb-3 mt-4',
                    key: 'mediumOfInstruction',
                    type: 'checkbox',
                    templateOptions: {
                      label: 'Medium of Instruction (MOI)'
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'model.waiver === false'
                    }
                  }
            ]
          }
        ];
        break;
      
      case 'det':
        this.layoutTestScores = [
          {
            fieldGroupClassName: 'row mn-4',
            fieldGroup: [
              // Save Changes Button
              {
                fieldGroupClassName: 'd-flex justify-content-end align-items-center',
                fieldGroup: [
                  {
                    fieldGroup: [
                      {
                        type: 'button',
                        templateOptions: {
                          btnType: 'primary px-5',
                          type: 'submit',
                          text: 'Save Changes'
                        },
                        hideExpression: () => !this.saveChangesTestScores
                      }
                    ]
                  }
                ]
              },
              // DET Fields
              {
                className: 'col-md-6 mb-3',
                key: 'totalScore',
                type: 'input',
                templateOptions: {
                  label: 'Total Score',
                  placeholder: 'Enter overall scores',
                  required: true,
                  type: 'number'
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '10',
                  'templateOptions.max': '160'
                },
                validation: {
                  messages: {
                    min: 'Total score must be at least 10',
                    max: 'Total score cannot exceed 160',
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'scoreReportCode',
                type: 'input',
                templateOptions: {
                  label: 'Score Report Code',
                  placeholder: 'Enter 16-digit number',
                  required: true
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)'
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'examDate',
                type: 'date',
                templateOptions: {
                  label: 'Date of Examination',
                  placeholder: 'Choose date',
                  required: true
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true'
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'literacy',
                type: 'input',
                templateOptions: {
                  label: 'Literacy',
                  placeholder: 'R',
                  required: true,
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '10',
                  'templateOptions.max': '160'
                },
                validation: {
                  messages: {
                    min: 'Literacy score must be at least 10',
                    max: 'Literacy score cannot exceed 160',
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'comprehension',
                type: 'input',
                templateOptions: {
                  label: 'Comprehension',
                  placeholder: 'L',
                  required: true,
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '10',
                  'templateOptions.max': '160'
                },
                validation: {
                  messages: {
                    min: 'Comprehension score must be at least 10',
                    max: 'Comprehension score cannot exceed 160',
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'conversation',
                type: 'input',
                templateOptions: {
                  label: 'Conversation',
                  placeholder: 'S',
                  required: true,
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '10',
                  'templateOptions.max': '160'
                },
                validation: {
                  messages: {
                    min: 'Conversation score must be at least 10',
                    max: 'Conversation score cannot exceed 160',
                  }
                }
              },
              {
                className: 'col-md-6 mb-3',
                key: 'production',
                type: 'input',
                templateOptions: {
                  label: 'Production',
                  placeholder: 'W',
                  required: true,
                  type:'number'
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true || (model.waiver === false && model.yetToReceive === true)',
                  'templateOptions.min': '10',
                  'templateOptions.max': '160'
                },
                validation: {
                  messages: {
                    min: 'Production score must be at least 10',
                    max: 'Production score cannot exceed 160',
                  }
                }
              },
              {
                className: 'col-md-3 mb-3',
                key: 'yetToReceive',
                type: 'select',
                defaultValue: false,
                templateOptions: {
                  label: 'Yet to Receive?',
                  required: true,
                  options: [
                    { label: 'No', value: false },
                    { label: 'Yes', value: true }
                  ]
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.waiver === true'
                }
              },
              {
                className: 'col-md-3 mb-3',
                key: 'testResultDate',
                type: 'date',
                templateOptions: {
                  label: 'Test Result Date',
                  placeholder: 'Select date'
                },
                expressionProperties: {
                  'templateOptions.disabled': '!model.yetToReceive || model.waiver === true'
                }
              },
              {
                className: 'col-md-3 mb-3',
                key: 'waiver',
                type: 'select',
                defaultValue: false,
                templateOptions: {
                  label: 'DET Waiver?',
                  required: true,
                  options: [
                    { label: 'No', value: false },
                    { label: 'Yes', value: true }
                  ]
                }
              },
                  {
                    className: 'col-md-2 mb-3 mt-4',
                    key: 'englishMarks',
                    type: 'checkbox',
                    templateOptions: {
                      label: '12th English Marks'
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'model.waiver === false'
                    }
                  },
                  {
                    className: 'col-md-3 mb-3 mt-4',
                    key: 'englishMarksValue',
                    type: 'input',
                    templateOptions: {
                      placeholder: 'Out of 100'
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'model.waiver === false'
                    }
                  },
                  {
                    className: 'col-md-3 mb-3 mt-4',
                    key: 'mediumOfInstruction',
                    type: 'checkbox',
                    templateOptions: {
                      label: 'Medium of Instruction (MOI)'
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'model.waiver === false'
                    }
                  }
            ]
          }
        ];
        break;

      }
    }

  dataPreProcessor() {
    const currentSection = this.getCurrentSection();
    if (currentSection) {
      this.model = {
        ...this.userDetails?.testScores?.[currentSection] || {},
      };
    }
  }

  dataProcessor(data) {
    const cleanedData = this.removeEmpty(data);
    this.saveChangesTestScores = false;
    // this.userIdentity.setUserDetails(cleanedData);
    return cleanedData;
  }

  onSubmit(event: any) {
    if (event?.error) {
      console.error('Form submission error:', event.error);
    } else {
      this.submitForm();
    }
  }
  
  submitForm() {
    const currentSection = this.getCurrentSection();
    if (!currentSection) return;
    const existingTestScores = { ...(this.userDetails?.testScores || {}) };
  
    let testScoresData = {
      testScores: {
        ...existingTestScores
      }
    };

    if (currentSection === 'gre') {
      console.info(testScoresData)
      testScoresData.testScores = {
        ...testScoresData.testScores,
        gre: {
          overallScore: this.model.overallScore,
          examDate: this.model.examDate,
          quantitative: this.model.quantitative,
          verbal: this.model.verbal,
          analyticalWriting: this.model.analyticalWriting,
          registrationNo: this.model.registrationNo
        }
      };
    } else if (currentSection === 'gmat') {
      const gmatData = {
        version: this.model.version,
        overallScore: this.model.overallScore,
        examDate: this.model.examDate,
        quantitative: this.model.quantitative,
        verbal: this.model.verbal,
        appointmentNumber: this.model.appointmentNumber
      };
  
      if (this.model.version === 'focus') {
        testScoresData.testScores = {
          ...testScoresData.testScores,
          gmat: {
            ...gmatData,
            dataInsightsScore: this.model.dataInsightsScore
          }
        };
      } else if (this.model.version === 'traditional') {
        testScoresData.testScores = {
          ...testScoresData.testScores,
          gmat: {
            ...gmatData,
            analyticalWriting: this.model.analyticalWriting,
            integratedReasoning: this.model.integratedReasoning
          }
        };
      }
    }else if (currentSection === 'sat') {
      testScoresData.testScores = {
        ...testScoresData.testScores,
        sat: {
          totalScore: this.model.totalScore,
          registrationNumber: this.model.registrationNumber,
          examDate: this.model.examDate,
          readingWriting: this.model.readingWriting,
          math: this.model.math,
          essay: this.model.essay,
          commandOfEvidence: this.model.commandOfEvidence,
          wordsInContext: this.model.wordsInContext,
          expressionOfIdeas: this.model.expressionOfIdeas,
          standardEnglishConventions: this.model.standardEnglishConventions,
          heartOfAlgebra: this.model.heartOfAlgebra,
          problemSolvingDataAnalysis: this.model.problemSolvingDataAnalysis,
          passportToAdvanceMath: this.model.passportToAdvanceMath
        }
      };
    }else if (currentSection === 'act') {
      testScoresData.testScores = {
        ...testScoresData.testScores,
        act: {
          overallScore: this.model.overallScore,
          actId: this.model.actId,
          examDate: this.model.examDate,
          english: this.model.english,
          math: this.model.math,
          reading: this.model.reading,
          science: this.model.science,
          essay: this.model.essay
        }
      };
    }else if (currentSection === 'ielts') {
    
    const ieltsData = {
      overallScore: this.model.overallScore,
      trfNo: this.model.trfNo,
      examDate: this.model.examDate,
      listening: this.model.listening,
      reading: this.model.reading,
      writing: this.model.writing,
      speaking: this.model.speaking,
      yetToReceive: this.model.yetToReceive,
      waiver: this.model.waiver
    };

    // Add conditional fields based on waiver and yetToReceive status
    if (this.model.waiver) {
      // If waiver is true, include waiver-related fields
      ieltsData['englishMarks'] = this.model.englishMarks;
      if (this.model.englishMarks) {
        ieltsData['englishMarksValue'] = this.model.englishMarksValue;
      }
      ieltsData['mediumOfInstruction'] = this.model.mediumOfInstruction;
    } else if (this.model.yetToReceive) {
      // If yet to receive is true, include test result date
      ieltsData['testResultDate'] = this.model.testResultDate;
    }

    testScoresData.testScores = {
      ...testScoresData.testScores,
      ielts: ieltsData
    };
    } else if (currentSection === 'toefl') {
  const toeflData = {
    overallScore: this.model.overallScore,
    registrationNumber: this.model.registrationNumber,
    examDate: this.model.examDate,
    reading: this.model.reading,
    listening: this.model.listening,
    speaking: this.model.speaking,
    writing: this.model.writing,
    yetToReceive: this.model.yetToReceive,
    waiver: this.model.waiver
  };

  // Add conditional fields based on waiver and yetToReceive status
  if (this.model.waiver) {
    // If waiver is true, include waiver-related fields
    toeflData['englishMarks'] = this.model.englishMarks;
    if (this.model.englishMarks) {
      toeflData['englishMarksValue'] = this.model.englishMarksValue;
    }
    toeflData['mediumOfInstruction'] = this.model.mediumOfInstruction;
  } else if (this.model.yetToReceive) {
    // If yet to receive is true, include test result date
    toeflData['testResultDate'] = this.model.testResultDate;
  }

  testScoresData.testScores = {
    ...testScoresData.testScores,
    toefl: toeflData
  };
    }else if (currentSection === 'pte') {
    const pteData = {
      overallScore: this.model.overallScore,
      scoreReportCode: this.model.scoreReportCode,
      examDate: this.model.examDate,
      reading: this.model.reading,
      listening: this.model.listening,
      speaking: this.model.speaking,
      writing: this.model.writing,
      yetToReceive: this.model.yetToReceive,
      waiver: this.model.waiver
    };
  
    // Add conditional fields based on waiver and yetToReceive status
    if (this.model.waiver) {
      // If waiver is true, include waiver-related fields
      pteData['englishMarks'] = this.model.englishMarks;
      if (this.model.englishMarks) {
        pteData['englishMarksValue'] = this.model.englishMarksValue;
      }
      pteData['mediumOfInstruction'] = this.model.mediumOfInstruction;
    } else if (this.model.yetToReceive) {
      // If yet to receive is true, include test result date
      pteData['testResultDate'] = this.model.testResultDate;
    }
  
    testScoresData.testScores = {
      ...testScoresData.testScores,
      pte: pteData
    };
    }else if (currentSection === 'det') {
    const detData = {
      totalScore: this.model.totalScore,
      scoreReportCode: this.model.scoreReportCode,
      examDate: this.model.examDate,
      literacy: this.model.literacy,
      comprehension: this.model.comprehension,
      conversation: this.model.conversation,
      production: this.model.production,
      yetToReceive: this.model.yetToReceive,
      waiver: this.model.waiver
    };
  
    // Add conditional fields based on waiver and yetToReceive status
    if (this.model.waiver) {
      // If waiver is true, include waiver-related fields
      detData['englishMarks'] = this.model.englishMarks;
      if (this.model.englishMarks) {
        detData['englishMarksValue'] = this.model.englishMarksValue;
      }
      detData['mediumOfInstruction'] = this.model.mediumOfInstruction;
    } else if (this.model.yetToReceive) {
      // If yet to receive is true, include test result date
      detData['testResultDate'] = this.model.testResultDate;
    }
  
    testScoresData.testScores = {
      ...testScoresData.testScores,
      det: detData
    };
    }
  
    testScoresData = this.removeEmpty(testScoresData);
  
    this.apiHelper.updateDatawithShortlistingUrl('university-students', this.studentDetails._id, testScoresData)
    .subscribe(
      (res: any) => {
        if (res && res.updatedApplicant) {

          this.userDetails = res.updatedApplicant;
          this.studentDetails = res.updatedApplicant;
          this.saveChangesTestScores = false;
          this.expandedSections[currentSection] = false;
          this.model = {};
          this.isLoading = false;

          this.cd.detectChanges();
        } else {
          console.error('Update response invalid:', res);
          this.isLoading = false;
        }
      },
      (error) => {
        console.error('Update error:', error);
        this.isLoading = false;
      }
    );
  }

  private getCurrentSection(): string {
    return Object.keys(this.expandedSections).find(key => this.expandedSections[key]) || '';
  }


  hasAptitudeScores(): boolean {
    if (!this.userDetails?.testScores) return false;
    return !!(
      this.userDetails.testScores.gre ||
      this.userDetails.testScores.gmat ||
      this.userDetails.testScores.sat ||
      this.userDetails.testScores.act
    );
  }

  hasEnglishScores(): boolean {
    if (!this.userDetails?.testScores) return false;
    return !!(
      this.userDetails.testScores.ielts ||
      this.userDetails.testScores.toefl ||
      this.userDetails.testScores.pte ||
      this.userDetails.testScores.det
    );
  }

  cancelEdit() {
    this.saveChangesTestScores = false;
    this.dataPreProcessor();
  }

  removeEmpty(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      } else if (typeof obj[propName] === 'object') {
        this.removeEmpty(obj[propName]);
      }
    }
    return obj;
  }


  // Add this method to your component
  deleteTestScore(testType: string) {
    if (confirm(`Are you sure you want to delete this ${testType.toUpperCase()} test score?`)) {
      this.isLoading = true;
      
      this.apiHelper.deleteDataWithShortlistingUrl(`university-students/${this.studentDetails._id}/test-scores/${testType}`)
        .then((response: any) => {
          if (response && response.updatedApplicant) {
            this.userDetails = response.updatedApplicant;
            this.studentDetails = response.updatedApplicant;
          } else {
            this.handleDeleteError();
          }
          this.isLoading = false;
          this.cd.detectChanges();
        })
        .catch((error) => {
          console.error(`Delete ${testType} test score error:`, error);
          this.handleDeleteError();
          this.isLoading = false;
          this.cd.detectChanges();
        });
    }
  }

  private handleDeleteError() {
    alert('Failed to delete test score. Please try again.');
  }
}