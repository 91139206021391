import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SharedService } from '../services/shared.service';

import { PartnerService } from '../services/partner.service';
import { SnackbarService } from '../services/snackbar.service';



import { environment } from 'projects/foreign-admits/src/environments/environment';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { getBudgetRangeOptions, getCurrencyOptions } from '../components/configs/currency.config';
import { COUNTRY_OPTIONS, COURSE_OPTIONS, HIGH_SCHOOL_BOARDS, LIST_OF_COURSES } from '../components/configs/education.config';
import { ChangeDetectorRef } from '@angular/core';


interface PhoneNumber {
  e164Number: string;
  internationalNumber: string;
  nationalNumber: string;
  number: string;
  countryCode: string;
  dialCode: string;
}

interface FormlyFieldExtended extends FormlyFieldConfig {
  model?: {
    graduationStatus?: string;
  };
}

interface TestNameMap {
  [key: string]: string;
}


@Component({
  selector: 'app-user-info-page',
  templateUrl: './user-info-page.component.html',
  styleUrls: ['./user-info-page.component.scss']
})
export class UserInfoPageComponent implements OnInit {
  private baseUrl: string = environment.apiBaseUrl;
  model: any = {};
  form = new FormGroup({});
  finalPayload: any = { entryTests: [] };
  step = 'studyDetails';
  progressBarWidth: string = '0%';
  otp: string = '';
  otpArray = Array(6);
  otpValues: string[] = ['', '', '', '', '', ''];
  countdown: number = 59;
  countdownInterval: any;
  public searchCountryField = SearchCountryField;
  public countryISO = CountryISO;
  public phoneNumberFormat = PhoneNumberFormat;
  maskedPhoneNumber: string = '';
  showError: boolean = false;
  userDetailsField: FormlyFieldConfig[] = [];
  partnerConsent: string = '';
  expectedGraduationYear: number | null = null;
  hasAttemptedSubmit: boolean = false;
  private studentRegisterUrl = environment.registerBaseUrl;
  isRegistrationCourseDropdownOpen = false;

  readonly countryOptions = COUNTRY_OPTIONS;
  readonly highSchoolBoards = HIGH_SCHOOL_BOARDS;
  readonly listOfCourses = LIST_OF_COURSES;
  readonly courseOptions = COURSE_OPTIONS;


  degreeOptions: string[] = ['Bachelors', 'Masters'];
  isCourseDropdownOpen: boolean = false;
  courseSearchTerm: string = '';

  aptitudeTestOptions: string[] = ['GRE', 'GMAT', 'Not Taken Yet'];
  languageTestOptions: string[] = ['IELTS', 'TOEFL', 'PTE', 'Duolingo', 'Not Taken Yet'];
  professionOptions: string[] = ['Student', 'Working'];

  // Error flags
  showAptitudeError: boolean = false;
  showLanguageError: boolean = false;

  // Validation criteria for each test type
  testValidationConfig: { [key: string]: { max: number, integerOnly: boolean } } = {
    'GRE': { max: 340, integerOnly: true },
    'GMAT': { max: 800, integerOnly: true },
    'IELTS': { max: 9, integerOnly: false },
    'TOEFL': { max: 120, integerOnly: true },
    'PTE': { max: 90, integerOnly: true },
    'Duolingo': { max: 160, integerOnly: true },
    'DET': { max: 160, integerOnly: true },
    'SAT': { max: 1600, integerOnly: true },
    'ACT': { max: 36, integerOnly: true }
  };



  readonly maxScores = {
    IELTS: 9.0,
    TOEFL: 120,
    PTE: 90,
    Duolingo: 160,
    GRE: 340,
    GMAT: 800,
  };


  constructor(private sharedService: SharedService,
    private router: Router,
    private http: HttpClient,
    private partnerService: PartnerService,
    private snackbarService: SnackbarService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('appliedFilters');
    localStorage.removeItem('initialFilter');
    localStorage.removeItem('loglevel');
    localStorage.removeItem('results');
    localStorage.removeItem('uid');
    localStorage.removeItem('currentFilter');
    localStorage.removeItem('created');
    localStorage.removeItem('parentId');

    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    if (puid) {
      this.fetchPartnerLogo(puid);
    }
    this.setUserDetailsLayout();
    this.updateProgressBar();
  }

  // Define the TEST_NAME_MAPPING with proper typing
  private readonly TEST_NAME_MAPPING: TestNameMap = {
    'Duolingo': 'DET'
  } as const;

  // Validate aptitude test score based on selected test
  validateAptitudeTestScore() {
    const testType = this.finalPayload.aptitudeTest;
    const score = this.finalPayload.aptitudeTestScore;
    if (testType && this.testValidationConfig[testType]) {
      const { max, integerOnly } = this.testValidationConfig[testType];
      this.showAptitudeError =
        (isNaN(score) || score < 0 || score > max || (integerOnly && !Number.isInteger(parseFloat(score))));

      // Display specific error message
      if (this.showAptitudeError) {
        console.error(`${testType} score must be between 0 and ${max}${integerOnly ? ' (integers only)' : ''}.`);
      }
    } else {
      this.showAptitudeError = false; // Reset for "Not Taken Yet" or undefined
    }
  }


  // Validate language test score based on selected test
  validateLanguageTestScore() {
    const testType = this.finalPayload.languageTest;
    const score = this.finalPayload.languageTestScore;

    if (testType && this.testValidationConfig[testType]) {
      const { max, integerOnly } = this.testValidationConfig[testType];

      if (testType === 'IELTS') {
        this.showLanguageError =
          (isNaN(score) || score < 0 || score > max || (score % 0.5 !== 0));
      } else {
        this.showLanguageError =
          (isNaN(score) || score < 0 || score > max || (integerOnly && !Number.isInteger(parseFloat(score))));
      }
      if (this.showLanguageError) {
        console.error(`${testType} score must be between 0 and ${max}${integerOnly ? ' (integers only)' : ''}.`);
      }
    } else {
      this.showLanguageError = false;
    }
  }


  // Update aptitude test options based on selected degree
  updateAptitudeTestOptions(): void {
    if (this.finalPayload.targetDegree === 'Undergraduate') {
      this.aptitudeTestOptions = ['SAT', 'ACT', 'Not Taken Yet'];
    } else if (this.finalPayload.targetDegree === 'Postgraduate') {
      this.aptitudeTestOptions = ['GRE', 'GMAT', 'Not Taken Yet'];
    }
  }

  // Generate placeholder based on selected test and validation config
  generatePlaceholder(testName: string): string {
    const config = this.testValidationConfig[testName];
    if (config) {
      return `Enter a score between 0 and ${config.max}${config.integerOnly ? '' : ''}`;
    }
    return 'Enter your score';
  }


  // Toggle dropdown visibility
  toggleCourseDropdown() {
    this.isCourseDropdownOpen = !this.isCourseDropdownOpen;
  }

  // Filter courses based on search term
  filteredCourses(): string[] {
    return this.listOfCourses.filter(course =>
      course.toLowerCase().includes(this.courseSearchTerm.toLowerCase())
    );
  }

  // Select a course from the dropdown
  selectCourse(course: string) {
    this.finalPayload.targetCourse = course;
    this.courseSearchTerm = '';
    this.isCourseDropdownOpen = false;
    console.log('Selected course:', this.finalPayload.targetCourse);
  }


  moveFocus(index: number, event: any) {
    const input = event.target;
    let nextInput: HTMLInputElement | null = null;
    let prevInput: HTMLInputElement | null = null;

    const inputs = document.querySelectorAll('.otp-box');

    // Find next and previous inputs
    if (index < inputs.length - 1) {
      nextInput = inputs[index + 1] as HTMLInputElement;
    }
    if (index > 0) {
      prevInput = inputs[index - 1] as HTMLInputElement;
    }

    // Handle backspace
    if (event.key === 'Backspace') {
      if (input.value === '') {
        // If current input is empty and backspace is pressed, move to previous input
        if (prevInput) {
          prevInput.value = '';
          prevInput.focus();
          this.otpValues[index - 1] = '';
        }
      } else {
        // Clear current input
        input.value = '';
        this.otpValues[index] = '';
      }
      event.preventDefault();
      return;
    }

    // Handle normal input
    const value = event.target.value;

    // Ensure only numbers are entered
    const numericValue = value.replace(/[^0-9]/g, '');
    this.otpValues[index] = numericValue;
    input.value = numericValue;

    // If a number is entered, move to next input
    if (numericValue && nextInput) {
      nextInput.focus();
    }

    // Prevent default behavior for non-numeric inputs
    if (value !== numericValue) {
      event.preventDefault();
    }
  }

  resendOtp() {
    this.countdown = 59;
    this.startCountdown();
    this.sendOtpToUser();
  }


  editMobileNumber() {
    this.step = 'registration';
    clearInterval(this.countdownInterval);
    this.cdr.detectChanges();
  }

  startCountdown() {
    this.countdown = 59;
    clearInterval(this.countdownInterval);
    this.countdownInterval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        clearInterval(this.countdownInterval);
      }
    }, 1000);
  }


  concatenateOTP() {
    const otp = this.otpValues.join('');
    this.finalPayload.otp = otp;
    console.log('Concatenated OTP:', otp);
  }

  // Select methods for button clicks
  selectCountry(country: { name: string, flagUrl: string }) {
    this.finalPayload.targetCountry = country.name;

    if (this.step === 'professionDetails') {
      this.setUserDetailsLayout();
    }
  }

  selectDegree(degree: string) {
    if (degree === 'Bachelors') {
      this.finalPayload.targetDegree = 'Undergraduate';
    } else if (degree === 'Masters') {
      this.finalPayload.targetDegree = 'Postgraduate';
    }
    this.updateAptitudeTestOptions();
  }


  selectAptitudeTest(test: string) {
    this.finalPayload.aptitudeTest = test;

    if (test === 'Not Taken Yet') {
      this.finalPayload.aptitudeTestScore = null;
      this.addEntryTest(test, null);
    } else {
      this.addEntryTest(test, this.finalPayload.aptitudeTestScore);
    }
  }

  updateAptitudeTestScore(score: number) {
    this.finalPayload.aptitudeTestScore = score;
    if (this.finalPayload.aptitudeTest && this.finalPayload.aptitudeTest !== 'Not Taken Yet') {
      this.addEntryTest(this.finalPayload.aptitudeTest, score);
    }
  }

  handleAptitudeChange(value: number): void {
    this.updateAptitudeTestScore(value); // First function
    this.validateAptitudeTestScore();   // Second function
  }

  handleLanguageChange(value: number): void {
    this.updateLanguageTestScore(value); // First function
    this.validateLanguageTestScore();   // Second function
  }


  selectLanguageTest(test: string) {
    this.finalPayload.languageTest = test;

    if (test === 'Not Taken Yet') {
      this.finalPayload.languageTestScore = null;
      this.addEntryTest(test, null);
    } else {
      const mappedTestName = test in this.TEST_NAME_MAPPING
        ? this.TEST_NAME_MAPPING[test]
        : test;
      this.addEntryTest(test, this.finalPayload.languageTestScore);
    }
  }

  updateLanguageTestScore(score: number) {
    this.finalPayload.languageTestScore = score;
    if (this.finalPayload.languageTest && this.finalPayload.languageTest !== 'Not Taken Yet') {
      const mappedTestName = this.finalPayload.languageTest in this.TEST_NAME_MAPPING
        ? this.TEST_NAME_MAPPING[this.finalPayload.languageTest]
        : this.finalPayload.languageTest;
      this.addEntryTest(this.finalPayload.languageTest, score);
    }
  }

//   addEntryTest(testName: string, testScore: number | null) {
//     // Use type-safe access for mapping
//     const mappedTestName = testName in this.TEST_NAME_MAPPING
//       ? this.TEST_NAME_MAPPING[testName]
//       : testName;
      
//     // Remove existing entries
//     this.finalPayload.entryTests = this.finalPayload.entryTests.filter(
//       (test: any) => test.testName !== mappedTestName && test.testName !== testName
//     );
    
//      // Only add the test if it's "Not Taken Yet" OR has a non-null score
//      if (testName === 'Not Taken Yet' || testScore !== null) {
//       this.finalPayload.entryTests.push({
//           testName: mappedTestName,
//           testScore: testScore
//       });
//      }

//     // Filter out any entries with null scores except "Not Taken Yet"
//     this.finalPayload.entryTests = this.finalPayload.entryTests.filter((test: any) => 
//         test.testName === 'Not Taken Yet' || test.testScore !== null
//     );
//   }

//  updateTestScore(testName: string, testScore: number | null) {
//   const testEntry = this.finalPayload.entryTests.find((t: any) => t.testName === testName);
//   if (testEntry) {
//       testEntry.testScore = testScore;
//   }
// }
// Function to add or update entry test
  addEntryTest(testName: string, testScore: number | null) {
    // Use type-safe access for mapping
    const mappedTestName = testName in this.TEST_NAME_MAPPING
      ? this.TEST_NAME_MAPPING[testName]
      : testName;

    // Check if it's a language test
    const isLanguageTest = this.languageTestOptions.includes(testName);
    const isAptitudeTest = this.aptitudeTestOptions.includes(testName);

    // Remove existing entries of the same type (language or aptitude)
    this.finalPayload.entryTests = this.finalPayload.entryTests.filter((test: any) => {
      const currentTestName = test.testName;
      const isCurrentLanguageTest = this.languageTestOptions.includes(currentTestName) ||
        currentTestName === 'DET'; // Special case for Duolingo/DET
      const isCurrentAptitudeTest = this.aptitudeTestOptions.includes(currentTestName);

      // Keep tests of different types
      return (isLanguageTest && !isCurrentLanguageTest) || 
            (isAptitudeTest && !isCurrentAptitudeTest) ||
            (!isLanguageTest && !isAptitudeTest);
    });

    // Only add the test if:
    // 1. It's "Not Taken Yet" OR
    // 2. Has a valid non-null score
    if (testName === 'Not Taken Yet' || testScore !== null) {
      this.finalPayload.entryTests.push({
        testName: mappedTestName,
        testScore: testScore
      });
    }

    // Log the updated entryTests array for debugging
    console.log('Updated entryTests:', this.finalPayload.entryTests);
  }

  // Function to update existing test score
  updateTestScore(testName: string, testScore: number | null) {
    const testEntry = this.finalPayload.entryTests.find((t: any) => 
      t.testName === testName || 
      (testName in this.TEST_NAME_MAPPING && t.testName === this.TEST_NAME_MAPPING[testName])
    );
    
    if (testEntry) {
      if (testScore !== null) {
        testEntry.testScore = testScore;
      } else {
        // If score is null, remove the entry unless it's "Not Taken Yet"
        this.finalPayload.entryTests = this.finalPayload.entryTests.filter((t: any) => 
          t.testName !== testName && 
          (testName in this.TEST_NAME_MAPPING ? t.testName !== this.TEST_NAME_MAPPING[testName] : true)
        );
      }
    }
  }

  onSubmit(goingBack: boolean = false) {
    // If going back, just navigate without validation
    if (!goingBack) {
      this.hasAttemptedSubmit = true;
    }

    if (goingBack) {
      this.navigateBack();
      return;
    }

    // Forward navigation with validation
    if (this.validateCurrentStep()) {
      this.navigateForward();
    }
  }

  validateCurrentStep(): boolean {
    this.showError = false;

    switch (this.step) {
      case 'studyDetails':
        if (!this.finalPayload.targetCountry || !this.finalPayload.targetDegree || !this.finalPayload.targetCourse) {
          this.showError = this.hasAttemptedSubmit;
          return false;
        }
        return true;


      case 'testScores':
        this.hasAttemptedSubmit = false;
        if (!this.finalPayload.aptitudeTest ||
          (this.finalPayload.aptitudeTest !== 'Not Taken Yet' && !this.finalPayload.aptitudeTestScore)) {
          this.showError = this.hasAttemptedSubmit;
          return false;
        }

        if (!this.finalPayload.languageTest ||
          (this.finalPayload.languageTest !== 'Not Taken Yet' && !this.finalPayload.languageTestScore)) {
          this.showError = this.hasAttemptedSubmit;
          return false;
        }
        return true;

      case 'professionDetails':
      case 'undergraduateDetails':
      case 'highSchoolDetails':
      case 'registration':
        if (!this.form.valid) {
          this.showError = this.hasAttemptedSubmit;
          return false;
        }
        return true;

      default:
        return true;
    }
  }

  //Navigate Forward
  navigateForward() {
    if (this.step === 'registration') {
      if (this.validateRegistrationForm()) {
        this.sendOtpToUser();
      }
      return;
    } else if (this.step === 'otpVerification') {
      this.verifyOtp();
    } else {
      // Store form data if valid
      if (this.form.valid) {
        this.storeData(this.form.value);
      }

      // Navigate to next step
      console.log(this.step)
      switch (this.step) {

        case 'studyDetails':
          this.step = this.finalPayload.targetDegree === 'Postgraduate' ?
            'undergraduateDetails' : 'highSchoolDetails';
          break;
        case 'undergraduateDetails':
        case 'highSchoolDetails':
          this.step = 'testScores';
          break;
        case 'testScores':
          this.step = 'professionDetails'; // Removed conditional, always go to professionDetails
          break;

        case 'professionDetails':
          this.step = 'registration';
          break;
      }

      this.setUserDetailsLayout();
      this.updateProgressBar();
    }
  }

  //Navigate Backwards
  navigateBack() {
    switch (this.step) {
      case 'otpVerification':
        this.step = 'registration';
        break;
      case 'registration':
        this.step = 'professionDetails';
        break;
      case 'professionDetails':
        this.step = 'testScores';
        break;
      case 'testScores':
        this.step = this.finalPayload.targetDegree === 'Postgraduate' ?
          'undergraduateDetails' : 'highSchoolDetails';
        break;
      case 'undergraduateDetails':
      case 'highSchoolDetails':
        this.step = 'studyDetails';
        break;
      case 'studyDetails':
        const puid = this.activatedRoute.snapshot.paramMap.get('puid');
        if (puid) {
          this.router.navigate(['/', puid]);
        } else {
          this.router.navigate(['/']);
        }
        return;
    }

    this.showError = false;
    this.setUserDetailsLayout();
    this.updateProgressBar();
  }


  validateRegistrationForm(): boolean {
    if (!this.form.valid) {
      this.showError = true;
      return false;
    }

    const phoneControl = this.form.get('phoneNumber');
    if (!phoneControl?.value) {
      this.showError = true;
      return false;
    }

    return true;
  }


  private getBudgetFieldGroup(targetCountry: string): FormlyFieldConfig[] {
    console.log('Target country in budget group:', targetCountry);
    const currencyOptions = getCurrencyOptions(targetCountry);
    console.log('Available currency options:', currencyOptions);

    return [
      {
        key: 'currency',
        type: 'select',
        className: 'w-1/6 currency-type',
        defaultValue: '',
        templateOptions: {
          placeholder: '',
          required: true,
          options: getCurrencyOptions(targetCountry),
        },
        hooks: {
          onInit: (field: FormlyFieldConfig) => {
            // Set initial currency options
            if (field.templateOptions) {
              field.templateOptions.options = getCurrencyOptions(targetCountry);
            }

            if (this.finalPayload.currency) {
              const budgetField = field.parent?.fieldGroup?.find(f => f.key === 'budgetRange');
              if (budgetField && budgetField.templateOptions) {
                budgetField.templateOptions.options = getBudgetRangeOptions(this.finalPayload.currency, targetCountry);
              }
            }

            // Subscribe to currency changes
            field.form?.get('currency')?.valueChanges.subscribe((currency: string) => {
              const budgetField = field.parent?.fieldGroup?.find(f => f.key === 'budgetRange');
              if (budgetField && budgetField.templateOptions) {
                budgetField.templateOptions.options = getBudgetRangeOptions(currency, targetCountry);
                if (currency !== this.finalPayload.currency) {
                  field.form?.get('budgetRange')?.patchValue('');
                }
              }
            });
          }
        },
        validation: {
          messages: {
            required: 'Please select currency.',
          },
        },
      },
      {
        key: 'budgetRange',
        type: 'select',
        className: 'w-2/6 budget-limit',
        defaultValue: '',
        templateOptions: {
          placeholder: '',
          required: true,
          options: getBudgetRangeOptions('', targetCountry),
        },
        validation: {
          messages: {
            required: 'Please select budget range.',
          },
        },
      }
    ];
  }


  // Submit form logic for each step
  handleSteps() {
    this.storeData(this.form.value);

    // Revalidate the form after setting up the layout
    this.form.markAsUntouched();
    this.form.markAsPristine();
    this.form.updateValueAndValidity();

    console.log('Form Validity after navigation:', this.form.valid);
    console.log('Current Step:', this.step);
    console.log('Final Payload:', this.finalPayload);

    if (!this.finalPayload.targetCountry || !this.finalPayload.targetDegree || !this.finalPayload.targetCourse) {
      console.error("Please make sure all required fields are filled.");
      return;
    }
    if (this.finalPayload.aptitudeTest) {
      this.updateTestScore(this.finalPayload.aptitudeTest, this.finalPayload.aptitudeTestScore);
    }
    if (this.finalPayload.languageTest) {
      this.updateTestScore(this.finalPayload.languageTest, this.finalPayload.languageTestScore);
    }

    // Step 1: Study Plans
    if (this.step === 'studyDetails') {
      if (this.finalPayload.targetDegree === 'Postgraduate') {
        this.step = 'undergraduateDetails';
      } else if (this.finalPayload.targetDegree === 'Undergraduate') {
        this.step = 'highSchoolDetails';
      } else {
        this.step = 'testScores';
      }
    }

    // Step 2: Undergraduate/High School Details
    else if (this.step === 'undergraduateDetails' || this.step === 'highSchoolDetails') {
      this.step = 'testScores';
    }

    // Step 3: Test Scores
    else if (this.step === 'testScores') {
      this.step = 'professionDetails';
    }

    // Step 4: Profession
    else if (this.step === 'professionDetails') {
      this.step = 'registration';
    }
    else if (this.step === 'registration') {
      this.step = 'otpVerification';
    }
    // Update the form layout and progress bar for the new step
    this.setUserDetailsLayout();
    this.updateProgressBar();
  }

  // Store data at each step
  storeData(result: any) {
    this.finalPayload = this.finalPayload || {};

    if (this.step === 'studyDetails') {
      // Make sure button-based selections are stored in finalPayload
      if (!this.finalPayload.targetCountry || !this.finalPayload.targetDegree) {
        console.error('Missing country or degree selection');
        return;
      }
      // Store form-based inputs (like targetCourse)
      // this.finalPayload.targetCourse = result.targetCourse;
    } else if (this.step === 'undergraduateDetails') {
      this.finalPayload.undergraduateCollegeName = result.undergraduateCollegeName;
      this.finalPayload.courseTaken = result.courseTaken;
      this.finalPayload.undergraduateScore = result.undergraduateScore;
      this.finalPayload.backlogs = result.backlogs;
      // if (result.undergraduateScore) {
      //   localStorage.setItem('student_percentage', result.undergraduateScore);
      // }
      // if (result.backlogs) {
      //   localStorage.setItem('student_backlogs', result.backlogs);
      // }

    } else if (this.step === 'highSchoolDetails') {
      this.finalPayload.highSchoolName = result.highSchoolName;
      this.finalPayload.highSchoolScore = result.highSchoolScore;
      // if (result.highSchoolScore) {
      //   localStorage.setItem('student_percentage', result.highSchoolScore);
      // }
      // if (result.highSchoolScore) {
      //   localStorage.setItem('student_backlogs', '0' );
      // }

    } else if (this.step === 'testScores') {
      // this.addEntryTest(this.finalPayload.aptitudeTest, result.aptitudeTestScore);
      // this.addEntryTest(this.finalPayload.languageTest, result.languageTestScore);
    } else if (this.step === 'professionDetails') {
      this.finalPayload.profession = result.profession;
      this.finalPayload.months_of_experience = result.profession === 'Working' ? result.months_of_experience : null;
      this.finalPayload.currency = result.currency;
      this.finalPayload.budgetRange = result.budgetRange;
    }
    console.log('Updated finalPayload:', this.finalPayload);
  }


  // Submit the final payload
  submitFinalPayload() {
    if (!this.form.valid) {
      console.error('Form is not valid');
      return;
    }

    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    if (puid) {
      this.finalPayload['puid'] = puid;
    }

    const uid = localStorage.getItem('uid');
    if (uid) {
      this.finalPayload['uid'] = uid;
    }

    const created = localStorage.getItem('created');
    if (created) {
      this.finalPayload['created'] = created;
    }

    const parentId = localStorage.getItem('parentId');
    if (parentId) {
      this.finalPayload['parentId'] = parentId;
    }

    const token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });


    console.log(this.finalPayload, 186)
    this.http.post(`${this.baseUrl}/student-choices/save-student-choices`, this.finalPayload, { headers }).subscribe(
      (response: any) => {
        console.log('Data saved successfully', response);
        // if (response && response.success && response.userDetails && response.userDetails.uid) {
        //   this.sharedService.setUid(response.userDetails.uid);

        // if (puid) {
        //   this.router.navigate(['/results/' + puid], { state: { data: response.data } });
        // } else {
        //   this.router.navigate(['/results'], { state: { data: response.data } });
        // }
        // }
      },
      (error: any) => {
        console.error('Error saving data', error);
      }
    );

  }

  // Set the form fields using Formly for dropdown-based inputs
  setUserDetailsLayout() {
    this.form = new FormGroup({});
    if (this.step === 'undergraduateDetails') {
      this.userDetailsField = [
        {
          key: 'undergraduateCollegeName',
          type: 'input',
          className: 'form-undergraduate-college text-left sm:text-left',
          templateOptions: {
            label: 'Undergraduate college name',
            placeholder: 'Enter College Name',
            required: true
          },
          validation: {
            messages: {
              required: 'Please enter your undergraduate college name.',
            },
          },
        },
        {
          fieldGroupClassName: 'flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4',
          fieldGroup: [
            {
              key: 'graduationStatus',
              type: 'select',
              className: 'form-graduation-status',
              templateOptions: {
                label: 'Are you graduated or still studying?',
                required: true,
                options: [
                  { label: 'Select an option', value: '', disabled: true },
                  { label: 'Graduated', value: 'graduated' },
                  { label: 'Still in College', value: 'studying' }
                ]
              },
              validation: {
                messages: {
                  required: 'Please select your graduation status.',
                },
              },
            },
            {
              hideExpression: '!model.graduationStatus',
              key: 'yearInfo',
              type: 'select',
              className: 'form-year-info',
              templateOptions: {
                label: 'What year are you in?',
                required: true,
                options: []
              },
              expressionProperties: {
                'templateOptions.label': (model: any) =>
                  model.graduationStatus === 'graduated' ?
                    'What year did you graduate?' : 'What year are you in?',
                'templateOptions.options': (model: any) => {
                  if (model.graduationStatus === 'graduated') {
                    return Array.from({ length: 30 }, (_, i) => ({
                      label: `${new Date().getFullYear() - i}`,
                      value: `${new Date().getFullYear() - i}`
                    }));
                  } else {
                    return [
                      { label: '1st Year', value: '1' },
                      { label: '2nd Year', value: '2' },
                      { label: '3rd Year', value: '3' },
                      { label: '4th Year', value: '4' },
                      { label: '5th Year', value: '5' }
                    ];
                  }
                }
              },
              hooks: {
                onInit: (field: any) => {
                  field.form.valueChanges.subscribe((values: any) => {
                    const currentYear: number = new Date().getFullYear();

                    if (values.graduationStatus === 'studying' && values.yearInfo) {
                      if (values.yearInfo === '5') {
                        this.expectedGraduationYear = currentYear;
                      } else {
                        const yearsLeft = 4 - parseInt(values.yearInfo);
                        this.expectedGraduationYear = currentYear + yearsLeft;
                      }
                      console.log('Expected Graduation Year:', this.expectedGraduationYear);
                    } else if (values.graduationStatus === 'graduated' && values.yearInfo) {
                      this.expectedGraduationYear = parseInt(values.yearInfo);
                    }
                  });
                }
              }
            },
          ]
        },
        {
          fieldGroupClassName: 'flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4',
          fieldGroup: [
            {
              hideExpression: '!model.graduationStatus',
              key: 'degreeType',
              type: 'select',
              defaultValue: '',
              className: 'form-score-system',
              templateOptions: {
                label: 'Which Degree did you pursue?',
                required: true,
                options: [
                  { label: 'Select Degree', value: '', disabled: true },
                  { label: 'B.E./ B.Tech', value: 'B.E./ B.Tech' },
                  { label: 'B.Sc', value: 'B.Sc' },
                  { label: 'B.Arch', value: 'B.Arch' },
                  { label: 'BCA', value: 'BCA' },
                  { label: 'B.Com', value: 'B.Com' },
                  { label: 'BBA', value: 'BBA' },
                  { label: 'BA', value: 'BA' },
                ],
              },
              validation: {
                messages: {
                  required: 'Please select your score system.',
                },
              },
            },
            {
              hideExpression: '!model.graduationStatus',
              key: 'degreeName',
              type: 'input',
              className: 'form-course-taken',
              templateOptions: {
                label: 'What course did you take?',
                placeholder: 'e.g: Electronics & Communication',
                required: true
              },
              validation: {
                messages: {
                  required: 'Please enter your course.',
                },
              },
            },
          ]
        },
        {
          hideExpression: '!model.graduationStatus',
          fieldGroupClassName: 'flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-0 custom-input-marks gap-0-important',
          fieldGroup: [
            {
              key: 'scoreSystem',
              type: 'select',
              defaultValue: '',
              className: 'form-score-system',
              templateOptions: {
                label: 'Grading System',
                required: true,
                options: [
                  { label: 'Select Grading System', value: '', disabled: true },
                  { label: 'Out of 4 CGPA', value: 4 },
                  { label: 'Out of 5 CGPA', value: 5 },
                  { label: 'Out of 7 CGPA', value: 7 },
                  { label: 'Out of 10 CGPA', value: 10 },
                  { label: 'Out of 100 Percentage', value: 100 }
                ],
              },
              validation: {
                messages: {
                  required: 'Please select your score system.',
                },
              },
            },

            {
              fieldGroupClassName: 'block gap-0-important',
              fieldGroup: [
                {
                  key: 'undergraduateScore',
                  type: 'input',
                  className: 'form-score-value',
                  templateOptions: {
                    label: 'Your marks',
                    required: true,
                    placeholder: 'Enter your score',
                    type: 'number',
                    min: 0,
                  },
                  expressionProperties: {
                    'templateOptions.label': (model: any) =>
                      model.graduationStatus === 'graduated'
                        ? 'Aggregate marks from all semester ?'
                        : 'Marks from the latest semester ?',
                    'templateOptions.max': (model: any) =>
                      model.scoreSystem ? model.scoreSystem : 100,
                    'templateOptions.placeholder': (model: any) =>
                      model.scoreSystem ? `Enter score (0-${model.scoreSystem})` : 'Enter score'
                  },
                  validators: {
                    scoreRange: {
                      expression: (c: AbstractControl) => {
                        const score = Number(c.value);
                        const scoreSystem = Number(c.parent?.get('scoreSystem')?.value);

                        if (isNaN(score) || isNaN(scoreSystem)) {
                          return true;
                        }
                        return !(score < 0 || score > scoreSystem);
                      },
                      message: (error: any, field: FormlyFieldConfig) =>
                        `Score must be between 0 and ${field.model.scoreSystem}`
                    }
                  },
                  validation: {
                    messages: {
                      required: 'Please enter your score.',
                    },
                  }
                },

                // === DISPLAY A MESSAGE & CURRENT VALUES IF OUT OF RANGE ===
                {
                  template: `
                <p class="text-red-500 text-sm text-left font-poppins" style="display: block;">
                  Score must be less than the Grading System! 

                </p>
              `,
                  hideExpression: (model: any) => {
                    const score = Number(model?.undergraduateScore);
                    const max = Number(model?.scoreSystem);
                    // Hide if no score system or if valid
                    if (!max || isNaN(score) || isNaN(max)) return true;
                    // Return false to show the message only if out-of-range
                    return !(score < 0 || score > max);
                  }
                }
              ]
            }
          ]
        },

        {
          hideExpression: '!model.graduationStatus',
          fieldGroupClassName: 'flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4',
          fieldGroup: [
            {
              key: 'backlogs',
              type: 'input',
              className: 'form-backlog-count',
              templateOptions: {
                label: 'Do you have any backlogs?',
                placeholder: 'e.g: 0',
                type: 'number',
                min: 0,
                required: true
              },
              validation: {
                messages: {
                  required: 'Please enter the number of backlogs.',
                },
              },
            },
            {
              key: 'months_of_experience',
              type: 'input',
              className: 'month-of-exp',
              hideExpression: 'model.graduationStatus !== "graduated"',
              templateOptions: {
                label: 'Relevant work experience in months',
                placeholder: 'e.g: 24',
                type: 'number',
                min: 0,
                required: true
              },
              validation: {
                messages: {
                  required: 'Please enter your work experience.',
                },
              },
            }
          ]
        }

      ];
    }
    //undo till here
    else if (this.step === 'highSchoolDetails') {
      this.userDetailsField = [
        {
          key: 'highSchoolName',
          type: 'select',
          className: 'high-school-page text-left sm:text-left select-red-placeholder',
          defaultValue: '',
          templateOptions: {
            label: 'High School Board',
            required: true,
            options: [
              { label: 'Enter your high school board', value: '', disabled: true },
              ...this.highSchoolBoards.map(board => ({ label: board, value: board }))
            ]
          },
          validation: {
            messages: {
              required: 'Please enter your high school name.',
            },
          },
        },
        {
          key: 'highSchoolCurrentStatus',
          type: 'select',
          defaultValue: '',
          className: 'form-score-system w-1/2',
          templateOptions: {
            label: 'Which standard are you studying ?',
            required: true,
            options: [
              { label: 'Select Class', value: '', disabled: true },
              { label: '8th', value: 8 },
              { label: '9th', value: 9 },
              { label: '10th', value: 10 },
              { label: '11th', value: 11 },
              { label: '12th', value: 12 }
            ],
          },
          hooks: {
            onInit: (field: any) => {
              field.form.get('highSchoolCurrentStatus').valueChanges.subscribe((currentStandard: number) => {
                if (currentStandard) {
                  const yearsToGraduation = 12 - currentStandard;
                  const currentYear = new Date().getFullYear();
                  this.expectedGraduationYear = currentYear + yearsToGraduation;
                  console.log('High School Expected Graduation Year:', this.expectedGraduationYear);
                }
              });
            }
          },
          validation: {
            messages: {
              required: 'Please select your score system.',
            },
          },
        },
        {
          fieldGroupClassName: 'flex flex-row space-x-0 custom-input-marks',
          fieldGroup: [
            {
              key: 'highSchoolScoreSystem',
              type: 'select',
              defaultValue: '',
              className: 'form-score-system w-1/2',
              templateOptions: {
                label: 'Grading System',
                required: true,
                options: [
                  { label: 'Select Grading System', value: '', disabled: true },
                  { label: 'Out of 4 CGPA', value: 4 },
                  { label: 'Out of 5 CGPA', value: 5 },
                  { label: 'Out of 7 CGPA', value: 7 },
                  { label: 'Out of 10 CGPA', value: 10 },
                  { label: 'Out of 100 Percentage', value: 100 }
                ],
              },
              validation: {
                messages: {
                  required: 'Please select your score system.',
                },
              },
            },
            {
              fieldGroupClassName: 'block gap-0-important',
              fieldGroup: [
                {
                  key: 'highSchoolScore',
                  type: 'input',
                  className: 'form-score-value w-1/2',
                  templateOptions: {
                    label: 'Marks from your current standard ?',
                    placeholder: 'Enter your score',
                    required: true,
                    type: 'number',
                    min: 0
                  },
                  expressionProperties: {
                    'templateOptions.max': (model: any) =>
                      model.highSchoolScoreSystem ? model.highSchoolScoreSystem : 100,
                    'templateOptions.placeholder': (model: any) =>
                      model.highSchoolScoreSystem ? `Enter score (0-${model.highSchoolScoreSystem})` : 'Enter score'
                  },
                  validators: {
                    scoreRange: {
                      expression: (c: AbstractControl) => {
                        const score = Number(c.value);
                        const scoreSystem = Number(c.parent?.get('highSchoolScoreSystem')?.value);

                        if (isNaN(score) || isNaN(scoreSystem)) {
                          return true;
                        }
                        return !(score < 0 || score > scoreSystem);
                      },
                      message: (error: any, field: FormlyFieldConfig) =>
                        `Score must be between 0 and ${field.model.highSchoolScoreSystem}`
                    }
                  },
                  validation: {
                    messages: {
                      required: 'Please enter your score.',
                    },
                  }
                },
                {
                  template: `
                    <p class="text-red-500 text-sm text-left font-poppins" style="display: block;">
                      Score must be less than the Grading System!
                    </p>
                  `,
                  hideExpression: (model: any) => {
                    const score = Number(model?.highSchoolScore);
                    const max = Number(model?.highSchoolScoreSystem);
                    // Hide if no score system or if valid
                    if (!max || isNaN(score) || isNaN(max)) return true;
                    // Return false to show the message only if out-of-range
                    return !(score < 0 || score > max);
                  }
                }
              ]
            }
          ]
        },
      ];
    }
    else if (this.step === 'professionDetails') {
      this.userDetailsField = [
        {
          fieldGroupClassName: 'flex flex-row space-x-4 custom-input-marks',
          fieldGroup: [
            {
              key: 'targetIntakeMonth',
              type: 'select',
              className: 'custom-placeholder-profession',
              templateOptions: {
                label: 'What Intake do you plan to start with?',
                placeholder: 'Select intake month',
                options: [
                  { label: 'January', value: 'January' },
                  { label: 'February', value: 'February' },
                  { label: 'March', value: 'March' },
                  { label: 'April', value: 'April' },
                  { label: 'May', value: 'May' },
                  { label: 'June', value: 'June' },
                  { label: 'July', value: 'July' },
                  { label: 'August', value: 'August' },
                  { label: 'September', value: 'September' },
                  { label: 'October', value: 'October' },
                  { label: 'November', value: 'November' },
                  { label: 'December', value: 'December' }
                ],
                required: true
              },
              validation: {
                messages: {
                  required: 'Please select Intake Month.',
                },
              },
            },
            {
              key: 'targetIntakeYear',
              type: 'select',
              className: 'custom-placeholder-profession',
              templateOptions: {
                label: 'What year you plan to start studying?',
                placeholder: 'Select a year',
                options: [],
                required: true
              },
              expressionProperties: {
                'templateOptions.options': () => {
                  console.log(this.expectedGraduationYear, 1078)
                  const currentYear: number = new Date().getFullYear();
                  let startYear: number = currentYear;  // Default to current year

                  if (this.finalPayload.targetDegree === 'Undergraduate') {
                    // For high school students, use their expected graduation year
                    startYear = this.expectedGraduationYear || currentYear;
                  } else {
                    // For undergraduate students
                    startYear = (this.finalPayload.graduationStatus === 'studying' && this.expectedGraduationYear)
                      ? this.expectedGraduationYear
                      : currentYear;
                  }
                  return [
                    ...Array.from({ length: 5 }, (_, i) => ({
                      label: (startYear + i).toString(),
                      value: (startYear + i).toString()
                    }))
                  ];
                }
              },
              validation: {
                messages: {
                  required: 'Please select intake year.',
                },
              },
            },
          ]
        },

        {
          template: '<label class="block font-medium mb-2 text-left">What is your budget per year</label>',
        },
        {
          fieldGroupClassName: 'flex flex-row space-x-4 budgeting',
          fieldGroup: this.getBudgetFieldGroup(this.finalPayload.targetCountry)
        }
      ];
    }
    else if (this.step === 'registration') {
      this.userDetailsField = [
        {
          fieldGroupClassName: 'flex flex-row  md:flex-row flex-col',
          fieldGroup: [
            {
              key: 'firstName',
              type: 'input',
              className: 'w-full md:w-1/2 firstName',
              templateOptions: {
                label: 'First Name',
                placeholder: 'Enter your first name',
                required: true,
              },
              validation: {
                messages: {
                  required: 'First name is required.',
                },
              },
            },
            {
              key: 'lastName',
              type: 'input',
              className: 'w-full md:w-1/2 lastName',
              templateOptions: {
                label: 'Last Name',
                placeholder: 'Enter your last name',
                required: true,
              },
              validation: {
                messages: {
                  required: 'Last name is required.',
                },
              },
            },
          ],
        },
        {
          key: 'email',
          type: 'input',
          className: 'w-full mb-4 email',
          templateOptions: {
            label: 'Email Address',
            placeholder: 'Enter your email',
            type: 'email',
            required: true,
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          },
          validation: {
            messages: {
              required: 'Email address is required.',
              email: 'Please enter a valid email address.',
              pattern: 'Please enter a valid email address format.'
            }
          }
        },
        {
          fieldGroupClassName: 'flex flex-row space-x-4 custom-input-mobile',
          fieldGroup: [
            {
              fieldGroupClassName: 'flex flex-row space-x-4 custom-input-mobile',
              fieldGroup: [
                {
                  key: 'phoneNumber',
                  type: 'intl-tel-input',
                  wrappers: ['form-field'],
                  className: 'w-[574px] mb-4 custom-phone-field',
                  templateOptions: {
                    label: 'Mobile Number',
                    placeholder: 'Enter your phone number',
                    required: true,
                    preferredCountries: [this.countryISO.India, this.countryISO.UnitedStates],
                    enableAutoCountrySelect: true,
                    enablePlaceholder: true,
                    searchCountryFlag: true,
                    searchCountryField: [this.searchCountryField.Iso2, this.searchCountryField.Name],
                    selectFirstCountry: true,
                    phoneValidation: true,
                    separateDialCode: true,
                    numberFormat: this.phoneNumberFormat.International,
                    showExclamation: true, // Add this to enable exclamation mark
                    customValidation: {
                      lengthValidation: true // Enable length validation
                    }
                  },
                  validation: {
                    messages: {
                      required: 'Please enter a valid phone number.',
                      phoneNumberLength: (error: any, field: any) => {
                        const countryCode = field.formControl?.value?.countryCode;
                        switch (countryCode) {
                          case 'IN':
                            return 'Indian phone numbers must be 10 digits';
                          case 'US':
                            return 'US phone numbers must be 10 digits';
                          default:
                            return 'Invalid phone number length for selected country';
                        }
                      }
                    },
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field.formControl?.valueChanges.subscribe((value: any) => {
                        if (value) {
                          const phoneNumber = value.number?.replace(/\D/g, '');
                          const countryCode = value.countryCode;
                          let isValid = true;

                          // Check length based on country
                          if (countryCode === 'IN') {
                            isValid = phoneNumber?.length === 10;
                          }

                          const inputElement = document.querySelector('.custom-phone-field input');
                          if (inputElement) {
                            if (!isValid && phoneNumber?.length > 0) {
                              inputElement.classList.add('show-exclamation');
                              // Add exclamation mark element if not exists
                              if (!document.querySelector('.exclamation-mark')) {
                                const exclamation = document.createElement('span');
                                exclamation.className = 'exclamation-mark';
                                exclamation.innerHTML = `
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#dc3545" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                              </svg>`;
                                exclamation.style.color = 'red';
                                exclamation.style.position = 'absolute';
                                exclamation.style.right = '10px';
                                exclamation.style.top = '50%';
                                exclamation.style.transform = 'translateY(-50%)';
                                inputElement.parentElement?.appendChild(exclamation);
                              }
                            } else {
                              inputElement.classList.remove('show-exclamation');
                              // Remove exclamation mark if exists
                              document.querySelector('.exclamation-mark')?.remove();
                            }
                          }
                        }
                      });
                    }
                  }
                }
              ]
            },
          ]
        },
        {
          // Adding the static HTML content below the phone number field
          type: 'html',
          className: 'info-text-below-phone-number text-left mt-2',
          template: `
            <p>${this.partnerConsent}</p>
            <p class="mt-0">By continuing you agree to our <span class="text-blue-500">Terms Conditions & Privacy Policy</span></p>
          `
        },
      ];
    }
    else if (this.step === 'otpVerification') {
      this.userDetailsField = [
        {
          key: 'otp',
          type: 'input',
          className: 'otp',
          templateOptions: {
            label: 'Enter the 6-digit OTP sent to your phone',
            placeholder: 'Enter OTP',
            maxLength: 6,
            required: true,
          },
        },
      ];
    }
    console.log(this.finalPayload, 870)
    if (this.finalPayload) {
      setTimeout(() => {
        this.form.patchValue(this.finalPayload);
      });
    }
    this.form.updateValueAndValidity();
  }


  isStepValid(): boolean {
    if (!this.form.valid) return false;

    if (this.step === 'undergraduateDetails' || this.step === 'highSchoolDetails') {
      const score = this.form.get('undergraduateScore')?.value;
      const scoreSystem = this.form.get('scoreSystem')?.value;

      // Add null/undefined check
      if (score !== undefined && score !== null && scoreSystem !== undefined && scoreSystem !== null) {
        const numScore = Number(score);
        const numSystem = Number(scoreSystem);
        if (numScore < 0 || numScore > numSystem) {
          return false;
        }
      }
    }

    return true;
  }


  // Update progress bar width based on the step
  updateProgressBar() {
    switch (this.step) {
      case 'studyDetails':
        this.progressBarWidth = '20%';
        break;
      case 'undergraduateDetails':
      case 'highSchoolDetails':
        this.progressBarWidth = '40%';
        break;
      case 'testScores':
        this.progressBarWidth = '60%';
        break;
      case 'professionDetails':
        this.progressBarWidth = '80%';
        break;
      case 'registration':
        this.progressBarWidth = '100%';
        break;
      default:
        this.progressBarWidth = '0%';
    }
  }

  //All functions related to login and partner data
  // Function to send OTP to the user's mobile number
  // sendOtpToUser() {
  //   const userType = 'student';
  //   const puid = this.activatedRoute.snapshot.paramMap.get('puid');
  //   const currentPuid = puid;

  //   const phoneControl = this.form.get('phoneNumber');
  //   const emailControl = this.form.get('email');

  //   if (!phoneControl?.value || !emailControl?.value) {
  //     console.error('Invalid phone number or email');
  //     return;
  //   }

  //   // Store the complete phone number object in finalPayload
  //   this.finalPayload.phoneNumber = phoneControl.value;
  //   const mobileNumber = this.finalPayload.phoneNumber?.e164Number;
  //   const email = emailControl.value;

  //   const checkPhonePayload = {
  //     phoneNumber: mobileNumber,
  //   };

  //   // Check phone first, then email if phone check passes
  // this.http.post(`${this.baseUrl}/student-choices/check-phone-puid`, checkPhonePayload)
  //   .pipe(
  //     switchMap((phoneResponse: any) => {
  //       // Phone exists in database
  //       if (phoneResponse.data.exists) {
  //         if (phoneResponse.data.hasPuid) {
  //           // Scenario 1: Phone exists with a PUID
  //           const dbPuid = phoneResponse.data.puid;

  //           if (dbPuid === currentPuid) {
  //             // Phone's PUID matches current PUID
  //             this.snackbarService.show('You are already registered. Please login.', 'warning', 3000);
  //             // this.router.navigate(['/student-login', currentPuid]);
  //             return of(null);
  //           } else {
  //             // Phone's PUID is different from current PUID
  //             this.snackbarService.show('This phone number is already registered with a different partner. Please use a different number.', 'warning', 3000);
  //             return of(null);
  //           }
  //         } else {
  //           // Phone exists but no PUID associated
  //           if (!currentPuid) {
  //             // Scenario 3a: No current PUID, redirect to login
  //             this.snackbarService.show('You are already registered. Please login with your existing credentials.', 'warning', 3000);
  //             // this.router.navigate(['/student-login']);
  //             return of(null);
  //           } else {
  //             // Scenario 3b: Current PUID exists
  //             this.snackbarService.show('This phone number is already registered with us from a different partner. Please use a different number.', 'warning', 3000);
  //             return of(null);
  //           }
  //         }
  //       }

  //       // Phone is new, proceed to check email
  //       return this.http.post(`${this.baseUrl}/student-choices/check-email`, { email });
  //     }),
  //     catchError(error => {
  //       console.error('Error checking phone number:', error);
  //       this.snackbarService.show('Failed to validate phone number. Please try again.', 'error');
  //       return of(null);
  //     })
  //   )
  //   .subscribe(
  //     (response: any) => {
  //       if (!response) {
  //         return; // Phone check failed or was stopped
  //       }

  //       if (response.data.exists) {
  //         this.snackbarService.show('This email is already registered. Please use a different email.', 'warning', 3000);
  //       } else {
  //         // Both phone and email are new, proceed with OTP
  //         this.proceedWithOtpFlow(mobileNumber, userType, puid);
  //       }
  //     },
  //     (error) => {
  //       console.error('Error checking email:', error);
  //       this.snackbarService.show('Failed to validate email. Please try again.', 'error');
  //     }
  //   );
  // }

  // private proceedWithOtpFlow(mobileNumber: string, userType: string, puid: string | null) {
  //   const requestPayload: any = {
  //     mobile: mobileNumber,
  //     type: userType,
  //     usersAvailOtp: true,
  //     addedBy: "University Shortlisting"
  //   };

  //   // Conditionally add puid if it exists
  //   if (puid) {
  //     requestPayload.puid = puid;
  //   }

  //   this.http.post(`${this.studentRegisterUrl}/users`, requestPayload)
  //     .subscribe(
  //       (response: any) => {
  //         console.log('Mobile number sent successfully for OTP', response);
  //         this.step = 'otpVerification';
  //         this.setUserDetailsLayout();
  //         this.updateProgressBar();
  //         this.snackbarService.show('Mobile number sent successfully!', 'success');
  //         this.startCountdown();
  //       },
  //       (error) => {
  //         console.error('Error sending mobile number', error);
  //         this.snackbarService.show('Failed to send mobile number. Please try again.', 'error');
  //       }
  //     );
  // }

  // Function to verify OTP entered by the user
  // verifyOtp() {

  //   const otp = this.otpValues.join('');

  //   if (!otp) {
  //     console.error('Invalid OTP');
  //     return;
  //   }
  //   const phoneNumber = this.finalPayload.phoneNumber;
  //   if (!phoneNumber?.e164Number) {
  //     console.error('Invalid phone number');
  //     return;
  //   }
  //   const mobileNumber = phoneNumber.e164Number;

  //   const puid = this.activatedRoute.snapshot.paramMap.get('puid');
  //   // this.http.post('http://127.0.0.1:3000/api/users/login-with-mobile-otp', {
  //   this.http.post(`${this.studentRegisterUrl}/users/login-with-mobile-otp`, {
  //     mobile: mobileNumber,
  //     otp: otp,
  //   })
  //   .subscribe(
  //     (response: any) => {
  //       console.log('OTP verified successfully', response);
  //       localStorage.setItem('access_token', response.id);
  //       localStorage.setItem('uid', response.userDetails.uid);
  //       localStorage.setItem('created', response.created);
  //       localStorage.setItem('parentId', response.userDetails.parentId);
  //       this.snackbarService.show('Login successful!', 'success')
  //       // After verification, save the user data
  //       this.submitFinalPayload();
  //       if (puid) {
  //         this.router.navigate(['/results/' + puid]);
  //       } else {
  //         this.router.navigate(['/results']);
  //       }
  //     },
  //     (error) => {
  //       console.error('OTP verification failed', error);
  //       this.snackbarService.show('Login failed. Please check the OTP and try again.', 'error')
  //     }
  //   );
  // }

  //Previous implemntations  
  // async sendOtpToUser() {
  //   const userType = 'student';
  //   const currentPuid = this.activatedRoute.snapshot.paramMap.get('puid');

  //   const phoneControl = this.form.get('phoneNumber');
  //   if (!phoneControl?.value) {
  //     console.error('Invalid phone number');
  //     return;
  //   }

  //   this.finalPayload.phoneNumber = phoneControl.value;
  //   const mobileNumber = this.finalPayload.phoneNumber?.e164Number;

  //   try {
  //     // Check phone number existence
  //     const checkResponse: any = await this.http.post(`${this.baseUrl}/student-choices/check-student-phone`, {
  //       phoneNumber: mobileNumber
  //     }).toPromise();

  //     console.log('Phone check response:', checkResponse);

  //     if (!checkResponse.data.exists) {
  //       // Case 1: Phone number doesn't exist - allow OTP flow
  //       this.proceedWithOtp(mobileNumber, currentPuid);
  //       return;
  //     }

  //     // Check if current PUID exists in the list of PUIDs
  //     const existingPuids = checkResponse.data.puids || [];

  //     if (currentPuid && existingPuids.includes(currentPuid)) {
  //       // Current PUID exists in the list - block registration
  //       this.snackbarService.show('This phone number is already registered with us. Please login.', 'warning');
  //       return;
  //     }

  //     if (!currentPuid && existingPuids.includes('FASTUDENT')) {
  //       this.snackbarService.show('This phone number is already registered with us. Please login.', 'warning');
  //       return;
  //     }

  //     this.proceedWithOtp(mobileNumber, currentPuid);

  //   } catch (error) {
  //     console.error('Error checking phone number:', error);
  //     this.snackbarService.show('Error checking phone number. Please try again.', 'error');
  //   }
  // }
  // private proceedWithOtp(mobileNumber: string, puid: string | null) {
  //   const requestPayload: any = {
  //     mobile: mobileNumber,
  //     type: 'student',
  //     usersAvailOtp: true,
  //     addedBy: "University Shortlisting"
  //   };

  //   if (puid) {
  //     requestPayload.puid = puid;
  //   }

  //   this.http.post(`${this.studentRegisterUrl}/users`, requestPayload)
  //     .subscribe(
  //       (response: any) => {
  //         console.log('Mobile number sent successfully for OTP', response);
  //         this.step = 'otpVerification';
  //         this.setUserDetailsLayout();
  //         this.updateProgressBar();
  //         this.snackbarService.show('Mobile number sent successfully!', 'success');
  //       },
  //       (error) => {
  //         console.error('Error sending mobile number', error);
  //         this.snackbarService.show('Failed to send mobile number. Please try again.', 'error');
  //       }
  //     );
  //   this.startCountdown();
  // }
  // verifyOtp() {

  //     const otp = this.otpValues.join('');

  //     if (!otp) {
  //       console.error('Invalid OTP');
  //       return;
  //     }
  //     const phoneNumber = this.finalPayload.phoneNumber;
  //     if (!phoneNumber?.e164Number) {
  //       console.error('Invalid phone number');
  //       return;
  //     }
  //     const mobileNumber = phoneNumber.e164Number;

  //     const puid = this.activatedRoute.snapshot.paramMap.get('puid');
  //     // this.http.post('http://127.0.0.1:3000/api/users/login-with-mobile-otp', {
  //     this.http.post(`${this.studentRegisterUrl}/users/login-with-mobile-otp`, {
  //       mobile: mobileNumber,
  //       otp: otp,
  //     })
  //     .subscribe(
  //       (response: any) => {
  //         console.log('OTP verified successfully', response);
  //         localStorage.setItem('access_token', response.id);
  //         localStorage.setItem('uid', response.userDetails.uid);
  //         localStorage.setItem('created', response.created);
  //         this.snackbarService.show('Login successful!', 'success')
  //         // After verification, save the user data
  //         this.submitFinalPayload();
  //         if (puid) {
  //           this.router.navigate(['/results/' + puid]);
  //         } else {
  //           this.router.navigate(['/results']);
  //         }
  //       },
  //       (error) => {
  //         console.error('OTP verification failed', error);
  //         this.snackbarService.show('Login failed. Please check the OTP and try again.', 'error')
  //       }
  //     );
  // }

  // In your UserInfoPageComponent

  sendOtpToUser() {
    const phoneControl = this.form.get('phoneNumber');
    if (!phoneControl?.value) {
      this.snackbarService.show('Invalid phone number', 'error');
      return;
    }

    this.finalPayload.phoneNumber = phoneControl.value;

    const phoneValue = phoneControl.value as any;
    const currentPuid = this.activatedRoute.snapshot.paramMap.get('puid');

    // First check phone and PUID combination
    this.http.post(`${this.baseUrl}/user/check-phone-puid`, {
      mobile: phoneValue.e164Number,
      currentPuid: currentPuid
    }).subscribe(
      (response: any) => {
        switch (response.status) {
          case 'ALLOW_REGISTER':
            // New phone number, proceed with registration
            this.proceedWithRegistration(phoneValue);
            break;

          case 'ALREADY_REGISTERED':
            // Phone exists with same PUID
            this.snackbarService.show('You are already registered with us. Please login.', 'warning');
            break;

          case 'ALLOW_NEW_PARTNER_REGISTRATION':
            // Phone exists but with different PUID
            this.proceedWithRegistration(phoneValue);
            break;

          default:
            this.snackbarService.show('Something went wrong. Please try again.', 'error');
        }
      },
      (error) => {
        console.error('Error checking phone:', error);
        this.snackbarService.show('Error checking phone number. Please try again.', 'error');
      }
    );
  }

  private proceedWithRegistration(phoneValue: any) {
    const registrationPayload = {
      mobile: phoneValue.e164Number,
      countryCode: phoneValue.dialCode,
      puid: this.activatedRoute.snapshot.paramMap.get('puid'),
      roleName: 'student'
    };

    this.http.post(`${this.baseUrl}/user/register`, registrationPayload)
      .subscribe(
        (response: any) => {
          if (response.message === "OTP sent successfully") {
            this.step = 'otpVerification';
            this.setUserDetailsLayout();
            this.updateProgressBar();
            this.startCountdown();
            this.snackbarService.show('OTP sent successfully!', 'success');
          } else {
            this.snackbarService.show('Registration failed. Please try again.', 'error');
          }
        },
        (error) => {
          console.error('Registration failed:', error);
          this.snackbarService.show(error.error?.message || 'Registration failed. Please try again.', 'error');
        }
      );
  }

  verifyOtp() {
    const otp = this.otpValues.join('');
    if (!otp) {
      this.snackbarService.show('Please enter OTP', 'error');
      return;
    }

    const phoneNumber = this.finalPayload.phoneNumber;
    if (!phoneNumber) {
      this.snackbarService.show('Invalid phone number', 'error');
      return;
    }

    const puid = this.activatedRoute.snapshot.paramMap.get('puid');

    // Prepare complete user details for verification
    const verificationPayload = {
      mobile: phoneNumber.e164Number,
      otp: otp,
      firstName: this.form.get('firstName')?.value,
      lastName: this.form.get('lastName')?.value,
      email: this.form.get('email')?.value,
      targetCountry: this.finalPayload.targetCountry,
      targetDegree: this.finalPayload.targetDegree,
      targetIntake: `${this.finalPayload.targetIntakeMonth} ${this.finalPayload.targetIntakeYear}`,
      puid: puid
    };

    this.http.post(`${this.baseUrl}/user/verify-otp`, verificationPayload)
      .subscribe(
        (response: any) => {
          if (response.user) {
            // Store the JWT token
            // localStorage.setItem('access_token', response.token);

            // Store user data
            localStorage.setItem('uid', response.user.uid);
            localStorage.setItem('created', response.user.created);

            this.snackbarService.show('Registration successful!', 'success');
            this.submitFinalPayload();
            // Navigate based on PUID

            if (puid) {
              this.router.navigate(['/results/' + puid]);
            } else {
              this.router.navigate(['/results']);
            }
          } else {
            this.snackbarService.show('Verification failed. Please try again.', 'error');
          }
        },
        (error) => {
          console.error('OTP verification failed:', error);
          this.snackbarService.show(error.error?.message || 'OTP verification failed. Please try again.', 'error');
        }
      );
  }


  //Function to fetch partner details
  fetchPartnerLogo(puid: string): void {
    this.partnerService.getPartnerLogo(puid).subscribe(
      (response) => {
        this.partnerConsent = response.data.company.consent; // Assuming the backend returns { logo: 'URL' }
      },
      (error) => {
        console.error('Error fetching partner logo:', error);
        this.partnerConsent = "By providing the mobile number, I agree to override the registry on NCPR/NDNC and authorize ForeignAdmits to connect me and send relevant notifications via call, Email, SMS and WhatsApp.";
      }
    );
  }


}
