import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiHelperService } from 'projects/apna-lib/src/lib/services/api-helper.service';
import { GlobalDataTableAppsComponent } from './global-data-table-apps/global-data-table-apps.component';

@Component({
  selector: 'fa-global-data-table',
  templateUrl: './global-data-table.component.html',
  styleUrls: ['./global-data-table.component.scss'],
})
export class GlobalDataTableComponent implements OnInit {
  selection = new SelectionModel<any>(true, []);
  public pageSize = 10;

  public pageSizeOptions: number[] = [10, 25, 50, 100];
  @Input() dataSource: any[] = [];
  @Input() displayedColumns: any = [];
  @Input() tableCss: any;
  @Input() typeOfUpdate: any;
  @Input() showBorders: boolean;
  @Input() columnDefinitions: any = [];
  @Output() actionClick = new EventEmitter<any>();
  @Input() selectColumnName: string;
  @Output() selectionOutput = new EventEmitter();
  @Output() updatedTableData = new EventEmitter<boolean>();

  matDataSource!: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dropdownData: { [key: string]: any[] } = {};

  ngOnInit(): void {
    this.initializeDataSource();
    this.fetchDropdownOptions();
  }

  constructor(
    private apiHelper: ApiHelperService,
    public matDialog: MatDialog
  ) { }

  ngAfterViewInit(): void {
    if (this.matDataSource) {
      this.matDataSource.paginator = this.paginator;
      this.matDataSource.sort = this.sort;
    }
    this.selection.changed.subscribe((data) => {
      this.selectionOutput.emit(data.source.selected);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource'] && changes['dataSource'].currentValue) {
      this.initializeDataSource();
      if (this.paginator) {
        this.selection.clear();
        this.matDataSource.paginator = this.paginator;
      }
    }
  }

  initializeDataSource(): void {
    this.matDataSource = new MatTableDataSource(this.dataSource);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.matDataSource.filter = filterValue.trim().toLowerCase();
  }

  onActionClick(row: any) {
    this.actionClick.emit(row);
  }

  updateApplication(row: any) {
    let matDialogRef = this.matDialog.open(GlobalDataTableAppsComponent, {
      maxWidth: '80vw',
      minWidth: '360px',
      width: '70vw',
      autoFocus: false,
      data: { ...row, typeOfUpdate: this.typeOfUpdate },
    });
    matDialogRef.afterClosed().subscribe((data: any) => {
      this.updatedTableData.emit(true);
    });
  }

  onDropdownChange(event: Event, row: any, column: any) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    const previousValue = row[column.key];
    row[column.key] = selectedValue;
  
    this.actionClick.emit({ 
      type: "assign", 
      data: row, 
      field: column.key,
      value: selectedValue,
      previousValue: previousValue
    });
  }

  fetchDropdownOptions() {
    this.columnDefinitions.forEach((column) => {
      if (column.isDropdown && column.apiConfig) {
        this.apiHelper
          .getData(column.apiConfig.url, column.apiConfig.params)
          .then((response: any) => {
            if (!response || !Array.isArray(response)) {
              this.dropdownData[column.key] = [];
              return;
            }

            this.dropdownData[column.key] = [
              { name: 'Not Assigned', id: '' },
              ...response.map((item: any) => ({
                name: item[column.apiConfig.nameField],
                id: item[column.apiConfig.valueField],
              })),
            ];
          })
          .catch((error) => {
            console.error(
              `Error fetching dropdown data for ${column.key}:`,
              error
            );
            this.dropdownData[column.key] = [];
          });
      }
    });
  }

  customSort(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return;
    }

    const column = this.columnDefinitions.find(
      (col) => col.key === sort.active
    );
    if (!column) return;

    this.matDataSource.data = this.matDataSource.data.sort((a, b) => {
      let valueA = this.getPropertyValue(a, column);
      let valueB = this.getPropertyValue(b, column);

      // Handle date sorting
      if (column.key === 'created') {
        valueA = new Date(a.createdAt).getTime();
        valueB = new Date(b.createdAt).getTime();
      }

      // Compare the values
      const comparison = valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      return sort.direction === 'asc' ? comparison : -comparison;
    });
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.forEach((row, idx) => {
        if (idx <= this.pageSize) {
          if (this.selectColumnName) {
            this.selection.select(row[this.selectColumnName]);
          } else {
            this.selection.select(row?.id);
          }
        }
      });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource ? this.dataSource.length : 0;
    return numSelected === numRows;
  }

  clearSelection(ev: any) {
    this.selection.clear();
  }

  private getPropertyValue(item: any, column: any): any {
    if (column.formatter) {
      return column.formatter(item[column.key], item);
    }

    if (column.key.includes('.')) {
      return column.key
        .split('.')
        .reduce((obj: any, key: string) => obj?.[key], item);
    }

    return item[column.key];
  }
}
