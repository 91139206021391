import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-custom-password',
  templateUrl: './formly-custom-password.component.html',
  styleUrls: ['./formly-custom-password.component.scss']
})
export class FormlyCustomPasswordComponent extends FieldType implements OnInit {

  public showPassword: boolean = false;
  showEyeLogo: boolean
  ngOnInit(): void {
    
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

}
