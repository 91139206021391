import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ChangeDetectorRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { UserInfoPageComponent } from './user-info-page/user-info-page.component'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResultsComponent } from './results/results.component';
import { UniversityDetailComponent } from './university-detail/university-detail.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { WishlistPageComponent } from './wishlist-page/wishlist-page.component';
import { FilterBarComponent } from './filter-bar/filter-bar.component';
import { StudentLoginComponent } from './student-login/student-login.component';

import { RouterModule } from '@angular/router';
import { PartnerService } from './services/partner.service';
import { StudentDetailsComponent } from './student-details/student-details.component';
import { FormlyModule } from '@ngx-formly/core';  
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormlyFieldIntlTelInputComponent } from './formly-field-intl-tel-input/formly-field-intl-tel-input.component';
import { RemoveAffiliationsPipe } from './remove-affiliations.pipe';
import { ConnectCounsellorComponent } from './connect-counsellor/connect-counsellor.component';
import { ProfileHelperComponent } from './components/profile-helper/profile-helper.component';
import { CostOfLivingChartComponent } from './cost-of-living-chart/cost-of-living-chart.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { NavbarComponent } from './components/navbar/navbar.component';
import { StudentProfilePageModule } from './components/student-profile-page/student-profile-page.module';
import { NgbCarouselModule, NgbModalModule, NgbModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ApiHelperService } from 'projects/apna-lib/src/lib/services/api-helper.service';
import { NgChartsModule } from 'ng2-charts';
import { environment } from '../../environments/environment';
import { ComponentsModule } from 'projects/apna-lib/src/lib/components/components.module';
import { AdmissionRoutingModule } from './admission-routing.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ChatAppModule } from './components/chat-app/chat-app.module';






export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    LandingPageComponent,
    UserInfoPageComponent,
    ResultsComponent,
    UniversityDetailComponent,
    WishlistComponent,
    WishlistPageComponent,
    FilterBarComponent,
    StudentLoginComponent,
    StudentDetailsComponent,
    PersonalDetailsComponent,
    FormlyFieldIntlTelInputComponent,
    RemoveAffiliationsPipe,
    ConnectCounsellorComponent,
    ProfileHelperComponent,
    CostOfLivingChartComponent,
    SnackbarComponent,
    TooltipDirective,
    ClickOutsideDirective,
    NavbarComponent,
    // StudentNavHeaderComponent
    // NavbarComponent
  ],
  imports: [
    ComponentsModule,
    BrowserModule,
    NgChartsModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    FormlyModule.forRoot({
      types: [
        { name: 'intl-tel-input', component: FormlyFieldIntlTelInputComponent },
      ],
    }),
    FormlyBootstrapModule,
    NgbModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,  
    AdmissionRoutingModule,
    NgbTooltipModule,
    NgbNavModule,
    NgbModalModule,
    NgbCarouselModule,
    ChatAppModule
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    PartnerService,
    ApiHelperService,
    { provide: 'environment', useValue: environment }  // This was likely missing
  ],
  bootstrap: []
})
export class AdmissionModule { }
