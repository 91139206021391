import { AbstractControl } from '@angular/forms';
import { FormlyFieldButtonComponent } from './formly-field-button/formly-field-button.component';
import { FormlyFieldDateFilterComponent } from './formly-field-date-filter/formly-field-date-filter.component';
import { FormlyFieldFileComponent } from './formly-field-file/formly-field-file.component';
import { FormlyFieldLinkButtonComponent } from './formly-field-link-button/formly-field-link-button.component';
import { FormlyFieldNumberFilterComponent } from './formly-field-number-filter/formly-field-number-filter.component';
import { FormlyFieldRepeatComponent } from './formly-field-repeat/formly-field-repeat.component';
import { FormlyFieldTextComponent } from './formly-field-text/formly-field-text.component';
import { FormlyWrapperPanelComponent } from './formly-wrapper-panel/formly-wrapper-panel.component';
import { FormlyFieldMobileNumberComponent } from './formly-field-mobile-number/formly-field-mobile-number.component';
import { FormyFieldRadioComponent } from './formy-field-radio/formy-field-radio.component';
import { FormlyFieldOtpComponent } from './formly-field-otp/formly-field-otp.component';
import { FormlyWrapperAccordianComponent } from './formly-wrapper-accordian/formly-wrapper-accordian.component';
import { FormlyWrapperAccordianItemComponent } from './formly-wrapper-accordian-item/formly-wrapper-accordian-item.component';
import { FormlyFieldMulticheckboxComponent } from './formly-field-multicheckbox/formly-field-multicheckbox.component';
import { FormlyFieldAutocompleteComponent } from './formly-field-autocomplete/formly-field-autocomplete.component';
import { FormlyFieldRangeSliderComponent } from './formly-field-range-slider/formly-field-range-slider.component';
import { FormlyFieldRadioV2Component } from './formly-field-radio-v2/formly-field-radio-v2.component';
import { FormlyFieldTinyMceComponent } from './formly-field-tiny-mce/formly-field-tiny-mce.component';
import { FormlyFieldInlineRepeatComponent } from './formly-field-inline-repeat/formly-field-inline-repeat.component'
import { FormlyWrapperNavComponent } from './formly-wrapper-nav/formly-wrapper-nav.component';
import { FormlyFieldCodeEditorComponent } from './formly-field-code-editor/formly-field-code-editor.component';
import { FormlyFieldCustomInputComponent } from './formly-field-custom-input/formly-field-custom-input.component';
import { FormlyFieldConfigGeneratorComponent } from './formly-field-config-generator/formly-field-config-generator.component';
import { FormlyFieldMultiSelectComponent } from './formly-field-multi-select/formly-field-multi-select.component';
import { FormlyMultiFieldFileComponent } from './formly-multi-field-file/formly-multi-field-file.component';
import { FormlyCurrencyComponent } from './formly-currency/formly-currency.component';
import { FormlyFieldEmojiComponent } from './formly-field-emoji/formly-field-emoji.component';
import { FormlyCustomPasswordComponent } from './formly-custom-password/formly-custom-password.component';
import { FormlyAdmissionDocumentsFileComponent } from 'projects/foreign-admits/src/app/admission/formly-components/formly-admission-documents-file/formly-admission-documents-file.component';

export const formlyComponents = [
  FormlyFieldButtonComponent,
  FormlyFieldDateFilterComponent,
  FormlyFieldLinkButtonComponent,
  FormlyFieldNumberFilterComponent,
  FormlyFieldTextComponent,
  FormlyFieldFileComponent,
  FormlyWrapperPanelComponent,
  FormlyFieldRepeatComponent,
  FormlyFieldMobileNumberComponent,
  FormyFieldRadioComponent,
  FormlyFieldOtpComponent,
  FormlyWrapperAccordianComponent,
  FormlyWrapperAccordianItemComponent,
  FormlyFieldAutocompleteComponent,
  FormlyFieldMulticheckboxComponent,
  FormlyFieldRangeSliderComponent,
  FormlyFieldRadioV2Component,
  FormlyFieldTinyMceComponent,
  FormlyFieldInlineRepeatComponent,
  FormlyWrapperNavComponent,
  FormlyFieldCodeEditorComponent,
  FormlyFieldCustomInputComponent,
  FormlyFieldConfigGeneratorComponent,
  FormlyFieldMultiSelectComponent,
  FormlyMultiFieldFileComponent,
  FormlyCurrencyComponent,
  FormlyFieldEmojiComponent,
  FormlyCustomPasswordComponent,
  FormlyAdmissionDocumentsFileComponent
];
let bankDetailsByIfsc: string;
export const formlyInputs = [
  {
    name: 'name',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'text',
        label: 'Name',
        placeholder: 'Enter name',
        required: true,
        maxLength: 40,
      },
    },
  },
  {
    name: 'email',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'email',
        label: 'Email',
        placeholder: 'Enter email',
        pattern:
          '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
      },
      validation: {
        messages: {
          pattern: 'Please enter a valid email address',
        },
      },
    },
  },
 
  {
    name: 'emailR',
    extends: 'email',
    defaultOptions: {
      templateOptions: {
        required: true,
        pattern:
          '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
      },
      validation: {
        messages: {
          required: 'Email is required',
          pattern: 'Please enter a valid email address',
        },
      },
    },
  },
  {
    name: 'mobile',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        label: 'Mobile',
        required: true,
        pattern: '^[6789][0-9]{9}$',
        placeholder: 'Enter mobile number',
      },
      validation: {
        messages: {
          pattern: 'Please provide valid Mobile Number!',
          required: 'Mobile is required',
        },
      },
    },
  },
  {
    name: 'emailOrMobile',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        label: 'Email / Mobile',
        required: true,
        pattern:
          '(^[6789][0-9]{9}$)|(^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$)',
      },
      validation: {
        messages: {
          pattern: 'Please provide valid Email or Mobile Number!',
          required: 'Email or mobile is required',
        },
      },
    },
  },

  {
    name: 'phone',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        label: 'Phone',
        required: true,
        pattern: '^(([+][0-9])[0-9]{0,3}-|0)[0-9]{2,4}-[0-9]{6,8}$',
      },
      validation: {
        messages: {
          pattern:
            'Please provide valid Phone Number! e.g (+91-XXXX-XXXXXX OR 0XXXX-XXXXXX)',
          required: 'Phone is required',
        },
      },
    },
  },
  {
    name: 'date',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'date',
        placeholder: "Select Date"
      },
    },
  },
  {
    name: 'dob',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'date',
        label: 'Date',
      },
    },
  },
  {
    name: 'website',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'text',
        label: 'Website',
        pattern:
          '^(http://www.|https://www.|http://|https://|www.)[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
      },
      validation: {
        messages: {
          pattern:
            'Please provide valid Website URL! e.g (http://example.com OR https://www.example.com OR XXX.XXX.XXX)',
        },
      },
    },
  },
  {
    name: 'pan',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'text',
        label: 'Pan No',
        placeholder: 'Enter pan',
        pattern:
          '^[A-Za-z]{3}[CPHFATBLJGcphfatblj]{1}[A-Za-z]{1}[0-9]{4}[A-Za-z]{1}$',
      },
      validation: {
        messages: {
          pattern: 'Please provide valid PAN.',
        },
      },
    },
  },
  {
    name: 'tan',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'text',
        label: 'Tan No',
        placeholder: 'Enter tan',
        pattern: '^[A-Za-z]{3}[A-Za-z]{1}[0-9]{5}[A-Za-z]{1}$',
      },
      validation: {
        messages: {
          pattern: 'Please provide valid TAN.',
        },
      },
    },
  },
  {
    name: 'esic',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'text',
        label: 'ESIC',
        placeholder: 'Enter esic',
        pattern: '^[0-9]{2}-[0-9]{2}-[0-9]{6}-[0-9]{3}-[0-9]{4}$',
      },
      validation: {
        messages: {
          pattern: 'Please provide valid ESIC.',
        },
      },
    },
  },
  {
    name: 'aadhar',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'text',
        label: 'Aadhar',
        placeholder: 'Enter aadhar',
        pattern: '^[0-9]{4}-? ?[0-9]{4}-? ?[0-9]{4}$',
      },
      validation: {
        messages: {
          pattern: 'Please provide valid AADHAR.',
        },
      },
    },
  },
  {
    name: 'serviceTax',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'text',
        label: 'Service tax no',
        placeholder: 'Enter service tax no',
        pattern:
          '^[A-Za-z]{3}[CPHFATBLJGcphfatblj]{1}[A-Za-z]{1}[0-9]{4}[A-Za-z]{1}(XM|xm|ST|st|SD|st)[0-9]{3}$',
      },
      validation: {
        messages: {
          pattern: 'Please provide valid service tax.',
        },
      },
    },
  },
  {
    name: 'gstNumber',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'text',
        label: 'GST',
        placeholder: 'Enter GST no.',
        pattern: '^[0-9]{2}[A-Za-z]{3}[CPHFATBLJGcphfatblj]{1}[A-Za-z]{1}[0-9]{4}[A-Za-z]{1}[0-9A-Za-z]{1}(Z|z)[0-9A-Za-z]{1}$',
      },
      validation: {
        messages: {
          pattern: 'Please provide valid GST number.',
        },
      },
    },
  },
  {
    name: 'postalCode',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'text',
        label: 'Postal Code',
        placeholder: 'Enter Postal Code',
        pattern: '^[0-9]{6}$',
      },
      validation: {
        messages: {
          pattern: 'Please provide valid Postal Code.',
        },
      },
    },
  },
  {
    name: 'datetime',
    extends: 'input',
    defaultOptions: {
      defaultValue: new Date(),
      templateOptions: {
        type: 'datetime-local',
        label: 'Date time',
      },
    },
  },
  {
    name: 'time',
    extends: 'input',
    defaultOptions: {
      // defaultValue: new Date(),
      templateOptions: {
        type: 'time',
        label: 'Time',
      },
    },
  },
  {
    name: 'password',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'password',
        label: 'Password',
        required: true,
        placeholder: 'Enter password',
        pattern:
          '([0-9].{7,})|((?=.*[0-9])(?=.*[a-z])(?=.*[@!~#$%^&*])(?=.*[A-Z]).{8,})',
      },
      validation: {
        messages: {
          pattern:
            'Your password must have 1) one upper case, 2) one lower case, 3) special char [@!~#$%^&*], 4) number, 5) Length at least 8 char!',
          required: 'Password is required',
        },
      },
    },
  },
  {
    name: 'string',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'text',
        label: 'String',
      },
    },
  },
  {
    name: 'number',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'number',
        label: 'Number',
        placeholder: 'Enter a number',
        min: 0,
      },
    },
  },
  {
    name: 'numberNeg',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'number',
        label: 'Number',
        placeholder: 'Enter a number',
      },
    },
  },
  {
    name: 'boolean',
    extends: 'checkbox',
    defaultOptions: {
      templateOptions: {
        type: 'checkbox',
        default: false,
        label: 'Remember Me',
        placeholder: 'Remember Me',
      },
    },
  },
  { name: 'button', component: FormlyFieldButtonComponent },
  { name: 'text', component: FormlyFieldTextComponent },
  { name: 'link', component: FormlyFieldLinkButtonComponent },
  {
    name: 'number-filter',
    component: FormlyFieldNumberFilterComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'date-filter',
    component: FormlyFieldDateFilterComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'file',
    component: FormlyFieldFileComponent,
    wrappers: ['form-field'],
    defaultOptions: {
      templateOptions: {
        label: 'File',
        required: false,
        placeholder: 'Enter mobile number',
        hideLabel: true
      },
      validation: {
        messages: {
          required: 'File is required',
        },
      },
    },
  },
  {
    name: 'files',
    component: FormlyMultiFieldFileComponent,
    wrappers: ['form-field'],
    defaultOptions: {
      templateOptions: {
        label: 'File',
        required: false,
        placeholder: 'Select File to Upload',
        hideLabel: true
      },
      // validation: {
      //   messages: {
      //     required: 'File is required',
      //   },
      // },
    },
  },
  {
    name: 'admission-files',
    component: FormlyAdmissionDocumentsFileComponent,
  },
  { name: 'repeat', component: FormlyFieldRepeatComponent },
  { name: 'inline-repeat', component: FormlyFieldInlineRepeatComponent },
  {
    name: 'mobile-number',
    component: FormlyFieldMobileNumberComponent,
    wrappers: ['form-field'],
    defaultOptions: {
      templateOptions: {
        label: 'Mobile',
        required: true,
        placeholder: 'Enter mobile number',
      },
      asyncValidators: {
        mobile: {
          expression: async (control) => {
            let val = await checkMobileAvailable(control.value);
            if (!val) {
              return false
            } else {
              return true;
            }

          },
          message: () => 'Country code not available.',
        },
      },
      validation: {
        messages: {
          required: 'Mobile is required',
        },
      },
    },
  },
  {
    name: 'fa-radio',
    component: FormyFieldRadioComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'fa-otp',
    component: FormlyFieldOtpComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'fa-multicheckbox',
    component: FormlyFieldMulticheckboxComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'slider',
    component: FormlyFieldRangeSliderComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'radio',
    component: FormlyFieldRadioV2Component,
    wrappers: ['form-field'],
  },
  {
    name: 'image',
    extends: 'file',
    defaultOptions: {
      templateOptions: {
        label: 'Upload Image',
        placeholder: 'Upload image',
        type: 'image',
      },
    },
  },
  {
    name: 'document',
    extends: 'file',
    defaultOptions: {
      templateOptions: {
        label: 'Upload Document',
        placeholder: 'Upload Document',
        type: 'document',
      },
    },
  },
  {
    name: 'autocomplete',
    component: FormlyFieldAutocompleteComponent,
    wrappers: ['form-field'],
    defaultOptions: {
      validation: {
        messages: {
          required: 'Field is required',
        },
      },
    },
  },
  {
    name: 'multi-select',
    component: FormlyFieldMultiSelectComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'tinymce',
    component: FormlyFieldTinyMceComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'code-editor',
    component: FormlyFieldCodeEditorComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'custom-input',
    component: FormlyFieldCustomInputComponent
  },
  {
    name: "config-generator",
    component: FormlyFieldConfigGeneratorComponent
  },
  {
    name: "currency",
    component: FormlyCurrencyComponent
  },
  {
    name: 'custom-password',
    component: FormlyCustomPasswordComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'feedback-emoji',
    component: FormlyFieldEmojiComponent,
    wrappers: ['form-field']
  }
];
export const formlyWrappers = [
  {
    name: 'panel',
    component: FormlyWrapperPanelComponent,
  },
  {
    name: "accordian",
    component: FormlyWrapperAccordianComponent
  },
  {
    name: "accordian-item",
    component: FormlyWrapperAccordianItemComponent
  },
  {
    name: "tabs",
    component: FormlyWrapperNavComponent
  }
];

export function fieldMatchValidator(control: AbstractControl) {
  const { password, confirmPassword } = control.value;

  // avoid displaying the message error when values are empty
  if (!confirmPassword || !password) {
    return null;
  }

  if (confirmPassword === password) {
    return null;
  }

  return { fieldMatch: { message: 'Password Not Matching' } };
}

export function checkEndTimeValidator(control: AbstractControl) {
  const { start, end } = control.value;

  // avoid displaying the message error when values are empty
  if (!end || !start) {

    return null;
  }

  if (end >= start) {

    return null;
  }

  return { checkEndTime: { message: 'End time should be greater than Start time' } };
}

export const FaFormlyConfig = {
  types: formlyInputs,
  wrappers: formlyWrappers,
  validators: [{ name: 'fieldMatch', validation: fieldMatchValidator }, { name: 'checkEndTime', validation: checkEndTimeValidator }],
};

async function checkIfscAvailable(ifsc) {

  const options = {
    method: 'GET',
  };

  if (ifsc) {
    var response = await fetch('https://ifsc.razorpay.com/' + ifsc, options)
    var bankDetails = await response.json()
    return bankDetails;
  }

}
async function checkMobileAvailable(mobileNo) {
  if(mobileNo === undefined){
    return true;
  }
  if (["+84"].includes(mobileNo?.slice(0, 3))) {
    return false;
  }
  return mobileNo
}