import { P } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { ApiHelperService } from 'projects/apna-lib/src/lib/services/api-helper.service';
import { ConfigService } from 'projects/apna-lib/src/lib/services/config.service';
import { RazorpayService } from 'projects/apna-lib/src/lib/services/razorpay.service';


@Component({
  selector: 'fa-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent {
  @ViewChild('paymentHistory') paymentHistory!: ElementRef;
  @Input() applicantData: any;
  @Input() studentId: string;
  @Input() applicantId: string;
  dataColumns: any[] = [];
  dataVerifiedColumns: any[] = [];
  payableAmount: any;
  receivedDataforPayment: any;
  paymentColumnsData: any[] = [];
  currentPaymentData: any;
  applicationFeesLoading = false;
  loading = false;
  paymentTableLoading = false;
  payable = false;
  amountPaid: any;
  public dataModel = 'payment/create-order';
  public verifyModel = 'payment/verify-signature';
  public getPaymentUrl = 'payment/get-payment-details';
  public universityStudentUrl = 'university-students';
  public universityApplicationUrl = 'university-applications';
  public feeWaiverDataModel = 'payment/create-waiver-order';
  currencyExchangeRatesOptions = this.configService.getConfig1(
    'foreignAssist',
    'currencyExchangeRates'
  );
  columnsToDisplay = [
    'select',
    'universityName',
    'courseName',
    'intakeSeason',
    'applicationFees',
    'conversionRate',
  ];
  verifiedColumnsToDisplay = [
    'universityName',
    'courseName',
    'intakeSeason',
    'applicationFees',
    'conversionRate',
  ];
  paymentColumnsToDisplay = [
    'razorpayPaymentId',
    'receipt',
    'universityName',
    'courseName',
    'intakeSeason',
    'applicationFees',
    'conversionRate',
    'amountPaid',
    'paymentStatus',
  ];
  studentTableColumns: any[];
  paymentTableColumns: any[];
  studentTableVerifyColumns: any[];

  constructor(
    private readonly configService: ConfigService,
    private razorPay: RazorpayService,
    private apiHelper: ApiHelperService
  ) {}

  ngOnInit(): void {
    this.setColumns();
    this.setDataColumns();
    this.setVerifiedColumns();
    this.setVerifiedDataCoulmns();
    this.getPayments(this.applicantData?._id);
    console.info(this.applicantData,86);
  }

  setVerifiedDataCoulmns() {
    this.dataVerifiedColumns = this.applicantData?.wishlist
      .filter((item) => item?.isAppFeeCheckedByFA === false) // Include paid items
      .map((item) => ({
        id: item.courseId, // Assigns courseId as unique id
        ...item,
      }));
  }

  scrollToPaymentHistory() {
    if (this.paymentHistory) {
      this.paymentHistory.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  indianCurrencyFormat(amount: string) {
    return Number(amount)
      .toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        currency: 'INR',
      })
      .replace('$', '')
      .split('.')[0]
      .toString();
  }

  setDataColumns() {
    this.dataColumns = this.applicantData?.wishlist
      ?.filter(
        (item) =>
          item?.paymentStatus !== 'Paid' && item.isAppFeeCheckedByFA === true
      )
      .map((item) => ({
        id: item.courseId,
        ...item,
      }));
    this.loading = false;
  }

  setColumns() {
    this.studentTableColumns = [
      {
        key: 'select',
        label: 'Select',
        isSelect: true,
      },
      {
        key: 'universityName',
        label: 'University Name',
        formatter: (_, row) => `${row.universityName}`,
      },

      {
        key: 'courseName',
        label: 'Program Name',
        formatter: (_, row) => row.courseName,
      },

      {
        key: 'intakeSeason',
        label: 'Intake',
        formatter: (_, row) => row.intakeSeason,
      },

      {
        key: 'applicationFees',
        label: 'Application Fees',
        formatter: (_, row) =>
          row.applicationFees === 'Waiver'
            ? 0
            : `${row.currency} ${row.applicationFees}`,
      },

      {
        key: 'conversionRate',
        label: 'Conversion Rate',
        formatter: (_, row) => {
          const exchangeRate =
            this.currencyExchangeRatesOptions?.[row?.currency] ?? 1;
          const applicationFees = Number(row?.applicationFees) || 0;
          const convertedAmount = exchangeRate * applicationFees;

          return `INR ${convertedAmount.toFixed(2)}`;
        },
        subText: (row) =>
          `(1 ${row?.currency} = ${
            this.currencyExchangeRatesOptions?.[row?.currency] ?? 1
          } INR)`,
      },
    ];
  }

  paymentColumns() {
    // Filter to include only items where paymentStatus is 'Paid'
    this.paymentColumnsData = this.paymentColumnsData?.reduce((acc, item) => {
      if (item.courses) {
        const mappedCourses = item.courses.map((course) => ({
          id: course.courseId,
          ...course,
          receipt: item.receipt,
          amountPaid: item.amountPaid,
        }));
        acc.push(...mappedCourses); // Flattening manually
      }
      return acc;
    }, []);

    this.paymentTableLoading = false;

    // Ensure the table updates correctly
    this.setPaymentColumns();
  }

  setPaymentColumns() {
    this.paymentTableColumns = [
      {
        key: 'razorpayPaymentId',
        label: 'Payment Id',
        formatter: (_, row) =>
          row.razorpayPaymentId ? row.razorpayPaymentId : '-',
      },
      {
        key: 'universityName',
        label: 'University Name',
        formatter: (_, row) => `${row.universityName}`,
      },

      {
        key: 'courseName',
        label: 'Program Name',
        formatter: (_, row) => row.courseName,
      },

      {
        key: 'intakeSeason',
        label: 'Intake',
        formatter: (_, row) => row.intakeSeason,
      },
      {
        key: 'applicationFees',
        label: 'Application Fees',
        formatter: (_, row) =>
          row.applicationFees === 'Waiver'
            ? 0
            : `${row.currency} ${row.applicationFees}`,
      },

      {
        key: 'conversionRate',
        label: 'Conversion Rate',
        formatter: (_, row) => {
          const exchangeRate =
            this.currencyExchangeRatesOptions?.[row?.currency] ?? 1;
          const applicationFees = Number(row?.applicationFees) || 0;
          const convertedAmount = exchangeRate * applicationFees;

          return `INR ${convertedAmount.toFixed(2)}`;
        },
        subText: (row) =>
          `(1 ${row?.currency} = ${
            this.currencyExchangeRatesOptions?.[row?.currency] ?? 1
          } INR)`,
      },
      {
        key: 'receipt',
        label: 'Receipt',
        formatter: (_, row) => row.receipt,
      },

      {
        key: 'amountPaid',
        label: 'Total Amount Paid',
        formatter: (_, row) => row.amountPaid,
      },

      {
        key: 'paymentStatus',
        label: 'Status',
        formatter: (_, row) =>
          row.paymentStatus ? row.paymentStatus : 'Initiated',
      },
    ];
  }

  setVerifiedColumns() {
    this.studentTableVerifyColumns = [
      {
        key: 'universityName',
        label: 'University Name',
        formatter: (_, row) => `${row.universityName}`,
      },

      {
        key: 'courseName',
        label: 'Program Name',
        formatter: (_, row) => row.courseName,
      },

      {
        key: 'intakeSeason',
        label: 'Intake',
        formatter: (_, row) => row.intakeSeason,
      },

      {
        key: 'applicationFees',
        label: 'Application Fees',
        formatter: (_, row) => `Yet to update`,
      },

      {
        key: 'conversionRate',
        label: 'Conversion Rate',
        formatter: (_, row) => `-`,
      },
    ];
  }

  payFeesForShortlistedPrograms(studentData: any, amount: string) {
    const orderDetails = {
      data: {
        studentId: studentData?.studentId,
        applicationId: studentData?.applicationId,
        firstName: studentData?.firstName,
        lastName: studentData?.lastName,
        email: studentData?.email,
        phoneNumber: studentData?.phoneNumber,
        originalAmount: studentData?.originalAmount, // Now stores multiple amounts
        convertedFrom: studentData?.convertedFrom, // Now stores multiple currencies
        courseId: studentData?.courseId,
        courses: studentData?.courses,
      },
      amount: amount,
      currency: 'INR',
      paymentStatus: 'Initiated',
      receipt: `${studentData?.firstName}-${studentData?.lastName}`,
    };

    const userDetails = {
      name: `${studentData?.firstName} ${studentData?.lastName}`,
      email: studentData?.email,
      mobile: studentData?.phoneNumber,
    };

    if (Number(amount) > 0) {
      this.razorPay.createOrder(this.dataModel, orderDetails, true).subscribe(
        (data: any) => {
          this.currentPaymentData = data;
          this.razorPay.openPaymentCheckout(data, userDetails, 'admission');
          this.payable = false;
        },
        (error: any) => {
          this.payable = false;
          console.log(error.error.message);
        }
      );
    } else {
      this.razorPay
        .createOrder(this.feeWaiverDataModel, orderDetails, true)
        .subscribe((data: any) => {
          this.currentPaymentData = data;
          this.payable = false;
          this.updateAdmissionPaymentStatus(data);
        });
    }
  }

  selectedReceivers(receivers: any[]) {
    // Extract courseIds from receivers
    // Filter wishlist based on courseIds
    const receivedData = this.applicantData.wishlist.filter((item) =>
      receivers.includes(item.courseId)
    );

    const studentData = {
      firstName: this.applicantData.personalInformation.firstName,
      lastName: this.applicantData.personalInformation.lastName,
      email: this.applicantData.personalInformation.email,
      phoneNumber: this.applicantData.personalInformation.phoneNumber,
      partnerId: this.applicantData.partnerId,
      studentId: this.applicantData.studentId,
      applicationId: this.applicantData._id,
      courses: receivedData,
    };

    this.receivedDataforPayment = { receivedData, studentData };

    // Extract original amounts and currencies

    // Calculate total application fees
    this.payableAmount = receivedData.reduce((sum, item) => {
      if (item.applicationFees?.toLowerCase() === 'waiver') {
        return sum;
      }

      const fee =
        this.currencyExchangeRatesOptions[item.currency] *
        Number(item?.applicationFees);
      return sum + fee;
    }, 0);

    if (this.payableAmount === 0) {
      this.payable = true;
    }
  }

  proccedToPayment(amount: any) {
    this.payFeesForShortlistedPrograms(
      this.receivedDataforPayment?.studentData,
      amount
    );
  }

  @HostListener('window:payment.admission.success', ['$event'])
  onPaymentSuccess(event: any): void {
    if (!event?.detail) {
      console.error('Payment event details are missing.');
      return;
    }

    const orderObj: any = {
      razorpayPaymentId: event.detail.razorpay_payment_id,
      razorpayOrderId: event.detail.razorpay_order_id,
      razorpaySignature: event.detail.razorpay_signature,
      studentId: this.applicantData?.studentId || null,
      applicationId: this.applicantData?._id || null,
      id: this.currentPaymentData?._id,
    };

    this.razorPay.updateOrder(this.verifyModel, orderObj, true).subscribe(
      (data: any) => {
        this.currentPaymentData = data;

        if (this.currentPaymentData?.paymentStatus?.toLowerCase() === 'paid') {
          this.updateAdmissionPaymentStatus(this.currentPaymentData);
        } else {
          alert('Your Payment Status is Failed');
        }
      },
      (error: any) => {
        console.error('Error updating payment order:', error);
        alert(
          'An error occurred while processing your payment. Please try again.'
        );
      }
    );
  }

  updateAdmissionPaymentStatus(data: any) {
    const courseIds = data?.courses.map((item) => String(item.courseId));

    this.applicantData?.wishlist.forEach((item) => {
      if (courseIds.includes(String(item.courseId))) {
        item.paymentStatus = 'Paid';
      }
    });

    this.apiHelper
      .updateDatawithShortlistingUrl(
        this.universityStudentUrl,
        data?.applicationId,
        { wishlist: this.applicantData?.wishlist }
      )
      .subscribe((res: any) => {
        this.applicantData = res?.updatedApplicant;
        this.getPayments(res?.updatedApplicant?._id);
        this.loading = true;

        this.postUniversityApplicationData(
          res?.updatedApplicant,
          data?.courses
        );
      });
  }

  getPayments(id: string) {
    
    this.apiHelper
      .getDataWithShortlistingUrl(`${this.getPaymentUrl}/${id}`)
      .then((res: any) => {
        this.paymentColumnsData = res?.paymentDetails;
        this.amountPaid = Number(
          Array.isArray(res?.paymentDetails)
            ? res.paymentDetails.reduce(
                (sum, item) => sum + (Number(item.amountPaid) || 0),
                0
              )
            : 0
        );

        this.paymentTableLoading = true;
        this.paymentColumns();
      });
  }

  postUniversityApplicationData(data: any, courses: any) {
    const copyObj = { ...data };
    this.applicantData = copyObj;
    delete data?.wishlist;
    const dataObj = {
      ...data,
      applicantId: data?._id,
      wishlist: courses,
    };
    delete dataObj._id;
    this.apiHelper
      .postDataWithShortlistingUrl(this.universityApplicationUrl, dataObj)
      .then((res) => {
        console.log(res);
        this.payableAmount = 0;
        this.setDataColumns();
      });
  }
}

