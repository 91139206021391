import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class WishlistService {
  private apiUrl = environment.apiBaseUrl;
  private wishlistCount = new BehaviorSubject<number>(0);
  wishlistCount$ = this.wishlistCount.asObservable(); // Expose as observable

  constructor(private http: HttpClient) { }

  // Helper method to get userId and create headers
  private getHeaders(): { headers: HttpHeaders, userId: string } | null {
    const storedData = localStorage.getItem('initialFilter');
    const initialFilter = storedData ? JSON.parse(storedData) : null;
    const userId = initialFilter?.userId || null;
    
    // Get puid from route or local storage if available
    const puid = localStorage.getItem('puid') || null;

    if (!userId) {
        console.error('No userId found in local storage');
        return null;
    }

    const headers = new HttpHeaders({
        'userId': userId
    });

    // Add puid to headers if available
    if (puid) {
        headers.append('partnerId', puid);
    }

    return {
        headers: headers,
        userId
   };    
 }

  addToWishlist(course: any): Observable<any> {
    const headerData = this.getHeaders();
    if (!headerData) {
        return throwError(() => new Error('Missing required authentication data'));
    }

    const payload = {
        courseId: course.course_id,
        courseName: course.course_name,
        universityName: course.university,
        tuitionFees: course.yearly_tuition_fees,
        courseDuration: course.duration,
        degree: course.study_level,
        intakeSeason: course.open_intakes || '',
        applicationFees: course.application_fee,
        location: course.campus || '',
        country: course.country,
        addedThrough: 'Student',
        test_scores: course.test_scores || [],
        partnerId: course.partnerId || null
    };

    return this.http.post(
        `${this.apiUrl}/student-choices/add-to-wishlist`,
        payload,
        { headers: headerData.headers }
    ).pipe(
        map(response => {
            console.log('Course added to wishlist:', response);
            this.refreshWishlistCount(); 
            return response;
        }),
        catchError(error => {
            console.error('Error adding to wishlist:', error);
            return throwError(() => error);
        })
    );
}
updateWishlistCount(newCount: number): void {
    this.wishlistCount.next(newCount);  
}


removeFromWishlist(courseId: string): Observable<any> {
    const headerData = this.getHeaders();
    if (!headerData) {
        return throwError(() => new Error('Missing required authentication data'));
    }

    return this.http.delete(
        `${this.apiUrl}/student-choices/remove-from-wishlist/${courseId}`,
        { headers: headerData.headers }
    ).pipe(
        map(response => {
            console.log('Course removed from wishlist:', response);
            this.refreshWishlistCount(); // ✅ Update count after removal
            return response;
        }),
        catchError(error => {
            console.error('Error removing from wishlist:', error);
            return throwError(() => error);
        })
    );
}

refreshWishlistCount(): void {
    this.getWishlist().subscribe(
        (wishlistData) => {
            this.wishlistCount.next(wishlistData.wishlistLength); // ✅ Update BehaviorSubject
            console.log('Updated Wishlist Count:', wishlistData.wishlistLength);
        },
        (error) => {
            console.error('Error updating wishlist count:', error);
        }
    );
}



getWishlist(headers?: HttpHeaders): Observable<any> {
    const headerData = this.getHeaders();
    if (!headerData) {
        return throwError(() => new Error('Missing required authentication data'));
    }

    return this.http.get(
        `${this.apiUrl}/student-choices/get-from-wishlist`,
        { headers: headerData.headers }
    ).pipe(
        map((response: any) => {
            if (response && response.data) {
                this.wishlistCount.next(response.data.length); // ✅ Update BehaviorSubject
                return {
                    wishlistLength: response.data.length,
                    data: response.data
                };
            }
            this.wishlistCount.next(0); // ✅ Ensure count updates even if empty
            return { wishlistLength: 0, data: [] };
        }),
        catchError(error => {
            console.error('Error fetching wishlist:', error);
            this.wishlistCount.next(0); // ✅ Set count to 0 on error
            return of({ wishlistLength: 0, data: [] });
        })
    );
}

 
}
