import { Component} from '@angular/core';
import { ConfigService } from 'projects/apna-lib/src/lib/services/config.service';

@Component({
  selector: 'fa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ForeignAdmits';
  constructor(
    private config: ConfigService,
  ) {
    // this.config.getAllConfig()
  }

  ngOnInit(){
    // this.titleService.updateTitle(); 
  }
}
