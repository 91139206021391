import { Injectable } from '@angular/core';
import { map, shareReplay, take, tap } from 'rxjs/operators';
import { ApiHelperService } from './api-helper.service';
export type ConfigIdType = "foreignAdmits" | "foreignAssist" | "category" | "modules" | "sources" | "formlyForms" | "dataModels" | "interviewPreparationPlans" | "navItems" | "UserRoleTypes"|"countrywiseServices"|'visaPlans';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public _allConfigurationData: any;
  constructor(
    private apiHelperService: ApiHelperService
  ) {
    // console.log("calling config from config")
    // this.getAllConfig();
  }

  getConfig1(configId: ConfigIdType, propertyName?: string) {
    let result;
    if (this._allConfigurationData && this._allConfigurationData instanceof Array) {
      this._allConfigurationData.map((data) => {
        if (data.id == configId) {
          if (data?.config) {
            if (propertyName) {
              result = data["config"][propertyName]
            }
            else {
              result = data["config"]
            }
          }
        }
      });
    }
    else {
      result = []
    }
    return result
  }

  getConfig(configId: ConfigIdType, propertyName?: string) {
    return this.apiHelperService.getDataById("configurations", configId).pipe(shareReplay(1), map((data: any) => {
      return propertyName ? data.config[propertyName] : data.config;
    })/* , tap(data => console.log(data)) */);

    //filter data as per configId, property and return result []object.
  }

  getConfigByFilter(configId: ConfigIdType, moduleName?: string) {
    return this.getConfig(configId).pipe(map((res: any) => {     //use the returned getConfig[] to get the result.
      return res.filter(item => item.moduleName == moduleName);
    }))
  }

  //create getConfig1, getConfigByFilter1 with new logic to test. If okay implement the same with old name.


  async getAllConfig() {
    const result = await this.apiHelperService.getData("configurations");
    this._allConfigurationData = result;
    // console.log(this._allConfigurationData);
    return this._allConfigurationData;
  }

  filterData(configId: string, moduleName: string, data: any[]) {
    // console.log(data);
    const singleObj = data.find(single => single.id === configId);
    if (configId === "category") {
      return singleObj.config.filter((single: any) => {
        return single.moduleName === moduleName
      });
    }
    else if (configId === "modules") {
      return singleObj.config[moduleName];
    } else if (configId === "foreignAssist" && moduleName) {
      return singleObj.config[moduleName];
    } else {
      return singleObj;
    }
  }


  getCustomConfig() {
    return this.apiHelperService.getData("configurations");
  }

  getDocumentNameFromUrl(url: string) {
    return decodeURIComponent(new URL(url).pathname.split("/").pop().substring(13));
  }

}
