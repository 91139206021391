import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiHelperService } from 'projects/apna-lib/src/lib/services/api-helper.service';
import { MediaService } from 'projects/apna-lib/src/lib/services/media.service';
import { ChatAppComponent } from '../../../chat-app/chat-app.component';

@Component({
  selector: 'app-student-admission-applications',
  templateUrl: './student-admission-applications.component.html',
  styleUrls: ['./student-admission-applications.component.scss']
})
export class StudentAdmissionApplicationsComponent implements OnInit {
  applicationData: any;
  loading: boolean = false;
  error: string = '';
  userDetails: any;
  chatData: any = null;
  currentUserId: string; 
  currentUserName: string;
  selectedApplicationId: string = '';

  @ViewChild(ChatAppComponent) chatComponent: ChatAppComponent;
  @Input() admissionData: any;
  @Input() studentId: string;
  @Input() applicantId: string;

  constructor(
    private apiHelper: ApiHelperService,
    private activatedRoute: ActivatedRoute
  ) { }

  
  ngOnInit(): void {
    console.info(this.studentId, this.applicantId, 36)
    console.info(this.admissionData, 37)

    this.currentUserId = this.studentId;
    this.currentUserName = this.admissionData?.personalInformation?.firstName;
    console.log(this.currentUserId, this.currentUserName, 44)
    this.getApplicationsData(this.applicantId)
  }



  getApplicationsData(applicantId: any) {
    
    const queryParams = { applicantId };
    this.apiHelper.getDataWithShortlistingUrl("university-applications/filter", queryParams).then((res: any) => {
      this.applicationData = res;
 
      console.info(this.applicationData)
    })
  }


  getChatMessages(applicationId: string) {
    this.apiHelper.getDataWithShortlistingUrl(`chat/application/${applicationId}`)
      .then((response: any) => {
        this.chatData = response;
        console.log('Chat data loaded:', this.chatData);
      })
      .catch(error => {
        console.error('Error loading chat data:', error);
        // Initialize empty chat data structure if no chat exists
        this.chatData = {
          applicationId: applicationId,
          applicantId: this.applicantId,
          messages: []
        };
      });
  }


  // Handle send message from chat component
  handleSendMessage(messageData: any) {
    if (!this.selectedApplicationId) return;

    const attachmentUrls = messageData.attachments.map(att => att.filePath);
    
    const payload = {
      applicationId: this.selectedApplicationId,
      applicantId: this.applicantId,
      message: messageData.message,
      name: this.currentUserName,
      userId: this.currentUserId,
      attachment: attachmentUrls 
    };
    
    // Add to local state for immediate feedback
    if (this.chatData) {
      this.chatData.messages.push({
        name: this.currentUserName,
        userId: this.currentUserId,
        message: messageData.message,
        attachment: attachmentUrls,
        createdAt: new Date().toISOString()
      });
    }
    
    this.apiHelper.postDataWithShortlistingUrl('chat/message', payload)
      .then((response: any) => {
        console.log('Message sent successfully:', response);
      })
      .catch(error => {
        console.error('Error sending message:', error);
        if (this.chatData && this.chatData.messages.length > 0) {
          this.chatData.messages.pop();
        }
      });
  }


  handleFileUpload(fileData: {file: File, formData: FormData}) {
    this.apiHelper.postDataWithShortlistingUrl('upload/chat-attachment', fileData.formData)
      .then((response: any) => {
        console.log('File uploaded successfully:', response);
        this.chatComponent.uploadStatusChange.emit({
          inProgress: false, 
          file: fileData.file.name,
          url: response.fileUrl 
        });
      })
      .catch(error => {
        console.error('Error uploading file:', error);
        this.chatComponent.uploadStatusChange.emit({
          inProgress: false, 
          file: fileData.file.name
        });
        // this.apiHelper.openSnackBar("File upload failed. Please try again.");
      });
  }

  selectApplication(applicationId: string) {
    this.selectedApplicationId = applicationId;
    this.getChatMessages(applicationId);
  }
}
