import { Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ApiHelperService } from 'projects/apna-lib/src/lib/services/api-helper.service';

@Component({
  selector: 'app-student-academic-qualification',
  templateUrl: './student-academic-qualification.component.html',
  styleUrls: ['./student-academic-qualification.component.scss']
})
export class StudentAcademicQualificationComponent implements OnInit {
  @Input() studentDetails: any;
  @Output() updatedProfileData = new EventEmitter<boolean>();
  public isLoading: boolean = false;
  public dataModel: string = "users";
  public model: any = {};
  layoutEducation: FormlyFieldConfig[] = [];
  userDetails: any;
  saveChangesEducation: boolean = false;

  // countryOptions = this.configService.getConfig1("foreignAssist", "countries");
  // stateOptions = this.configService.getConfig1("foreignAssist", "states");
  educationLevelOptions = [
    { label: 'Postgraduate', value: 'postgraduate' },
    { label: 'Undergraduate', value: 'undergraduate' },
    { label: 'Grade 12th', value: 'grade12' },
    { label: 'Grade 10th', value: 'grade10' }
  ];
  gradingSystemOptions = [
    { label: 'Percentage', value: 100 },
    { label: 'CGPA (10)', value: 10 },
    { label: 'CGPA (7)', value: 7 },
    { label: 'CGPA (5)', value: 5 },
    { label: 'CGPA (4)', value: 4 }
  ];

  constructor(
    private apiHelper: ApiHelperService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.dataPreProcessor();
    this.userDetails = this.studentDetails;
    console.log('Education Component Data:', this.studentDetails); 
    
  }

  openEditSection(key: string) {
    if (key === 'saveChangesEducation') {
      this.saveChangesEducation = true;
      this.dataPreProcessor();
    }
  }


  setLayout() {
    this.layoutEducation = [
      {
        fieldGroupClassName: 'row mb-4',
        fieldGroup: [
          
          // Education Summary
          {
            template: '<h5 class="mb-3">Education Summary</h5>'
          },
          {
            className: 'col-md-6',
            key: 'countryOfEducation',
            type: 'input',
            templateOptions: {
              label: 'Country of Education*',
              
              // options: this.countryOptions,
              placeholder: 'Select country'
            }
          },
          {
            className: 'col-md-6',
            key: 'highestLevel',
            type: 'select',
            templateOptions: {
              label: 'Highest Level of Education*',
              
              options: this.educationLevelOptions,
              placeholder: 'Select level'
            }
          },
  
          // Postgraduate Section
          {
            template: '<h5 class="mb-3 mt-6">Postgraduate Details</h5>',
            className: 'col-12',
            hideExpression: 'model.highestLevel !== "postgraduate"'
          },
          {
            fieldGroupClassName: 'row mt-2',
            fieldGroup: [
                  {
                    className: 'col-md-4',
                    key: 'postgraduateMarks.country',
                    type: 'input',
                    templateOptions: {
                      label: 'Country of Study*',
                      
                      // options: this.countryOptions,
                      placeholder: 'Select Country'
                    }
                  },
                  {
                    className: 'col-md-4',
                    key: 'postgraduateMarks.state',
                    type: 'input',
                    templateOptions: {
                      label: 'State of Study*',
                      
                      // options: this.stateOptions,
                      placeholder: 'Select'
                    }
                  },
                  {
                    className: 'col-md-4',
                    key: 'postgraduateMarks.level',
                    type: 'select',
                    templateOptions: {
                      label: 'Level of Study*',
                      options: [
                        { label: 'Postgraduate', value: 'postgraduate' }
                      ],
                      placeholder: 'Select level'
                    },
                  },

                  {
                    className: 'col-md-6',
                    key: 'postgraduateMarks.postgradCollegeName',
                    type: 'input',
                    templateOptions: {
                      label: 'Name of University*',
                      
                      placeholder: 'Enter university name'
                      
                    }
                  },
                  {
                    className: 'col-md-6',
                    key: 'postgraduateMarks.degreeType',
                    type: 'input',
                    templateOptions: {
                      label: 'Qualification Achieved/ Degree Awarded*',
                      
                      placeholder: 'eg. M.Tech, M.E., M.Sc.'
                    }
                  },
               
          
                  {
                    className: 'col-md-6',
                    key: 'postgraduateMarks.city',
                    type: 'input',
                    templateOptions: {
                      label: 'City of Study*',
                      
                      placeholder: 'Enter city'
                    }
                  },
                  {
                        className: 'col-md-3',
                        key: 'postgraduateMarks.scoreSystem',
                        type: 'select',
                        templateOptions: {
                          label: 'Grading system & Marks scored*',
                          
                          options: this.gradingSystemOptions,
                          placeholder: 'Select'
                        }
                  },
                  {
                        className: 'col-md-3 custom-margin-top',
                        key: 'postgraduateMarks.score',
                        type: 'input',
                        templateOptions: {
                          type: 'number',
                          placeholder: 'Enter Marks'
                        },
                        expressionProperties: {
                          'templateOptions.min': '0',
                          'templateOptions.max': 'model.postgraduateMarks.scoreSystem'
                        },
                        validation: {
                          messages: {
                            min: 'Score must be at least 0',
                            max: 'Score cannot exceed the max Score value'
                          }
                        }
                  },  
                  {
                    className: 'col-md-6',
                    key: 'postgraduateMarks.language',
                    type: 'input',
                    templateOptions: {
                      label: 'Primary Language of Instruction*',
                      
                      placeholder: 'Enter Language'
                    }
                  },
                  {
                    className: 'col-md-6',
                    key: 'postgraduateMarks.degreeBacklogs',
                    type: 'input',
                    templateOptions: {
                      label: 'Backlogs*',
                      type: 'number',
                      
                      placeholder: 'Enter Backlogs',
                      min: 0
                    }
                  },
                         
                  {
                    className: 'col-md-6',
                    key: 'postgraduateMarks.startDate',
                    type: 'date',
                    templateOptions: {
                      label: 'Start Date*',
                      
                      placeholder: 'Choose date'
                    }
                  },

                  {
                    className: 'col-md-6',
                    key: 'postgraduateMarks.endDate',
                    type: 'date',
                    templateOptions: {
                      label: 'End Date*',
                      
                      placeholder: 'Choose date'
                    }
                  }             
            ],
            hideExpression: 'model.highestLevel !== "postgraduate"'
          },
           
          //Undergraduate Section
          {
            template: '<h5 class="mb-3 mt-6">Undergraduate Details</h5>',
            className: 'col-12',
            hideExpression: 'model.highestLevel !== "postgraduate" && model.highestLevel !== "undergraduate"'
          },
          {
            fieldGroupClassName: 'row mt-2',
            fieldGroup: [
              {
                className: 'col-md-4',
                key: 'undergraduateMarks.country',
                type: 'input',
                templateOptions: {
                  label: 'Country of Study*',
                  
                  // options: this.countryOptions,
                  placeholder: 'Select Country'
                }
              },
              {
                className: 'col-md-4',
                key: 'undergraduateMarks.state',
                type: 'input',
                templateOptions: {
                  label: 'State of Study*',
                  
                  // options: this.stateOptions,
                  placeholder: 'Select'
                }
              },
              {
                className: 'col-md-4',
                key: 'undergraduateMarks.level',
                type: 'select',
                templateOptions: {
                  label: 'Level of Study*',
                  options: [
                    { label: 'Undergraduate', value: 'undergraduate' }
                  ],
                  placeholder: 'Select level'
                }
              },
              {
                className: 'col-md-6',
                key: 'undergraduateMarks.undergradCollegeName',
                type: 'input',
                templateOptions: {
                  label: 'Name of University*',
                  
                  placeholder: 'Enter university name'
                }
              },
              {
                className: 'col-md-6',
                key: 'undergraduateMarks.degreeType',
                type: 'input',
                templateOptions: {
                  label: 'Qualification Achieved/ Degree Awarded*',
                  
                  placeholder: 'eg. B.Tech, B.E., B.Sc.'
                }
              },
              {
                className: 'col-md-6',
                key: 'undergraduateMarks.city',
                type: 'input',
                templateOptions: {
                  label: 'City of Study*',
                  
                  placeholder: 'Enter city'
                }
              },
              {
                  className: 'col-md-3',
                  key: 'undergraduateMarks.scoreSystem',
                  type: 'select',
                  templateOptions: {
                    label: 'Grading system & Marks scored*',
                    
                    options: this.gradingSystemOptions,
                    placeholder: 'Select'
                  }
              },
              {
                    className: 'col-md-3 custom-margin-top',
                    key: 'undergraduateMarks.score',
                    type: 'input',
                    templateOptions: {
                      type: 'number',
                      
                      placeholder: 'Enter Marks'
                    },
                    expressionProperties: {
                      'templateOptions.min': '0',
                      'templateOptions.max': 'model.undergraduateMarks.scoreSystem'
                    },
                    validation: {
                      messages: {
                        min: 'Score must be at least 0',
                        max: 'Score cannot exceed the max Score value'
                      }
                    }
              },
              {
                className: 'col-md-6',
                key: 'undergraduateMarks.language',
                type: 'input',
                templateOptions: {
                  label: 'Primary Language of Instruction*',
                  
                  placeholder: 'Enter Language'
                }
              },
              {
                className: 'col-md-6',
                key: 'undergraduateMarks.degreeBacklogs',
                type: 'input',
                templateOptions: {
                  label: 'Backlogs*',
                  type: 'number',
                  
                  placeholder: 'Enter Backlogs',
                  min: 0
                }
              },
              {
                className: 'col-md-6',
                key: 'undergraduateMarks.startDate',
                type: 'date',
                templateOptions: {
                  label: 'Start Date*',
                  
                  placeholder: 'Choose date'
                }
              },
              {
                className: 'col-md-6',
                key: 'undergraduateMarks.endDate',
                type: 'date',
                templateOptions: {
                  label: 'End Date*',
                  
                  placeholder: 'Choose date'
                }
              }
            ],
            hideExpression: 'model.highestLevel !== "postgraduate" && model.highestLevel !== "undergraduate"'
          },

          //12th Grade Section
          {
            template: '<h5 class="mb-3 mt-6">Grade 12th or Equivalent</h5>',
            className: 'col-12',
            hideExpression: 'model.highestLevel !== "postgraduate" && model.highestLevel !== "undergraduate" && model.highestLevel !== "grade12"'
          },
          {
            fieldGroupClassName: 'row mt-2', 
            fieldGroup: [
              {
                className: 'col-md-4',
                key: 'highSchoolMarks.country',
                type: 'input',
                templateOptions: {
                  label: 'Country of Study*',       
                  // options: this.countryOptions,
                  placeholder: 'Select Country'
                }
              },
              {
                className: 'col-md-4', 
                key: 'highSchoolMarks.state',
                type: 'input',
                templateOptions: {
                  label: 'State of Study*',         
                  // options: this.stateOptions,
                  placeholder: 'Select'
                }
              },
              {
                className: 'col-md-4',
                key: 'highSchoolMarks.level',
                type: 'select',
                templateOptions: {
                  label: 'Level of Study*', 
                  options: [
                    { label: 'Grade 12', value: 'grade12' }
                  ],
                  placeholder: 'Select level'
                }
              },
              {
                className: 'col-md-6',
                key: 'highSchoolMarks.boardName',
                type: 'input',
                templateOptions: {
                  label: 'Name of Board*',
                  placeholder: 'Enter board name'
                }
              },
              {
                className: 'col-md-6',
                key: 'highSchoolMarks.degreeType',
                type: 'input',
                templateOptions: {
                  label: 'Qualification Achieved/ Degree Awarded*', 
                  placeholder: 'eg. 12th, HSC, etc.'
                }
              },
              {
                className: 'col-md-6',
                key: 'highSchoolMarks.highSchoolName',
                type: 'input',
                templateOptions: {
                  label: 'Name of Institution*',   
                  placeholder: 'Enter institution name'
                }
              },
              {
                className: 'col-md-6',
                key: 'highSchoolMarks.city',
                type: 'input',
                templateOptions: {
                  label: 'City of Study*',                 
                  placeholder: 'Enter city'
                }
              },
              {
                    className: 'col-md-3',
                    key: 'highSchoolMarks.highSchoolScoreSystem',
                    type: 'select',
                    templateOptions: {
                      label: 'Grading system & Marks scored*',
                      options: this.gradingSystemOptions,
                      placeholder: 'Select'
                    }
              },
              {
                    className: 'col-md-3 custom-margin-top',
                    key: 'highSchoolMarks.highSchoolScore',
                    type: 'input',
                    templateOptions: {
                      type: 'number',  
                      placeholder: 'Enter Marks'
                    },
                    expressionProperties: {
                      'templateOptions.min': '0',
                      'templateOptions.max': 'model.highSchoolMarks.highSchoolScoreSystem'
                    },
                    validation: {
                      messages: {
                        min: 'Score must be at least 0',
                        max: 'Score cannot exceed the max Score value'
                      }
                    }
              },
              {
                className: 'col-md-6',
                key: 'highSchoolMarks.language',
                type: 'input',
                templateOptions: {
                  label: 'Primary Language of Instruction*',
                  
                  placeholder: 'Enter Language'
                }
              },
              {
                className: 'col-md-6',
                key: 'highSchoolMarks.startDate',
                type: 'date',
                templateOptions: {
                  label: 'Start Date*',
                  
                  placeholder: 'Choose date'
                }
              },
              {
                className: 'col-md-6',
                key: 'highSchoolMarks.endDate',
                type: 'date',
                templateOptions: {
                  label: 'End Date*',
                  
                  placeholder: 'Choose date'
                }
              }
            ],
            hideExpression: 'model.highestLevel !== "postgraduate" && model.highestLevel !== "undergraduate" && model.highestLevel !== "grade12"'
          },

          //10th Grade Section
           {
            template: '<h5 class="mb-3 mt-6">Grade 10th or Equivalent</h5>',
            className: 'col-12',
           },
           {
            fieldGroupClassName: 'row mt-2',
            fieldGroup: [
              {
                className: 'col-md-4',
                key: 'grade10.country',
                type: 'input',
                templateOptions: {
                  label: 'Country of Study*',
                  
                  // options: this.countryOptions,
                  placeholder: 'Select Country'
                }
              },
              {
                className: 'col-md-4',
                key: 'grade10.state',
                type: 'input',
                templateOptions: {
                  label: 'State of Study*',
                  
                  // options: this.stateOptions,
                  placeholder: 'Select'
                }
              },
              {
                className: 'col-md-4',
                key: 'grade10.level',
                type: 'select',
                templateOptions: {
                  label: 'Level of Study*',
                  options: [
                    { label: 'Grade 10', value: 'grade10' }
                  ],
                  placeholder: 'Select level'
                }
              },
              {
                className: 'col-md-6',
                key: 'grade10.boardName',
                type: 'input',
                templateOptions: {
                  label: 'Name of Board*',
                  
                  placeholder: 'Enter board name'
                }
              },
              {
                className: 'col-md-6',
                key: 'grade10.degreeType',
                type: 'input',
                templateOptions: {
                  label: 'Qualification Achieved/ Degree Awarded*',
                  
                  placeholder: 'eg. 10th, SSC, etc.'
                }
              },
              {
                className: 'col-md-6',
                key: 'grade10.schoolName',
                type: 'input',
                templateOptions: {
                  label: 'Name of Institution*',
                  
                  placeholder: 'Enter institution name'
                }
              },
              {
                className: 'col-md-6',
                key: 'grade10.city',
                type: 'input',
                templateOptions: {
                  label: 'City of Study*',
                  
                  placeholder: 'Enter city'
                }
              },
              {
                    className: 'col-md-3',
                    key: 'grade10.schoolScoreSystem',
                    type: 'select',
                    templateOptions: {
                      label: 'Grading system & Marks scored*',                    
                      options: this.gradingSystemOptions,
                      placeholder: 'Select'
                    }
              },
              {
                    className: 'col-md-3 custom-margin-top',
                    key: 'grade10.schoolScore',
                    type: 'input',
                    templateOptions: {
                      type: 'number',                     
                      placeholder: 'Enter Marks'
                    },
                    expressionProperties: {
                      'templateOptions.min': '0',
                      'templateOptions.max': 'model.grade10.schoolScoreSystem'
                    },
                    validation: {
                      messages: {
                        min: 'Score must be at least 0',
                        max: 'Score cannot exceed the max Score value'
                      }
                    }
              },
              {
                className: 'col-md-6',
                key: 'grade10.language',
                type: 'input',
                templateOptions: {
                  label: 'Primary Language of Instruction*',
                  
                  placeholder: 'Enter Language'
                }
              },
              {
                className: 'col-md-6',
                key: 'grade10.startDate',
                type: 'date',
                templateOptions: {
                  label: 'Start Date*',
                  
                  placeholder: 'Choose date'
                }
              },
              {
                className: 'col-md-6',
                key: 'grade10.endDate',
                type: 'date',
                templateOptions: {
                  label: 'End Date*',
                  
                  placeholder: 'Choose date'
                }
              }
            ],
            hideExpression: '!model.highestLevel'
           },
           {
            fieldGroupClassName: 'fixed-bottom-button-container',
            fieldGroup: [
              {
                fieldGroup: [
                  {
                    type: 'button',
                    templateOptions: {
                      btnType: 'primary px-5 ',
                      type: 'submit',
                      text: 'Save Changes'
                    },
                    hideExpression: () => !this.saveChangesEducation
                  }
                ]
              }
            ]
          },

        ]
      }
    ];
  }

  dataPreProcessor() {
    // Start with a complete model structure with all required nested objects
    const defaultModel = {
      countryOfEducation: null,
      highestLevel: null,
      grade10: {
        country: null,
        state: null,
        level: 'grade10',
        boardName: null,
        degreeType: null,
        schoolName: null,
        city: null,
        schoolScoreSystem: null,
        schoolScore: null,
        language: null,
        startDate: null,
        endDate: null,
      },
      highSchoolMarks: {
        country: null,
        state: null,
        level: 'grade12',
        boardName: null,
        degreeType: null,
        highSchoolName: null,
        city: null,
        highSchoolScoreSystem: null,
        highSchoolScore: null,
        language: null,
        startDate: null,
        endDate: null,
      },
      undergraduateMarks: {
        country: null,
        state: null,
        level: 'undergraduate',
        undergradCollegeName: null,
        degreeType: null,
        city: null,
        scoreSystem: null,
        score: null,
        language: null,
        degreeBacklogs: 0,
        startDate: null,
        endDate: null,
      },
      postgraduateMarks: {
        country: null,
        state: null,
        level: 'postgraduate',
        postgradCollegeName: null,
        degreeType: null,
        city: null,
        scoreSystem: null,
        score: null,
        language: null,
        degreeBacklogs: 0,
        startDate: null,
        endDate: null,
      }
    };
  
    // Merge existing data from userDetails if available
    if (this.userDetails?.academicQualification) {
      // Deep merge to ensure nested objects are properly combined
      this.model = this.deepMerge(defaultModel, this.userDetails.academicQualification);
    } else {
      this.model = defaultModel;
    }
    
    this.setLayout();
  }
  
  // Helper method for deep merging objects
  deepMerge(target, source) {
    const output = Object.assign({}, target);
    
    if (target && typeof target === 'object' && !Array.isArray(target) && 
        source && typeof source === 'object' && !Array.isArray(source)) {
      Object.keys(source).forEach(key => {
        if (source[key] && typeof source[key] === 'object' && !Array.isArray(source[key])) {
          if (!(key in target)) {
            Object.assign(output, { [key]: source[key] });
          } else {
            output[key] = this.deepMerge(target[key], source[key]);
          }
        } else {
          Object.assign(output, { [key]: source[key] });
        }
      });
    }
    
    return output;
  }
  


  dataProcessor(data) {
    return data;
  }

  onSubmit(event: any) {
    if (event?.error) {
      console.error('Form submission error:', event.error);
    } else {
      let obj = {
        academicQualification: {
          countryOfEducation: this.model.countryOfEducation || null,
          highestLevel: this.model.highestLevel || null,
          grade10: {
            country: this.model.grade10?.country || null,
            state: this.model.grade10?.state || null,
            level: this.model.grade10?.level || 'Grade 12',
            boardName: this.model.grade10?.boardName || 'Grade 12',
            degreeType: this.model.grade10?.degreeType || null,
            schoolName: this.model.grade10?.schoolName || null,
            city: this.model.grade10?.city || null,
            schoolScoreSystem: this.model.grade10?.schoolScoreSystem || null,
            schoolScore: this.model.grade10?.schoolScore || null,
            language: this.model.grade10?.language || null,
            startDate: this.model.grade10?.startDate || null,
            endDate: this.model.grade10?.endDate || null,
          },
          highSchoolMarks: {
            country: this.model.highSchoolMarks?.country || null,
            state: this.model.highSchoolMarks?.state || null,
            level: this.model.highSchoolMarks?.level || 'Grade 12',
            boardName: this.model.highSchoolMarks?.boardName || 'Grade 12',
            degreeType: this.model.highSchoolMarks?.degreeType || null,
            highSchoolName: this.model.highSchoolMarks?.highSchoolName || null,
            city: this.model.highSchoolMarks?.city || null,
            highSchoolScoreSystem: this.model.highSchoolMarks?.highSchoolScoreSystem || null,
            highSchoolScore: this.model.highSchoolMarks?.highSchoolScore || null,
            language: this.model.highSchoolMarks?.language || null,
            startDate: this.model.highSchoolMarks?.startDate || null,
            endDate: this.model.highSchoolMarks?.endDate || null,
          },
          undergraduateMarks: {
            country: this.model.undergraduateMarks?.country || null,
            state: this.model.undergraduateMarks?.state || null,
            level: this.model.undergraduateMarks?.level || 'Undergraduate', 
            undergradCollegeName: this.model.undergraduateMarks?.undergradCollegeName || null,
            degreeType: this.model.undergraduateMarks?.degreeType || null,
            city: this.model.undergraduateMarks?.city || null,
            scoreSystem: this.model.undergraduateMarks?.scoreSystem || null,
            score: this.model.undergraduateMarks?.score || null,
            language: this.model.undergraduateMarks?.language || null,
            degreeBacklogs: this.model.undergraduateMarks?.degreeBacklogs || 0,
            startDate: this.model.undergraduateMarks?.startDate || null,
            endDate: this.model.undergraduateMarks?.endDate || null,
          },
          postgraduateMarks: {
            country: this.model.postgraduateMarks?.country || null,
            state: this.model.postgraduateMarks?.state || null,
            level: this.model.postgraduateMarks?.level || 'Postgraduate', 
            postgradCollegeName: this.model.postgraduateMarks?.postgradCollegeName || null,
            degreeType: this.model.postgraduateMarks?.degreeType || null,
            city: this.model.postgraduateMarks?.city || null,
            scoreSystem: this.model.postgraduateMarks?.scoreSystem || null,
            score: this.model.postgraduateMarks?.score || null,
            language: this.model.postgraduateMarks?.language || null,
            degreeBacklogs: this.model.postgraduateMarks?.degreeBacklogs || 0,
            startDate: this.model.postgraduateMarks?.startDate || null,
            endDate: this.model.postgraduateMarks?.endDate || null,
            
          }
        }
      };
  
      this.isLoading = true;
    this.apiHelper.updateDatawithShortlistingUrl('university-students', this.studentDetails._id, obj)
      .subscribe(
        (res: any) => {
          if (res && res.updatedApplicant) {
            this.userDetails = res.updatedApplicant;
            this.studentDetails = res.updatedApplicant;
            this.model = {
              ...res.updatedApplicant.academicQualification,
            };
            
            this.saveChangesEducation = false;
            this.isLoading = false;

            this.cd.detectChanges();
          } else {
            console.error('Update response invalid:', res);
            this.isLoading = false;
          }
        },
        (error) => {
          console.error('Update error:', error);
          this.isLoading = false;
        }
      );

      this.updatedProfileData.emit(true);
      
    }
  }

  removeEmpty(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      } else if (typeof obj[propName] === 'object') {
        this.removeEmpty(obj[propName]);
      }
    }
    return obj;
  }
}