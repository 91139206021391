import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { WishlistService } from '../../wishlist.service';
import { ConnectCounsellorComponent } from '../../connect-counsellor/connect-counsellor.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnChanges {

  @Input() partnerLogo!: string;
  // @Input() wishlistLength!: number;
  @Input() puid!: string;
  @Input() initialFilter: any;

  @ViewChild('connectCounsellor') connectCounsellor!: ConnectCounsellorComponent;

  wishlistLength: number = 0;
  private wishlistCount = new BehaviorSubject<number>(0);
  wishlistCount$ = this.wishlistCount.asObservable();

  constructor(private router: Router, private wishlistService: WishlistService) { }


  ngOnInit(): void {
    console.log('Navbar Initialized with Wishlist Length:', this.wishlistLength);
    console.log('PUID in Navbar:', this.puid);

    this.wishlistService.getWishlist().subscribe((wishlistData) => {
      this.wishlistLength = wishlistData.wishlistLength;
      console.log('Updated Navbar Wishlist Count:', this.wishlistLength);
    });
  
   
    this.wishlistService.wishlistCount$.subscribe(count => {
      this.wishlistLength = count;
      console.log('Navbar wishlist updated:', this.wishlistLength);
    });
  }
  

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['wishlistLength']) {
      console.log('Navbar Wishlist Length Updated:', changes['wishlistLength'].currentValue);
    }
  }

  openConnectModal(): void {
    this.connectCounsellor.openModal();
  }

  viewWishlist(): void {
    if (this.puid) {
      this.router.navigate(['/wishlist', this.puid]);
    } else {
      this.router.navigate(['/wishlist']);
    }
  }

  viewProfile(): void {
    if (this.puid) {
      this.router.navigate(['/personal-details', this.puid]);
    } else {
      this.router.navigate(['/personal-details']);
    }
  }

  onRequestSubmitted(): void {
    console.log('Counsellor request submitted successfully!');
  }
}
