import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { UserInfoPageComponent } from './user-info-page/user-info-page.component';
import { ResultsComponent } from './results/results.component';
import { UniversityDetailComponent } from './university-detail/university-detail.component';
import { WishlistPageComponent } from './wishlist-page/wishlist-page.component';
import { StudentLoginComponent } from './student-login/student-login.component';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { ProfileHelperComponent } from './components/profile-helper/profile-helper.component';
import { AuthGuard } from './guards/auth.guard';
import { StudentProfilePageComponent } from './components/student-profile-page/student-profile-page.component';



const routes: Routes = [
  { path: '', component: LandingPageComponent },


  { path: 'user-info', component: UserInfoPageComponent },
  { path: 'user-info/:puid', component: UserInfoPageComponent },

  { path: 'results', component: ResultsComponent, canActivate: [AuthGuard] },
  { path: 'results/:puid', component: ResultsComponent, canActivate: [AuthGuard] },

  { path: 'university-detail/:course_id', component: UniversityDetailComponent, canActivate: [AuthGuard] },
  { path: 'university-detail/:course_id/:puid', component: UniversityDetailComponent, canActivate: [AuthGuard] },

  { path: 'wishlist', component: WishlistPageComponent },
  { path: 'wishlist/:puid', component: WishlistPageComponent },

  { path: 'student-login', component: StudentLoginComponent },
  { path: 'student-login/:puid', component: StudentLoginComponent },


  { path: 'personal-details', component: PersonalDetailsComponent, canActivate: [AuthGuard] },
  { path: 'personal-details/:puid', component: PersonalDetailsComponent, canActivate: [AuthGuard] },

  { path: 'profile-helper', component: ProfileHelperComponent },
  { path: 'profile-helper/:puid', component: ProfileHelperComponent },

  { path: "student-dashboard", component: StudentProfilePageComponent },
  { path: "student-dashboard/:puid", component: StudentProfilePageComponent },

  { path: ':puid', component: LandingPageComponent },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AdmissionRoutingModule {

  constructor() {
    console.info("loaded ruting")
  }
}

