import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'fa-document-type-dialog',
  templateUrl: './document-type-dialog.component.html',
  styleUrls: ['./document-type-dialog.component.scss']
})
export class DocumentTypeDialogComponent implements OnInit {

  layout: FormlyFieldConfig[] = [];
  model: any = {};

  ngOnInit(): void {
    this.setLayout();
    this.dataPreprocessor()
  }

  constructor(
    public dialogRef: MatDialogRef<DocumentTypeDialogComponent>,
    private modal: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {
      universityName: string;
      category: string;
      userType: string;
      documentType?: string;
      documentDescription: string;
      fileIndex?: number;
    }
  ) {
    console.info(this.data)
  }


  dataPreprocessor(){
    if(this.data?.documentType) {
      this.model.documentName = this.data?.documentType;
      this.model.documentDescription  =this.data?.documentDescription
    }
  }

  setLayout() {
    this.layout = [
      {
        fieldGroupClassName: 'row custom-input',
        fieldGroup: [
          {
            className: 'col-12 mb-2',
            key: 'documentName',
            type: 'input',
            templateOptions: {
              label: 'Document Type',
              placeholder: 'Enter document type',
              required: true,
              disabled: this.data.documentType ? true : false
            }
          },
          {
            className: 'col-12 mb-2',
            key: 'documentDescription',
            type: 'input',
            templateOptions: {
              label: 'Document Description',
              placeholder: 'Enter document description',
              required: true,
              disabled: this.data.documentType ? true : false
            },
          },
          {
            className: 'col-12 mb-2',
            key: 'files',
            type: 'admission-files',
            templateOptions: {
              label: "Upload document",
              placeholder: 'Upload',
              required: true,
              maxFiles: 1
            },
            hideExpression: () => {
              if ((this.data?.category === "uploadedByFA") || (this.data?.category === "additionalRequests" && this.data?.userType !== 'superadmin')) {
                return false;
              }
              return true;
            }
          },
          {
            fieldGroupClassName: '',
            fieldGroup: [
              {
                className: 'd-flex justify-content-center',
                type: 'button',
                templateOptions: {
                  btnType: 'primary',
                  type: 'submit',
                  text: 'Submit Document'
                }
              }
            ]
          }
        ]
      }
    ];
  }

  onSubmit(ev: any) {
    if (ev) {
      this.dialogRef.close(ev);
    }
  }

  closeModal() {
    this.modal.closeAll()
  }
}
