<div class="nav-header">
      <!-- Partner Logo on the left -->
  <div class="logo" *ngIf="partnerLogo">
    <img [src]="partnerLogo" alt="Partner Logo" class="partner-logo">
  </div>

    <div class="nav-actions">
      <button class="apply-btn"  (click)="logout()">
        <img src="assets/icons/Apply.svg" alt="Apply">
      </button>
    </div>
  </div>
  