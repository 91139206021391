<div class="main-container">
  <div class="background-image"></div>
  <div class="content-wrapper">
    <!-- Header Section -->
    <header class="d-flex justify-content-between align-items-center py-3 px-4 bg-white">
      <div class="partner-logo">
        <img *ngIf="partnerLogo" [src]="partnerLogo" alt="Partner Logo" />
      </div>
      <div class="d-flex gap-3">
        <div>
          <button (click)="navigateToLogin()" class="btn btn-outline-primary login-btn">
            Login
          </button>
        </div>
        <div class="find-your-college-desktop">
          <button (click)="navigateToRegister()" class="btn btn-primary find-college-btn">
            Find Your College!
          </button>
        </div>
      </div>
    </header>

    <!-- Main Section - Desktop -->
    <div class="d-flex flex-grow-1 flex-column align-items-center text-center main-container-desktop mt-4">
      <div class="d-flex justify-content-center mt-4">
        <!-- University Image -->
        <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729342617936uni.png"
          alt="University Image" class="mb-2 uni-image me-3" />
        <div class="heading-text">
          Find Your Next
        </div>
        <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729342666288student.png"
          alt="Person Image" class="mb-2 student-image ms-3" />
      </div>
      <div class="heading-text">
        Academic Adventure!
      </div>
      <div class="mb-2 mt-2">
        <p class="sub-title">Explore and compare top universities abroad to find</p>
        <p class="sub-title">the perfect fit for your academic goals.</p>
      </div>
      <!-- Call to Action Button -->
      <button (click)="navigateToRegister()" class="btn btn-primary mt-5 find-college-btn">
        Find Your College!
      </button>
    </div>

    <!-- For Mobile View -->
    <div class="d-flex flex-column align-items-center justify-content-center text-center main-container-mobile">
      <div class="d-flex justify-content-center">
        <div class="mobile-heading">Find Your Next</div>
      </div>

      <div class="d-flex mb-3">
        <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729342617936uni.png"
          alt="University Image" class="mb-2 uni-image-mobile ms-2" />
        <div class="mobile-heading">Academic Adventure!</div>
        <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729342666288student.png"
          alt="Person Image" class="mb-2 student-image-mobile me-2" />
      </div>

      <div class="mt-3 info-below mb-3">
        <p class="mobile-headings">Explore and compare top universities abroad to find the
          perfect fit for your academic goals.</p>
      </div>
      
      <!-- Call to Action Button -->
      <button (click)="navigateToRegister()" class="btn btn-primary find-college-btn">
        Find Your College!
      </button>
    </div>
  </div>
</div>