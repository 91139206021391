<div *ngIf="loading">
    <h5 class="text-primary-dark">Please wait...</h5>
</div>
<div *ngIf="!loading" class="content-height mb-8">
    <div class="d-flex justify-content-end align-items-center">
        <p class="text-muted text-smaller me-5">Max file size 5MB | Supported Formats: PDF, PNG, JPG, JPEG</p>
    </div>

    <div *ngIf="applicationsData">

        <div *ngIf="uploadByFaLoading || additionalRequestsLoading">
            <h6>Please wait...</h6>
        </div>

        <div *ngIf="!uploadByFaLoading && !additionalRequestsLoading">
            <div class="row position-relative bg-white">
                <div class="col-6">
                    <div class="border border-1 rounded-1 p-2 height-custom position-relative">
                        <!-- Show this section when applications exist -->
                        <ng-container *ngIf="applicationsData && applicationsData.length > 0">
                            <div *ngFor="let application of applicationsData">
                                <div *ngFor="let single of application?.wishlist">
                                    <div class="border border-1 rounded-1 mb-2 p-2"
                                        (click)="selectUniversity(single?.universityName)"
                                        [ngClass]="{'selected-university': selectedUniversity === single?.universityName}">
                                        <p class="mb-0 fw-bold">{{single?.courseName}}</p>
                                        <p class="text-smaller text-primary-dark mb-0">{{ single?.universityName }}</p>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        
                        <!-- Show this when no applications exist -->
                        <div *ngIf="!applicationsData || applicationsData.length === 0" class="position-absolute top-50 start-50 translate-middle">
                            <h6 class="text-primary-dark text-center">No Applications Made Yet</h6>
                        </div>
                    </div>
                </div>


                <div *ngIf="selectedCategory === 'uploadedByFA'" class="col-6 position-relative">
                    <div class="border border-1 rounded-1 p-2 height-custom"
                        *ngIf="selectedUniversity && !notSelectedUniversity">
                        <p class="text-muted text-smaller text-center"> You can add upto 30 documents per university</p>
                        <div class="height-custom-document">
                            <ng-container *ngIf="documentData?.uploadedByFaDocs?.[selectedUniversity] && 
                            documentData?.uploadedByFaDocs?.[selectedUniversity] | keyvalue as selectedUniversityDocs; 
                            else noDocsTemplate">
                                <ng-container *ngIf="selectedUniversityDocs?.length > 0; else noDocsTemplate">
                                    <ng-container *ngFor="let docType of selectedUniversityDocs">
                                        <div class="mb-2">
                                            <div class="bg-light rounded roduned-1 p-2">
                                                <p class="fw-semibold mb-1">Document Name: <span
                                                        class="text-primary-dark fw-semibold">{{ docType?.key }}</span>
                                                </p>

                                                <ng-container *ngFor="let fileObj of docType.value; let i = index">
                                                    <p class="fw-semibold mb-1">Document Description: <span class="text-primary-dark fw-semibold">{{ fileObj?.description || 'N/A' }}</span></p>

                                                    <p class="fw-semibold mb-1">Uploaded By: <span
                                                            class="text-primary-dark fw-semibold">{{ fileObj?.uploadedBy
                                                            }}</span></p>
                                                    <div
                                                        class="d-flex justify-content-between align-items-center me-4 bg-white border-1 rounded-1 p-2">
                                                        <div class="d-flex justify-content-start align-items-center">
                                                            <div>
                                                                <ng-container
                                                                    *ngIf="isImage(fileObj?.filePath); else fileTemplate">
                                                                    <div class="img-box-height">
                                                                        <ng-container
                                                                            *ngIf="fileObj?.filePath?.includes('public'); else privateImage">
                                                                            <img *ngIf="fileObj?.filePath"
                                                                                [src]="fileObj?.filePath"
                                                                                class="uploaded-image"
                                                                                alt="Preview Image" />
                                                                        </ng-container>
                                                                        <ng-template #privateImage>
                                                                            <div class="async-image-box">
                                                                                <fa-async-image
                                                                                    [src]="fileObj?.filePath"
                                                                                    [width]="'40'"
                                                                                    [styleClass]="'img img-fluid me-2 set-async-img-height'">
                                                                                </fa-async-image>
                                                                            </div>
                                                                        </ng-template>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-template #fileTemplate>
                                                                    <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1682432634780pdf-1.png"
                                                                        class="uploaded-image-pdf ms-n3"
                                                                        alt="PDF Document" />
                                                                </ng-template>
                                                            </div>

                                                            <div class="row align-items-center mt-3">
                                                                <p [matTooltip]="fileObj?.fileName">
                                                                    {{ fileObj?.fileName | slice:0:20 }}...
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex justify-content-end align-items-center">
                                                            <button (click)="downloadDocumentByS3Uri(fileObj?.filePath)"
                                                                class="btn btn-primary btn-sm me-2">View</button>
                                                            <i (click)="removeFile(selectedUniversity, docType.key)"
                                                                class="bi bi-trash ms-3 text-danger"
                                                                title="Delete File"></i>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>

                            <ng-template #noDocsTemplate>
                                <h6 class="text-primary-dark text-center">No documents available for {{ selectedUniversity }}.
                                </h6>
                            </ng-template>

                        </div>


                        <div class="position-absolute bottom-0 start-50 translate-middle-x">
                            <button class="btn btn-primary rounded-1 mb-1" (click)="addNewDocument()">+ Add Document</button>
                        </div>
                    </div>

                    <div *ngIf="notSelectedUniversity" class="">
                        <div class="position-absolute top-50 start-50 translate-middle">
                            <h6 class="text-primary-dark text-center">Please select atleast one university</h6>
                        </div>

                    </div>
                </div>


                <div *ngIf="selectedCategory === 'additionalRequests'" class="col-6 position-relative">
                    <div class="border border-1 rounded-1 p-2 height-custom" *ngIf="selectedUniversity && !notSelectedUniversity">
                        <p class="text-muted text-smaller text-center"> You can add upto 30 documents per university</p>
                        <div class="height-custom-document">
                            <ng-container *ngIf="documentData?.additionalRequestsDocs?.[selectedUniversity] && documentData?.additionalRequestsDocs?.[selectedUniversity] | keyvalue as selectedUniversityDocs; else noDocsTemplate">
                                    <ng-container *ngFor="let docType of selectedUniversityDocs">
                                        <div class="mb-2">
                                            <div class="bg-light rounded roduned-1 p-2">
                                                <ng-container *ngFor="let fileObj of docType.value; let i = index">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <p class="fw-semibold mb-1">Document Name: <span class="text-primary-dark fw-semibold">{{ docType.key }}</span></p>
                                                            <p class="fw-semibold mb-1">Document Description: <span class="text-primary-dark fw-semibold">{{ fileObj?.description || 'N/A' }}</span></p>
                                                        </div>
                            
                                                        <div class="d-flex justify-content-end align-items-center">
                                                            <div *ngIf="(!fileObj?.filePath) && (userDetails?.type === 'partner' || userDetails?.type === 'partnerUser')">
                                                                <button (click)="addNewDocument(docType.key, i, fileObj?.description)" class="btn btn-outline-primary btn-sm me-2">Upload</button>
                                                            </div>
                            
                                                            <div *ngIf="!fileObj?.filePath">
                                                                <button class="btn btn-outline-gray disabled btn-sm me-2">View</button>
                                                            </div>
                                                        </div>
                                                    </div>
                            
                                                    <ng-container *ngIf="fileObj?.filePath">
                                                        <p class="fw-semibold mb-1">Uploaded By: <span class="text-primary-dark fw-semibold">{{ fileObj?.uploadedBy }}</span> </p>
                                                        <div class="d-flex justify-content-between align-items-center me-4 bg-white border-1 rounded-1 p-2">
                                                            <div class="d-flex justify-content-start align-items-center">
                                                                <div>
                                                                    <ng-container *ngIf="isImage(fileObj?.filePath); else fileTemplate">
                                                                        <div class="img-box-height">
                                                                            <ng-container *ngIf="fileObj?.filePath?.includes('public'); else privateImage">
                                                                                <img *ngIf="fileObj?.filePath" [src]="fileObj?.filePath" class="uploaded-image" alt="Preview Image" />
                                                                            </ng-container>
                                                                            <ng-template #privateImage>
                                                                                <div class="async-image-box">
                                                                                    <fa-async-image [src]="fileObj?.filePath" [width]="'40'" [styleClass]="'img img-fluid me-2 set-async-img-height'">
                                                                                    </fa-async-image>
                                                                                </div>
                                                                            </ng-template>
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-template #fileTemplate>
                                                                        <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1682432634780pdf-1.png"
                                                                            class="uploaded-image-pdf ms-n3"
                                                                            alt="PDF Document" />
                                                                    </ng-template>
                                                                </div>
                            
                                                                <div class="row align-items-center mt-3">
                                                                    <p [matTooltip]="fileObj?.fileName">
                                                                        {{ fileObj?.fileName | slice:0:20 }}...
                                                                    </p>
                                                                </div>
                                                            </div>
                            
                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <button (click)="downloadDocumentByS3Uri(fileObj?.filePath)" class="btn btn-primary btn-sm me-2">View</button>
                                                                <i (click)="removeFile(selectedUniversity, docType.key)" class="bi bi-trash ms-3 text-danger" title="Delete File"></i>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                
                            </ng-container>
                            
                            <!-- No Documents Template -->
                            <ng-template #noDocsTemplate>
                                <h6 class="text-primary-dark text-center">No requested documents found for {{ selectedUniversity }}.</h6>
                            </ng-template>
                            
                        </div>


                        <div *ngIf="userDetails?.type === 'superadmin' "
                            class="position-absolute bottom-0 start-50 translate-middle-x">
                            <button class="btn btn-primary rounded-1 mb-1" (click)="addNewDocument()">+ Request
                                Document</button>
                        </div>
                    </div>

                    <div *ngIf="notSelectedUniversity" class="">
                        <div class="position-absolute top-50 start-50 translate-middle">
                            <h6 class="text-primary-dark text-center">Please select atleast one university</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!applicationsData" class="p-0">
        <aerp-base-schema-form *ngIf="data?.model && layout" [dataProcessor]="dataProcessor.bind(this)"
            [model]="data?.model" [layout]="layout" [dataModel]="dataModel" (onSubmit)="onSubmitResponse($event)">
        </aerp-base-schema-form>
    </div>
</div>