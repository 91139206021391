import { Inject, Injectable } from '@angular/core';
import { ApiHelperService } from './api-helper.service';
import { ConfigService } from './config.service';
import { EnvironmentVars } from '../interfaces/environment-vars';
import { HttpClient } from '@angular/common/http';
declare var Razorpay: any;
@Injectable({
  providedIn: 'root',
})
export class RazorpayService {
  razorpayConfig: any;
  razorpayOptions = {
    handler: function (response) {
      let event = new CustomEvent('payment.success', {
        detail: response,
        bubbles: true,
      });
      window.dispatchEvent(event);
    },
  };
  razorpayVisaOptions = {
    handler: function (response) {
      let event = new CustomEvent('payment.visa.success', {
        detail: response,
        bubbles: true,
      });
      window.dispatchEvent(event);
    },
  };
  razorpayOnetoOneoptions = {
    handler: function (response) {
      let event = new CustomEvent('payment.oneToOne.success', {
        detail: response,
        bubbles: true,
      });
      window.dispatchEvent(event);
    },
  };
  razorpayAdmissionOptions = {
    handler: function (response) {
      let event = new CustomEvent('payment.admission.success', {
        detail: response,
        bubbles: true,
      });
      window.dispatchEvent(event);
    },
  };
  constructor(
    private apiHelper: ApiHelperService,
    private config: ConfigService,

    private http: HttpClient,
    @Inject('environment') private environment: EnvironmentVars
  ) {
    this.razorpayConfig = this.config.getConfig1(
      'foreignAssist',
      'razorpayConfig'
    );
  }
  private shortlistingUrl: string = this.environment.apiBaseUrl;

  openPaymentCheckout(data: any, userDetails: any, paymentFor: string) {
    let extraOptions = {
      order_id: data?.orderDetails?.id,
      amount: data?.orderDetails?.amount,
      currency: data?.orderDetails?.currency,
      key: this.razorpayConfig?.keyId,
      prefill: {
        name: userDetails?.name,
        email: userDetails?.email,
        contact: userDetails?.mobile,
      },
    };
    if (paymentFor === 'Interview') {
      Object.assign(this.razorpayOptions, extraOptions);
      let razor = new Razorpay(this.razorpayOptions);
      razor.open();

      razor.on('payment.failed', function (response) {
        // Todo - store this information in the server
        this.error = response.error.reason;
      });
    }
    if (paymentFor === 'VisaPlan') {
      Object.assign(this.razorpayVisaOptions, extraOptions);
      let razor = new Razorpay(this.razorpayVisaOptions);
      razor.open();
      razor.on('payment.failed', function (response) {
        // Todo - store this information in the server
        this.error = response.error.reason;
      });
    }
    if (paymentFor === 'oneToOne') {
      Object.assign(this.razorpayOnetoOneoptions, extraOptions);
      let razor = new Razorpay(this.razorpayOnetoOneoptions);
      razor.open();
      razor.on('payment.failed', function (response) {
        // Todo - store this information in the server
        this.error = response.error.reason;
      });
    }
    if (paymentFor === 'admission') {
      Object.assign(this.razorpayAdmissionOptions, extraOptions);
      let razor = new Razorpay(this.razorpayAdmissionOptions);
      razor.open();
      razor.on('payment.failed', function (response) {
        // Todo - store this information in the server
        this.error = response.error.reason;
      });
    }
  }
  createOrder(
    dataModel: any,
    orderDetails: any,
    admissionServerPost?: boolean
  ) {
    if (orderDetails && !admissionServerPost) {
      return this.apiHelper.postData(dataModel, orderDetails);
    }
    if (orderDetails && admissionServerPost) {
      return this.http.post(
        `${this.shortlistingUrl}/${dataModel}`,
        orderDetails
      );
    }
  }

  updateOrder(
    dataModel: any,
    orderDetails: any,
    admissionServerPost?: boolean
  ) {
    if (orderDetails && !admissionServerPost) {
      return this.apiHelper.postData(dataModel, orderDetails);
    }
    if (orderDetails && admissionServerPost) {
      return this.http.post(
        `${this.shortlistingUrl}/${dataModel}`,
        orderDetails
      );
    }
  }
}
