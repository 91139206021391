import { Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ApiHelperService } from 'projects/apna-lib/src/lib/services/api-helper.service';
@Component({
  selector: 'app-student-work-experience',
  templateUrl: './student-work-experience.component.html',
  styleUrls: ['./student-work-experience.component.scss']
})
export class StudentWorkExperienceComponent implements OnInit {
  @Input() studentDetails: any;
  @Output() updatedProfileData = new EventEmitter<boolean>();

  public isLoading: boolean = false;
  public dataModel: string = "users";
  public model: any = {};
  layoutWorkExp: FormlyFieldConfig[];
  userDetails: any;
  workExperiences: any[] = [];
  editIndex: number = -1;
  saveChangesWorkExp: boolean = false;
  isAddingNew: boolean = false;

  constructor(
    private apiHelper: ApiHelperService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.userDetails = this.studentDetails;
    this.workExperiences = this.userDetails?.workExperience || [];
    this.dataPreProcessor();
    console.log('work ex Data:', this.studentDetails);
  }

  openEditSection(key: string) {
    if (key === 'saveChangesWorkExp') {
      this.saveChangesWorkExp = true;
      this.isAddingNew = true;
      this.editIndex = -1;
      this.model = {};
      this.setLayout();
    }
  }

  editExperience(index: number) {
    this.editIndex = index;
    this.saveChangesWorkExp = true;
    this.isAddingNew = false;
    this.model = { ...this.workExperiences[index] };
    this.setLayout();
  }

  deleteExperience(index: number) {
    if (confirm('Are you sure you want to delete this work experience?')) {
      this.isLoading = true;
      
      const updatedWorkExperience = [...this.workExperiences];
      updatedWorkExperience.splice(index, 1);
      
      this.workExperiences = updatedWorkExperience;
      this.studentDetails.workExperience = updatedWorkExperience;
      this.cd.detectChanges();
      
      const updatePayload = {
        workExperience: updatedWorkExperience
      };
      
      this.apiHelper.updateDatawithShortlistingUrl(
        'university-students', 
        this.studentDetails._id, 
        updatePayload
      ).subscribe({
        next: (response: any) => {
          if (response.message === 'Applicant updated successfully' && response.updatedApplicant) {
            this.workExperiences = response.updatedApplicant.workExperience;
            this.studentDetails = response.updatedApplicant;
            this.userDetails = response.updatedApplicant;
          } else {
            this.handleDeleteError();
          }
          this.isLoading = false;
          this.cd.detectChanges();
        },
        error: (error) => {
          console.error('Delete error:', error);
          this.handleDeleteError();
          this.isLoading = false;
          this.cd.detectChanges();
        }
      });
    }
  }

  private handleDeleteError() {
    if (this.studentDetails?.workExperience) {
      this.workExperiences = [...this.studentDetails.workExperience];
    }
    alert('Failed to delete work experience. Please try again.');
  }

  

  setLayout() {
    this.layoutWorkExp = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          
          {
            className: 'col-md-6',
            key: 'organizationName',
            type: 'input',
            templateOptions: {
              label: 'Name of the Organization & Address',
              required: true,
              placeholder: 'Enter the name and address'
            }
          },
          {
            className: 'col-md-6',
            key: 'position',
            type: 'input',
            templateOptions: {
              label: 'Position',
              required: true,
              placeholder: 'Enter position'
            }
          },
          {
            className: 'col-md-6',
            key: 'jobProfile',
            type: 'input',
            templateOptions: {
              label: 'Job Profile',
              required: true,
              placeholder: 'Enter job profile'
            }
          },
          {
            className: 'col-md-6',
            key: 'workingFrom',
            type: 'date',
            templateOptions: {
              label: 'Working From',
              required: true,
              placeholder: 'Choose date'
            }
          },
          {
            className: 'col-md-6',
            key: 'workingEnd',
            type: 'date',
            templateOptions: {
              label: 'Working End Date',
              placeholder: 'Choose date'
            },
            hideExpression: 'model.currentlyWorking'
          },
          {
            className: 'col-md-6',
            key: 'modeOfSalary',
            type: 'select',
            templateOptions: {
              label: 'Mode of Salary',
              placeholder: 'Choose',
              options: [
                { label: 'Monthly', value: 'monthly' },
                { label: 'Annually', value: 'annually' }
              ]
            }
          },
          {
            className: 'col-12',
            key: 'currentlyWorking',
            type: 'checkbox',
            templateOptions: {
              label: 'I am currently working here.'
            }
          },
          {
            fieldGroupClassName: 'fixed-bottom-button-container',
            fieldGroup: [
              {
                fieldGroup: [
                  {
                    type: 'button',
                    templateOptions: {
                      btnType: 'primary px-3',
                      type: 'submit',
                      text: 'Save Changes',
                    },
                    hideExpression: () => !this.saveChangesWorkExp,
                  },
                ],
              },
            ],
          },
        ]
      }
    ];
  }

  dataPreProcessor() {
    this.model = {};
    this.setLayout();
  }

  onSubmit(event: any) {
    if (event?.error) {
      console.error('Form submission error:', event.error);
      return;
    }

    const workExperience = {
      organizationName: this.model.organizationName,
      position: this.model.position,
      jobProfile: this.model.jobProfile,
      workingFrom: this.model.workingFrom,
      workingEnd: this.model.workingEnd,
      modeOfSalary: this.model.modeOfSalary,
      currentlyWorking: this.model.currentlyWorking,
    };


    if (this.editIndex > -1) {
      this.workExperiences[this.editIndex] = workExperience;
    } 
    else if (this.isAddingNew) {
      if (!this.workExperiences) {
        this.workExperiences = [];
      }
      this.workExperiences.push(workExperience);
    } else if (this.editIndex > -1) {
      this.workExperiences[this.editIndex] = workExperience;
    }

    const obj = {
      workExperience: this.workExperiences
    };

    this.updateData(obj);
  }

  updateData(obj: any) {
    this.isLoading = true;
    this.apiHelper.updateDatawithShortlistingUrl('university-students', this.studentDetails._id, obj)
      .subscribe(
        (res: any) => {
          if (res && res.updatedApplicant) {
            this.userDetails = res.updatedApplicant;
            this.studentDetails = res.updatedApplicant;
            this.workExperiences = res.updatedApplicant.workExperience || [];
          
            this.saveChangesWorkExp = false;
            this.isAddingNew = false;
            this.editIndex = -1;
            this.model = {};
            this.isLoading = false;
            
            this.cd.detectChanges();
          } else {
            console.error('Update response invalid:', res);
            this.isLoading = false;
          }
        },
        (error) => {
          console.error('Update error:', error);
          this.isLoading = false;
        }
      );
  }

  cancelEdit() {
    this.saveChangesWorkExp = false;
    this.isAddingNew = false;
    this.editIndex = -1;
    this.model = {};
  }
}