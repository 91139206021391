<div class="mb-10">
    <div class="p-1 scrollable-container mb-6">
        <ng-container *ngIf="!isLoading && !saveChangesEducation else showEducationEdit">
            <div class="d-flex justify-content-between align-items-center">
                <h4 class="text-primary-dark"></h4>
                <button (click)="openEditSection('saveChangesEducation')" 
                        class="btn btn-primary d-flex align-items-center gap-1 px-2 py-1 rounded-2">
                    <i class="bi bi-plus-lg fs-5"></i>
                    <span class="fs-5">Add Academic Info</span>
                </button>
            </div>
    
            <!-- Education Summary -->
            <h5>Education Summary</h5>
            <div class="row mb-2">
                <div class="col-md-6">
                    <label class="mb-1">Country of Education*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.countryOfEducation}">
                        {{userDetails?.academicQualification?.countryOfEducation ?? 'Enter Details Here'}}
                    </p>
                </div>
                <div class="col-md-6">
                    <label class="mb-1">Highest Level of Education*</label>
                    <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.highestLevel}">
                        {{userDetails?.academicQualification?.highestLevel ?? 'Enter Details Here'}}
                    </p>
                </div>
            </div>
    
            <hr class="my-4 border border-secondary" style="height: 1px;">
    
            <!-- Postgraduate Details -->
            <ng-container *ngIf="userDetails?.academicQualification?.highestLevel === 'postgraduate'">
                <h5>Postgraduate Details</h5>
                <div class="mb-2">
                    <div class="row mb-3">
                        <div class="col-md-4">
                            <label class="mb-1">Country of Study*</label>
                            <p class="border  rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.postgraduateMarks?.country}">
                                {{userDetails?.academicQualification?.postgraduateMarks?.country ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-4">
                            <label class="mb-1">State of Study*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.postgraduateMarks?.state}">
                                {{userDetails?.academicQualification?.postgraduateMarks?.state ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-4">
                            <label class="mb-1">Level of Study*</label>
                            <p class="border  rounded-1 p-1">Postgraduate</p>
                        </div>
                    </div>
    
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label class="mb-1">Name of University*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.postgraduateMarks?.postgradCollegeName}">
                                {{userDetails?.academicQualification?.postgraduateMarks?.postgradCollegeName ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-6">
                            <label class="mb-1">Qualification Achieved/ Degree Awarded*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.postgraduateMarks?.degreeType}">
                                {{userDetails?.academicQualification?.postgraduateMarks?.degreeType ?? 'Enter Details Here'}}
                            </p>
                        </div>
                    </div>
    
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label class="mb-1">City of Study*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.postgraduateMarks?.city}">
                                {{userDetails?.academicQualification?.postgraduateMarks?.city ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-6">
                            <label class="mb-1">Grading system & Marks scored*</label>
                            <div class="d-flex">
                                <p class="border rounded-1 p-1 me-2" style="width: 40%;" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.postgraduateMarks?.scoreSystem}">
                                    {{userDetails?.academicQualification?.postgraduateMarks?.scoreSystem ?? 'Enter Details Here'}} 
                                </p>
                                <p class="border rounded-1 p-1 me-2" style="width: 60%;" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.postgraduateMarks?.score}">
                                    {{userDetails?.academicQualification?.postgraduateMarks?.score ?? 'Enter Details Here'}}
                                </p>
                                
                            </div>
                        </div>
                    </div>
    
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label class="mb-1">Primary Language of Instruction*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.postgraduateMarks?.language}">
                                {{userDetails?.academicQualification?.postgraduateMarks?.language ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-6">
                            <label class="mb-1">Backlogs*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.postgraduateMarks?.degreeBacklogs}">
                                {{userDetails?.academicQualification?.postgraduateMarks?.degreeBacklogs ?? 'Enter Details Here'}}
                            </p>
                        </div>
                    </div>
    
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label class="mb-1">Start Date*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.postgraduateMarks?.startDate}">
                                {{userDetails?.academicQualification?.postgraduateMarks?.startDate ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-6">
                            <label class="mb-1">End Date*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.postgraduateMarks?.endDate}">
                                {{userDetails?.academicQualification?.postgraduateMarks?.endDate ?? 'Enter Details Here'}}
                            </p>
                        </div>
                    </div>
                </div>
                <hr class="my-4 border border-secondary" style="height: 1px;">
            </ng-container>
    
            <!-- Undergraduate Section -->
            <ng-container *ngIf="['postgraduate', 'undergraduate'].includes(userDetails?.academicQualification?.highestLevel)">
                <h5>Undergraduate Details</h5>
                <div class="mb-2">
                    <div class="row mb-3">
                        <div class="col-md-4">
                            <label class="mb-1">Country of Study*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.undergraduateMarks?.country}">
                                {{userDetails?.academicQualification?.undergraduateMarks?.country ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-4">
                            <label class="mb-1">State of Study*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.undergraduateMarks?.state}">
                                {{userDetails?.academicQualification?.undergraduateMarks?.state ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-4">
                            <label class="mb-1">Level of Study*</label>
                            <p class="border  rounded-1 p-1">Undergraduate</p>
                        </div>
                    </div>
    
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label class="mb-1">Name of University*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.undergraduateMarks?.undergradCollegeName}">
                                {{userDetails?.academicQualification?.undergraduateMarks?.undergradCollegeName ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-6">
                            <label class="mb-1">Qualification Achieved/ Degree Awarded*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.undergraduateMarks?.degreeType}">
                                {{userDetails?.academicQualification?.undergraduateMarks?.degreeType ?? 'Enter Details Here'}}
                            </p>
                        </div>
                    </div>
    
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label class="mb-1">City of Study*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.grade10?.endDate}">
                                {{userDetails?.academicQualification?.grade10?.endDate ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-6">
                            <label class="mb-1">Grading system & Marks scored*</label>
                            <div class="d-flex">
                                <p class="border rounded-1 p-1 me-2" style="width: 40%;" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.undergraduateMarks?.scoreSystem}">
                                    {{userDetails?.academicQualification?.undergraduateMarks?.scoreSystem ?? 'Enter Details Here'}} 
                                </p>
                                <p class="border rounded-1 p-1 me-2" style="width: 60%;" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.undergraduateMarks?.score}">
                                    {{userDetails?.academicQualification?.undergraduateMarks?.score ?? 'Enter Details Here'}}
                                </p>
                            </div>
                        </div>
                    </div>
    
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label class="mb-1">Primary Language of Instruction*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.undergraduateMarks?.language}">
                                {{userDetails?.academicQualification?.undergraduateMarks?.language ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-6">
                            <label class="mb-1">Backlogs*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.undergraduateMarks?.degreeBacklogs}">
                                {{userDetails?.academicQualification?.undergraduateMarks?.degreeBacklogs ?? 'Enter Details Here'}}
                            </p>
                        </div>
                    </div>
    
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label class="mb-1">Start Date*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.undergraduateMarks?.startDate}">
                                {{userDetails?.academicQualification?.undergraduateMarks?.startDate ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-6">
                            <label class="mb-1">End Date*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.undergraduateMarks?.endDate}">
                                {{userDetails?.academicQualification?.undergraduateMarks?.endDate ?? 'Enter Details Here'}}
                            </p>
                        </div>
                    </div>
                </div>
                <hr class="my-4 border border-secondary" style="height: 1px;">
            </ng-container>
    
            <!-- Grade 12th Section -->
            <ng-container *ngIf="['postgraduate', 'undergraduate', 'grade12'].includes(userDetails?.academicQualification?.highestLevel)">
                <h5>Grade 12th or Equivalent</h5>
                <div class="mb-2">
                    <div class="row mb-3">
                        <div class="col-md-4">
                            <label class="mb-1">Country of Study*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.highSchoolMarks?.country}">
                                {{userDetails?.academicQualification?.highSchoolMarks?.country ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-4">
                            <label class="mb-1">State of Study*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.highSchoolMarks?.state}">
                                {{userDetails?.academicQualification?.highSchoolMarks?.state ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-4">
                            <label class="mb-1">Level of Study*</label>
                            <p class="border  rounded-1 p-1">Grade 12th or Equivalent</p>
                        </div>
                    </div>
    
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label class="mb-1">Name of Board*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.highSchoolMarks?.boardName}">
                                {{userDetails?.academicQualification?.highSchoolMarks?.boardName ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-6">
                            <label class="mb-1">Qualification Achieved/ Degree Awarded*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.highSchoolMarks?.degreeType}">
                                {{userDetails?.academicQualification?.highSchoolMarks?.degreeType ?? 'Enter Details Here'}}
                            </p>
                        </div>
                    </div>
    
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label class="mb-1">Name of Institution*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.highSchoolMarks?.highSchoolName}">
                                {{userDetails?.academicQualification?.highSchoolMarks?.highSchoolName ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-6">
                            <label class="mb-1">City of Study*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.highSchoolMarks?.city}">
                                {{userDetails?.academicQualification?.highSchoolMarks?.city ?? 'Enter Details Here'}}
                            </p>
                        </div>
                    </div>
    
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label class="mb-1">Grading system & Marks scored*</label>
                            <div class="d-flex">
                                <p class="border rounded-1 p-1 me-2" style="width: 40%;" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.highSchoolMarks?.highSchoolScoreSystem}">
                                    {{userDetails?.academicQualification?.highSchoolMarks?.highSchoolScoreSystem ?? 'Enter Details Here'}} 
                                </p>
                                <p class="border rounded-1 p-1 me-2" style="width: 60%;" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.highSchoolMarks?.highSchoolScore}">
                                    {{userDetails?.academicQualification?.highSchoolMarks?.highSchoolScore ?? 'Enter Details Here'}}
                                </p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="mb-1">Primary Language of Instruction*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.highSchoolMarks?.language}">
                                {{userDetails?.academicQualification?.highSchoolMarks?.language ?? 'Enter Details Here'}}
                            </p>
                        </div>
                    </div>
    
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label class="mb-1">Start Date*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.highSchoolMarks?.startDate}">
                                {{userDetails?.academicQualification?.highSchoolMarks?.startDate ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-6">
                            <label class="mb-1">End Date*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.highSchoolMarks?.endDate}">
                                {{userDetails?.academicQualification?.highSchoolMarks?.endDate ?? 'Enter Details Here'}}
                            </p>
                        </div>
                    </div>
                </div>
                <hr class="my-4 border border-secondary" style="height: 1px;">
            </ng-container>
    
            <!-- Grade 10th Section -->
            <ng-container *ngIf="userDetails?.academicQualification?.highestLevel">
                <h5>Grade 10th or Equivalent</h5>
                <div>
                    <div class="row mb-3">
                        <div class="col-md-4">
                            <label class="mb-1">Country of Study*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.grade10?.country}">
                                {{userDetails?.academicQualification?.grade10?.country ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-4">
                            <label class="mb-1">State of Study*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.grade10?.state}">
                                {{userDetails?.academicQualification?.grade10?.state ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-4">
                            <label class="mb-1">Level of Study*</label>
                            <p class="border  rounded-1 p-1">Grade 10th or Equivalent</p>
                        </div>
                    </div>
    
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label class="mb-1">Name of Board*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.grade10?.boardName}">
                                {{userDetails?.academicQualification?.grade10?.boardName ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-6">
                            <label class="mb-1">Qualification Achieved/ Degree Awarded*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.grade10?.degreeType}">
                                {{userDetails?.academicQualification?.grade10?.degreeType ?? 'Enter Details Here'}}
                            </p>
                        </div>
                    </div>
    
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label class="mb-1">Name of Institution*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.grade10?.schoolName}">
                                {{userDetails?.academicQualification?.grade10?.schoolName ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-6">
                            <label class="mb-1">City of Study*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.grade10?.city}">
                                {{userDetails?.academicQualification?.grade10?.city ?? 'Enter Details Here'}}
                            </p>
                        </div>
                    </div>
    
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label class="mb-1">Grading system & Marks scored*</label>
                            <div class="d-flex">
                                <p class="border rounded-1 p-1 me-2" style="width: 40%;" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.grade10?.schoolScoreSystem}">
                                    {{userDetails?.academicQualification?.grade10?.schoolScoreSystem ?? 'Enter Details Here'}} 
                                </p>
                                <p class="border rounded-1 p-1 me-2" style="width: 60%;" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.grade10?.schoolScore}">
                                    {{userDetails?.academicQualification?.grade10?.schoolScore ?? 'Enter Details Here'}}
                                </p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="mb-1">Primary Language of Instruction*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.grade10?.language}">
                                {{userDetails?.academicQualification?.grade10?.language ?? 'Enter Details Here'}}
                            </p>
                        </div>
                    </div>
    
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label class="mb-1">Start Date*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.grade10?.startDate}">
                                {{userDetails?.academicQualification?.grade10?.startDate ?? 'Enter Details Here'}}
                            </p>
                        </div>
                        <div class="col-md-6">
                            <label class="mb-1">End Date*</label>
                            <p class="border rounded-1 p-1" [ngClass]="{'placeholder-text': !userDetails?.academicQualification?.grade10?.endDate}">
                                {{userDetails?.academicQualification?.grade10?.endDate ?? 'Enter Details Here'}}
                            </p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    
        <ng-template #showEducationEdit>
            <aerp-base-schema-form 
                *ngIf="model" 
                (onSubmit)="onSubmit($event)"
                [dataProcessor]="dataProcessor.bind(this)" 
                [dataModel]="dataModel"
                [model]="model"
                [layout]="layoutEducation">
            </aerp-base-schema-form>
        </ng-template>
    </div>
    </div>