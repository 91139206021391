<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs profile-page-bar text-dark text-center rounded-2 d-flex justify-content-between">
    
    <li class="me-3" [ngbNavItem]="1">
        <a class="link" ngbNavLink>
            Personal Information <br> <span class="mandatory-text">(Mandatory)</span>
        </a>
        <ng-template ngbNavContent>
            <app-student-personal-information
                *ngIf="admissionData"
                [studentDetails]="admissionData" (updatedProfileData)="getUpdatedProfileData($event)">
            </app-student-personal-information>
        </ng-template>
    </li>
    
    <li class="me-3" [ngbNavItem]="2">
        <a class="link" ngbNavLink>
            Academic Qualification <br> <span class="mandatory-text">(Mandatory)</span> 
        </a>
        <ng-template ngbNavContent>
            <app-student-academic-qualification
                *ngIf="admissionData"
                [studentDetails]="admissionData" (updatedProfileData)="getUpdatedProfileData($event)">
            </app-student-academic-qualification>
        </ng-template>
    </li>

    <li class="me-3" [ngbNavItem]="3">
        <a class="link" ngbNavLink>
            Competetive Test Scores <br><span class="mandatory-text"> (Mandatory)</span>
        </a>
        <ng-template ngbNavContent>
            <app-student-competitive-test-scores
            *ngIf="admissionData"
            [studentDetails]="admissionData" (updatedProfileData)="getUpdatedProfileData($event)">
            </app-student-competitive-test-scores>
        </ng-template>
    </li>

    <li class="me-3" [ngbNavItem]="4">
        <a class="link" ngbNavLink>
            Work Experience <br> <span class="mandatory-text">(Optional)</span>
        </a>
        <ng-template ngbNavContent>
            <app-student-work-experience
            *ngIf="admissionData"
            [studentDetails]="admissionData" (updatedProfileData)="getUpdatedProfileData($event)">
        </app-student-work-experience>
        </ng-template>
    </li>
</ul>

<main class="text-secondary">
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</main>
