import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'projects/foreign-admits/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OpenAIService {
  private baseUrl: string = environment.apiBaseUrl;
  private backendUrl = `${this.baseUrl}/university-courses/generate-job-opportunities`; 
  

  constructor(private http: HttpClient) {}

  getJobDetails(payload: { country: string; course: string; university: string }): Observable<any> {
    return this.http.post<any>(this.backendUrl, payload);
  }

  getCostOfLivingDetails(payload: { city: string; country: string }): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/university-courses/generate-cost-of-living`, payload);
  }
}
