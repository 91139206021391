import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentsConfig } from '../../../document/document.component';
import { UploadDocumentData } from '../../../document/document-components/upload-form/upload-form.component';
import { UserIdentityService } from 'projects/apna-lib/src/lib/services/user-identity.service';
import { ApiHelperService } from 'projects/apna-lib/src/lib/services/api-helper.service';
import { ConfigService } from 'projects/apna-lib/src/lib/services/config.service';
@Component({
  selector: 'app-student-admission-documents',
  templateUrl: './student-admission-documents.component.html',
  styleUrls: ['./student-admission-documents.component.scss']
})
export class StudentAdmissionDocumentsComponent implements OnInit{
  isSingleUpload: boolean = true;

  @Input() studentId: string;
  @Input() applicantId: string;
  @Input() studentApplicantData: any;

  @Output() applicationDocLockData = new EventEmitter<boolean>();
  @Output() documentUpdatedData = new EventEmitter<any>();

  @Input() config: DocumentsConfig = {
    showUploadForm: true,
    showAllDownloadButton: false,
    showHeading: true,
    showBulkUpload: true
  };

  loading: boolean = false;
  documentData: any;
  userDetails: any;
  selectedCategory: any;
  uploadFormData: UploadDocumentData;
  workExperienceExist: boolean = false;
  active: number = 1;
  applicationDocLock: boolean = true;
  requiredGeneralDoc: any = [];

  documentOptionsList: any;

  documentCategoryOptions = [
    { label: 'General Docs', value: 'generalDocs' },

    { label: 'Uploaded by FA', value: 'uploadedByFA' },

    { label: 'Additional Requests', value: 'additionalRequests' }
  ];

  documentOptionsListCopy: any[];

  ngOnInit(): void {
    if (this.studentApplicantData?.workExperience?.length > 0) {
      this.workExperienceExist = true;
    }

    this.setDocumentOptionsList("generalDocs", this.studentApplicantData?.academicQualification?.highestLevel, this.workExperienceExist)
    this.setDocumentOptions("generalDocs");
  }

  constructor(
    private user: UserIdentityService,
    private apiHelper: ApiHelperService,
    private configService: ConfigService,
  ) { }

  setDocumentOptions(category: string) {
    if (category === "generalDocs") {
      this.setDocumentOptionsList("generalDocs", this.studentApplicantData?.academicQualification?.highestLevel);
      let formData = { ...this.uploadFormData };
      this.uploadFormData = undefined;
      this.documentOptionsListCopy = this.documentOptionsList.filter((single) => single?.category?.includes(category));
      if (formData?.model?.category === "generalDocs") {
        formData = Object.assign(formData, { documentOptions: [...this.documentOptionsListCopy] });
      }
      else {
        formData = {
          model: {
            category: "generalDocs"
          },
          documentOptions: [...this.documentOptionsListCopy],
          studentId: this.studentId,
          applicantId: this.applicantId
        };
      };
      setTimeout(() => {
        this.uploadFormData = { ...formData };
      }, 200);
    }

    else if (category === "uploadedByFA") {
      this.setDocumentOptionsList("uploadedByFA")
      let formData = { ...this.uploadFormData };
      this.uploadFormData = undefined;
      this.documentOptionsListCopy = this.documentOptionsList.filter((single) => single?.category?.includes(category));
      if (formData?.model?.category === "uploadedByFA") {
        formData = Object.assign(formData, { documentOptions: [...this.documentOptionsListCopy] });
      }
      else {
        formData = {
          model: {
            category: "uploadedByFA"
          },
          documentOptions: [...this.documentOptionsListCopy],
          studentId: this.studentId,
          applicantId: this.applicantId
        };
      };
      setTimeout(() => {
        this.uploadFormData = { ...formData };
      }, 200);
    }

    else if (category === "additionalRequests") {
      this.setDocumentOptionsList("additionalRequests")
      let formData = { ...this.uploadFormData };
      this.uploadFormData = undefined;
      this.documentOptionsListCopy = this.documentOptionsList.filter((single) => single?.category?.includes(category));
      if (formData?.model?.category === "additionalRequests") {
        formData = Object.assign(formData, { documentOptions: [...this.documentOptionsListCopy] });
      }
      else {
        formData = {
          model: {
            category: "additionalRequests"
          },
          documentOptions: [...this.documentOptionsListCopy],
          studentId: this.studentId,
          applicantId: this.applicantId
        };
      };
      setTimeout(() => {
        this.uploadFormData = { ...formData };
      }, 200);
    }
  }

  changeCategory(category: string) {

    this.selectedCategory = category;

    if (this.applicationDocLock) {
      this.apiHelper.openSnackBar("You need to upload all mandatory documents first.");
      return;
    }

    if (!this.uploadFormData) {
      console.error("uploadFormData is undefined, waiting for it to be initialized...");
      return;
    }

    if (this.uploadFormData.model && this.uploadFormData.model?.hasOwnProperty('category')) {
      this.uploadFormData.model.category = category;
      this.setDocumentOptions(category);
    }
  }

  updateDocumentUpload(documents: any) {
    if (!documents || !documents.generalDocs) {
      return;
    }

    this.documentUpdatedData.emit(documents);

    let categoryCounts: any = {};

    Object.keys(documents).forEach(category => {
      if (typeof documents[category] === 'object' && documents[category] !== null) {
        let count = Object.keys(documents[category]).filter(docType => {
          let doc = documents[category][docType];
          return doc?.filePath?.length > 0;
        }).length;

        categoryCounts[category] = count;
      }
    });

    this.loading = true;

    // Ensure `documents.generalDocs` is defined before filtering
    let missingGeneralDocs = this.requiredGeneralDoc.filter(docType => {
      return !documents.generalDocs?.[docType] ||
        !documents.generalDocs[docType]?.filePath ||
        documents.generalDocs[docType]?.filePath.length === 0;
    });

    if (missingGeneralDocs?.length === 0) {
      this.applicationDocLock = false;
    } else {
      this.applicationDocLock = true;
    }

    this.loading = false;
  }

  setDocumentOptionsList(category: string, degreeType?: string, workExperience?: boolean) {
    if (category === "generalDocs") {
      let allDocuments = [
        {
          "label": "Passport Copy",
          "value": "passportCopy",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 2,
          "required": true,
          "sampleDocumentLink": "https://foreign-admits-s3-bucket.s3.ap-south-1.amazonaws.com/6746c1daeca27f8db23c7d4a/1738321303894Frame%201000002475.png"
        },

        {
          "label": "10th Standard Marksheet and Pass Certificate",
          "value": "tenthMarksheet",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 2,
          "required": true,
          "degreeRequired": "grade10"
        },

        {
          "label": "12th Standard Marksheet and Pass Certificate",
          "value": "twelthMarksheet",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 2,
          "required": true,
          "degreeRequired": "grade12"
        },

        {
          "label": "UG Diploma/ Certificate/Associate Degree Transcript",
          "value": "transcript",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 2,
          "required": true,
          "degreeRequired": "undergraduate"
        },

        {
          "label": "Undergraduate Marksheets",
          "value": "undergraduateMarksheet",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 10,
          "required": true,
          "degreeRequired": "undergraduate"
        },

        {
          "label": "Postgraduate Marksheets",
          "value": "postgraduateMarksheet",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 6,
          "required": true,
          "degreeRequired": "postgraduate"
        },

        {
          "label": "PhD. Degree Marksheets",
          "value": "phdMarksheet",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 2,
          "required": true,
          "degreeRequired": "phd"
        },

        {
          "label": "CV",
          "value": "cv",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 1,
          "required": true,
        },

        {
          "label": "External Credential Evaluation",
          "value": "externalCredentialEvaluation",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 1,
          "required": true,
        },

        {
          "label": "Academic Letter of Recommendation",
          "value": "letterOfRecommendationFromUniversity",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 3,
          "required": true,
        },

        {
          "label": "Professional Letter of Recommendation",
          "value": "letterOfRecommendationFromWorkExp",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 3,
          "required": true,
          "showOnlyIfWorkExperience": true
        },

        {
          "label": "Recommender Details",
          "value": "recommenderDetails",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 6,
          "required": true,
        },

        {
          "label": "Bank Balance Certificate",
          "value": "bankBalanceCertificate",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 4,
          "required": false,
        },

        {
          "label": "Financial Affidavit",
          "value": "financialAffidavit",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 2,
          "required": false,
        },

        {
          "label": "Bonafide Certificate",
          "value": "bonafideCertificate",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 2,
          "required": false,
        },

        {
          "label": "Power of Attorney",
          "value": "powerOfAttorney",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 2,
          "required": false,
        },

        {
          "label": "GRE/GMAT Scorecard/Marksheet/Certificate",
          "value": "gregmatMarksheet",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 2,
          "required": false,
        },

        {
          "label": "English Language Certificate",
          "value": "englishLanguageCertificate",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 2,
          "required": false,
        },

        {
          "label": "English Language Test Waiver Certificate",
          "value": "englishTestWaiverCertificate",
          "category": [
            "generalDocs"
          ],
          "maxFiles": 1,
          "required": false,
          "sampleDocumentLink": "https://foreign-admits-s3-bucket.s3.ap-south-1.amazonaws.com/6746c1daeca27f8db23c7d4a/1738321303894Frame%201000002475.png"
        },

        {
          "label": "Statement of Purpose",
          "value": "statementOfPurpose",
          "category": [
            "generalDocs",
          ],
          "maxFiles": 30,
          "required": true,
          "sampleDocumentLink": "https://foreign-admits-s3-bucket.s3.ap-south-1.amazonaws.com/6746c1daeca27f8db23c7d4a/1738321303894Frame%201000002475.png"
        },
      ];

      this.requiredGeneralDoc = [
        "passportCopy", "cv", "externalCredentialEvaluation",
        "letterOfRecommendationFromUniversity", "recommenderDetails", "statementOfPurpose"
      ];

      if (degreeType === "grade10") {
        this.requiredGeneralDoc.push("tenthMarksheet");
      }
      if (degreeType === "grade12") {
        this.requiredGeneralDoc.push("tenthMarksheet", "twelthMarksheet");
      }
      if (degreeType === "undergraduate") {
        this.requiredGeneralDoc.push("tenthMarksheet", "twelthMarksheet", "transcript", "undergraduateMarksheet");
      }
      if (degreeType === "postgraduate") {
        this.requiredGeneralDoc.push("tenthMarksheet", "twelthMarksheet", "transcript", "undergraduateMarksheet", "postgraduateMarksheet");
      }
      if (degreeType === "phd") {
        this.requiredGeneralDoc.push("tenthMarksheet", "twelthMarksheet", "transcript", "undergraduateMarksheet", "postgraduateMarksheet", "phdMarksheet");
      }

      // Include work experience document if applicable
      if (this.workExperienceExist) {
        this.requiredGeneralDoc.push("letterOfRecommendationFromWorkExp");
      }

      this.documentOptionsList = allDocuments.filter(doc => {
        if (doc.showOnlyIfWorkExperience && !this.workExperienceExist) {
          return false;
        }

        if (!doc.degreeRequired) return true;
        if (degreeType === "grade10") return doc.degreeRequired === "grade10";
        if (degreeType === "grade12") return ["grade10", "grade12"].includes(doc.degreeRequired);
        if (degreeType === "undergraduate") return ["grade10", "grade12", "undergraduate"].includes(doc.degreeRequired);
        if (degreeType === "postgraduate") return ["grade10", "grade12", "undergraduate", "postgraduate"].includes(doc.degreeRequired);
        if (degreeType === "phd") return ["grade10", "grade12", "undergraduate", "postgraduate", "phd"].includes(doc.degreeRequired);
      });

    }

    else if (category === "uploadedByFA") {
      this.documentOptionsList = [
        {
          "label": "Statement of Purpose",
          "value": "statementOfPurpose",
          "category": [
            "uploadedByFA",
          ],
          "maxFiles": 30,
          "required": true,
          "sampleDocumentLink": "https://foreign-admits-s3-bucket.s3.ap-south-1.amazonaws.com/6746c1daeca27f8db23c7d4a/1738321303894Frame%201000002475.png"
        },
      ]
    }

    else if (category === "additionalRequests") {
      this.documentOptionsList = [
        {
          "label": "Additional Request Document",
          "value": "additionalRequestDocument",
          "category": [
            "additionalRequests",
          ],
          "maxFiles": 30,
          "required": true,
          "sampleDocumentLink": "https://foreign-admits-s3-bucket.s3.ap-south-1.amazonaws.com/6746c1daeca27f8db23c7d4a/1738321303894Frame%201000002475.png"
        },
      ]
    }
  }
}
