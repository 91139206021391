import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiHelperService } from 'projects/apna-lib/src/lib/services/api-helper.service';
import { ConfigService } from 'projects/apna-lib/src/lib/services/config.service';
import { UploadDocumentData } from '../../../../document/document-components/upload-form/upload-form.component';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MatDialog } from '@angular/material/dialog';
import { th } from 'date-fns/locale';
import { DocumentTypeDialogComponent } from './document-type-dialog/document-type-dialog.component';
import { UserIdentityService } from 'projects/apna-lib/src/lib/services/user-identity.service';


@Component({
  selector: 'fa-admission-document-upload',
  templateUrl: './admission-document-upload.component.html',
  styleUrls: ['./admission-document-upload.component.scss']
})
export class AdmissionDocumentUploadComponent{

  @Input() data: UploadDocumentData;
  @Output() documentUploaded = new EventEmitter<boolean>();
  @Output() emitDocumentData = new EventEmitter<any>();
  layout: FormlyFieldConfig[];
  loading: boolean = true;
  sampleImageUrl: string;
  applicationsData: any;
  documentSamples = this.configService.getConfig1("foreignAdmits", "documentSampleImages");
  isPartnerLink: boolean = false;
  partnerLogo: string;
  documentData: any;
  categoryData: any;
  dataModel: string = "user-documents";
  uploadedDocs: string[] = [];
  selectedUniversity: string = '';
  uploadByFaLoading: boolean = false;
  additionalRequestsLoading: boolean = false;
  notSelectedUniversity: boolean = true;
  userDetails: any;
  selectedCategory: any;

  constructor(
    private configService: ConfigService,
    private apiHelper: ApiHelperService,
    private spinner: NgxSpinnerService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    public userIdentityService: UserIdentityService
  ) {
    this.userDetails = this.userIdentityService.getUserDetails();
  }

  ngOnInit(): void {
    this.fetchDocumentByApplicantId(this.data?.applicantId);
    if (this.data?.model?.category === "uploadedByFA" || this.data?.model?.category === "additionalRequests") {
      this.selectedCategory = this.data?.model?.category;
      this.getApplicationsData(this.data?.applicantId);
    }
  }


  fetchDocumentByApplicantId(applicantId: any) {
    this.loading = true;
    if (!applicantId) {
      return;
    }

    this.apiHelper.getDataWithShortlistingUrl("documents", { applicantId }).then(
      (response: any) => {
        if (response) {
          this.documentData = response;
          let newModel = this.data?.model;
          this.data.model = undefined;
          this.data.model = newModel


          this.loading = false;
          this.emitDocumentData.emit(this.documentData)
          this.documentUploaded.emit(true);

          if (!this.documentData.uploadedByFaDocs) {
            this.documentData.uploadedByFaDocs = {};
          }


          if (this.documentData?.generalDocs) {
            this.prefillUploadedDocuments();
          }
          if (this.data?.model?.category === "generalDocs") {
            this.setLayout();
          }

        }
      },
      (err: any) => {
        console.error("Error fetching document by applicantId:", err);
        this.loading = false;
        if (this.data?.model?.category === "generalDocs") {
          this.setLayout();
        }
      }
    );
  }

  prefillUploadedDocuments() {

    if (!this.documentData) return;

    ["generalDocs"].forEach(category => {
      if (this.documentData[category]) {
        Object.keys(this.documentData[category]).forEach((docType) => {
          let fileData = this.documentData[category][docType];
          if (fileData?.filePath?.length) {
            this.prefillFormControl(docType, fileData);
          }
        });
      }
    });
  }

  prefillFormControl(docType: string, documentData: any) {
    const prefilledFiles = documentData.filePath.map((filePath: string, index: number) => ({
      filePath: filePath,
      fileName: documentData.fileNames?.[index] || "Unknown File",
      fileSize: documentData.fileSizes?.[index] || 0,
      mimeType: documentData.mimeTypes?.[index] || "unknown"
    }));

    this.data.model[docType] = prefilledFiles;
  }

  dataProcessor(data: any) {
    if (!data?.category) {
      return;
    }

    let transformedData = {
      studentId: this.data?.studentId,
      applicantId: this.data?.applicantId,
      category: data?.category,
      [data?.category]: {}
    };

    Object.keys(data).forEach((key) => {
      if (key !== "category" && data[key] && Array.isArray(data[key]) && data[key].length > 0) {
        transformedData[data.category][key] = {
          filePath: data[key].map(file => file.filePath),
          fileNames: data[key].map(file => file.fileName),
          fileSizes: data[key].map(file => file.fileSize),
          mimeTypes: data[key].map(file => file.mimeType)
        };
      } else {
        transformedData[data.category][key] = { filePath: [], fileNames: [], fileSizes: [], mimeTypes: [] };
      }
    });

    return transformedData;
  }

  onSubmitResponse(ev: any) {
    let formData = ev?.data || {};
    let category = formData?.category;

    let requestData = {
      studentId: this.data?.studentId,
      applicantId: this.data?.applicantId,
      category: category,
      [category]: {}
    };

    Object.keys(formData).forEach((key) => {
      if (key !== "category") {
        if (Array.isArray(formData[key]) && formData[key].length > 0) {
          requestData[category][key] = {
            filePath: formData[key].map(file => file.filePath),
            fileNames: formData[key].map(file => file.fileName),
            fileSizes: formData[key].map(file => file.fileSize),
            mimeTypes: formData[key].map(file => file.mimeType)
          };
        } else {
          requestData[category][key] = { filePath: [], fileNames: [], fileSizes: [], mimeTypes: [] };
        }
      }
    });

    if (Object.keys(requestData[category]).length === 0) {
      this.apiHelper.openSnackBar("Please upload at least one document before saving.");
      return;
    }

    if (this.documentData?._id) {
      this.apiHelper.updateDatawithShortlistingUrl("documents", this.documentData._id, requestData)
        .subscribe(
          (res: any) => {
            this.apiHelper.openSnackBar("Documents updated successfully!");
            this.documentUploaded.emit(true);
          },
          (err: any) => {
            this.apiHelper.openSnackBar("Error updating documents. Please try again.");
          }
        );
    } else {
      this.apiHelper.postDataWithShortlistingUrl('documents', requestData)
        .then((res: any) => {
          this.apiHelper.openSnackBar("Documents uploaded successfully!");
        })
        .catch((err: any) => {
          this.apiHelper.openSnackBar("Error uploading documents. Please try again.");
        });
    }

    setTimeout(() => {
      this.fetchDocumentByApplicantId(this.data?.applicantId);
    }, 300)

  }

  selectUniversity(universityName: string) {
    if (this.data?.model?.category === "uploadedByFA") {
      this.notSelectedUniversity = false;
      this.selectedUniversity = universityName;

      if (!this.documentData.uploadedByFaDocs) {
        this.documentData.uploadedByFaDocs = {};
      }

      if (!this.documentData.uploadedByFaDocs[this.selectedUniversity]) {
        this.documentData.uploadedByFaDocs[this.selectedUniversity] = {};
      }

      this.uploadByFaLoading = true
      setTimeout(() => {
        this.uploadByFaLoading = false;
        this.cd.detectChanges();
      }, 300);
    }
    else if (this.data?.model?.category === "additionalRequests") {
      this.notSelectedUniversity = false;
      this.selectedUniversity = universityName;

      if (!this.documentData.additionalRequestsDocs) {
        this.documentData.additionalRequestsDocs = {};
      }

      if (!this.documentData.additionalRequestsDocs[this.selectedUniversity]) {
        this.documentData.additionalRequestsDocs[this.selectedUniversity] = {};
      }

      this.additionalRequestsLoading = true
      setTimeout(() => {
        this.additionalRequestsLoading = false;
        this.cd.detectChanges();
      }, 300);
    }
  }

  setLayout() {
    const requiredDocs = this.data?.documentOptions.filter(doc => doc.required);
    const optionalDocs = this.data?.documentOptions.filter(doc => !doc.required);

    const isApplicationDocs = this.data?.model?.category === "applicationDocs";

    this.layout = [
      {
        fieldGroupClassName: "row general-doc-height",
        fieldGroup: [
          {
            className: 'col-12',
            wrappers: ['panel'],
            templateOptions: { label: 'Mandatory Documents' },
            fieldGroup: requiredDocs.map(doc => this.getDocumentField(doc))
          },

          ...(!isApplicationDocs ? [{
            className: 'col-12',
            wrappers: ['panel'],
            templateOptions: { label: 'Non-Mandatory Documents' },
            fieldGroup: optionalDocs.map(doc => this.getDocumentField(doc))
          }] : []),
        ]

      },


      {
        fieldGroupClassName: "row save-document-button mb-3",
        fieldGroup: [
          {

            className: "col-12 text-center mt-3",
            type: "button",
            templateOptions: {
              btnType: 'primary rounded rounded-1',
              text: "Save All Documents",
            }
          }
        ]
      }
    ];
  }

  getDocumentField(doc) {
    return {
      className: 'col-12',
      fieldGroupClassName: 'row align-items-center p-2 border rounded custom-document-styling mb-0',
      fieldGroup: [
        {
          className: 'col-12',
          key: `${doc.value}`,
          type: 'admission-files',
          templateOptions: {
            label: `${doc?.label}`,
            hideRequiredMarker: true,
            placeholder: 'Upload',
            attributes: {
              multiple: "multiple",
            },
            maxFiles: doc?.maxFiles ?? 1,
            sampleDocumentLink: doc?.sampleDocumentLink ?? null
          },
          defaultValue: this.data?.model?.[doc.value] || [],
          validation: doc.required ? { messages: { required: 'This document is required!' } } : {},
        }
      ]
    };
  }

  addNewDocument(documentType?: string, fileIndex?: number, description?: string) {
    if (!this.selectedUniversity) {
      this.apiHelper.openSnackBar("Please select a university first!");
      return;
    }

    const dialogRef = this.dialog.open(DocumentTypeDialogComponent, {
      width: "80vw",
      data: {
        universityName: this.selectedUniversity,
        category: this.selectedCategory,
        userType: this.userDetails?.type,
        documentType: documentType,
        documentDescription: description
      }
    });

    dialogRef.afterClosed().subscribe((res: any) => {

      const { documentName, documentDescription, files } = res.data;

      if (this.selectedCategory === "uploadedByFA") {
        if (!res || !res.isValid || !res.data) {
          return;
        }

        if (!documentName || files.length === 0) {
          return;
        }

        if (!this.documentData.uploadedByFaDocs) {
          this.documentData.uploadedByFaDocs = {};
        }

        if (!this.documentData.uploadedByFaDocs[this.selectedUniversity]) {
          this.documentData.uploadedByFaDocs[this.selectedUniversity] = {};
        }

        const totalDocuments = Object.values(this.documentData?.uploadedByFaDocs[this.selectedUniversity])?.reduce((acc, docList: any) => acc + docList.length, 0);

        if (totalDocuments + files?.length > 30) {
          this.apiHelper.openSnackBar("You cannot upload more than 30 documents for this university.");
          return;
        }

        if (!this.documentData.uploadedByFaDocs[this.selectedUniversity][documentName]) {
          this.documentData.uploadedByFaDocs[this.selectedUniversity][documentName] = [];
        }


        files.forEach(file => {
          this.documentData.uploadedByFaDocs[this.selectedUniversity][documentName].push({
            description: documentDescription,
            filePath: file.filePath,
            fileName: file.fileName,
            fileSize: file.fileSize,
            mimeType: file.mimeType,
            uploadedBy: this.userDetails?.name
          });
        });

        this.onSubmitResponseForFaDocs();
      }

      else if (this.selectedCategory === "additionalRequests") {
        if (!documentDescription) {
          this.apiHelper.openSnackBar("Document Description is required.");
          return;
        }

        if (!this.documentData.additionalRequestsDocs) {
          this.documentData.additionalRequestsDocs = {};
        }

        if (!this.documentData.additionalRequestsDocs[this.selectedUniversity]) {
          this.documentData.additionalRequestsDocs[this.selectedUniversity] = {};
        }

        if (!this.documentData.additionalRequestsDocs[this.selectedUniversity][documentName]) {
          this.documentData.additionalRequestsDocs[this.selectedUniversity][documentName] = [];
        }

        if (fileIndex !== undefined) {
          this.documentData.additionalRequestsDocs[this.selectedUniversity][documentName][fileIndex] = {
            description: documentDescription,
            filePath: files[0]?.filePath || "",
            fileName: files[0]?.fileName || "",
            fileSize: files[0]?.fileSize || "",
            mimeType: files[0]?.mimeType || "",
            uploadedBy: this.userDetails?.name
          };
        } else {
          this.documentData.additionalRequestsDocs[this.selectedUniversity][documentName].push({
            description: documentDescription,
            filePath: "",
            fileName: "",
            fileSize: "",
            mimeType: "",
            uploadedBy: this.userDetails?.name
          });
        }


        this.onSubmitResponseForAdditionalRequests();
      }
    });
  }

  onSubmitResponseForFaDocs() {
    if (this.selectedCategory === "uploadedByFA") {
      let requestData = {
        studentId: this.data?.studentId,
        applicantId: this.data?.applicantId,
        category: "uploadedByFA",
        uploadedByFA: { ...this.documentData.uploadedByFaDocs }
      };

      if (!requestData.uploadedByFA[this.selectedUniversity]) {
        requestData.uploadedByFA[this.selectedUniversity] = {};
      }

      Object.keys(this.documentData.uploadedByFaDocs[this.selectedUniversity] || {}).forEach(docType => {
        let existingFiles = this.documentData.uploadedByFaDocs[this.selectedUniversity][docType];


        if (!existingFiles || typeof existingFiles !== "object") {
          existingFiles = { filePath: [], fileNames: [], fileSizes: [], mimeTypes: [] };
        }

        existingFiles.filePath = existingFiles.filePath || [];
        existingFiles.fileNames = existingFiles.fileNames || [];
        existingFiles.fileSizes = existingFiles.fileSizes || [];
        existingFiles.mimeTypes = existingFiles.mimeTypes || [];

        if (existingFiles?.filePath?.length > 0) {
          requestData.uploadedByFA[this.selectedUniversity][docType] = {
            filePath: [...existingFiles.filePath],
            fileNames: [...existingFiles.fileNames],
            fileSizes: [...existingFiles.fileSizes],
            mimeTypes: [...existingFiles.mimeTypes]
          };
        }
      });

      if (this.documentData?._id) {
        this.apiHelper.updateDatawithShortlistingUrl("documents", this.documentData._id, requestData)
          .subscribe(
            (res: any) => {
              this.apiHelper.openSnackBar("Documents updated successfully!");
              this.fetchDocumentByApplicantId(this.data?.applicantId);
            },
            (err: any) => {
              this.apiHelper.openSnackBar("Error updating documents. Please try again.");
            }
          );
      }
      else {
        this.apiHelper.postDataWithShortlistingUrl('documents', requestData)
          .then((res: any) => {
            this.apiHelper.openSnackBar("Documents uploaded successfully!");
            this.fetchDocumentByApplicantId(this.data?.applicantId);
          })
          .catch((err: any) => {
            this.apiHelper.openSnackBar("Error uploading documents. Please try again.");
          });
      }
    }
  }

  onSubmitResponseForAdditionalRequests() {
    const requestData = {
      studentId: this.data?.studentId,
      applicantId: this.data?.applicantId,
      category: "additionalRequests",
      additionalRequestsDocs: this.documentData.additionalRequestsDocs
    };

    if (this.documentData?._id) {
      this.apiHelper.updateDatawithShortlistingUrl("documents", this.documentData._id, requestData)
        .subscribe(
          (res: any) => {
            this.apiHelper.openSnackBar("Additional Request document saved successfully!");
          },
          (err: any) => {
            this.apiHelper.openSnackBar("Error saving additional request document. Please try again.");
          }
        );
    } else {
      this.apiHelper.postDataWithShortlistingUrl('documents', requestData)
        .then((res: any) => {
          this.apiHelper.openSnackBar("Additional Request document created successfully!");
        })
        .catch((err: any) => {
          this.apiHelper.openSnackBar("Error creating additional request document. Please try again.");
        });
    }

    setTimeout(() => {
      this.fetchDocumentByApplicantId(this.data?.applicantId);
    }, 300);
  }

  getDocumentTypes(university: string): string[] {
    return this.documentData?.uploadedByFaDocs[university] ? Object.keys(this.documentData.uploadedByFaDocs[university]) : [];
  }

  getApplicationsData(applicantId: any) {
    const queryParams = { applicantId };
    this.apiHelper.getDataWithShortlistingUrl("university-applications/filter", queryParams).then((res: any) => {
      this.applicationsData = res;
    })
  }

  isImage(filePath: string): boolean {
    if (!filePath) return false;
    const validExtensions = ["jpeg", "jpg", "png", "gif"];
    const ext = filePath.split('.').pop()?.toLowerCase();
    return validExtensions.includes(ext || '');
  }

  removeFile(university: string, docType: string) {

    if (this.selectedCategory === "uploadedByFA") {
      if (!this.documentData?.uploadedByFaDocs?.[university] || !this.documentData?.uploadedByFaDocs?.[university][docType]) {
        return;
      }

      const documentId = this.documentData?._id;
      if (!documentId) {
        return;
      }
      const apiUrl = `documents/${documentId}/uploadedByFaDocs/${encodeURIComponent(university)}/${encodeURIComponent(docType)}`;

      this.apiHelper.deleteDataWithShortlistingUrl(apiUrl)
        .then((response: any) => {
          this.apiHelper.openSnackBar("Files deleted successfully!");

          delete this.documentData.uploadedByFaDocs[university][docType];

          if (Object.keys(this.documentData.uploadedByFaDocs[university]).length === 0) {
            delete this.documentData.uploadedByFaDocs[university];
          }

          this.cd.detectChanges();
        })
        .catch((error) => {
          this.apiHelper.openSnackBar("Error deleting files. Please try again.");
        });
    }

    else if (this.selectedCategory === "additionalRequests") {
      if (!this.documentData?.additionalRequestsDocs?.[university] || !this.documentData?.additionalRequestsDocs?.[university][docType]) {
        return;
      }

      const documentId = this.documentData?._id;
      if (!documentId) {
        return;
      }
      const apiUrl = `documents/${documentId}/additionalRequestsDocs/${encodeURIComponent(university)}/${encodeURIComponent(docType)}`;

      this.apiHelper.deleteDataWithShortlistingUrl(apiUrl)
        .then((response: any) => {
          this.apiHelper.openSnackBar("Files deleted successfully!");

          delete this.documentData.additionalRequestsDocs[university][docType];

          if (Object.keys(this.documentData.additionalRequestsDocs[university]).length === 0) {
            delete this.documentData.additionalRequestsDocs[university];
          }

          this.cd.detectChanges();
        })
        .catch((error) => {
          this.apiHelper.openSnackBar("Error deleting files. Please try again.");
        });
    }

  }

  updateDatabaseAfterFileRemoval(university: string, docType: string) {
    const requestData = {
      studentId: this.data?.studentId,
      applicantId: this.data?.applicantId,
      category: "uploadedByFA",
      uploadedByFA: {
        [university]: {
          [docType]: this.documentData.uploadedByFaDocs[university][docType]  // Only updating the specific docType
        }
      }
    };

    this.apiHelper.updateDatawithShortlistingUrl("documents", this.documentData._id, requestData)
      .subscribe(
        (res: any) => {
          this.apiHelper.openSnackBar("File removed successfully from the database!");
        },
        (err: any) => {
          this.apiHelper.openSnackBar("Error removing file from the database. Please try again.");
        }
      );
  }

  onImageError(event: any, filePath: string): void {
    console.error("Image failed to load:", filePath);
    event.target.src = "https://via.placeholder.com/100?text=Error"; // Replace with a fallback image
  }

  downloadDocumentByS3Uri(uri: string) {
    this.apiHelper.downloadDocumentByS3Uri(uri)
  }

}


