import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiHelperService } from './api-helper.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from '../interceptors/request';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EnvironmentVars } from '../interfaces/environment-vars';
// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { AuthService } from './auth.service';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    MatSnackBarModule,
    NgxSpinnerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true, },
    AuthService,
  ]
})
export class ServicesModule {
  public static forRoot(environment: EnvironmentVars): ModuleWithProviders<any> {

    return {
      ngModule: ServicesModule,
      providers: [
        ApiHelperService,
        {
          provide: 'environment', // you can also use InjectionToken
          useValue: environment
        }
      ]
    };
  }
}
