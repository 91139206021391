import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerService } from '../services/partner.service';
import { FormBuilder } from '@angular/forms';
import { SnackbarService } from '../services/snackbar.service';
import { environment } from 'projects/foreign-admits/src/environments/environment';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-student-login',
  templateUrl: './student-login.component.html',
  styleUrls: ['./student-login.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class StudentLoginComponent implements OnInit {
  model: any = {};
  otpModel: any = {};
  layout: FormlyFieldConfig[];
  otpFields: FormlyFieldConfig[];

  loading = true;
  enterMobileStep = true;
  enterOtpStep = false;
  processingBtn = false;
  timer = 29;

  puid: string = '';
  partnerLogo: string = '';
  partnerConsent: string = '';
  isReady = false;
  otpMobileNumber: string;

  dataModel: string = "users";
  otpDataModel = 'users/login-with-mobile-otp';

  constructor(
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private partnerService: PartnerService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService,
  ) { 
  }

  ngOnInit(): void {
    const hasRefreshed = sessionStorage.getItem('hasRefreshed');
    if (!hasRefreshed) {
      sessionStorage.setItem('hasRefreshed', 'true');
      window.location.reload();
      return;
    }

    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    if (puid) {
      this.puid = puid;
    }

    this.clearLocalStorage();
    this.isReady = true;
    this.setLayout();
    this.setOtpFields();

    setTimeout(() => {
      this.loading = false;
    }, 100);
  }

  setLayout() {
    this.layout = [
      {
        fieldGroupClassName: "row align-items-center custom-input",
        fieldGroup: [
          {
            className: "col-12 text-dark",
            key: "mobile",
            type: "mobile-number",
            templateOptions: {
              required: true,
              label: "Enter your phone number to begin.",
              hideRequiredMarker: false,
            },
            validation: {
              messages: {
                required: 'Mobile number is required',
              },
            }
          },
          {

            className: "text-gray list",
            template: `<small class="list">${this.partnerConsent || 'By providing the mobile number, I agree to override the registry on NCPR/NDNC and authorize us to connect me and send relevant notifications via call, Email, SMS and WhatsApp.'}</small>`
          },

          {
            template: `<small class="text-gray">By continuing you agree to our <span class="text-blue-500">Terms Conditions & Privacy Policy</span></small>`
          }
        ]
      }
    ];
  }

  setOtpFields() {
    this.otpFields = [
      {
        key: 'otp',
        type: 'fa-otp',
        className: "rounded rounded-1 text-dark",
        templateOptions: {
          label: 'Enter the 6-digit OTP sent to your phone.',
          placeholder: 'Enter OTP'
        },
      }
    ];
  }

  clearLocalStorage() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('appliedFilters');
    localStorage.removeItem('initialFilter');
    localStorage.removeItem('loglevel');
    localStorage.removeItem('results');
    localStorage.removeItem('uid');
    localStorage.removeItem('currentFilter');
    localStorage.removeItem('created');
  }

  startTimer() {
    this.timer = 29;
    let interval = setInterval(() => {
      this.timer--;
      if (this.timer === 0) {
        clearInterval(interval);
        this.timer = 29;
      }
    }, 1000);
  }

  showOtp(mobile: string) {
    if (this.otpMobileNumber && this.otpMobileNumber === mobile) {
      return;
    }

    this.otpModel = { mobile };
    this.otpMobileNumber = mobile;
    this.enterMobileStep = false;
    this.enterOtpStep = true;
    this.startTimer();
  }

  async sendOtp(resendOtp?: string) {
    if (resendOtp === 'resendOtp') {
      this.startTimer();
      this.processingBtn = true;
      setTimeout(() => {
        this.processingBtn = false;
      }, 2000);
    }

    try {
      const mobileNumber = resendOtp ? this.otpMobileNumber : this.model.mobile;

      const response = await this.http.post(`${environment.apiBaseUrl}/user/login`, {
        mobile: mobileNumber,
        currentPuid: this.puid
      }).toPromise();

      if (!resendOtp) {
        this.showOtp(mobileNumber);
      }

      this.snackbarService.show('OTP sent successfully!', 'success');
    } catch (error: any) {
      this.snackbarService.show(error.error?.message || 'Unable to send OTP', 'error');
    }
  }

  async onSubmit(event: any) {
    if (!event?.mobile) {
      this.snackbarService.show('Please enter a valid mobile number', 'error');
      return;
    }

    this.processingBtn = true;
    try {
      this.model.mobile = event.mobile;
      await this.sendOtp();
    } catch (error) {
      this.snackbarService.show('Failed to send OTP', 'error');
    } finally {
      this.processingBtn = false;
    }
  }

  submitOtp() {
    if (!this.otpModel.otp) {
      this.snackbarService.show('Please enter OTP', 'error');
      return;
    }

    this.http.post(`${environment.apiBaseUrl}/user/login-with-mobile-otp`, {
      mobile: this.otpMobileNumber,
      otp: this.otpModel.otp,
      currentPuid: this.puid
    }).subscribe(
      (response: any) => {
        localStorage.setItem('access_token', response.id);
        localStorage.setItem('uid', response.userDetails.uid);

        this.snackbarService.show('Login successful!', 'success');

        if (this.puid) {
          this.router.navigate(['/personal-details/' + this.puid]);
        } else {
          this.router.navigate(['/personal-details']);
        }
      },
      (error) => {
        this.otpModel = { mobile: this.otpMobileNumber };
        this.snackbarService.show(error.error?.message || 'Invalid OTP. Please try again.', 'error');
      }
    );
  }

  goToMobileNumber() {
    this.enterOtpStep = false;
    this.enterMobileStep = true;
  }

  goBack() {
    if (this.puid) {
      this.router.navigate(['/' + this.puid]);
    } else {
      this.router.navigate(['/']);
    }
  }

  dataProcessor(data: any) {
    return data;
  }
}