import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiHelperService } from 'projects/apna-lib/src/lib/services/api-helper.service';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { UserIdentityService } from 'projects/apna-lib/src/lib/services/user-identity.service';

@Component({
  selector: 'app-student-content-forms',
  templateUrl: './student-content-forms.component.html',
  styleUrls: ['./student-content-forms.component.scss']
})
export class StudentContentFormsComponent implements OnInit {

  active = 1;
  studentData: any;
  studentId: any;
  applicantData:any;
  applicantId: any;
  // shortlistingUrl: string;
  userDetails: any;
  overallCompletion: number = 0; 
  @ViewChild('nav') navRef: NgbNav;
  puid: any;

  


  ngOnInit(): void {
    this.puid = this.activateRoute.snapshot.paramMap.get('puid');
    this.getStudentId();
  }

  constructor(
    private apiHelper: ApiHelperService,
    private activateRoute: ActivatedRoute,
    private route: Router,
    private userIdentity: UserIdentityService
  ) { 
    this.userIdentity.getBucketAcccesToken()
  }


  private getStudentId() {
    const uid = localStorage.getItem('uid');
    
    if (!uid) {
      console.error('No UID found in localStorage');

      this.route.navigate(['/login']);
      return;
    }

    // Use existing getDataWithShortlistingUrl method to get student ID
    this.apiHelper.getDataWithShortlistingUrl(`user/student-id/${uid}`)
      .then((response: any) => {
        console.log(response,47)
        if (response) {
          this.studentId = response.data.studentId ; 
          this.getStudentAdmissionInfo(this.studentId);
        } else {
          console.error('Failed to get student ID');
          this.studentData = null;
        }
      })
      .catch((error: any) => {
        console.error('Error fetching student ID:', error);
        this.studentData = null;
      });
  }

  getStudentAdmissionInfo(id: any) {
    this.apiHelper.getDataWithShortlistingUrl(`university-students/by-student-id/${id}`)
      .then((res: any) => {
        if (res && Object.keys(res).length > 0) {
          this.studentData = res; 
          this.applicantId = res?._id;
          console.info( this.applicantId, 67)
          this.getWishlistedUniversities()
          setTimeout(() => {
            // this.profileCompletion = this.calculatePersonalProfileCompletion(res);
            // this.academicCompletion = this.calculateAcademicCompletion(res.academicQualification || {});
            // this.testScoreCompletion = this.calculateTestScoreCompletion(res);
            
            // this.calculateOverallCompletion();
          }, 1000);
        } else {
          this.studentData = null;
        }
      })
      .catch((error: any) => {
        console.error('Error fetching applicant data:', error);
        this.studentData = null; 
      });
  }


  checkapplicationDocLockData(ev:any){
  
  }




  getUpdateProfileData(ev:boolean) {
    console.info(ev)
    if(ev){
      this.getStudentAdmissionInfo(this.studentId)
    }
  }

  getWishlistedUniversities(){

    const query = {
      where: JSON.stringify({ _id: this.applicantId })
    };

    this.apiHelper.getDataWithShortlistingUrl("university-students/", query).then((res: any) => {
      this.applicantData = res?.data[0];
    });

  }


  handleTabChange(tabId: number) {

    if (tabId === 5) {
      this.route.navigate(['/results']);
      setTimeout(() => {
        this.active = this.active;
        this.navRef?.select(this.active);
      }, 0);
      return false;
    } else if (tabId === 6) {
      this.route.navigate(['/wishlist']);
      setTimeout(() => {
        this.active = this.active;
        this.navRef?.select(this.active);
      }, 0);
      return false; 
    }
    return true; 
  }

}
