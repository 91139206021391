import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
const routes: Routes = [
  {
    path: "",
    loadChildren: ()=> import('./admission/admission-routing.module').then(m=>m.AdmissionRoutingModule)
  },
  { path: 'f1-gtm', loadChildren: () => import('./admission/admission.module').then(m => m.AdmissionModule) },
  {
    path: '**',
    loadChildren: () =>
    import('./../../../apna-lib/src/lib/components/page404/page404.module').then((m) => m.Page404Module),
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
