import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WishlistService } from '../wishlist.service';
import { OpenAIService } from '../services/openai.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { PartnerService } from '../services/partner.service';
import { BehaviorSubject } from 'rxjs';
import { SnackbarService } from '../services/snackbar.service';
import { environment } from 'projects/foreign-admits/src/environments/environment';

interface JobDetail {
  jobTitle: string;
  salaryPerYear: string;
}

@Component({
  selector: 'app-university-detail',
  templateUrl: './university-detail.component.html',
  styleUrls: ['./university-detail.component.scss']
})
export class UniversityDetailComponent implements OnInit {
  course: any;
  jobDetails: JobDetail[] | null = null;
  jobDetailsSource: string | null = null;
  isLoading = false;
  partnerLogo: string = '';
  puid: string = "";
  wishlistLength: number = 0;
  wishlist: any[] = [];
  config: any[] = [];
  costOfLivingDetails: any = null;
  costOfLivingSource: string | null = null;
  isCostOfLivingLoading = false;
  private baseUrl: string = environment.apiBaseUrl;
  selectedLiving: string = 'budgetLiving';
  activeSection: string = 'entry-requirements';
  sections = ['entry-requirements', 'fees', 'scholarships', 'rankings', 'job-opportunities', 'cost-of-living'];

  private wishlistCount = new BehaviorSubject<number>(0);
  wishlistCount$ = this.wishlistCount.asObservable(); // Expose as observable

  initialFilter: any = {};

  constructor(private router: Router,
    private http: HttpClient,
    private partnerService: PartnerService,
    private activatedRoute: ActivatedRoute,
    private snackbarService: SnackbarService,
    private wishlistService: WishlistService,
    private openAIService: OpenAIService) {
    const navigation = this.router.getCurrentNavigation();
    this.course = navigation?.extras?.state?.['course'];
    // console.log('Course data:', this.course);
  }

  ngOnInit(): void {
    this.updateWishlistLength();
    this.updateActiveSection();
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');

    const courseId = this.activatedRoute.snapshot.paramMap.get('course_id');
    const uid = localStorage.getItem('uid');
    console.log(courseId, 49)

    const savedFilter = localStorage.getItem('initialFilter');
    if (savedFilter) {
      this.initialFilter = JSON.parse(savedFilter);
    }

    // Fallback if no initialFilter found, set default name
    if (!this.initialFilter.firstName) {
      this.initialFilter.firstName = localStorage.getItem('firstName') || 'User'; // Ensure correct name usage
    }




    if (courseId) {
      this.fetchCourseDetails(courseId);
    } else {
      console.error('No course_id provided');
      if (puid) {
        this.router.navigate(['/results', puid]);
      } else {
        this.router.navigate(['/results']);
      }
    }
    if (puid) {
      this.puid = puid;
      this.fetchPartnerLogo(puid);
    }
    console.log(puid, 20)
    setTimeout(() => {
      this.fetchJobDetails();
      this.fetchCostOfLivingDetails();
      if (uid && courseId) {
        console.log('request sen', 75)
        this.checkIfCourseExists(uid, courseId);
      }
    }, 1500);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.updateActiveSection();
  }

  scrollToSection(section: string) {
    const element = document.getElementById(section);
    if (element) {
      const container = document.querySelector('.page-container'); // Scroll inside this
      const headerElement = document.querySelector('.scroll-buttons-desktop');
      const headerOffset = headerElement ? headerElement.clientHeight + 20 : 100;

      const elementPosition = element.getBoundingClientRect().top + container.scrollTop;
      const offsetPosition = elementPosition - headerOffset;

      container.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });

      this.activeSection = section;
    }
  }


  private updateActiveSection() {
    for (const sectionId of this.sections) {
      const element = document.getElementById(sectionId);
      if (element) {
        const rect = element.getBoundingClientRect();
        // Check if the section is in viewport
        if (rect.top >= 0 && rect.top <= window.innerHeight * 0.5) {
          this.activeSection = sectionId;
          break;
        }
      }
    }
  }

  getTotalCost(): number {
    const livingData = this.costOfLivingDetails[this.selectedLiving];
    return livingData ? livingData.total || 0 : 0;
  }


  prevStep() {
    const navigation = this.router.getCurrentNavigation();
    const origin = navigation?.extras?.state?.['origin'];
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');

    if (origin === 'wishlist') {
      if (puid) {
        this.router.navigate(['/wishlist', puid]);
      } else {
        this.router.navigate(['/wishlist']);
      }
    } else {
      if (puid) {
        this.router.navigate(['/results', puid]);
      } else {
        this.router.navigate(['/results']);
      }
    }
  }



  fetchCourseDetails(courseId: string): void {
    this.isLoading = true;

    this.http.get(`${this.baseUrl}/university-courses/course-details/${courseId}`).subscribe(
      (response: any) => {
        if (response.success) {
          const { university, country, loan_offers, courseDetails } = response.data;
          this.course = {
            ...courseDetails,
            university,
            country,
            loan_offers,
          };
          console.log('Fetched course details:', this.course);
        } else {
          console.error('Error retrieving course details:', response.message);
          this.router.navigate(['/results']);
        }
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching course details:', error);
        this.router.navigate(['/results']);
        this.isLoading = false;
      }
    );
  }



  // Function to update wishlist length ------
  updateWishlistLength() {
    const token = localStorage.getItem('access_token');
    const uid = localStorage.getItem('uid');

    if (!uid) {
      console.error('No UID found in local storage');
      throw new Error('No UID found in local storage');
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'uid': uid
    });

    this.wishlistService.getWishlist(headers).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.wishlistLength = response.data.length;
          this.wishlistCount.next(this.wishlistLength);
          console.log('Wishlist Length:', this.wishlistLength);
          this.isLoading = false;
        } else {
          this.wishlistLength = 0;
          this.isLoading = false;
        }
      },
      (error) => {
        console.error('Error fetching wishlist:', error);
        this.wishlistLength = 0;
        this.isLoading = false;
      }
    );
  }

  // Function to update wishlist and wishlist length
  updateWishlist() {
    const token = localStorage.getItem('access_token');
    const uid = localStorage.getItem('uid');

    if (!uid) {
      console.error('No UID found in local storage');
      throw new Error('No UID found in local storage');
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'uid': uid
    });

    this.wishlistService.getWishlist(headers).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.wishlist = response.data;
          this.wishlistLength = this.wishlist.length;
          this.config.forEach(course => {
            course.isInWishlist = this.wishlist.some(wish => wish.courseId === course.course_id);
          });

          console.log('Wishlist Length:', this.wishlistLength);
          this.isLoading = false;
        } else {
          this.wishlistLength = 0;
          this.isLoading = false;
        }
      },
      (error) => {
        console.error('Error fetching wishlist:', error);
        this.wishlistLength = 0;
        this.isLoading = false;
      }
    );
  }

  fetchPartnerLogo(puid: string): void {
    this.partnerService.getPartnerLogo(puid).subscribe(
      (response) => {
        this.partnerLogo = response.data.company.logo;
      },
      (error) => {
        console.error('Error fetching partner logo:', error);
      }
    );
  }

  addToWishlist(course: any, event: MouseEvent) {
    event.stopPropagation();
    this.wishlistService.addToWishlist(course).subscribe(
      (response: any) => {
        console.log('Course added to wishlist:', response);
        course.isInWishlist = true;

        // Trigger wishlist update immediately
        this.updateWishlistCount();
      },
      (error) => {
        console.error('Error adding course to wishlist:', error);
        if (error?.status === 409) {
          this.snackbarService.show('Course already exists in your wishlist.', 'warning');
        } else {
          this.snackbarService.show('Failed to add the course to your wishlist. Please try again.', 'error');
        }
      }
    );
  }


  // Function to update wishlist count in Navbar
  updateWishlistCount() {
    const token = localStorage.getItem('access_token');
    const uid = localStorage.getItem('uid');

    if (!uid) {
      console.error('No UID found in local storage');
      return;
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'uid': uid
    });

    this.wishlistService.getWishlist(headers).subscribe(
      (response: any) => {
        if (response && response.data) {
          const newCount = response.data.length;
          this.wishlistLength = newCount;
          this.wishlistService.updateWishlistCount(newCount);
          console.log('Updated Wishlist Count:', newCount);
        }
      },
      (error) => {
        console.error('Error fetching updated wishlist:', error);
      }
    );
  }




  // Fetch wishlist and mark courses already in it
  markCoursesInWishlist() {
    const token = localStorage.getItem('access_token');
    const uid = localStorage.getItem('uid');

    if (!uid) {
      console.error('No UID found in local storage');
      throw new Error('No UID found in local storage');
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'uid': uid
    });

    this.wishlistService.getWishlist(headers).subscribe(
      (response: any) => {
        const wishlist = response.data;
        this.config.forEach(course => {
          course.isInWishlist = wishlist.some((wish: any) => wish.courseId === course.course_id);
        });
      },
      (error) => {
        console.error('Error fetching wishlist:', error);
      }
    );
  }


  viewWishlist() {

    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    console.log(puid, 67)

    if (puid) {
      this.router.navigate(['/wishlist/' + puid]);
    }
    else {
      this.router.navigate(['/wishlist']);
    }
  }

  fetchJobDetails() {
    this.isLoading = true;

    const payload = {
      country: this.course.country,
      course: this.course.course_name,
      university: this.course.university,
      course_id: this.course.course_id,
    };

    console.log('Payload:', payload); // Debug log
    this.openAIService.getJobDetails(payload).subscribe(
      (response: any) => {
        if (response && response.data) {
          console.log('Response:', response);

          try {
            // Save job details and source separately
            const jobData = response.data; // Use response.data (array)
            this.jobDetails = jobData.filter((item: any) => item.jobTitle);
            const sourceItem = jobData.find((item: any) => item.Source);
            this.jobDetailsSource = sourceItem ? sourceItem.Source : null;

            console.log('Job Details:', this.jobDetails);
            console.log('Source:', this.jobDetailsSource);
          } catch (error) {
            console.error('Error parsing job details:', error);
            this.jobDetails = null;
            this.jobDetailsSource = null;
          }
        }
        this.isLoading = false;
      },
      (error: any) => {
        console.error('Error fetching job details:', error);
        this.jobDetails = null;
        this.jobDetailsSource = null;
        this.isLoading = false;
      }
    );
  }


  fetchCostOfLivingDetails() {
    this.isCostOfLivingLoading = true;

    const payload = {
      city: this.course.campus,
      country: this.course.country,
      course_id: this.course.course_id
    };

    console.log('Payload:', payload);

    this.openAIService.getCostOfLivingDetails(payload).subscribe(
      (response: any) => {
        if (response && response.data) {
          console.log('Response:', response);

          try {
            const costOfLivingData = response.data;
            this.costOfLivingDetails = {
              lavishLiving: costOfLivingData.lavishLiving,
              middleClassLiving: costOfLivingData.middleClassLiving,
              budgetLiving: costOfLivingData.budgetLiving
            };

            // Extract source
            this.costOfLivingSource = costOfLivingData.source || null;

            console.log('Cost of Living Details:', this.costOfLivingDetails);
            console.log('Source:', this.costOfLivingSource);
          } catch (error) {
            console.error('Error parsing cost of living details:', error);
            this.costOfLivingDetails = null;
            this.costOfLivingSource = null;
          }
        }
        this.isCostOfLivingLoading = false;
      },
      (error: any) => {
        console.error('Error fetching cost of living details:', error);
        this.costOfLivingDetails = null;
        this.costOfLivingSource = null;
        this.isCostOfLivingLoading = false;
      }
    );
  }


  checkIfCourseExists(uid: string, courseId: string) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      'uid': uid
    });
    console.log(courseId, 408)
    this.http.get(`${this.baseUrl}/student-choices/does-course-exists`, { headers, params: { courseId: this.course.course_id } }).subscribe(
      (response: any) => {
        if (response.success) {
          this.course.isInWishlist = response.data; // Set isInWishlist to true or false based on the response
          console.log(`Course exists: ${response.data}`);
        } else {
          console.error('Failed to check course existence:', response.message);
        }
      },
      (error) => {
        console.error('Error checking course existence:', error);
      }
    );
  }



}
