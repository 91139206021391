<ng-container *ngIf="data?.typeOfUpdate==='updateApplicationFees'">
    <div class="modal-content">
        <div class="modal-header">
            <h3 class="modal-title d-flex justify-content-center">Update Application Fees </h3>
            <button class="btn-close" matDialogClose></button>
        </div>
        <div class="modal-body">
            <aerp-base-schema-form *ngIf="updateFeesModel && updateFeesLayout" [dataModel]="updateFeesDataModel"
                [model]="updateFeesModel" [layout]="updateFeesLayout"
                (onSubmit)="onSubmitFeesModel($event)"></aerp-base-schema-form>
        </div>
    </div>
</ng-container>