import { Component, Input, OnInit, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ApiHelperService } from 'projects/apna-lib/src/lib/services/api-helper.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-student-personal-information',
  templateUrl: './student-personal-information.component.html',
  styleUrls: ['./student-personal-information.component.scss']
})
export class StudentPersonalInformationComponent implements OnInit {
  @Input() studentDetails: any;
  @Output() updatedProfileData = new EventEmitter<boolean>();

  public isLoading: boolean = false;
  public dataModel: string = 'users';
  public model: any = {};
  layoutPersonalInfo: FormlyFieldConfig[];
  userDetails: any;
  saveChangesPersonal: boolean = false;

  // countryOptions = this.configService.getConfig1('foreignAssist', 'countries');
  // citizenshipOptions = this.configService.getConfig1('foreignAssist','countries');
  // pincodeOptions = this.configService.getConfig1('foreignAssist','countries');
  genderOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Other', value: 'other' },
  ];
  maritalStatusOptions = [
    { label: 'Single', value: 'single' },
    { label: 'Married', value: 'married' },
    { label: 'Divorced', value: 'divorced' },
    { label: 'Widowed', value: 'widowed' },
  ];

  constructor(
    private apiHelper: ApiHelperService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.dataPreProcessor();
    this.userDetails = this.studentDetails;
  }

  openEditSection(key: string) {
    if (key === 'saveChangesPersonal') {
      this.saveChangesPersonal = true;
      this.dataPreProcessor();
    }
  }

  setLayout() {
    this.layoutPersonalInfo = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          //Personal Information
          {
            template: '<h5 class="col-12">Personal Information</h5>',
            className: 'col-12',
          },
          {
            fieldGroupClassName: 'row mt-2',
            fieldGroup: [
              {
                className: 'col-md-4',
                key: 'dateOfBirth',
                type: 'date',
                templateOptions: {
                  label: 'Date of Birth*',

                  placeholder: 'Choose Date',
                },
              },
              {
                className: 'col-md-4',
                key: 'gender',
                type: 'select',
                templateOptions: {
                  label: 'Gender*',

                  options: this.genderOptions,
                  placeholder: 'Select',
                },
              },
              {
                className: 'col-md-4',
                key: 'maritalStatus',
                type: 'select',
                templateOptions: {
                  label: 'Marital Status*',

                  options: this.maritalStatusOptions,
                  placeholder: 'Select',
                },
              },
            ],
          },
          // Mailing Address Section
          {
            template: '<h5 class="col-12 mt-4">Mailing Address</h5>',
            className: 'col-12',
          },
          {
            fieldGroupClassName: 'row mt-2',
            fieldGroup: [
              {
                className: 'col-md-4',
                key: 'mailingAddress.line1',
                type: 'input',
                templateOptions: {
                  label: 'Address Line 1*',

                  placeholder: 'Enter Address',
                },
              },
              {
                className: 'col-md-4',
                key: 'mailingAddress.line2',
                type: 'input',
                templateOptions: {
                  label: 'Address Line 2*',
                  placeholder: 'Enter Address',
                },
              },
              {
                className: 'col-md-4',
                key: 'mailingAddress.country',
                type: 'input',
                templateOptions: {
                  label: 'Country*',

                  // options: this.countryOptions,
                  placeholder: 'Select',
                },
              },
              {
                className: 'col-md-4',
                key: 'mailingAddress.city',
                type: 'input',
                templateOptions: {
                  label: 'City*',

                  placeholder: 'Enter City',
                },
              },
              {
                className: 'col-md-4',
                key: 'mailingAddress.state',
                type: 'input',
                templateOptions: {
                  label: 'State*',

                  placeholder: 'Enter City',
                },
              },
              {
                className: 'col-md-4',
                key: 'mailingAddress.pincode',
                type: 'input',
                templateOptions: {
                  label: 'Pincode*',

                  placeholder: 'Enter Pincode',
                },
              },
            ],
          },
          //Permanent Address
          {
            template: '<h5 class="col-12 mt-4">Permanent Address</h5>',
            className: 'col-12',
          },
          {
            key: 'sameAsMailingAddress',
            type: 'checkbox',
            className: 'align-right',
            templateOptions: {
              label: 'Same as Mailing Address'
            }
          },
          {
            fieldGroupClassName: 'row mt-2',
            fieldGroup: [
              {
                className: 'col-md-4',
                key: 'permanentAddress.line1',
                type: 'input',
                templateOptions: {
                  label: 'Address Line 1*',

                  placeholder: 'Enter Address',
                },
                expressionProperties: {
                  'model.permanentAddress.line1': 'model.sameAsMailingAddress ? model.mailingAddress.line1 : model.permanentAddress.line1',
                  'templateOptions.disabled': 'model.sameAsMailingAddress'
                }
              },
              {
                className: 'col-md-4',
                key: 'permanentAddress.line2',
                type: 'input',
                templateOptions: {
                  label: 'Address Line 2*',

                  placeholder: 'Enter Address',
                },
                expressionProperties: {
                  'model.permanentAddress.line2': 'model.sameAsMailingAddress ? model.mailingAddress.line2 : model.permanentAddress.line2',
                  'templateOptions.disabled': 'model.sameAsMailingAddress'
                }
              },
              {
                className: 'col-md-4',
                key: 'permanentAddress.country',
                type: 'input',
                templateOptions: {
                  label: 'Country*',

                  // options: this.countryOptions,
                  placeholder: 'Select',
                },
                expressionProperties: {
                  'model.permanentAddress.country': 'model.sameAsMailingAddress ? model.mailingAddress.country : model.permanentAddress.country',
                  'templateOptions.disabled': 'model.sameAsMailingAddress'
                }
              },
              {
                className: 'col-md-4',
                key: 'permanentAddress.city',
                type: 'input',
                templateOptions: {
                  label: 'City*',

                  placeholder: 'Enter City',
                },
                expressionProperties: {
                  'model.permanentAddress.city': 'model.sameAsMailingAddress ? model.mailingAddress.city : model.permanentAddress.city',
                  'templateOptions.disabled': 'model.sameAsMailingAddress'
                }
              },
              {
                className: 'col-md-4',
                key: 'permanentAddress.state',
                type: 'input',
                templateOptions: {
                  label: 'State*',

                  placeholder: 'Enter City',
                },
                expressionProperties: {
                  'model.permanentAddress.state': 'model.sameAsMailingAddress ? model.mailingAddress.state : model.permanentAddress.state',
                  'templateOptions.disabled': 'model.sameAsMailingAddress'
                }
              },
              {
                className: 'col-md-4',
                key: 'permanentAddress.pincode',
                type: 'input',
                templateOptions: {
                  label: 'Pincode*',

                  placeholder: 'Enter Pincode',
                },
                expressionProperties: {
                  'model.permanentAddress.pincode': 'model.sameAsMailingAddress ? model.mailingAddress.pincode : model.permanentAddress.pincode',
                  'templateOptions.disabled': 'model.sameAsMailingAddress'
                }
              },
            ],
          },
          // Passport Information Section
          {
            template: '<h5 class="col-12 mt-4">Passport Information</h5>',
          },
          {
            fieldGroupClassName: 'row mt-2',
            fieldGroup: [
              {
                className: 'col-md-4',
                key: 'passport.number',
                type: 'input',
                templateOptions: {
                  label: 'Passport Number*',

                  placeholder: 'Enter Passport Number',
                },
              },
              {
                className: 'col-md-4',
                key: 'passport.issueDate',
                type: 'date',
                templateOptions: {
                  label: 'Issue Date*',

                  placeholder: 'Choose Date',
                },
              },
              {
                className: 'col-md-4',
                key: 'passport.expiryDate',
                type: 'date',
                templateOptions: {
                  label: 'Expiry Date*',

                  placeholder: 'Choose Date',
                },
              },
              {
                className: 'col-md-4',
                key: 'passport.isssueCountry',
                type: 'input',
                templateOptions: {
                  label: 'Issue Country*',

                  placeholder: 'Choose Issue Country',
                },
              },
              {
                className: 'col-md-4',
                key: 'passport.cityOfBirth',
                type: 'input',
                templateOptions: {
                  label: 'City of Birth*',

                  placeholder: 'Choose City of Birth',
                },
              },
              {
                className: 'col-md-4',
                key: 'passport.countryOfBirth',
                type: 'input',
                templateOptions: {
                  label: 'Country of Birth*',

                  placeholder: 'Choose Country of Birth',
                },
              },
            ],
          },
          // Nationality
          {
            template: '<h5 class="col-12 mt-4">Nationality</h5>',
          },
          {
            fieldGroupClassName: 'row mt-2',
            fieldGroup: [
              {
                className: 'col-md-6',
                key: 'nationality.country',
                type: 'input',
                templateOptions: {
                  label: 'Nationality*',

                  placeholder: 'Enter Nationality',
                },
                validation: {
                  messages: {
                    required: 'Nationality is required',
                  },
                },
              },
              {
                className: 'col-md-6',
                key: 'nationality.citizenship',
                type: 'input',
                templateOptions: {
                  label: 'Citizenship*',

                  // options: this.citizenshipOptions,
                  placeholder: 'Select',
                },
                validation: {
                  messages: {
                    required: 'Citizenship is required',
                  },
                },
              },
              //First Question Group
              {
                className: 'col-md-6',
                fieldGroup: [
                  {
                    template: '<p>Does the applicant have multiple citizenship?*</p>',
                  },
                  {
                    className: 'align-items-center',
                    fieldGroup: [
                      {
                        key: 'nationality.hasMultipleCitizenship',
                        type: 'select',
                        className: 'col-md-6',
                        templateOptions: {
                          options: [
                            { label: 'Yes', value: true },
                            { label: 'No', value: false }
                          ],
                          placeholder: 'Select',
                        }
                      },
                      {
                        key: 'nationality.hasMultipleCitizenshipCountry',
                        type: 'input',
                        className: 'col-md-6',
                        templateOptions: {
                          placeholder: 'Enter Country',
                        },
                        hideExpression: 'model.nationality.hasMultipleCitizenship !== true',
                      }
                    ]
                  }
                ]
              },
              
              // Second Question Group
              {
                className: 'col-md-6',
                fieldGroup: [
                  {
                    template: '<p>Is the applicant living & studying in any other country?*</p>',
                  },
                  {
                    className: 'align-items-center',
                    fieldGroup: [
                      {
                        key: 'nationality.isStudyingAbroad',
                        type: 'select',
                        className: 'col-md-6',
                        templateOptions: {
                          options: [
                            { label: 'Yes', value: true },
                            { label: 'No', value: false }
                          ],
                          placeholder: 'Select',
                        }
                      },
                      {
                        key: 'nationality.isStudyingAbroadCountry',
                        type: 'input',
                        className: 'col-md-6',
                        templateOptions: {
                          placeholder: 'Enter Country',
                        },
                        hideExpression: 'model.nationality.isStudyingAbroad !== true',
                      }
                    ]
                  }
                ]
              }
            ],
          },
          // Background Information Section
          {
            template: '<h5 class="col-12 mt-4">Background Information</h5>',
          },
          {
            fieldGroupClassName: 'row gx-4',
            fieldGroup: [
              // Immigration Application Question
              {
                className: 'col-md-6 mb-4',
                fieldGroup: [
                  {
                    template: '<p>Has the applicant applied for immigration to any country?*</p>',
                  },
                  {
                    className: 'align-items-center gap-2',
                    fieldGroup: [
                      {
                        key: 'background.immigrationApplied',
                        type: 'select',
                        className: 'col-md-6',
                        templateOptions: {
                          options: [
                            { label: 'Yes', value: true },
                            { label: 'No', value: false }
                          ],
                          placeholder: 'Select',
                        }
                      },
                      {
                        key: 'background.immigrationAppliedCountry',
                        type: 'input',
                        className: 'col-md-6',
                        templateOptions: {
                          placeholder: 'Enter Country',
                        },
                        hideExpression: 'model.background.immigrationApplied !== true',
                      }
                    ]
                  }
                ]
              },
          
              // Medical Condition Question
              {
                className: 'col-md-6 mb-4',
                fieldGroup: [
                  {
                    template: '<p>Does the applicant suffer from a serious medical condition?*</p>',
                  },
                  {
                    className: 'align-items-center gap-2',
                    fieldGroup: [
                      {
                        key: 'background.hasMedicalCondition',
                        type: 'select',
                        className: 'col-md-6',
                        templateOptions: {
                          options: [
                            { label: 'Yes', value: true },
                            { label: 'No', value: false }
                          ],
                          placeholder: 'Select',
                        }
                      },
                      {
                        key: 'background.medicalDetails',
                        type: 'input',
                        className: 'col-md-6',
                        templateOptions: {
                          placeholder: 'Specify here',
                        },
                        hideExpression: 'model.background.hasMedicalCondition !== true',
                      }
                    ]
                  }
                ]
              },
          
              // Visa Refusal Question
              {
                className: 'col-md-6 mb-4',
                fieldGroup: [
                  {
                    template: '<p>Has the applicant ever had a VISA refusal for any country?*</p>',
                  },
                  {
                    className: 'flex-column gap-2',
                    fieldGroup: [
                      {
                        className: 'align-items-center',
                        fieldGroup: [
                          {
                            key: 'background.visaRefused',
                            type: 'select',
                            className: 'col-md-6',
                            templateOptions: {
                              options: [
                                { label: 'No', value: false },
                                { label: 'Yes', value: true }
                                
                              ],
                              placeholder: 'Select',
                            }
                          },
                          {
                            key: 'background.visaRefusalCountry',
                            type: 'input',
                            className: 'col-md-6',
                            templateOptions: {
                              placeholder: 'Enter Country',
                            },
                            hideExpression: 'model.background.visaRefused !== true',
                          }
                        ]
                      },
                      {
                        key: 'background.visaRefusalType',
                        type: 'input',
                        className: 'col-md-6',
                        templateOptions: {
                          placeholder: 'Visa Type',
                        },
                        hideExpression: 'model.background.visaRefused !== true',
                      }
                    ]
                  }
                ]
              },
          
              // Criminal Record Question
              {
                className: 'col-md-6 mb-4',
                fieldGroup: [
                  {
                    template: '<p>Has the applicant ever been convicted of a criminal offence?*</p>',
                  },
                  {
                    className: 'align-items-center gap-2',
                    fieldGroup: [
                      {
                        key: 'background.hasCriminalRecord',
                        type: 'select',
                        className: 'col-md-6',
                        templateOptions: {
                          options: [
                            { label: 'Yes', value: true },
                            { label: 'No', value: false }
                          ],
                          placeholder: 'Select',
                        }
                      },
                      {
                        key: 'background.criminalRecordDetails',
                        type: 'input',
                        className: 'col-md-6',
                        templateOptions: {
                          placeholder: 'Specify here',
                        },
                        hideExpression: 'model.background.hasCriminalRecord !== true',
                      }
                    ]
                  }
                ]
              }
            ]
          },

          // Emergency Contact Section
          {
            template: '<h5 class="col-12 mt-4">Emergency Contact</h5>',
          },
          {
            fieldGroupClassName: 'row mt-2',
            fieldGroup: [
              {
                className: 'col-md-6',
                key: 'emergency.name',
                type: 'input',
                templateOptions: {
                  label: 'Name*',

                  placeholder: 'Enter name',
                },
              },
              {
                className: 'col-md-6',
                key: 'emergency.phone',
                type: 'mobile-number',
                templateOptions: {
                  label: 'Phone*',
                  required: false,
                  placeholder: 'Enter phone number',
                },
              },
              {
                className: 'col-md-6',
                key: 'emergency.email',
                type: 'email',
                templateOptions: {
                  label: 'Email*',

                  placeholder: 'Enter Email',
                },
              },
              {
                className: 'col-md-6',
                key: 'emergency.relationshipWithApplicant',
                type: 'input',
                templateOptions: {
                  label: 'Relationship with Applicant*',

                  placeholder: 'Enter Relationship',
                },
              },
            ],
          },
          {
            fieldGroupClassName:'fixed-bottom-button-container',
            fieldGroup: [
              {
                fieldGroup: [
                  {
                    type: 'button',
                    templateOptions: {
                      btnType: 'primary px-5 ',
                      type: 'submit',
                      text: 'Save Changes',
                    },
                    hideExpression: () => !this.saveChangesPersonal,
                  },
                ],
              },
            ],
          },
        ],
      },
    ];
  }

  dataPreProcessor() {
    // Start with a complete model structure with all required nested objects
    const defaultModel = {
      dateOfBirth: null,
      gender: null,
      maritalStatus: null,
      sameAsMailingAddress: false,
      mailingAddress: {
        line1: null,
        line2: null,
        country: null,
        city: null,
        state: null,
        pincode: null
      },
      permanentAddress: {
        line1: null,
        line2: null,
        country: null,
        city: null,
        state: null,
        pincode: null
      },
      passport: {
        number: null,
        issueDate: null,
        expiryDate: null,
        isssueCountry: null,
        cityOfBirth: null,
        countryOfBirth: null
      },
      nationality: {
        country: null,
        citizenship: null,
        hasMultipleCitizenship: null,
        hasMultipleCitizenshipCountry: null,
        isStudyingAbroad: null,
        isStudyingAbroadCountry: null
      },
      background: {
        immigrationApplied: null,
        immigrationAppliedCountry: null,
        hasMedicalCondition: null,
        medicalDetails: null,
        visaRefused: null,
        visaRefusalCountry: null,
        visaRefusalType: null,
        hasCriminalRecord: null,
        criminalRecordDetails: null
      },
      // In your defaultModel object
      emergency: {
        name: null,
        phone: "+1", // Initialize with empty string instead of null
        email: null,
        relationshipWithApplicant: null
      }
    };
  
    // Merge existing data from userDetails if available
    if (this.userDetails?.personalInformation) {
      // Merge existing data with the default structure
      const merged = {...defaultModel};
      
      // Manually merge each section to ensure correct nesting
      Object.assign(merged, this.userDetails.personalInformation);
      
      // Handle nested objects carefully
      if (this.userDetails.personalInformation.mailingAddress) {
        merged.mailingAddress = {...defaultModel.mailingAddress, ...this.userDetails.personalInformation.mailingAddress};
      }
      
      if (this.userDetails.personalInformation.permanentAddress) {
        merged.permanentAddress = {...defaultModel.permanentAddress, ...this.userDetails.personalInformation.permanentAddress};
      }
      
      if (this.userDetails.personalInformation.passport) {
        merged.passport = {...defaultModel.passport, ...this.userDetails.personalInformation.passport};
      }
      
      if (this.userDetails.personalInformation.nationality) {
        merged.nationality = {...defaultModel.nationality, ...this.userDetails.personalInformation.nationality};
      }
      
      if (this.userDetails.personalInformation.background) {
        merged.background = {...defaultModel.background, ...this.userDetails.personalInformation.background};
      }
      
      if (this.userDetails.personalInformation.emergency) {
        merged.emergency = {...defaultModel.emergency, ...this.userDetails.personalInformation.emergency};
      }
      
      this.model = merged;
    } else {
      this.model = defaultModel;
    }
    
    this.setLayout();
  }

  dataProcessor(data) {
    return data;
  }

  onSubmit(event: any) {
    if (event?.error) {
      console.error('Form submission error:', event.error);
    } else {
      const { sameAsMailingAddress, ...modelWithoutFlag } = this.model;
      let obj = {
        personalInformation: {
          email: this.model.email || null,
          firstName: this.model.firstName || null,
          lastName: this.model.lastName || null,
          phoneNumber:this.model.phoneNumber || null,
          dateOfBirth: this.model.dateOfBirth || null,
          gender: this.model.gender || null,
          maritalStatus: this.model.maritalStatus || null,
          mailingAddress: {
            line1: this.model.mailingAddress?.line1 || null,
            line2: this.model.mailingAddress?.line2 || null,
            country: this.model.mailingAddress?.country || null,
            city: this.model.mailingAddress?.city || null,
            state: this.model.mailingAddress?.state || null,
            pincode: this.model.mailingAddress?.pincode || null,
          },
          permanentAddress: {
            line1: this.model.permanentAddress?.line1 || null,
            line2: this.model.permanentAddress?.line2 || null,
            country: this.model.permanentAddress?.country || null,
            city: this.model.permanentAddress?.city || null,
            state: this.model.permanentAddress?.state || null,
            pincode: this.model.permanentAddress?.pincode || null,
          },
          passport: {
            number: this.model.passport?.number || null,
            issueDate: this.model.passport?.issueDate || null,
            expiryDate: this.model.passport?.expiryDate || null,
            isssueCountry: this.model.passport?.isssueCountry || null,
            cityOfBirth: this.model.passport?.cityOfBirth || null,
            countryOfBirth: this.model.passport?.countryOfBirth || null,
          },
          nationality: {
            country: this.model.nationality?.country || null,
            citizenship: this.model.nationality?.citizenship || null,
            hasMultipleCitizenship: this.model.nationality?.hasMultipleCitizenship === undefined || 
              this.model.nationality?.hasMultipleCitizenship === null ? 
              null : this.model.nationality?.hasMultipleCitizenship,
            hasMultipleCitizenshipCountry: this.model.nationality?.hasMultipleCitizenshipCountry || null,
            isStudyingAbroad: this.model.nationality?.isStudyingAbroad === undefined || 
              this.model.nationality?.isStudyingAbroad === null ? 
              null : this.model.nationality?.isStudyingAbroad,
            isStudyingAbroadCountry: this.model.nationality?.isStudyingAbroadCountry || null,
          },
          background: {
            immigrationApplied: this.model.background?.immigrationApplied === undefined || 
              this.model.background?.immigrationApplied === null ? 
              null : this.model.background?.immigrationApplied,
            immigrationAppliedCountry: this.model.background?.immigrationAppliedCountry || null,
            hasMedicalCondition: this.model.background?.hasMedicalCondition === undefined || 
              this.model.background?.hasMedicalCondition === null ? 
              null : this.model.background?.hasMedicalCondition,
            medicalDetails: this.model.background?.medicalDetails || null,
            visaRefused: this.model.background?.visaRefused === undefined || 
              this.model.background?.visaRefused === null ? 
              null : this.model.background?.visaRefused,
            visaRefusalCountry: this.model.background?.visaRefusalCountry || null,
            visaRefusalType: this.model.background?.visaRefusalType || null,
            hasCriminalRecord: this.model.background?.hasCriminalRecord === undefined || 
              this.model.background?.hasCriminalRecord === null ? 
              null : this.model.background?.hasCriminalRecord,
            criminalRecordDetails: this.model.background?.criminalRecordDetails || null,
          },
          emergency: {
            name: this.model.emergency?.name || null,
            phone: (!this.model.emergency?.phone || this.model.emergency?.phone === "+1") 
            ? null 
            : this.model.emergency?.phone,
            email: this.model.emergency?.email || null,
            relationshipWithApplicant: this.model.emergency?.relationshipWithApplicant || null,
          },
        },
      };

      this.isLoading = true;
      this.apiHelper.updateDatawithShortlistingUrl('university-students', this.studentDetails._id, obj)
      .subscribe(
        (res: any) => {
          if (res && res.updatedApplicant) {
            this.userDetails = res.updatedApplicant;
            this.studentDetails = res.updatedApplicant;
            this.model = {
              ...res.updatedApplicant.personalInformation,
              sameAsMailingAddress: false,
            };
            
            this.saveChangesPersonal = false;
            this.isLoading = false;
            this.cd.detectChanges();
          } else {
            console.error('Update response invalid:', res);
            this.isLoading = false;
          }
        },
        (error) => {
          console.error('Update error:', error);
          this.isLoading = false;
        }
      );

      this.updatedProfileData.emit(true);
    }
  }

  removeEmpty(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      } else if (typeof obj[propName] === 'object') {
        this.removeEmpty(obj[propName]);
      }
    }
    return obj;
  }
}
